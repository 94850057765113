export const CHANGE_IS_LOGGED = 'CHANGE_IS_LOGGED'
export const CHANGE_VALUE = 'CHANGE_VALUE'
export const CHANGE_ACCOUNT_VALUE = 'CHANGE_ACCOUNT_VALUE'
export const CHANGE_ACCOUNT_STATUT = 'CHANGE_ACCOUNT_STATUT'
export const FETCH_USER = 'FETCH_USER'
export const SAVE_USER = 'SAVE_USER'
export const FETCH_USER_ROLE = 'FETCH_USER_ROLE'
export const SAVE_USER_ROLE = 'SAVE_USER_ROLE'
export const FETCH_ACCOUNT_FORMATIONS = 'FETCH_ACCOUNT_FORMATIONS'
export const SAVE_ACCOUNT_FORMATIONS = 'SAVE_ACCOUNT_FORMATIONS'
export const FETCH_PUT_ACCOUNT_P = 'FETCH_PUT_ACCOUNT_P'
export const FETCH_PUT_ACCOUNT_E = 'FETCH_PUT_ACCOUNT_E'
export const FETCH_PARTICULIERS = 'FETCH_PARTICULIERS'
export const SAVE_PARTICULIERS = 'SAVE_PARTICULIERS'
export const ADD_PARTICULER_ON_ENTREPRISE = 'ADD_PARTICULER_ON_ENTREPRISE'
export const FETCH_POST_USER_PARTICULIER = 'FETCH_POST_USER_PARTICULIER'
export const FETCH_POST_PARTICULIER = 'FETCH_POST_PARTICULIER'
export const FETCH_POST_USER_ENTREPRISE = 'FETCH_POST_USER_ENTREPRISE'
export const FETCH_POST_ENTREPRISE = 'FETCH_POST_ENTREPRISE'
export const FETCH_SIREN = 'FETCH_SIREN'
export const SAVE_SIREN_DATA = 'SAVE_SIREN_DATA'
export const FETCH_ACCOUNT_INFO = 'FETCH_ACCOUNT_INFO'
export const FETCH_LOST_PASSWORD = 'FETCH_LOST_PASSWORD'
export const FETCH_POST_NEW_PASSWORD = 'FETCH_POST_NEW_PASSWORD'
export const SAVE_CODE_RESET_PASSWORD = 'SAVE_CODE_RESET_PASSWORD'
export const DISCONNECT_USER = 'DISCONNECT_USER'
export const SET_LOAD_CONNEXION = 'SET_LOAD_CONNEXION'
export const REDIRECT_TO_ACCOUNT = 'REDIRECT_TO_ACCOUNT'
export const SET_FULL_PAGE_LOADER_PUTACCOUNT = 'SET_FULL_PAGE_LOADER_PUTACCOUNT'
export const FETCH_SCORE_RECRUTEMENT_PARTICULIER = 'FETCH_SCORE_RECRUTEMENT_PARTICULIER'
export const SET_TIME_RECRUTEMENT = 'SET_TIME_RECRUTEMENT'
export const FETCH_POST_SCORE_RECRUTEMENT = 'FETCH_POST_SCORE_RECRUTEMENT'
export const SAVE_SCORE_RECRUTEMENT = 'SAVE_SCORE_RECRUTEMENT'
export const FETCH_GET_SCORE_RECRUTEMENT = 'FETCH_GET_SCORE_RECRUTEMENT'
export const SAVE_SCORE_RECRUTEMENT_OBJECT = 'SAVE_SCORE_RECRUTEMENT_OBJECT'
export const SET_FULL_PAGE_LOADER_RECRUTEMENT_OBJ = 'SET_FULL_PAGE_LOADER_RECRUTEMENT_OBJ'


export const changeIsLogged = (bool) => ({
    type: CHANGE_IS_LOGGED,
    bool
})

export const changeValue = (value, name) => ({
    type: CHANGE_VALUE,
    value,
    name,
  })

export const changeAccountValue = (value, name) => ({
  type: CHANGE_ACCOUNT_VALUE,
  value,
  name,
})

export const changeAccountStatut = (statut) => ({
  type: CHANGE_ACCOUNT_STATUT,
  statut
})

export const fetchUser = () => ({
  type: FETCH_USER,
})

export const saveUser = (data) => ({
  type: SAVE_USER,
  data
})

export const fetchUserRole = (id) => ({
  type: FETCH_USER_ROLE,
  id
})

export const saveUserRole = (data, role) => ({
  type: SAVE_USER_ROLE,
  data,
  role
})

export const fetchAccountFormations = () => ({
  type: FETCH_ACCOUNT_FORMATIONS,
})

export const saveAccountFormations = (data) => ({
  type: SAVE_ACCOUNT_FORMATIONS,
  data
})

export const fetchPutAccountP = () => ({
  type: FETCH_PUT_ACCOUNT_P
})

export const fetchPutAccountE = () => ({
  type: FETCH_PUT_ACCOUNT_E
})

export const saveParticuliers = (data) => ({
  type: SAVE_PARTICULIERS,
  data
})

export const fetchParticuliers = () => ({
  type: FETCH_PARTICULIERS
})

export const addParticulierOnEntreprise = (id) => ({
  type: ADD_PARTICULER_ON_ENTREPRISE,
  id
})

export const fetchPostUserParticulier = () => ({
  type: FETCH_POST_USER_PARTICULIER
})

export const fetchPostParticulier = (userId) => ({
  type: FETCH_POST_PARTICULIER,
  userId
})

export const fetchPostUserEntreprise = () => ({
  type: FETCH_POST_USER_ENTREPRISE
})

export const fetchPostEntreprise = (userId) => ({
  type: FETCH_POST_ENTREPRISE,
  userId
})

export const fetchSiren = (siren) => ({
  type: FETCH_SIREN,
  siren
})

export const saveSirenData = (data) => ({
  type: SAVE_SIREN_DATA,
  data
})

export const fetchAccountInfo = () => ({
  type: FETCH_ACCOUNT_INFO,
})

export const fetchLostPassword = () => ({
  type: FETCH_LOST_PASSWORD,
})

export const fetchPostNewPassword = () => ({
  type: FETCH_POST_NEW_PASSWORD,
})

export const saveCodeResetPassword = (code) => ({
  type: SAVE_CODE_RESET_PASSWORD,
  code
})

export const disconnectUser = () => ({
  type: DISCONNECT_USER,
})

export const setLoadConnexion = (bool) => ({
  type: SET_LOAD_CONNEXION,
  bool
})

export const redirectToAccount = (bool) => ({
  type: REDIRECT_TO_ACCOUNT,
  bool
})

export const setFullPageLoaderPutAccount = (bool) => ({
  type: SET_FULL_PAGE_LOADER_PUTACCOUNT,
  bool
})

export const fetchScoreRecrutementParticulier = (score, time) => ({
  type: FETCH_SCORE_RECRUTEMENT_PARTICULIER,
  score,
  time
})

export const setTimeRecrutement = (time) => ({
  type: SET_TIME_RECRUTEMENT,
  time
})

export const fetchPostScoreRecrutement = () => ({
  type: FETCH_POST_SCORE_RECRUTEMENT
})

export const saveScoreRecrutement = (id) => ({
  type: SAVE_SCORE_RECRUTEMENT,
  id
})

export const fetchGetScoreRecrutement = () => ({
  type: FETCH_GET_SCORE_RECRUTEMENT
})

export const saveScoreRecrutementObject = (data) => ({
  type: SAVE_SCORE_RECRUTEMENT_OBJECT,
  data
})

export const setFullPageLoaderRecrutementObj = (bool) => ({
  type: SET_FULL_PAGE_LOADER_RECRUTEMENT_OBJ,
  bool
})