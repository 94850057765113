/**** Import des modules ****/
import React, {useEffect } from 'react'
import AOS from 'aos';
import "aos/dist/aos.css"

import { TiLightbulb } from "react-icons/ti";
import { FiTarget } from "react-icons/fi";
import { IoSchoolOutline } from "react-icons/io5";
import { FaUsers, FaHandshake } from "react-icons/fa";
import { BsGraphUp } from "react-icons/bs";

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'

/**** import des images ****/
import logo from '../../assets/img/illustration/qui_sommes_nous.png'
import teamWork from '../../assets/img/illustration/team-work.png'
import orga from '../../assets/img/illustration/organigramebis.png'
import testimg from '../../assets/img/illustration/DEMANDEUR-EMPLOI-02.png'
import logoright from '../../assets/img/illustration/cwok_casual.png'

/**** Import des css ****/
import WeforStyled from './WeforStyled'
import { changeAccountStatut } from '../../actions/login'

const Wefor = ({}) => {
  useEffect(()=> {
    AOS.init({ duration: 1000 })
  }, [])

  const serviceArray = [
    {
      id: 1,
      titre: 'Leur concept ?',
      image: 'TiLightbulb',
      text:'Une formation ET un emploi. Rien que ça !'
    },
    {
      id: 2,
      titre: 'Leur objectif ?',
      image: 'FiTarget',
      text:'Se mettre au service de l’employabilité et de l’insertion professionnelle.'
    },
    {
      id: 3,
      titre: 'A l’arrivée ?',
      image: 'IoSchoolOutline',
      text:'Des formations courtes, professionnelles, ludiques et reconnues. Des diplômés bien formés et immédiatement opérationnels en entreprise. Des offres d’emploi qui leur sont réservées. Des recrutements de qualité, pile poil adaptés aux besoins des entreprises.'
    },
  ]

  return (
    <WeforStyled>
      <Header bcgColor={'rgba(100,154,206,1)'}/>
      <section className="top-illustration">
        <div className="opacity-light-section">
        <img src={logo} alt='logo wefor'/>
          <div className="left" >
            <h1 className="bold">Qui sommes-nous ?</h1>
          </div>
            
        </div>
      </section>
      <main className="page-wefor">
      <section className="first-section-home-de background-losange section-home-not-choose">
        <div className="right-cards">
          <div className={"white-card "} data-aos="fade-left">
            <TiLightbulb className='pink'/>
            <div>
              <h5 className="bold">Leur concept ?</h5>
              <p>Une formation ET un emploi. Rien que ça !</p>
            </div>
          </div>
          <div className={"white-card "} data-aos="fade-left">
            <FiTarget className='orange'/>
            <div>
              <h5 className="bold">Leur objectif ?</h5>
              <p>Se mettre au service de l’employabilité et de l’insertion professionnelle.</p>
            </div>
          </div>
          <div className={"white-card "} data-aos="fade-left">
            <IoSchoolOutline className='green'/>
            <div>
              <h5 className="bold">A l’arrivée ?</h5>
              <p>Des formations courtes, professionnelles, ludiques et reconnues. Des diplômés bien formés et immédiatement opérationnels en entreprise. Des offres d’emploi qui leur sont réservées. Des recrutements de qualité, pile poil adaptés aux besoins des entreprises.</p>
            </div>
          </div>
        </div>
        <div className="left left-home-wstatut">
          <h2 className="bold">Notre <span className="blue-underline bold">histoire</span></h2>
          <p>Wefor, c’est d’abord la rencontre de deux hommes, Michaël et Cyril, qui n’avaient pas l’intention de s’arrêter à mi-chemin. Pour eux, proposer une formation sans un emploi à la clé revenait à laisser leurs apprenants au milieu du guet, et c’est tout ce qu’ils ne voulaient pas.</p>
          <img src={logoright} alt="un test" />
        </div>
    </section>
      <section className="valeur-section">
        <div className="wrapper-valeur">
        <div className="left">
        <h2 className="bold">Nos <span className="blue-underline bold">valeurs</span></h2>
          <p>Au service de l’employabilité et de l’insertion professionnelle, notre mission est de satisfaire ET les besoins de nos apprenants qui souhaitent se reconvertir ou évoluer professionnellement ET les besoins de nos entreprises partenaires qui souhaitent recruter de nouveaux talents.</p>
          <p>Au cœur de cette mission, 3 valeurs clés !</p>
          <div className="whites-cards-shadow-wrapper">
            <div className="cards-shadow">
              <FaHandshake />
              <h3 className="bold">L’engagement.</h3>
              <p>Accompagner nos apprenants jusqu’à l’emploi. Accompagner nos entreprises partenaires dans leur choix de ressources adaptées à leur activité.</p>
            </div>
            <div className="cards-shadow">
              <BsGraphUp />
              <h3 className="bold">L’efficacité.</h3>
              <p>Acquérir rapidement des compétences en adéquation avec le marché de l’emploi.</p>
            </div>
            <div className="cards-shadow">
              <FaUsers />
              <h3 className="bold">L’humanisme.</h3>
              <p>Croire en la capacité de TOUS à trouver sa voie professionnelle. Et cultiver la bienveillance au quotidien !</p>
            </div>
          </div>
        </div>
        </div>
      </section>
      {/*
      <section className="organigramme-section">
        <h2 className="bold">Organisation</h2>
          <img 
            src={orga} className="teamwork-picto" 
            alt="team work picto and logo" 
          />
      </section>
      */}
      </main>
      <Footer />
    </WeforStyled>
  )
}

Wefor.propTypes = {
}

export default Wefor