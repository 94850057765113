import styled from 'styled-components';

const AccountProfilStyled = styled.div`
.page-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    max-width: 1300px;
    margin: 175px auto 0;
    

    .right-wrapper {
        width: calc(100% - 250px);
        display: flex;
        align-items: center;
        padding-top: 50px;
        justify-content: center;
        flex-direction: column;
        
        #file-upload {
            display: none;
        }

        .cv-title {
            width: 96%;
            max-width: 600px;
            margin: 0 auto 10px;
            color: #707070;
        }

        .dl-link {
            color: #649ACE;
            cursor: pointer;
        }

        .drop-file-wrapper {
            width: 96%;
            max-width: 600px;
            margin: 0 auto 30px;
            height: 60px;

            .file-upload-btn {
                background-color: #649ACE;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                border-radius: 10px;
                transition: all .3s ease;
                cursor: pointer;

                &:hover {
                    opacity: .7;
                }
    
                div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .account-form-comp {
            width: 96%;
            max-width: 600px;
            display: flex;
            flex-direction: column;
            font-size: 16px;
            color: #707070;
            padding-bottom: 50px;

            input:focus {
                outline: 2px solid #007aff;
            }

            .toggle-button-statut {
                padding:10px 0 30px;

                button {
                    width: 50%;
                    border-radius: 10px;

                    &.MuiToggleButtonGroup-grouped:not(:last-of-type) {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }

                    &.MuiToggleButtonGroup-grouped:not(:first-of-type) {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                }
            }

            label {
                width: 100%;
            }

            input {
                width: 100%;
                border: 0;
                border-radius: 10px;
                background-color: #F2F2F2;
                padding: 20px;
                margin: 10px 0px 30px;
                box-sizing: border-box;
            }

            .mdp-wrapper, .name-wrapper, .date-wrapper {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                align-items: center;
                justify-content: space-between;
            }

            .mdp-wrapper, .name-wrapper {
                
                label {
                    width: 45%;
                }
            }

            .date-title {
                text-align: center;
                padding-bottom: 20px;
            }

            .date-wrapper {
                
                label {
                    width: 30%;

                    select {
                        width: 100%;
                        border: 0;
                        border-radius: 10px;
                        background-color: #F2F2F2;
                        padding: 20px;
                        margin: 10px 0px 30px;
                        box-sizing: border-box;
                    }
                }
            }

            .search-wrapper {
                background-color: #E1EDF7;
                border-radius: 10px;
                padding: 10px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
                box-sizing: border-box;

                h4 {
                    width: 100%;
                }

                label {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    width: 45%;

                    input {
                        width: 30px;
                        margin: 20px 0;
                    }
                }
            }

            .lightblue-btn {
                width: 300px;
                margin: 30px auto 0;
                color: #fff;
                background-color: #649ACE;
                border: 2px solid #87B7E5;
                padding: 15px;
                font-size: 18px;
                box-sizing: border-box;
                cursor: pointer;
                box-shadow: 16px 0px 16px #00000014;

                &:hover {
                    color: #649ACE;
                    background-color: #fff;
                }
            }
        }

        .invisible-front {
            display: none;
        }

        .search-adresse-wrapper {

            position: relative;
    
            .result-adresse-wrapper {
                position: absolute;
                top: 70px;
                left: 0;
                background-color: #f2f2f2;
                width: calc(100% + 4px);
                margin-left: -2px;
                border-radius: 10px;
                padding-top: 15px;
                border: 2px solid #007aff;
                box-sizing: border-box;
                border-top: 0;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
    
                li {
                    padding: 10px 10px 10px 22px;
                    font-size: 14px;
    
                    &:last-child {
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;
                    }
    
                    &:hover {
                        background-color: #fff;
                    }
                }
            }
        }

        .account-form-comp-ent {
            width: 96%;
            max-width: 900px;
            display: flex;
            flex-direction: column;
            font-size: 16px;
            color: #707070;
            padding-bottom: 50px;

            input:focus {
                outline: 2px solid #007aff;
            }
        
            label {
                width: 100%;
            }
        
            h3 {
                color: #649ACE;
                font-size: 20px;
                margin: 10px 0 30px; 
            }
        
            .blue {
                color: #649ACE;
            }
        
            input {
                width: 100%;
                border: 0;
                border-radius: 10px;
                background-color: #F2F2F2;
                padding: 20px;
                margin: 10px 0px 30px;
                box-sizing: border-box;
            }

            .invisible-front {
                display: none;
            }
        
            .mdp-wrapper, .name-wrapper, .contact-wrapper {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                position: relative;
        
                img {
                    width: 45%;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        
            .mdp-wrapper, .name-wrapper, .contact-wrapper {
                
                label {
                    width: 45%;
                }
            }
        
            .date-title {
                text-align: center;
                padding-bottom: 20px;
            }
        
            .secteur-wrapper {
                width: 45%;
            }
        
            .date-wrapper, .secteur-wrapper {
                
                label {
                    width: 30%;
        
                    select {
                        width: 100%;
                        border: 0;
                        border-radius: 10px;
                        background-color: #F2F2F2;
                        padding: 20px;
                        margin: 10px 0px 30px;
                        box-sizing: border-box;
                    }
                }
            }

            .contact-wrapper.align-left {
                flex-direction: column;
                align-items: flex-start;
            }
        
            .search-wrapper {
                background-color: #E1EDF7;
                border-radius: 10px;
                padding: 10px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
                box-sizing: border-box;
                margin: 0 0 30px;
        
                h4 {
                    width: 100%;
                }
        
                label {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    width: 45%;
        
                    input {
                        width: 30px;
                        margin: 20px 0;
                    }
                }
            }
        
            .confirmation-wrapper {
                display:flex;
                align-items: center;
                justify-content: space-between;
        
                p {
                    width: 45%;
                    margin: 0;
                }
        
                .lightblue-btn {
                    margin: 0;
                }
            }

            .search-adresse-wrapper {

                position: relative;
        
                .result-adresse-wrapper {
                    position: absolute;
                    top: 70px;
                    left: 0;
                    background-color: #f2f2f2;
                    width: calc(100% + 4px);
                    margin-left: -2px;
                    border-radius: 10px;
                    padding-top: 15px;
                    border: 2px solid #007aff;
                    box-sizing: border-box;
                    border-top: 0;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    z-index: 2;
        
                    li {
                        padding: 10px 10px 10px 22px;
                        font-size: 14px;
        
                        &:last-child {
                            border-bottom-left-radius: 10px;
                            border-bottom-right-radius: 10px;
                        }
        
                        &:hover {
                            background-color: #fff;
                        }
                    }
                }
            }
            
            .btn-wrapper {
                width: 100%;
                text-align: center;
            }
            .lightblue-btn {
                align-self: center;
                width: 300px;
                margin: 0px auto 30px;
                color: #fff;
                background-color: #649ACE;
                border: 2px solid #87B7E5;
                padding: 15px;
                font-size: 18px;
                box-sizing: border-box;
                cursor: pointer;
                box-shadow: 16px 0px 16px #00000014;
        
                &:hover {
                    color: #649ACE;
                    background-color: #fff;
                }
            }
        }
    }
}

@media only screen and (max-width: 1400px) {
    .page-wrapper {
        .right-wrapper {
            width: calc(100% - 250px);
        }
    }
}

@media only screen and (max-width: 1079px) {
    .page-wrapper {
        margin: 150px auto 0;
        .right-wrapper {
            width: calc(100% - 250px);

            .img-none-mobile {
                display: none;
            }
        }
    }
}

@media only screen and (min-width: 0px) and (max-width: 759px) {
    .page-wrapper {
        .right-wrapper {
            width: calc(100%);
        }
    }
}
`;

export default AccountProfilStyled;