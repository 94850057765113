import { connect } from 'react-redux'

import ModalPostulerTuto from '../../components/ModalPostulerTuto'
import { toggleModalPostulerTuto, setJobIdModalPostulerTuto, setCvPosted, setDidTest, fetchJobPostuler, setFullPageLoaderJobInsc, setDidOcean } from '../../actions/job'
import { openModal } from '../../actions/modal'
import { changeActivCourseId } from '../../actions/course'


const mapStateToProps = (state) => ({
	isModalPostulerTutoOpen: state.job.isModalPostulerTutoOpen,
	jobIdModalPostulerTuto: state.job.jobIdModalPostulerTuto,
	didTest: state.job.didTest,
    didOcean: state.job.didOcean,
	cvPosted: state.job.cvPosted,
    userInfo: state.login.userInfo,
    isLogged: state.login.isLogged,
    userRole: state.login.userRole,
    nameOfCourse: state.job.nameOfCourse,
    activJobId: state.job.activJobId,
    jobs: state.job.jobs,
    jobsHome: state.job.jobsHome,
    jobForModalPostuler: state.job.jobForModalPostuler
})

const mapDispatchToProps = (dispatch) => ({
    toggleModalPostulerTuto: (bool) => {
        dispatch(toggleModalPostulerTuto(bool));
    },
	setJobIdModalPostulerTuto: (id) => {
        dispatch(setJobIdModalPostulerTuto(id));
    },
	setCvPosted: (bool) => {
        dispatch(setCvPosted(bool));
    },
	setDidTest: (bool) => {
        dispatch(setDidTest(bool));
    },
    setDidOcean: (bool) => {
        dispatch(setDidOcean(bool));
    },
    fetchJobPostuler: (id) => {
        dispatch(fetchJobPostuler(id));
    },
    setFullPageLoaderJobInsc: (bool) => {
        dispatch(setFullPageLoaderJobInsc(bool));
    },
    changeActivCourseId: (id) => {
        dispatch(changeActivCourseId(id));
    },
    openModal: (name) => {
        dispatch(openModal(name));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(ModalPostulerTuto)

