import { connect } from 'react-redux'

import CourseProg from '../../pages/CourseProg'

import { changeActivCourseId } from '../../actions/course'
import { openModal } from '../../actions/modal'

const mapStateToProps = (state) => ({
  formations: state.course.formations,
  userRole: state.login.userRole,
})

const mapDispatchToProps = (dispatch) => ({
    openModal: (name) => {
        dispatch(openModal(name));
      },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(CourseProg)