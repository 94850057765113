import { connect } from 'react-redux'

import AccountCandidats from '../../pages/AccountCandidats'

import { setSelectionJobOffer, fetchJobOffer, fetchContactCandidat, fetchDemandesJobFromJobOffer, fetchScoresFormation, fetchJobOfferPageCandidat, fetchCandidatFromJobOffer, setOffreIdHP } from '../../actions/job'

const mapStateToProps = (state) => ({
    currentProfilUser: state.menu.currentProfilUser,
    user: state.login.session.user,
    userInfo: state.login.userInfo,
    userRole: state.login.userRole,
    isLogged: state.login.isLogged,
    jobsEntreprisePageCandidat: state.job.jobsEntreprisePageCandidat,
    selectionJobOffer: state.job.selectionJobOffer,
    fullPageLoaderJobOffer: state.actions.fullPageLoaderJobOffer,
    demandesFromJobOffer: state.job.demandesFromJobOffer,
    myScoresFormation: state.job.myScoresFormation,
    candidatFromJob: state.job.candidatFromJob,
    candidatFromJobPagination: state.job.candidatFromJobPagination,
    fullPageLoaderCandidatFromJob: state.job.fullPageLoaderCandidatFromJob,
    offreIdHP: state.job.offreIdHP
})

const mapDispatchToProps = (dispatch) => ({
    setSelectionJobOffer: (data) => {
        dispatch(setSelectionJobOffer(data));
      },
      fetchJobOfferPageCandidat: (id) => {
        dispatch(fetchJobOfferPageCandidat(id));
      },
      fetchContactCandidat: (candidatId) => {
        dispatch(fetchContactCandidat(candidatId));
      },
      fetchDemandesJobFromJobOffer: (id) => {
        dispatch(fetchDemandesJobFromJobOffer(id));
      },
      fetchScoresFormation: (userId) => {
        dispatch(fetchScoresFormation(userId));
      },
      fetchCandidatFromJobOffer: (offerId, candidatNumber, candidatPage) => {
        dispatch(fetchCandidatFromJobOffer(offerId, candidatNumber, candidatPage));
      },
      setOffreIdHP : (id) => {
        dispatch(setOffreIdHP(id));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(AccountCandidats)