import styled from 'styled-components';

const StepTwoStyled = styled.section`


@media only screen and (max-width: 1400px) {
}

@media only screen and (min-width: 760px) and (max-width: 1079px) {
}

@media only screen and (min-width: 0px) and (max-width: 759px) {
}

`;

export default StepTwoStyled;