/**** Import des modules ****/
import React, {useEffect} from 'react'
import { Link } from "react-router-dom"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'
import AOS from 'aos';
import "aos/dist/aos.css"

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import CourseCard from '../../containers/CourseCard'

/**** Import des images ****/
import documentgrey from '../../assets/img/illustration/documentgrey.png'
import contrat from '../../assets/img/illustration/contrat.png'
import euroyellow from '../../assets/img/illustration/euroyellow.png'
import graphgreen from '../../assets/img/illustration/graphgreen.png'
import programme from '../../assets/img/illustration/programme.png'
import contratcheck from '../../assets/img/illustration/contratcheck.png'
import logo from '../../assets/img/illustration/DEMANDEUR-EMPLOI-02.png'

/**** Import des datas JS ****/
import datagraph from '../../assets/js/datagraph'

/**** Import des css ****/
import JobFicheStyled from './JobFicheStyled'

const JobFiche = ({jobFicheId, fetchThisJobOffer, currentJobFiche }) => {
  
  useEffect((() => {
    fetchThisJobOffer(jobFicheId)
  }), []);

  useEffect(()=> {
    AOS.init({ duration: 1000 })
  }, [])

  return (
    <JobFicheStyled>
      <Header bcgColor={'#247558'}/>
      {currentJobFiche !== undefined && 
      <>
      <section className="illustration-header">
        <div className="opacity-light-section">
        <img src={logo} alt='logo wefor'/>
          <div className="left">
          <div className="title-wrapper-prog">
              <h2 className="bold border">Fiche métier {currentJobFiche.attributes.secteur.data !== null ? currentJobFiche.attributes.secteur.data.attributes.nom : ''}</h2>
              <h1 className="bold">{currentJobFiche.attributes.nom}</h1>
          </div>
        </div>
        </div>
      </section>
      <main>
        <section className="section-job-def">
          <div className="title-wrapper">
            <img src={contrat} alt="contrat avec fond jaune" />
            <h2>DÉFINITION DU MÉTIER<span className="separator-blue"></span></h2>
          </div>
          <div className="def-wrapper">
            <p className="txt-left">{currentJobFiche.attributes.definition}</p>
            <div className="salary-wrapper">
              <img src={euroyellow} alt="contrat aevc fond jaune" />
              <div className="flex-box-wrapper">
                <h3 className="bold">Rémunération moyenne</h3>
                <p className="bold">{currentJobFiche.attributes.remuneration} € <span className="bold">brut annuel</span></p>
              </div>
              <p className="disclaimer">*sources: site <a href="https://fr.talent.com/" target="_blank" rel="noreferrer" className="bold">talent.com</a></p>
            </div>
          </div>
        </section>
        <section className="section-formation-list">
          <h2>FORMATIONS WEFOR POUR ACCÉDER À CE MÉTIER<span className="separator-blue"></span></h2>
          <div className="card-wrapper">
          {currentJobFiche !== undefined && currentJobFiche.attributes.formations.data.map((formation) => (
            <CourseCard key={formation.id} courseId={formation.id} {...formation}/>
          ))}
          </div>
          <Link to="/formation">
            <button className="btn-blue">Voir toutes nos formations</button>
          </Link>
        </section>
        <section className="section-mission-list">
          <h2>MISSIONS / COMPÉTENCES-CLÉS<span className="separator-blue"></span></h2>
          <div className="list-wrapper">
            <div className="left">
              <ReactMarkdown>{currentJobFiche.attributes.missions}</ReactMarkdown>
            </div>
          </div>
        </section>
        <section className="section-evolution-job">
          <h2>ÉVOLUTIONS POSSIBLES<span className="separator-blue"></span></h2>
          <div className="evo-list">
            <img src={graphgreen} alt="contrat avec fond jaune" />
            <ReactMarkdown>{currentJobFiche.attributes.evolution}</ReactMarkdown>
          </div>
        </section>
        <section className="section-perspective-graph">
          <h2>PERSPECTIVES D'EMPLOI<span className="separator-blue"></span></h2>
          <ReactMarkdown className="txt-grey">{currentJobFiche.attributes.perspectives_emploi}</ReactMarkdown>
          <p className="txt-blue bold">Courbe de progression de salaire d’un <span className="bold">{currentJobFiche.attributes.nom}</span></p>
          <LineChart
            width={500}
            height={300}
            data={[
              {
                name: 'Junior',
                salaire: `${currentJobFiche.attributes.salaire_junior}`,
              },
              {
                name: 'Senior',
                salaire: `${currentJobFiche.attributes.salaire_senior}`,
              },
            ]}
            className='graph bold'
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="5 5" />
            <XAxis dataKey="name" stroke="#757991" />
            <YAxis label={{ value: 'K€', position: 'insideTopLeft' }} stroke="#757991"/>
            <Tooltip />
            <Line type="monotone" dataKey="salaire" stroke="#639ACD" />
          </LineChart>
        </section>
        <section className="section-btn-bottom">
            <Link to="/formation">
              <div className="btn-right">
                <img src={contratcheck} alt="contrat avec fond jaune" />
                <p>Les formations</p>
              </div>
            </Link>
        </section>
      </main>
      </>
      }
      <Footer />
    </JobFicheStyled>
  )
}

JobFiche.propTypes = {
}

export default JobFiche