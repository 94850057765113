/**** Import de modules ****/
import React, { useState, useEffect } from 'react'
import useScrollPosition from '@react-hook/window-scroll'
import { Link, useLocation } from "react-router-dom"
import PropTypes from 'prop-types'
import { FaPhoneAlt, FaRegEnvelope, FaUserAlt } from "react-icons/fa";

/**** Import d'images' ****/
import logo from '../../assets/img/logo/WEFOR_COULEUR.png'
import logoW from '../../assets/img/logo/WEFOR_COULEUR_BLANC-ai.png'
import phonePicto from '../../assets/img/picto/phone.png'
import contactPicto from '../../assets/img/picto/contact.png'
import accountPicto from '../../assets/img/picto/compte.png'
import accountwhite from '../../assets/img/picto/accountwhite.png'

/**** Import des components ou containers ****/
import JobLi from '../../containers/Header/JobLi'
import CourseLi from '../../containers/Header/CourseLi'
import AccountForm from '../../containers/Header/AccountForm'
import NavUser from '../../containers/Header/NavUser'

/**** Import des css ****/
import HeaderStyled from './HeaderStyled'

const Header = ({isLogged, prenom, windowTop, user, isShowAccountFormMenu, setIsShowAccountFormMenu, changeCurrentProfilUser, userRole, bcgColor }) => {

  let locationAccount = useLocation().pathname.includes('/account')
  let comboHeaderColor = windowTop && !locationAccount
  return (
    
    <HeaderStyled windowTop={windowTop} comboHeaderColor={comboHeaderColor} bcgColor={bcgColor}>
      <div className="wrapper-header-top">
        <div className="header-top">
        { userRole === 'entreprise' ?
          <Link to="/">
          <img src={ comboHeaderColor ? logo : logo } className="header-logo" alt="logo carré wefor" />
          </Link>
          :
          <Link to="/" onClick={()=> changeCurrentProfilUser('notChoose')}>
          <img src={ comboHeaderColor ? logo : logo } className="header-logo" alt="logo carré wefor" />
          </Link>
          }
          <div className="menu-wrapper">
            <ul className="pictos-ul">
              <li className="mobile-none">
              <Link to="/contact">
                <FaRegEnvelope className="menu-picto-contact" alt="picto contact" /> 
                <span className="mobile-none">Nous contacter</span>
              </Link>
              </li>  
              <li className="margin-left-20">
                <a href="tel:+33972575262">
                <FaPhoneAlt className="menu-picto menu-picto-phone" alt="picto phone" /> 
                <span className="bold phone">09 72 57 52 62</span>
                </a>
              </li>
              {isShowAccountFormMenu &&
              <li 
                className="account-li actif-class"
              >
                {isLogged &&
                <>
                  <FaUserAlt onClick={() => setIsShowAccountFormMenu(false)} className="menu-picto" alt="picto compte" /> 
                  <span className="bold prenom-header" onClick={() => setIsShowAccountFormMenu(false)}>{user && user.prenom && user.prenom}</span>
                </>
                }
                {!isLogged &&
                <>
                  <FaUserAlt onClick={() => setIsShowAccountFormMenu(false)} className="menu-picto" alt="picto compte" /> 
                  <span onClick={() => setIsShowAccountFormMenu(false)}>Mon compte</span>
                </>
                }
                {isShowAccountFormMenu && 
                  <AccountForm />
                }
              </li>
              }
              {!isShowAccountFormMenu &&
              <li 
                className="account-li"
                onClick={() => setIsShowAccountFormMenu(true)}
              >
                {isLogged &&
                <>
                  <FaUserAlt className="menu-picto" alt="picto compte" /> 
                  <span className="bold capitalize prenom-header">{user && user.prenom && user.prenom}</span>
                </>
                }
                {!isLogged &&
                <>
                  <FaUserAlt className="menu-picto" alt="picto compte" /> 
                  <span className="">Mon compte</span>
                </>
                }
                {isShowAccountFormMenu && 
                  <AccountForm />
                }
              </li>
              }
            </ul>
            <div>
            </div>
          </div>
        </div>
      </div>
      <NavUser />
    </HeaderStyled>
  )
}

Header.propTypes = {
  isLogged: PropTypes.bool.isRequired,
  prenom: PropTypes.string.isRequired,
}

export default Header