/**** Import de modules ****/
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { saveAs } from 'file-saver'
import { MdDownloadForOffline } from "react-icons/md"
import { RiBallPenFill } from "react-icons/ri";
import Skeleton from '@mui/material/Skeleton';

import urlBackEndSave from '../../assets/js/urlBackEndSave'

/**** import des components ****/
import ModalJobFiche from '../../containers/ModalJobFiche';
import ContratCourseU from './ContratU'

/**** Import d'images' ****/


/**** Import des css ****/
import ContratCourseStyled from './ContratCourseStyled'

const ContratCourse = ({factureP, factureE, userRole, toggleModalSignature, factureEStatut, facturePStatut }) => {

  const savePdf = (url, name) => {
    saveAs(
      `${urlBackEndSave}${url}`,
      `${name}`
    );
  };

  
  return (
    <ContratCourseStyled>
      <section className="tab-wrapper">
        <div className='legend'>
          <div className="row">
            <div className='bold'>Numéro</div>
            <div className='bold'>Date</div>
          </div>
          <div className="row">
            <div className='bold'>Nom</div>
            <div className='bold'>Accès</div>
          </div>
          <div className="row bold">
            Fichier
          </div>
          <div className="row last bold">
            Statut
          </div>
        </div>
        {userRole === 'entreprise' && !factureEStatut &&
        <>
        <div className='full-width-w'>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-statut" variant="rectangular" />
          </div>
        </div>
          <div className='full-width-w'>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-statut" variant="rectangular" />
          </div>
        </div>
          <div className='full-width-w'>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-statut" variant="rectangular" />
          </div>
        </div>
          <div className='full-width-w'>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-statut" variant="rectangular" />
          </div>
        </div>
        </>
        }
        {userRole !== 'entreprise' && !facturePStatut &&
        <>
        <div className='full-width-w'>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-statut" variant="rectangular" />
          </div>
        </div>
          <div className='full-width-w'>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-statut" variant="rectangular" />
          </div>
        </div>
          <div className='full-width-w'>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-statut" variant="rectangular" />
          </div>
        </div>
          <div className='full-width-w'>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-statut" variant="rectangular" />
          </div>
        </div>
        </>
        }
      {(userRole === 'entreprise' ? factureE.length : factureP.length) > 0 && 
        (userRole === 'entreprise' ? factureE : factureP).map((facture) => (
          <ContratCourseU key={facture.id} facture={facture} userRole={userRole} toggleModalSignature={toggleModalSignature} factureEStatut={factureEStatut} facturePStatut={facturePStatut}/>
        ))
      }
      </section>
    </ContratCourseStyled>
  )
}

ContratCourse.propTypes = {
}

export default ContratCourse