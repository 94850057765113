/**** Import des modules ****/
import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import Moment from 'react-moment'

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'


/**** Import des images ****/


/**** Import des css ****/
import NewsFluxStyled from './NewsFluxStyled'

const NewsFlux = ({ fetchGetNews, search, newsFlux, changeSearchNews }) => {

  useEffect(() => {
    fetchGetNews(search)
  }
  , []);

  return (
    <NewsFluxStyled>
      <Header bcgColor={'rgba(100,154,206,1)'}/>
      <section className="full-page">
        <div className="search-wrapper">
          <h1>Actualités</h1>
          <p className="first-p">Recherche actuelle : {search}</p>
          <p className="second-p">Vous pouvez faire une recherche par mot(s) clé(s)</p>
          <TextField 
            id="filled-basic"
            label="Mot(s) clé(s)"
            variant="filled"
            className="search-input"
            onChange={(event) => changeSearchNews(event.target.value)} 
          />
          <button 
            className="search-btn"
            onClick={() => fetchGetNews(search)} 
          >Rechercher</button>
        </div>
        <div className="result-wrapper">
          {newsFlux.articles && newsFlux.articles.length > 0 && newsFlux.articles.map((article, index)=> (
            <div className='article-wrapper' key={index}>
              <h3 className="bold">{article.title}</h3>
              {article.author !== null ? 
              <p className="auteur">Auteur : {article.author}</p>
              :
              <p className="auteur">Aucun auteur</p>
              }
              <p>{article.excerpt} <a href={article.link} target="_blank" rel="noopener noreferrer">En savoir plus</a></p>
              <p className='published'>Publiée: <span><Moment format="DD/MM/YYYY"  className="bold">{article.publishedAt}</Moment></span></p>
              {article.media !== null && 
                <img src={article.media} alt="illustration de l'article" className='img-article' />
              }
            </div>
          ))}
          {newsFlux.articles === undefined &&
            <p>Aucun resultat pour cette recherche !</p>
          }
        </div>
      </section>
      <Footer />
    </NewsFluxStyled>
  )
}

NewsFlux.propTypes = {
}

export default NewsFlux