import { connect } from 'react-redux'

import AccountOcean from '../../pages/AccountOcean'

import { fetchQuestionOcean, fetchReponseOcean, changeValueOceanQ, fetchPostOceanResult, fetchCategorieOcean, changeManagerName, setFullPageLoaderOcean } from '../../actions/ocean'
import { setDidOcean } from '../../actions/job'

const mapStateToProps = (state) => ({
    currentProfilUser: state.menu.currentProfilUser,
    user: state.login.session.user,
    userInfo: state.login.userInfo,
    userRole: state.login.userRole,
    isLogged: state.login.isLogged,
    formationsTerminee: state.login.formationsTerminee,
    offre_emplois_postule: state.login.offre_emplois_postule,
    formations: state.course.formations,
    jobsEntreprise: state.job.jobsEntreprise,
    reponseOcean: state.ocean.reponseOcean,
    questionOcean: state.ocean.questionOcean,
    valueInputs: state.ocean.valueInputs,
    categorieOcean: state.ocean.categorieOcean,
    managerNom: state.ocean.managerNom,
    managerPrenom: state.ocean.managerPrenom,
})

const mapDispatchToProps = (dispatch) => ({
    fetchQuestionOcean: () => {
        dispatch(fetchQuestionOcean());
    },
    fetchReponseOcean: () => {
        dispatch(fetchReponseOcean());
    },
    changeValueOceanQ: (value, index, score, categorie) => {
        dispatch(changeValueOceanQ(value, index, score, categorie));
    },
    fetchPostOceanResult: () => {
        dispatch(fetchPostOceanResult());
    },
    fetchCategorieOcean: () => {
        dispatch(fetchCategorieOcean());
    },
    changeManagerName: (name, value) => {
        dispatch(changeManagerName(name, value));
    },
    setFullPageLoaderOcean: (bool) => {
        dispatch(setFullPageLoaderOcean(bool));
    },
    setDidOcean: (bool) => {
        dispatch(setDidOcean(bool));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(AccountOcean)