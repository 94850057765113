import styled from 'styled-components';

const BannerAccountStyled = styled.section`
    position: relative;
    margin-top: 150px;

    .banner-header {
        width: 100%;
        height: 350px;
    }

    .img-absolute-header {
        width: 300px;
        position: absolute;
        top: 70px;
        left: calc(50% - 150px);
    }
`;

export default BannerAccountStyled;