import { 
    CHANGE_VALUE_SIGNIN,
    CHANGE_SIGNIN_STEP,
    SAVE_ADRESSE_LIST
  } from "../actions/signin";
  
  export const initialState = {
    signInStep: 1,
    days: [
      {
        label: 'Choisir',
        value: 'Choisir',
      },
      {
        label: 1,
        value: 1
      },
      {
        label: 2,
        value: 2
      },
      {
        label: 3,
        value: 3
      },
      {
        label: 4,
        value: 4
      },
      {
        label: 5,
        value: 5
      },
      {
        label: 6,
        value: 6
      },
      {
        label: 7,
        value: 7
      },
      {
        label: 8,
        value: 8
      },
      {
        label: 9,
        value: 9
      },
      {
        label: 10,
        value: 10
      },
      {
        label: 11,
        value: 11
      },
      {
        label: 12,
        value: 12
      },
      {
        label: 13,
        value: 13
      },
      {
        label: 14,
        value: 14
      },
      {
        label: 15,
        value: 15
      },
      {
        label: 16,
        value: 16
      },
      {
        label: 17,
        value: 17
      },
      {
        label: 18,
        value: 18
      },
      {
        label: 19,
        value: 19
      },
      {
        label: 20,
        value: 20
      },
      {
        label: 21,
        value: 21
      },
      {
        label: 22,
        value: 23
      },
      {
        label: 24,
        value: 24
      },
      {
        label: 25,
        value: 25
      },
      {
        label: 26,
        value: 26
      },
      {
        label: 27,
        value: 27
      },
      {
        label: 28,
        value: 28
      },
      {
        label: 29,
        value: 29
      },
      {
        label: 30,
        value: 30
      },
      {
        label: 31,
        value: 31
      },
    ],
    mounths: [
      {
        label: 'Choisir',
        value: 'Choisir',
      },
      {
        label: 'Janvier',
        value: 'Janvier',
      },
      {
        label: 'Février',
        value: 'Février',
      },
      {
        label: 'Mars',
        value: 'Mars',
      },
      {
        label: "Avril",
        value: "Avril"
      },
      {
        label: "Mai",
        value: "Mai"
      },
      {
        label: "Juin",
        value: "Juin"
      },
      {
        label: "Juillet",
        value: "Juillet"
      },
      {
        label: "Août",
        value: "Août"
      },
      {
        label: "Septembre",
        value: "Septembre"
      },
      {
        label: "Octobre",
        value: "Octobre"
      },
      {
        label: "Novembre",
        value: "Novembre"
      },
      {
        label: "Décembre",
        value: "Décembre"
      },
    ],
    JobSectors: [
      {
        label: 'Assurance',
        value: 'Assurance',
      },
    ],
    accountJobSector: 'Assurance',
    accountBdDay: 'Choisir',
    accountBdMounth: 'Choisir',
    accountEntrepriseSignin:'',
    accountSirenSignin: '',
    accountAdresseSignin: '',
    accountPasswordSignin:'',
    accountConfirmationPasswordSignin:'',
    accountSecteurSignin: 'Assurance',
    adresseList: [],
    accountAdresseNumStreetSignin: '',
    accountAdresseStreetSignin: '',
    accountAdresseCodeSignin: '',
    accountAdresseCitySignin: '',
    accountStatut: '',
    accountCvSignin: '',
  };
  
  // reducer = traducteur d'une intention/action vers une modification du state
  const signin = (state = initialState, action = {}) => {
    switch (action.type) {
        case CHANGE_VALUE_SIGNIN:
          return {
            ...state,
            [action.name]: action.value,
          };
        case CHANGE_SIGNIN_STEP:
          return {
            ...state,
            signInStep: action.number,
          };
        case SAVE_ADRESSE_LIST:
          return {
            ...state,
            adresseList: action.data,
          };
      default:
        return state;
    }
  };
  
  export default signin;