import axios from 'axios'
import * as qs from 'qs'
import { toast } from 'react-toastify';

import urlBackEnd from '../assets/js/urlBackEnd';

import { FETCH_JOBS, saveJobs, changeLoadJobs, FETCH_THIS_JOB_OFFER, saveCurrentJobFiche, FETCH_JOB_POSTULER, FETCH_JOB_OFFER, saveJobsEntreprise, FETCH_JOB_FICHES, saveJobFiches, FETCH_POST_JOB_OFFER, FETCH_CONTACT_CANDIDAT, changeActivJobId, FETCH_DELETE_JOB_OFFER, fetchJobOffer, FETCH_GET_DIRECTION, saveDistanceOnJobs, setShowDistance, FETCH_PUT_JOB_OFFER, changeJobsAccountLoading, setFullPageLoaderDistance, setFullPageLoaderJobInsc, SIGN_CONVENTION, FETCH_PUT_SIGNED_CONVENTION, fetchPutSignedConvention, fetchSendEmailConventionSigned, FETCH_SEND_EMAIL_CONVENTION_SIGNED, setFullPageLoaderJobOfferAction, FETCH_DEMANDES_JOB_FROM_JOB_OFFER, saveDemandesFromJobOffer, FETCH_SCORES_FORMATION, saveMyScoresFormation, toggleModalPostulerTuto, saveJobsHome, saveJobsPagination, FETCH_JOB_OFFER_PAGE_CANDIDAT, saveJobsEntreprisePageCandidat, FETCH_CANDIDAT_FROM_JOB_OFFER, saveCandidatsFromJob, setFullPageLoaderCandidat, fetchCandidatFromJobOffer, setSelectionJobOffer, fetchJobOfferPageCandidat } from '../actions/job';
import { fetchUserRole, fetchUser } from '../actions/login';
import { setFullPageLoaderJobOffer, setFullPageLoader } from '../actions/actions';
import { toggleModalConvention } from '../actions/modal'

axios.defaults.baseURL = urlBackEnd
//const URL = "http://localhost:1338/api/";
const URL = urlBackEnd;
 /*const HEROKUURL ="https://wefor-api.herokuapp.com/api/"
const GOOGLEDIRURL = "https://maps.googleapis.com/maps/api/directions/json?"
const GOOGLEDIRAPIKEY = "AIzaSyD03zG8gEBoPIWCZDb-Rh1jnrC252IRDjQ"*/
const queryJobs = qs.stringify({
  populate: {
    formation: {
      populate: '*',
    },
    domaines: {
      populate: '*',
    },
    fiche_metier: {
      populate: '*',
    },
    secteurs: {
      populate: '*',
    },
    entreprise: {
      populate: {
        user: {
          populate:'*'
        }
      }
    }
  } 
  }, {
    encodeValuesOnly: true,
  });

const queryOneJobFiche = qs.stringify({
  populate: {
    formations: {
      populate: '*',
    },
    domaine: {
      populate: '*',
    },
    offre_emplois: {
      populate: '*',
    },
    secteur: {
      populate: '*',
    }
  } 
  }, {
    encodeValuesOnly: true,
  });

const jobsMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case FETCH_JOBS: {
      const paramObj = action.paramObj === undefined ? {page: 'app' ,intemNb: 10, pageNb: 1} : action.paramObj
      const state = store.getState();
      store.dispatch(changeLoadJobs(true));
      const secteurJobfilter = state.menu.secteurJobFilter
      const queryJobObj = () => {
        if (secteurJobfilter === '') {
          const queryJobsB = qs.stringify({
            populate: '*',
            pagination: {
              page: paramObj.pageNb,
              pageSize: paramObj.intemNb,
            },
            sort: ['publishedAt:desc']
            }, {
              encodeValuesOnly: true,
            });
          return queryJobsB
        } else {
          const queryJobsB = qs.stringify({
            populate: '*',
            filters: {
              secteurs: {
                id: Number(secteurJobfilter)
              },
            },
            pagination: {
              page: paramObj.pageNb,
              pageSize: paramObj.intemNb,
            },
            sort: ['publishedAt:desc']
            }, {
              encodeValuesOnly: true,
            });
          return queryJobsB
        }
      }

      axios.get(`${URL}offre-emplois?${queryJobObj()}`)
        .then((response) => {
          store.dispatch(changeLoadJobs(true));
          if (paramObj.page === 'home') {
            store.dispatch(saveJobsHome(response.data.data));
          } else {
            store.dispatch(saveJobs(response.data.data));
            store.dispatch(saveJobsPagination(response.data.meta.pagination))
          }
          if (response.data.data.length > 0) {
            store.dispatch(changeActivJobId(response.data.data[0].id))
          }
          store.dispatch(changeLoadJobs(false));
        })
        .catch((error) => {
          console.error(error);
        });
      next(action);
      break;
    }
    case FETCH_THIS_JOB_OFFER: {
      axios.get(`${URL}fiche-metiers/${action.id}?${queryOneJobFiche}`)
        .then((response) => {
          store.dispatch(saveCurrentJobFiche(response.data.data));
        })
        .catch((error) => {
          console.error(error);
        });
      next(action);
      break;
    }
    case FETCH_JOB_POSTULER : {
      const state = store.getState();
      const jobId = action.id
      const dataJ = []
      state.login.userInfo.data[0].attributes.offre_emplois_postule.data.map((item) => dataJ.push(item.id)) 
      const alreadyAsked = dataJ.includes(jobId)
      const particulierId = state.login.userInfo.data[0].id
      const queryUser = qs.stringify({
        filters: {
          entreprise: {
            id: state.job.jobForModalPostuler.attributes.entreprise.data.id
          }
        },
        sort: ['publishedAt:desc']
        }, {
          encodeValuesOnly: true,
        });

      axios.get(`${URL}users?${queryUser}`)
      .then((response) => {

        let entrepriseEmail = response.data[0].email
        if (alreadyAsked === false) {
          dataJ.push(jobId)
          axios.put(`${URL}particuliers/${particulierId}`, {
            data: {
              offre_emplois_postule: dataJ,
              userName: state.login.session.user.email,
              jobId: jobId,
              jobName: state.job.jobForModalPostuler.attributes.nom,
              jobSiren: state.job.jobForModalPostuler.attributes.entreprise.data.attributes.siren,
              jobEmail: entrepriseEmail,
              jobEntreprise: state.job.jobForModalPostuler.attributes.entreprise.data.attributes.Nom_entreprise,
              emailPart: state.login.session.user.email,
              nom: state.login.session.user.nom,
              prenom: state.login.session.user.prenom,
              telephone: state.login.session.user.telephone,
            }
          },
          {
            headers: {
              Authorization:
                `Bearer ${state.login.session.jwt}`,
              },
          })
          .then((response) => {
            store.dispatch(fetchUserRole())
            store.dispatch(setFullPageLoaderJobInsc(false))
            store.dispatch(toggleModalPostulerTuto(false))
            toast(`Votre demande a été envoyé !`, {
              position: "bottom-right",
              type: "success",
              theme:"colored",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            })
          })
          .catch((error) => {
            console.error(error);
          });
        } else {
          store.dispatch(setFullPageLoaderJobInsc(false))
          store.dispatch(toggleModalPostulerTuto(false))
          toast(`Vous avez déjà fait une demande pour cette offre !`, {
            position: "bottom-right",
            type: "error",
            theme:"colored",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        }
      })
      .catch((error) => {
        console.error(error);
      });

      next(action);
      break;
    }
    case FETCH_JOB_OFFER: {
      const state = store.getState();
      store.dispatch(changeJobsAccountLoading(true))
      store.dispatch(setFullPageLoaderJobOffer(true))
      const page = action.nbPage
      const pageSize = action.nbOffer
      const queryJobOffer = qs.stringify({
        populate: {
          particuliers_demande: '*',
          formation: '*',
          secteurs: '*',
          domaine: '*',
        },
        filters: {
          entreprise: {
            id: action.id
          }
        },
        pagination: {
          page: page,
          pageSize: pageSize,
        },
        sort: ['publishedAt:desc', 'nom:desc']
        }, {
          encodeValuesOnly: true,
        });
      axios.get(`${URL}offre-emplois?${queryJobOffer}`, {
        headers: {
          Authorization:
            `Bearer ${state.login.session.jwt}`,
          },
      }) 
        .then((response) => {
          store.dispatch(saveJobsEntreprise(response.data.data, response.data.meta.pagination));
          store.dispatch(setFullPageLoaderJobOffer(false))
          store.dispatch(setFullPageLoaderJobOfferAction(false))
          store.dispatch(changeJobsAccountLoading(false))
        })
        .catch((error) => {
          console.error(error);
          store.dispatch(setFullPageLoaderJobOfferAction(false))
        });
      next(action);
      break;
    }
    case FETCH_JOB_OFFER_PAGE_CANDIDAT: {
      const state = store.getState();
      store.dispatch(changeJobsAccountLoading(true))
      store.dispatch(setFullPageLoaderJobOffer(true))
      const queryJobOffer = qs.stringify({
        populate: {
          particuliers_demande: '*',
          formation: '*',
          secteurs: '*',
          particuliers_contacters: '*'
        },
        filters: {
          entreprise: {
            id: action.id
          }
        },
        sort: ['publishedAt:desc', 'nom:desc']
        }, {
          encodeValuesOnly: true,
        });
      axios.get(`${URL}offre-emplois?${queryJobOffer}`, {
        headers: {
          Authorization:
            `Bearer ${state.login.session.jwt}`,
          },
      }) 
        .then((response) => {
          store.dispatch(saveJobsEntreprisePageCandidat(response.data.data));
          if (state.job.selectionJobOffer !== '') {
            const offerId = state.job.selectionJobOffer.id
            const offers = response.data.data
            console.log(offerId)
            console.log(offers)
            const offerToPush = offers.find(offer => offer.id === offerId)
            store.dispatch(setSelectionJobOffer(offerToPush))
          }
          store.dispatch(setFullPageLoaderJobOffer(false))
          store.dispatch(setFullPageLoaderJobOfferAction(false))
          store.dispatch(changeJobsAccountLoading(false))
        })
        .catch((error) => {
          console.error(error);
          store.dispatch(setFullPageLoaderJobOfferAction(false))
        });
      next(action);
      break;
    }
    case FETCH_JOB_FICHES: {
      const queryJobFiches = qs.stringify({
        populate: '*',
        sort: ['nom:asc'] 
        }, {
          encodeValuesOnly: true,
        });
      axios.get(`${URL}fiche-metiers?${queryJobFiches}`)
        .then((response) => {
          store.dispatch(saveJobFiches(response.data.data));
        })
        .catch((error) => {
          console.error(error);
        });
      next(action);
      break;
    }
    case FETCH_POST_JOB_OFFER: {
      const state = store.getState();

      store.dispatch(setFullPageLoaderJobOfferAction(true))
      const nomOfManager = action.nomOfManager
      const prenomOfManager = action.prenomOfManager
      
      axios.post(`${URL}offre-emplois`, {
          data: {
            entreprise: state.login.userInfo.data[0].id ,
            secteurs: state.job.secteurJob,
            domaines: 2,
            nom: state.job.nomjob,
            remuneration: (state.job.remunerationjob === '' ? 0 : state.job.remunerationjob),
            type_emploi: state.job.contratjob,
            lieu: state.job.localisationjob,
            description: state.job.descriptifjob,
            missions: state.job.missionjob,
            profil: state.job.profiljob,
            nom_manager: nomOfManager !== '' ? nomOfManager : '',
            prenom_manager: prenomOfManager !== '' ? prenomOfManager : ''
          },
      },
      {
      headers: {
        Authorization:
          `Bearer ${state.login.session.jwt}`,
        },
      }
      )
        .then((response) => {
          store.dispatch(fetchJobOffer(state.login.userInfo.data[0].id, 5, 1))
          toast(`Votre offre a été créée`, {
            position: "bottom-right",
            type: "success",
            theme:"colored",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
        .catch((error) => {
          console.error(error.message);
          store.dispatch(setFullPageLoaderJobOfferAction(false))
        });
      next(action);
      break;
    }
    case FETCH_CONTACT_CANDIDAT: {
      const state = store.getState();
      const userP = action.candidatId.attributes.user.data
      const jobName = state.job.selectionJobOffer.attributes.nom
      const jobOfferId = state.job.selectionJobOffer.id
      store.dispatch(setFullPageLoaderCandidat(true))
      axios.post(`${URL}sendemailcontactEtoP`, {
          data: {
            emailE: state.login.userInfo.data[0].attributes.user.data.attributes.email,
            nomE: state.login.userInfo.data[0].attributes.Nom_entreprise,
            sirenE: state.login.userInfo.data[0].attributes.siren,
            idE: state.login.userInfo.data[0].attributes.user.data.id,
            emailP: userP.attributes.email,
            nomP: userP.attributes.nom,
            prenomP: userP.attributes.prenom,
            telephoneP: userP.attributes.telephone,
            idP: userP.id,
            jobName: jobName
          }
      },
      )
        .then((response) => {
          store.dispatch(setFullPageLoaderCandidat(false))
          const dataPID = []
          const candidats = state.job.selectionJobOffer.attributes.particuliers_contacters.data
          const dataPIDFunc = () => { 
            dataPID.push(action.candidatId.id)
            candidats.map((candidat) => {
              dataPID.push(candidat.id)
            })
            return dataPID
          }
          axios.put(`${URL}offre-emplois/${jobOfferId}`, {
            data: {
              particuliers_contacters: dataPIDFunc()
            }
          },
          {
          headers: {
            Authorization:
              `Bearer ${state.login.session.jwt}`,
            },
          }
          )
            .then((response) => {
              store.dispatch(fetchCandidatFromJobOffer(state.job.selectionJobOffer.id, 6, state.job.candidatFromJobPagination.page))
              store.dispatch(fetchJobOfferPageCandidat(state.login.userInfo.data[0].id))
            })
            .catch((error) => {
              console.error(error.message);
            });
          toast(`Votre message a été envoyé ! Vous allez être contacté par notre équipe`, {
            position: "bottom-right",
            type: "success",
            theme:"colored",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
        .catch((error) => {
          console.error(error.message);
          store.dispatch(setFullPageLoaderCandidat(false))
          toast('Le message n\'a pas été envoyé ! Une erreur s\'est produite', {
            position: "bottom-right",
            type: "error",
            theme:"colored",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        });
      next(action);
      break;
    }
    case FETCH_DELETE_JOB_OFFER: {
      const state = store.getState();
      const entrepriseId = action.id
      axios.delete(`${URL}offre-emplois/${entrepriseId}`,
    {
    headers: {
      Authorization:
        `Bearer ${state.login.session.jwt}`,
      },
    }
    )
      .then((response) => {
        store.dispatch(fetchJobOffer(state.login.userInfo.data[0].id, 5, (Object.keys(state.job.jobsEntreprisePagination).length > 0 ? state.job.jobsEntreprisePagination.pageCount : 1)))
        toast(`Votre offre a été supprimée`, {
          position: "bottom-right",
          type: "success",
          theme:"colored",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .catch((error) => {
        console.error(error.message);
        store.dispatch(setFullPageLoaderJobOfferAction(false))
        toast(`une erreur s'est produite. Contactez nous si le problème perciste`, {
          position: "bottom-right",
          type: "error",
          theme:"colored",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      });
    next(action);
    break;
    }
    case FETCH_GET_DIRECTION: {
      const state = store.getState();
      
      const modeToGet = action.mode
      const userInfo = state.login.userInfo.data[0].attributes
      const originUser = `${userInfo.numero_voie} ${userInfo.nom_voie} ${userInfo.code_postale} ${userInfo.ville}`
      const jobsToMap = state.job.jobs 

      let countIndex = 0

      jobsToMap.map((jobDir, indexJob) => {
        
        const destination = jobDir.attributes.entreprise.data.attributes.adresse
        const queryDirD = qs.stringify({
          origins: originUser ,
          destinations: destination,
          mode: modeToGet,
          key:'AIzaSyD03zG8gEBoPIWCZDb-Rh1jnrC252IRDjQ',
          }, {
            encodeValuesOnly: true,
          });

        let sentence = `https://maps.googleapis.com/maps/api/distancematrix/json?${queryDirD}`
        axios.post(`${URL}fetchTrajetGoogle`, {
          data: {
            sentence: sentence
          },
        })
        .then((response) => {
          store.dispatch(setShowDistance(false))
          const distanceKm = response.data.rows[0].elements[0].distance
          const distanceTime = response.data.rows[0].elements[0].duration
          const goodJob = jobsToMap.find(jobToChange => jobToChange.id === jobDir.id)
          const goodJobId = jobsToMap.findIndex(jobToChange => jobToChange.id === jobDir.id)
          goodJob.direction = {}
          goodJob.direction.km = distanceKm
          goodJob.direction.time = ''
          goodJob.direction.time = distanceTime
          goodJob.direction.mode = modeToGet
          jobsToMap.splice(goodJobId, 1, goodJob)
          store.dispatch(setFullPageLoaderDistance(true))
          store.dispatch(saveDistanceOnJobs(jobsToMap)) 
          store.dispatch(setShowDistance(true))


          countIndex ++

          if((jobsToMap.length) === countIndex) {

            store.dispatch(setFullPageLoaderDistance(false))
          }
        })
        .catch((error) => {
          console.error(error.message);
        })

        return ''
      })

      /*
      jobsToMap.map((jobDir) => {
        const destination = jobDir.attributes.entreprise.data.attributes.adresse

        axios.get(`https://www.mapquestapi.com/directions/v2/route?${queryDirD}`)
          .then((response) => {
            const distanceKmD = Math.round(response.data.route.distance)
            const distanceTimeD = response.data.route.formattedTime
            const mode = 'voiture'
            const goodJob = jobsToMap.find(jobToChange => jobToChange.id === jobDir.id)
            const goodJobId = jobsToMap.findIndex(jobToChange => jobToChange.id === jobDir.id)
            goodJob.distanceD = {}
            goodJob.distanceD.km = distanceKmD
            goodJob.distanceD.time = distanceTimeD
            goodJob.distanceD.mode = mode
            jobsToMap.splice(goodJobId, 1, goodJob)
          })
          .catch((error) => {
            console.error(error.message);
          })
          axios.get(`https://www.mapquestapi.com/directions/v2/route?${queryDirV}`)
          .then((response) => {
            const goodJobBis = jobsToMap.find(jobToChange => jobToChange.id === jobDir.id)
            const goodJobIdBis = jobsToMap.findIndex(jobToChange => jobToChange.id === jobDir.id)
            const distanceKmV = Math.round(response.data.route.distance)
            const distanceTimeV = response.data.route.formattedTime
            const mode = 'velo'
            goodJobBis.distanceV = {}
            goodJobBis.distanceV.km = distanceKmV
            goodJobBis.distanceV.time = distanceTimeV
            goodJobBis.distanceV.mode = mode
            jobsToMap.splice(goodJobIdBis, 1, goodJobBis)
          })
          .catch((error) => {
            console.error(error.message);
          });
      })
      store.dispatch(saveDistanceOnJobs(jobsToMap)) 
      */
    next(action);
    break;
    }
    case FETCH_PUT_JOB_OFFER: {
      const state = store.getState();
      const nomOfManager = action.nomOfManager
      const prenomOfManager = action.prenomOfManager

      store.dispatch(changeJobsAccountLoading(true))

      let data = {}
      if (state.job.secteurJobPut !== undefined && state.job.secteurJobPut !== '') {
        data.secteurs = state.job.secteurJobPut
      }
      if (state.job.ficheJobPut !== undefined && state.job.ficheJobPut !== '') {
        data.fiche_metier = state.job.ficheJobPut
      } 
      if (state.job.nomjobPut !== undefined && state.job.nomjobPut !== '') {
        data.nom = state.job.nomjobPut
      } 
      if (state.job.remunerationjobPut !== undefined && state.job.remunerationjobPut !== '') {
        data.remuneration = state.job.remunerationjobPut
      } 
      if (state.job.contratjobPut !== undefined && state.job.contratjobPut !== '') {
        data.type_emploi = state.job.contratjobPut
      } 
      if (state.job.localisationjobPut !== undefined && state.job.localisationjobPut !== '') {
        data.lieu = state.job.localisationjobPut
      } 
      if (state.job.descriptifjobPut !== undefined && state.job.descriptifjobPut !== '') {
        data.description = state.job.descriptifjobPut
      } 
      if (state.job.missionjobPut !== undefined && state.job.missionjobPut !== '') {
        data.missions = state.job.missionjobPut
      } 
      if (state.job.profiljobPut !== undefined && state.job.profiljobPut !== '') {
        data.profil = state.job.profiljobPut
      } 
      if (nomOfManager !== '') {
        data.nom_manager = nomOfManager
      } 
      if (prenomOfManager !== '') {
        data.prenom_manager = prenomOfManager
      } 
      if (Object.keys(data).length > 0) {
      axios.put(`${URL}offre-emplois/${action.id}`, {
        data
      },
      {
      headers: {
        Authorization:
          `Bearer ${state.login.session.jwt}`,
        },
      }
      )
        .then((response) => {
          store.dispatch(fetchJobOffer(state.login.userInfo.data[0].id, 5, (Object.keys(state.job.jobsEntreprisePagination).length > 0 ? state.job.jobsEntreprisePagination.pageCount : 1)))
          toast(`Votre offre a été modifiée`, {
            position: "bottom-right",
            type: "success",
            theme:"colored",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
        .catch((error) => {
          console.error(error.message);
          store.dispatch(setFullPageLoaderJobOfferAction(false))
        });
      } else {
        store.dispatch(setFullPageLoaderJobOfferAction(false))
        toast(`Merci de remplir au moins 1 champ.`, {
          position: "bottom-right",
          type: "error",
          theme:"colored",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
      next(action);
      break;
    }
    case SIGN_CONVENTION: {
      const state = store.getState();
      const pdfFile = action.urlImage
      const entrepriseId = action.entrepriseId
      const fileName = action.fileName

      const formData = new FormData()
      formData.append('files', pdfFile, fileName)
      formData.append("refId", entrepriseId);
      formData.append("ref", "api::entreprise.entreprise");
      formData.append("field", "convention");

      axios.post(`${URL}upload`, formData,
      {
        headers: {
          Authorization:
          `Bearer ${state.login.session.jwt}`,
        }
      })
      .then((response) => {
        store.dispatch(fetchPutSignedConvention(state.modal.modalConventionEntrepriseId, response.data[0].url))
        toast(`Votre convention a été envoyé signé à notre équipe.`, {
          position: "bottom-right",
          type: "success",
          theme:"colored",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .catch((error) => {
        console.error(error);
        store.dispatch(setFullPageLoader(false))
      });

      next(action);
      break;
    }
    case FETCH_PUT_SIGNED_CONVENTION: {
      const state = store.getState();
      const entrepriseId = action.id
      const nomE = state.login.userInfo.data[0].attributes.Nom_entreprise
      const urlConvention = `https://www.test-t6dnbai-h5b47fjo5ugck.ovhcloud-fr-1.webpaas.ovh.net${action.url}`
      axios.put(`${URL}entreprises/${entrepriseId}`, 
      {
        data: {
          convention_signed: true
        }
      },
      {
        headers: {
          Authorization:
          `Bearer ${state.login.session.jwt}`,
        }
      })
      .then((response) => {
        store.dispatch(fetchUser())
        store.dispatch(toggleModalConvention(false, 0, '', ''))
        store.dispatch(fetchSendEmailConventionSigned(nomE, urlConvention))
        store.dispatch(setFullPageLoader(false))
      })
      .catch((error) => {
        store.dispatch(setFullPageLoader(false))
      });
      next(action);
      break;
    }
    case FETCH_SEND_EMAIL_CONVENTION_SIGNED: {
      const state = store.getState();
      const nomE = action.nomE
      const url = action.url
      axios.post(`${URL}sendemailConventionSigned`, {
          data: {
            nomE: nomE,
            url: url,
          }
      },
      )
        .then((response) => {
        })
        .catch((error) => {
        });
      next(action);
      break;
    }
    case FETCH_DEMANDES_JOB_FROM_JOB_OFFER: {
      const state = store.getState();
      const offerId = action.id
      const queryFilterPart = qs.stringify({
        filters: {
          offre_emplois_postule: {
            id: offerId
          }
        },
        populate: {
          cv: {
            populate: '*'
          }
        }
        }, {
          encodeValuesOnly: true,
        });

      if(state.job.demandesFromJobOffer.length > 0) {
        const findedOfferObj = state.job.demandesFromJobOffer.find(offer => offer.demandesFromJobOffer === offerId)

        if(findedOfferObj < 1) {
          axios.get(`${URL}particuliers?${queryFilterPart}`, {})
            .then((response) => {
              const objectToPush = {
                  demandesFromJobOffer : offerId,
                  offerData : response.data.data
                }

              store.dispatch(saveDemandesFromJobOffer(objectToPush, `demandesFromJobOffer_${offerId}`))
            })
            .catch((error) => {
              console.error(error);
            });
          }
      } else {
      axios.get(`${URL}particuliers?${queryFilterPart}`, {})
        .then((response) => {
          const objectToPush = {
              demandesFromJobOffer : offerId,
              offerData : response.data.data
            }
          store.dispatch(saveDemandesFromJobOffer(objectToPush, `demandesFromJobOffer_${offerId}`))
        })
        .catch((error) => {
          console.error(error);
        });
      }
      next(action);
      break;
    }
    case FETCH_SCORES_FORMATION: {
      const state = store.getState();
      const userId = action.userId
      const queryFilterPart = qs.stringify({
        filters: {
          particulier: {
            id: userId
          }
        }
        }, {
          encodeValuesOnly: true,
        });

      axios.get(`${URL}score-formations?${queryFilterPart}`, {})
        .then((response) => {
          store.dispatch(saveMyScoresFormation(response.data.data))
        })
        .catch((error) => {
          console.error(error);
        });
      next(action);
      break;
    }
    case FETCH_CANDIDAT_FROM_JOB_OFFER: {
      store.dispatch(setFullPageLoaderCandidat(true))
      const state = store.getState();
      const offerId = action.offerId
      const page = action.candidatPage
      const candidatNumber = action.candidatNumber

      const queryFilterPart = qs.stringify({
        populate: '*',
        filters: {
          offre_emplois_postule: {
            id: offerId
          }
        },
        pagination: {
          page: page,
          pageSize: candidatNumber,
        },
        sort: ['publishedAt:desc']
        }, {
          encodeValuesOnly: true,
        });

      axios.get(`${URL}particuliers?${queryFilterPart}`, {})
        .then((response) => {
          store.dispatch(saveCandidatsFromJob(response.data.data, response.data.meta.pagination))
          store.dispatch(setFullPageLoaderCandidat(false))
        })
        .catch((error) => {
          store.dispatch(setFullPageLoaderCandidat(false))
          console.error(error);
        });

      next(action);
      break;
    }
    default:
      next(action);
  }
};

export default jobsMiddleware;