import { connect } from 'react-redux'

import SignInEntreprise from '../../pages/SignInEntreprise'

import { 
    changeSigninStep,
    changeValueSignin
} from '../../actions/signin'

const mapStateToProps = (state) => ({
    signInStep: state.signin.signInStep
})

const mapDispatchToProps = (dispatch) => ({
    changeSigninStep: (number) => {
        dispatch(changeSigninStep(number));
    },
    changeValueSignin: (value, name) => {
        dispatch(changeValueSignin(value, name));
      },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(SignInEntreprise)