import { connect } from 'react-redux'

import AccountProfil from '../../pages/AccountProfil'

import { 
  changeValueSignin,
  fetchSearchAdresse,
  fetchCvParticulier
} from '../../actions/signin'

import { changeAccountValue, fetchPutAccountP, fetchPutAccountE, fetchSiren, setFullPageLoaderPutAccount } from '../../actions/login'

const mapStateToProps = (state) => ({
  days: state.login.days, 
  mounths: state.login.mounths, 
  accountBdMounth: state.login.accountBdMounth, 
  accountBdDay: state.login.accountBdDay,
  currentProfilUser: state.menu.currentProfilUser,
  JobSectors: state.signin.JobSectors,
  accountJobSector: state.signin.accountJobSector,
  accountSecteur: state.login.accountSecteur,
  user: state.login.session.user,
  userInfo: state.login.userInfo,
  userRole: state.login.userRole,
  isLogged: state.login.isLogged,
  accountAdresseNumStreet: state.signin.accountAdresseNumStreet,
  accountAdresseStreet: state.signin.accountAdresseStreet ,
  accountAdresseCode: state.signin.accountAdresseCode ,
  accountAdresseCity: state.signin.accountAdresseCity,
  accountStatut: state.login.accountStatut,
  accountAdresseSignin: state.signin.accountAdresseSignin,
  adresseList: state.signin.adresseList,
})

const mapDispatchToProps = (dispatch) => ({
    changeAccountValue: (value, name) => {
        dispatch(changeAccountValue(value, name));
      },
    fetchPutAccountP: () => {
      dispatch(fetchPutAccountP());
    },
    fetchPutAccountE: () => {
      dispatch(fetchPutAccountE());
    },
    fetchSiren: (siren) => {
      dispatch(fetchSiren(siren));
    },
    fetchSearchAdresse: (adresse) => {
      dispatch(fetchSearchAdresse(adresse));
    },
    changeValueSignin: (value, name) => {
      dispatch(changeValueSignin(value, name));
    },
    fetchCvParticulier: (file, particulierId) => {
      dispatch(fetchCvParticulier(file, particulierId));
    },
    setFullPageLoaderPutAccount: (bool) => {
      dispatch(setFullPageLoaderPutAccount(bool));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(AccountProfil)