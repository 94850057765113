/**** Import des modules ****/
import React from 'react'
import PropTypes from 'prop-types'
import { VscTools } from "react-icons/vsc"
import { FaLinkedin, FaEnvelope, FaPhoneSquare } from "react-icons/fa"

/**** Import d'images ****/
import logo from '../../assets/img/logo/WEFOR_COULEUR.png'

/**** Import des css ****/
import MaintenanceStyled from './MaintenanceStyled'

const Maintenance = () => {
  return (
    <MaintenanceStyled>
        <div>
          <VscTools className='vsc-tools'/>
          <h2 className="bold">Site web en maintenance </h2>
          <img src={logo} className="logo" alt="logo wefor foncé" />
        </div>
        <div>
          <p className="bold">Notre plateforme d'Elearning reste accessible !</p>
          <a href="https://elearning.wefor.fr/" target='_blank' rel='noreferrer'>
            <div className='btn-orange bold'>Connectez vous</div>
          </a>
        </div>
        <div className='contact-m'>
          <a href="https://www.linkedin.com/company/wefor2022/" target='_blank' rel='noreferrer'><FaLinkedin/></a>
          <a href="tel:0972575262" target='_blank' rel='noreferrer'><FaEnvelope /></a>
          <a href="mailto:contact@wefor.fr" target='_blank' rel='noreferrer'><FaPhoneSquare /></a>
        </div>
    </MaintenanceStyled>
  )
}

Maintenance.propTypes = {
}

export default Maintenance