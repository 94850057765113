import styled from 'styled-components';

const ArrowStyledStyled = styled.div`
    position: fixed;
    right: 50px;
    bottom: 50px;
    z-index: 250;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #649ACE;
    display: flex;
    align-items: center;
    justify-content: center;

    .arrow-up {
        color: #fff;
        font-size: 25px;
        cursor: pointer;
    }
`;

export default ArrowStyledStyled;