import styled from 'styled-components';

const JobCardStyled = styled.article`
    width: 270px;
    height: 350px;
    color: #d47558;
    border-radius: 30px; 
    padding: 15px;
    box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
    -webkit-box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
    -moz-box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
    position: relative;
    margin: 50px 10px 0 25px;
    text-align: right;
    box-sizing: border-box;
    background-color: #fff;
    position: relative;

    .absolute-img {
        position: absolute;
        width: 90px;
        height: 90px;
        top: -25px;
        left: -25px;
        border-radius: ${props => props.typeCard === 'jobs' ? '50%' : '10px'}
    }

    .top-wrapper {
        width: 100%;
        display: flex;
        align-items: flex-end;
        flex-direction: column;

        .skeleton-txt {
            width: 50%;
            font-size: 25px;
        }
    }

    .center-wrapper {
        margin: 30px 0 20px;

        .skeleton-rect {
            width: 100%;
            height: 50px;
        }

        .skeleton-txt {
            width: 30%;
            height: 30px;
            margin: 10px auto;
        }
    }

    .bottom-wrapper {
        .skeleton-btn {
            width: 60%;
            height: 25px;
            border-radius: 10px;
            margin: 0 auto 15px;

        }
    }


    @media only screen and (max-width: 1400px) {
        width: 270px;
        border-radius: 30px; 
        padding: 15px;
        margin: 40px 10px 0 10px;
    }

    @media only screen and (min-width: 760px) and (max-width: 1079px) {
        .mobile-none {
            display: none;
        }
    }
  
    @media only screen and (min-width: 0px) and (max-width: 759px) {
      
    }
`;

export default JobCardStyled;