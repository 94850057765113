import styled from 'styled-components';
import bcgHeader from '../../assets/img/header/financement-ill.jpg'

const FinancementStyled = styled.main`
    padding-top: 0px;

    .illustration-header {
        width: 100vw;
        height: 700px;
        padding-top: 125px;
        z-index: 10;
        position: relative;
        margin-bottom: 150px;
    
        &::before {
            content:'';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width:100%;
            background: linear-gradient(180deg, rgba(214,231,245,1) 0%, rgba(100,154,206,1) 100%);
            clip-path: polygon(0% 0%,0% 80.6708%, 1.69492% 78.8616%, 3.38983% 77.0922%, 5.08475% 75.3821%, 6.77966% 73.75%, 8.47458% 72.2138%, 10.1695% 70.7902%, 11.8644% 69.495%, 13.5593% 68.3422%, 15.2542% 67.3446%, 16.9492% 66.513%, 18.6441% 65.8565%, 20.339% 65.3824%, 22.0339% 65.0959%, 23.7288% 65%, 25.4237% 65.0959%, 27.1186% 65.3824%, 28.8136% 65.8565%, 30.5085% 66.513%, 32.2034% 67.3446%, 33.8983% 68.3422%, 35.5932% 69.495%, 37.2881% 70.7902%, 38.9831% 72.2138%, 40.678% 73.75%, 42.3729% 75.3821%, 44.0678% 77.0922%, 45.7627% 78.8616%, 47.4576% 80.6708%, 49.1525% 82.5%, 50.8475% 84.3293%, 52.5424% 86.1385%, 54.2373% 87.9078%, 55.9322% 89.6179%, 57.6271% 91.25%, 59.322% 92.7862%, 61.017% 94.2098%, 62.7119% 95.505%, 64.4068% 96.6578%, 66.1017% 97.6555%, 67.7966% 98.487%, 69.4915% 99.1435%, 71.1864% 99.6176%, 72.8814% 99.9041%, 74.5763% 100%, 76.2712% 99.9041%, 77.9661% 99.6176%, 79.661% 99.1435%, 81.3559% 98.487%, 83.0509% 97.6555%, 84.7458% 96.6578%, 86.4407% 95.505%, 88.1356% 94.2098%, 89.8305% 92.7862%, 91.5254% 91.25%, 93.2203% 89.6179%, 94.9153% 87.9078%, 96.6102% 86.1385%, 98.3051% 84.3293%, 100% 82.5%, 100% 0%);
            shape-outside: polygon(0% 0%,0% 80.6708%, 1.69492% 78.8616%, 3.38983% 77.0922%, 5.08475% 75.3821%, 6.77966% 73.75%, 8.47458% 72.2138%, 10.1695% 70.7902%, 11.8644% 69.495%, 13.5593% 68.3422%, 15.2542% 67.3446%, 16.9492% 66.513%, 18.6441% 65.8565%, 20.339% 65.3824%, 22.0339% 65.0959%, 23.7288% 65%, 25.4237% 65.0959%, 27.1186% 65.3824%, 28.8136% 65.8565%, 30.5085% 66.513%, 32.2034% 67.3446%, 33.8983% 68.3422%, 35.5932% 69.495%, 37.2881% 70.7902%, 38.9831% 72.2138%, 40.678% 73.75%, 42.3729% 75.3821%, 44.0678% 77.0922%, 45.7627% 78.8616%, 47.4576% 80.6708%, 49.1525% 82.5%, 50.8475% 84.3293%, 52.5424% 86.1385%, 54.2373% 87.9078%, 55.9322% 89.6179%, 57.6271% 91.25%, 59.322% 92.7862%, 61.017% 94.2098%, 62.7119% 95.505%, 64.4068% 96.6578%, 66.1017% 97.6555%, 67.7966% 98.487%, 69.4915% 99.1435%, 71.1864% 99.6176%, 72.8814% 99.9041%, 74.5763% 100%, 76.2712% 99.9041%, 77.9661% 99.6176%, 79.661% 99.1435%, 81.3559% 98.487%, 83.0509% 97.6555%, 84.7458% 96.6578%, 86.4407% 95.505%, 88.1356% 94.2098%, 89.8305% 92.7862%, 91.5254% 91.25%, 93.2203% 89.6179%, 94.9153% 87.9078%, 96.6102% 86.1385%, 98.3051% 84.3293%, 100% 82.5%, 100% 0%);
            z-index: -10;
        }
    
        .opacity-light-section {
            height: 100%;
            top: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: center;
            padding: 0px 0;
            max-width: 1300px;
            margin: 0 auto;
    
            .left {
                width: calc(50%);
                margin-left: 5%;
                padding-top: 100px;
                text-align: right;
    
                h1 {
                    font-size: 50px;
                    color: #202c3c;
                    text-align: left;
                }

                .btn-contact {
                    padding: 15px 30px;
                    font-size: 22px;
                    background-color: rgba(100,154,206,1);
                    color: #fff;
                    border: 0; 
                    border-radius: 30px;
                    color: #fff;
                    cursor: pointer;

                    &:hover {
                        background-color: rgba(214,231,245,1);
                        color: rgba(100,154,206,1);
                    }
                }
    
                p {
                    font-size: 25px;
                    color: #fff;
                    line-height: 30px;
                    padding: 30px 0;
                    text-align: justify;
                }
            }
            img {
                margin-top: 50px;
                box-sizing: border-box;
                width: 50%;
                max-width: 580px;
            }
        }
    }


    .description-financement {
        max-width: 1200px;
        padding: 50px 0 50px;
        margin: 0 auto;

        p {
            color: #656464;
            line-height: 25px;
        }
    }

    .section-financement:nth-child(even) {
        background-color: #E1EDF7;
    }

    .section-financement {
        padding: 50px 0 50px;

        .wrapper-maxwidth {
            max-width: 1200px;
            margin: 0 auto;
            text-align: center;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            h2 {
                font-size: 50px;
                display: inline-block;
                margin: 0 auto;

                span {
                    position: relative;
                }
    
                .separator-blue {
                    background-color: #6399ce;
                    height: 6px;
                    width: 240px;
                    margin: 10px 0 0 auto;
                    position: absolute;
                    right: 0;
                }
            }

            h3 {
                font-size: 20px;
                width: 100%;
                color: #656464;
                text-align: left;
                margin: 50px 0 0px;
            }

            .p-wrapper {
                font-size: 18px;
                line-height: 25px;
                text-align: justify;
                width: 55%;

                p {
                    color: #656464;
                    margin: 20px 0 0 0;
                }
            }

            .video-wrapper {
                width: 40%;
                margin: 20px 0 0 0;

                iframe {
                    width 100%;
                    height: 250px;
                }
            }
        }
    }

    .bcg-blue-wrapper {
        background: linear-gradient(180deg,rgba(248,253,255,0) 0%,rgba(241,252,255,0.51782) 48.22%,#F6FDFF 100%);
    }

    .contact-link {
        font-size: 24px;
        text-align: center;
        margin: 50px 0;
    }

    .step-one-section, .step-two-section, .step-three-section {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-between;
        width: 96%;
        max-width: 1300px;
        margin: 0px auto;
        padding: 50px 0;
        color: #4d4d4d;
        position: relative;

        .uppercase {
            text-transform: uppercase;
        }

        .left-wrapper {
            width: 28%;
            min-height: 500px;

            .card-wrapper-step {
                background: linear-gradient(180deg,rgba(248,253,255,0) 0%,rgba(241,252,255,0.51782) 48.22%,#F6FDFF 100%);
                box-shadow: 0 0.8rem 1.8rem 0 rgb(31 21 80 / 15%);
                border-radius: 15px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                padding: 0px 0;
                text-align: center;
                box-sizing: border-box;
                height: 100%;
                overflow: hidden;
                transition: all .2s;

                &:hover {
                    transform: scale(1.02);
                    cursor: pointer;
                    background-color: #dbe8f4;
                }

                .red {
                    color: #dc4a46;
                }

                h3 {
                    font-size: 30px;
                    width: 100%;
                    padding: 15px 0 15px;
                    color: #fff;
                    background-color: #202c3c;
                }

                h2 {
                    font-size: 24px;
                    padding: 30px 10px 0; 
                    width: 100%;
                }

                img {
                    margin-top: 50px;
                    width: 50%;
                }

                .logo-list {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    margin-top: 50px;

                    a {
                        height: 70px;
                        width: 70px;
                        margin: 10px 10px 10px 10px;
                        transition: all .2s;
                        display: flex;
                        align-items: center;

                        &:hover {
                            transform: scale(1.02);
                        }
                    }

                    img {
                        width: 100%;
                        margin: 0;
                    }
                }
            }
        }

        .right-wrapper {
            width: 68%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            box-shadow: 0 0.8rem 1.8rem 0 rgb(31 21 80 / 15%);
            padding: 20px;
            border-radius: 15px;
            box-sizing: border-box;
            background: linear-gradient(180deg,rgba(248,253,255,0) 0%,rgba(241,252,255,0.51782) 48.22%,#F6FDFF 100%);

            p {
                text-align: justify;
                font-size: 18px;
                line-height: 26px;
            }

            .paiement {
                margin-top: 10px;
            }


            img {
                width: 60px;
                margin-right: 30px;
                margin-top: 20px
            }

            .cb-wrapper {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-top: 10px;

                img {
                    width: 60px;
                    margin-right: 30px;
                    margin-top: 20px
                }
            }

            .video-wrapper {
                width: 100%;
                height: 100%;
                margin-top: 50px;
                text-align: center;

                iframe {
                    height: 100%;
                    width: 100%;
                }
            }
        }

        .two-grid {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;

            .case-title {
                color: #202c3c;
                font-size: 22px;
                text-transform: uppercase;
                padding-bottom: 20px;
            }

            .p-wrapper {
                width: 45%;
            }
        }
    }

    .and-or {
        margin: 0 auto;
        font-size: 30px;
        width: 250px;
        text-transform: uppercase;
        text-align: center;
        padding: 20px 0;
        background-color: #202c3c;
        border-radius: 15px;
        color: #fff;
        position: absolute;
        bottom: -35px;
        left: calc(50% - 125px);
        display: none;
    }

    @media only screen and (max-width: 1400px) {
        .illustration-header {
    
            .opacity-light-section {

                img {
                    margin-left: 0px;
                }

                .left {
                    margin-left: 0;
                }
            }
        }
        .description-financement, .section-financement {
            padding: 50px 50px;

            .wrapper-maxwidth {
                h2 {
                    font-size: 40px;
                }
            }
        }
    }
    
    @media only screen and (max-width: 1079px) {

        .illustration-header {
    
            .opacity-light-section {
                height: auto;
                flex-direction: column-reverse;
    
                .left{
                    padding: 50px 0 0 0;
                    width: calc(96%);
                    margin: 0 auto;
                    height: 300px;
                    text-align: center;
    
                    h1 {
                        font-size: 35px;
                        text-align: center;
                        margin-top: 20px;
                    }
    
                    p {
                        font-size: 18px;
                        line-height: 22px;
                        padding: 15px 0;
                        text-align: center;
                    }
                }
                img {
                    max-width: 400px;
                    margin-left: 0px;
                    margin: 0 auto;
                }
            }
        }

        .description-financement, .section-financement {
            padding: 50px 50px;

            .wrapper-maxwidth {
                h2 {
                    font-size: 30px;

                    .separator-blue {
                        width: 100px;
                    }
                }

                .p-wrapper {
                    width: 100%;
                }
    
                .video-wrapper {
                    width: 100%;
                    margin: 20px 0 0 0;
                }
            }
        }

    }
    
    @media only screen and (min-width: 0px) and (max-width: 759px) {
        .illustration-header {

            height: 450px;

            &::before {
                clip-path: none;
            }
    
            .opacity-light-section {
                flex-direction: column-reverse;
    
                .left {
                    margin:0 auto;
                    width: 96%;
                    box-sizing: border-box;
                    height: 250px;
                    display: flex; 
                    justify-content: center;
                    flex-direction: column;
                    padding-top: 0;
    
                    h1 {
                        font-size: 35px;
                        text-align: center;
                    }
    
                    p {
                        font-size: 18px;
                        line-height: 22px;
                        text-align: justify;
                        padding: 20px 0;
                        width: 100%;
                        margin: 0 auto;
                    }
                }
                img {
                    width: 80%;
                    max-width: 250px;
                }
            }
        }

        .step-one-section, .step-two-section, .step-three-section {

            flex-direction: column;
            

            .right-wrapper {
                width: 100%;
            }

            .left-wrapper {
                width: 100%;
                min-height: 400px;

                .card-wrapper-step img {
                    margin-bottom: 10px;
                }
            }
        }

        .description-financement, .section-financement {
            padding: 50px 20px;

            .wrapper-maxwidth {
                h2 {
                    .separator-blue {
                        width: 50px;
                        position: static;
                        margin: 10px auto 0;
                    }
                }
            }
        }
    }
`;

export default FinancementStyled;