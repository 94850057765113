import { connect } from 'react-redux'

import CourseDoneCard from '../../components/CourseDoneCard'

const mapStateToProps = (state) => ({
    activCourseId: state.course.activCourseId,
    userInfo: state.login.userInfo
})

const mapDispatchToProps = (dispatch) => ({
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(CourseDoneCard)