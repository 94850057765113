export const initialState = {
  list: [
    {
      id: "1",
      name: "MOBILISEZ VOTRE COMPTE CPF",
      step: "Étape 1",
      cible: "Qui est concerné ? salarié, indépendant, demandeur d’emploi, sans activité professionnelle.",
      text: [
        "Le Compte Personnel de Formation (CPF) remplace le Droit Individuel à la Formation (DIF) et permet à tout travailleur d’acquérir des droits à la formation. Depuis 2019, le compte n’est plus crédité en heures, mais en euros. Les droits acquis ne sont pas perdus et peuvent être mobilisés à tout instant (changement de statut, période de chômage, période travaillée…). Le CPF peut être utilisé par toute personne à partir de 16 ans, dès son entrée sur le marché du travail (15 ans pour le jeune ayant signé un contrat d’apprentissage) et ce jusqu’à son départ à la retraite. Les retraités ayant une activité et bénéficiant du cumul emploi-retraite peuvent également mobiliser leur CPF.",
      ],
      bcgColor: "#fff",
      videoUrl:"https://www.youtube.com/watch?v=_CliF89mJmU&ab_channel=COMPTALIA",
      videoId: "_CliF89mJmU",
      for: ["Salarié", "Entreprise", "Demandeur d'emploi", "Autres", "notChoose"]
    },
    {
      id: "2",
      name: "En cas de reste à charge.",
      subname: "SOLLICITEZ VOTRE PÔLE EMPLOI",
      step: "Étape 2",
      cible: "Qui est concerné ? demandeur d’emploi.",
      text: [
        "Pôle emploi finance prioritairement les formations des demandeurs d’emploi. La condition est d’être inscrit à Pôle emploi et d’avoir validé son projet de formation avec un conseiller Pôle emploi. Dans la majorité des situations, le coût pédagogique est pris en charge. Cependant, en fonction des financeurs et de votre statut à la date d’entrée en formation, il est parfois demandé aux stagiaires une participation financière aux frais de formation. Renseignez-vous avant votre entrée en formation. ",
        "Dans le cas d’une formation agréée par la Région au titre de la rémunération des stagiaires, et selon la distance qui vous sépare de votre domicile au lieu de stage, des possibilités s’offrent à vous : ",
        "l’AFPA offre une possibilité d’hébergement et de restauration pour chaque stagiaire qui suit une action de formation (dans la limite des places disponibles) Accessible à certains demandeurs d’emploi, (sous condition), Illico Solidaire permet un accès à des tarifs préférentiels ou à la gratuité des transports avec une carte transport de la région Rhône-Alpes valable 1 an."
      ],
      bcgColor: "#E1EDF7",
      videoUrl:"https://www.youtube.com/watch?v=Bm1yJNJ_rv0&ab_channel=P%C3%B4leemploi",
      videoId: "Bm1yJNJ_rv0",
      for: ["Demandeur d'emploi", "Autres", "notChoose"]
    },
    {
      id: "3",
      name: "En cas de reste à charge.",
      subname: "SOLLICITEZ LE CAS ECHEANT VOTRE ENTREPRISE",
      step: "Étape 2",
      cible: "Qui est concerné ? salarié.",
      text: [
        "Un OPCO (Opérateur de Compétences) est un organisme agréé par l'Etat qui a pour but d'aider les entreprises ne dépassant pas 50 salariés à accompagner et à favoriser l'évolution professionnelle des salariés par le biais de la formation continue. En facilitant l'accès des actifs à des actions de formation, les OPCO permettent aux (futurs) professionnels de mieux faire face aux mutations technologiques, économiques et environnementales de leur secteur d'activité.",
        "Les OPCO sont donc des acteurs clés de la formation professionnelle. Ils pourront contribuer activement au financement des vos futures formations au même titre qu'un dispositif comme le CPF (Compte Personnel de Formation).",
        "Pour trouver l'OPCO dont vous dépendez, vous devez vous référer au code IDDC (Identifiant de Convention Collective), un numéro de 4 chiffres compris entre 0001 et 999 que vous trouverez en principe sur votre bulletin de salaire et également sur la page OPCO du site du ministère du Travail où vous pourrez télécharger une table de correspondance IDDC > OPCO."
      ],
      bcgColor: "#fff",
      videoUrl:"https://www.youtube.com/watch?v=SkOz9J4P5c8&ab_channel=CentredeformationCEFIOB",
      videoId: "SkOz9J4P5c8",
      for: ["Salarié", "Entreprise", "notChoose"]
    },
    {
      id: "4",
      name: "FINANCEMENT EN CAS DE PSE (Plan de Sauvegarde de l'Emploi)",
      step: "Étape 2",
      cible: "Qui est concerné ? salarié.",
      text: [
        "Lorsqu’une entreprise en difficulté financière comporte plus de 1000 salariés, elle a l’obligation d’indemniser les personnes dont le poste est supprimé à hauteur de leur salaire habituel, durant une période déterminée qui s’étale sur une période de 4 à 12 mois (durée légale). Cette période s’appelle le congé de reclassement. Le salarié continue donc d’être rémunéré par l’entreprise, mais il n’est plus en poste. ",
        "L’entreprise doit également fournir une enveloppe destinée à financer les actions de formation du salarié licencié, afin de favoriser son employabilité. Le budget alloué peut varier en fonction de l’objectif de la formation, simple montée en compétences ou reconversion totale, en fonction de l’âge du salarié, les seniors étant généralement privilégiés, voire en fonction du statut du salarié (cadre, agent de maîtrise…).",
        "Le salarié est accompagné par un cabinet externe afin de construire son projet. Cet accompagnement démarre en amont du licenciement, pour «préparer» le salarié à son départ, puis il se poursuit jusqu’à la fin du congé de reclassement. Le salarié doit avoir concrétisé son projet (formation, nouveau poste, création d’entreprise) avant la fin du congé de reclassement. Si ce n’est pas le cas, le salarié entre chez Pôle Emploi sous le statut de demandeur d’emploi classique."
      ],
      bcgColor: "#E1EDF7",
      videoUrl:"https://www.youtube.com/watch?v=ZNBTIfQIPw4&ab_channel=DerriennicAssoci%C3%A9s",
      videoId: "ZNBTIfQIPw4",
      for: ["Salarié", "Entreprise", "notChoose"]
    },
    {
      id: "5",
      name: "FONDS INTERPROFESSIONNEL DE FORMATION DES PROFESSIONNELS LIBÉRAUX FIF PL",
      cible: "Qui est concerné ? Indépendant.",
      text: [
        "Il a été créé à l’initiative de l’UNAPL (Union Nationale des Professions Libérales) et des organisations professionnelles adhérentes, conformément aux dispositions de la loi du 31 décembre 1991, portant sur la formation continue des Travailleurs Indépendants et des Professionnels Libéraux, faisant obligation à tous de s’acquitter de la Contribution à la Formation Professionnelle (CFP).",
        "L’univers des professionnels libéraux est d’une grande diversité : professions du Cadre de Vie, Juridiques, Techniques et de Santé. Les thèmes de formation sont tout aussi diversifiés que les professions le sont entre elles. Les organisations syndicales étudient et arrêtent chaque année des critères (thèmes et montants) de prise en charge spécifiques à leur profession. ",
        "Tout professionnel libéral qui souhaite bénéficier d’une prise en charge doit compléter préalablement sa demande de prise en charge en ligne."
      ],
      bcgColor: "#fff",
      videoUrl:"https://www.youtube.com/watch?v=HL50js7enzw&ab_channel=FabriceMouchain",
      videoId:"HL50js7enzw",
      for: ["Autres", "notChoose"]
    },
    {
      id: "6",
      name: "En cas de reste à charge",
      subname: "FINANCEZ DIRECTEMENT VOS FORMATIONS",
      step: "Étape 3",
      cible: "Qui est concerné ? Indépendant.",
      text: [
        "Vous pouvez payer partiellement ou l'intégralité de vos formations par carte bleue.",
        "",
      ],
      bcgColor: "#fff",
      videoUrl:"",
      videoId:"",
      for: ["Autres", "notChoose"]
    },
    {
      id: "7",
      name: "FINANCEZ LES FORMATIONS DE VOS COLLABORATEURS.",
      subname: "VIA VOTRE OPCO",
      step: "Étape 2",
      cible: "Qui est concerné ? salarié.",
      text: [
        "Un OPCO (Opérateur de Compétences) est un organisme agréé par l'Etat qui a pour but d'aider les entreprises ne dépassant pas 50 salariés à accompagner et à favoriser l'évolution professionnelle des salariés par le biais de la formation continue. En facilitant l'accès des actifs à des actions de formation, les OPCO permettent aux (futurs) professionnels de mieux faire face aux mutations technologiques, économiques et environnementales de leur secteur d'activité.",
        "Les OPCO sont donc des acteurs clés de la formation professionnelle. Ils pourront contribuer activement au financement des vos futures formations au même titre qu'un dispositif comme le CPF (Compte Personnel de Formation).",
        "Pour trouver l'OPCO dont vous dépendez, vous devez vous référer au code IDDC (Identifiant de Convention Collective), un numéro de 4 chiffres compris entre 0001 et 999 que vous trouverez en principe sur votre bulletin de salaire et également sur la page OPCO du site du ministère du Travail où vous pourrez télécharger une table de correspondance IDDC > OPCO."
      ],
      bcgColor: "#fff",
      videoUrl:"https://www.youtube.com/watch?v=SkOz9J4P5c8&ab_channel=CentredeformationCEFIOB",
      videoId: "SkOz9J4P5c8",
      for: ["Salarié", "Entreprise", "notChoose"]
    },
  ],
};

// reducer = traducteur d'une intention/action vers une modification du state
const financement = (state = initialState, action = {}) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default financement;