/**** Import de modules ****/
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { saveAs } from 'file-saver'
import { MdDownloadForOffline } from "react-icons/md"
import { RiBallPenFill } from "react-icons/ri";
import Skeleton from '@mui/material/Skeleton';
import axios from 'axios'
import * as qs from 'qs'
import MenuItem from '@mui/material/MenuItem';

import urlBackEndSave from '../../../assets/js/urlBackEndSave'
import urlBackEnd from '../../../assets/js/urlBackEnd'
const URL = urlBackEnd

/**** import des components ****/


/**** Import d'images' ****/


/**** Import des css ****/


const MenuItemS = ({ salarie, setSalarieClicked }) => {

  const [salarieAccount, setSalarieAccount] = useState('')


  useEffect(() => {
	if(salarieAccount === '') {
		const queryFilterObj = qs.stringify({
			filters: {
			particulier: {
				id: salarie.id
			},
			}
			}, {
			encodeValuesOnly: true,
			});

		axios.get(`${URL}users?${queryFilterObj}`, {})
		.then((response) => {
		setSalarieAccount(response.data[0])
		})
		.catch((error) => {
			console.error(error);
		});
	}
	}
  , [])
  
  return (
    <>
		{salarieAccount !== '' ?
			<span>
				{salarieAccount.prenom} {salarieAccount.nom}
			</span>
			:
			<Skeleton variant="text" sx={{ fontSize: '1rem', width: '150px' }} />
		}
    </>
  )
}

MenuItemS.propTypes = {
}

export default MenuItemS