import * as React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const steps = [
  'Informations de la formation et création des accès.',
  'Création de la session de formation.',
  'Acceder à notre plateform.',
];

export default function StepperComp({stepForModalLMSSession}) {
  return (
    <Stepper activeStep={stepForModalLMSSession} alternativeLabel>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}