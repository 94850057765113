export const CHANGE_ACTIV_JOB_ID = 'CHANGE_ACTIV_JOB_ID'
export const CHANGE_VALUE_NEWJOB = 'CHANGE_VALUE_NEWJOB'
export const CHANGE_VALUE_MODIF_JOB = 'CHANGE_VALUE_MODIF_JOB'
export const FETCH_JOBS = 'FETCH_JOBS'
export const SAVE_JOBS = 'SAVE_JOBS'
export const SAVE_JOBS_HOME = 'SAVE_JOBS_HOME'
export const SAVE_JOBS_PAGINATION = 'SAVE_JOBS_PAGINATION'
export const CHANGE_LOAD_JOBS = 'CHANGE_LOAD_JOBS'
export const FETCH_THIS_JOB_OFFER = 'FETCH_THIS_JOB_OFFER'
export const SAVE_CURRENT_JOB_FICHE = 'SAVE_CURRENT_JOB_FICHE'
export const SET_JOB_FICHE_ID = 'SET_JOB_FICHE_ID'
export const FETCH_JOB_POSTULER = 'FETCH_JOB_POSTULER'
export const FETCH_JOB_OFFER = 'FETCH_JOB_OFFER'
export const FETCH_JOB_OFFER_PAGE_CANDIDAT = 'FETCH_JOB_OFFER_PAGE_CANDIDAT'
export const SAVE_JOBS_ENTREPRISE = 'SAVE_JOBS_ENTREPRISE'
export const SAVE_JOBS_ENTREPRISE_PAGE_CANDIDAT = 'SAVE_JOBS_ENTREPRISE_PAGE_CANDIDAT'
export const FETCH_JOB_FICHES = 'FETCH_JOB_FICHES'
export const SAVE_JOB_FICHES = 'SAVE_JOB_FICHES'
export const FETCH_POST_JOB_OFFER = 'FETCH_POST_JOB_OFFER'
export const SET_SELECTION_JOB_OFFER = 'SET_SELECTION_JOB_OFFER'
export const FETCH_CONTACT_CANDIDAT = 'FETCH_CONTACT_CANDIDAT'
export const FETCH_DELETE_JOB_OFFER = 'FETCH_DELETE_JOB_OFFER'
export const FETCH_GET_DIRECTION = 'FETCH_GET_DIRECTION'
export const SAVE_DISTANCE_ON_JOBS = 'SAVE_DISTANCE_ON_JOBS'
export const SET_SHOW_DISTANCE = 'SET_SHOW_DISTANCE'
export const FETCH_PUT_JOB_OFFER = 'FETCH_PUT_JOB_OFFER'
export const CHANGE_JOBS_ACCOUNT_LOADING = 'CHANGE_JOBS_ACCOUNT_LOADING'
export const SET_FULL_PAGE_LOADER_DISTANCE = 'SET_FULL_PAGE_LOADER_DISTANCE'
export const SET_FULL_PAGE_LOADER_JOBINSC = 'SET_FULL_PAGE_LOADER_JOBINSC'
export const SET_FULL_PAGE_LOADER_JOB_OFFER_ACTION = 'SET_FULL_PAGE_LOADER_JOB_OFFER_ACTION'
export const SIGN_CONVENTION = 'SIGN_CONVENTION'
export const FETCH_PUT_SIGNED_CONVENTION = 'FETCH_PUT_SIGNED_CONVENTION'
export const FETCH_SEND_EMAIL_CONVENTION_SIGNED = 'FETCH_SEND_EMAIL_CONVENTION_SIGNED'
export const FETCH_DEMANDES_JOB_FROM_JOB_OFFER = 'FETCH_DEMANDES_JOB_FROM_JOB_OFFER'
export const SAVE_DEMANDES_FROM_JOB_OFFER = 'SAVE_DEMANDES_FROM_JOB_OFFER'
export const FETCH_SCORES_FORMATION = 'FETCH_SCORES_FORMATION'
export const SAVE_MY_SCORES_FORMATION = 'SAVE_MY_SCORES_FORMATION'
export const TOGGLE_MODAL_POSTULER_TUTO = 'TOGGLE_MODAL_POSTULER_TUTO'
export const SET_JOB_ID_MODAL_POSTULER_TUTO = 'SET_JOB_ID_MODAL_POSTULER_TUTO'
export const SET_CV_POSTER = 'SET_CV_POSTER'
export const SET_DID_TEST = 'SET_DID_TEST'
export const SET_DID_OCEAN = 'SET_DID_OCEAN'
export const SET_JOB_FOR_MODAL_POSTULER = 'SET_JOB_FOR_MODAL_POSTULER'
export const FETCH_CANDIDAT_FROM_JOB_OFFER = 'FETCH_CANDIDAT_FROM_JOB_OFFER'
export const SAVE_CANDIDATS_FROM_JOB = 'SAVE_CANDIDATS_FROM_JOB'
export const SET_FULL_PAGE_LOADER_CANDIDAT = 'SET_FULL_PAGE_LOADER_CANDIDAT'
export const SET_OFFRE_ID_HP = 'SET_OFFRE_ID_HP'

export const changeActivJobId = (id) => ({
    type: CHANGE_ACTIV_JOB_ID,
    id
})

export const changeValueNewJob = (value, name) => ({
    type: CHANGE_VALUE_NEWJOB,
    value,
    name,
})

export const changeValueModifJob = (value, name) => ({
    type: CHANGE_VALUE_MODIF_JOB,
    value,
    name,
})

export const fetchJobs = (paramObj) => ({
    type: FETCH_JOBS,
    paramObj
});
  
export const saveJobs = (jobs) => ({
    type: SAVE_JOBS,
    jobs,
});

export const saveJobsPagination = (data) => ({
    type: SAVE_JOBS_PAGINATION,
    data,
});

export const saveJobsHome = (jobs) => ({
    type: SAVE_JOBS_HOME,
    jobs,
});

export const changeLoadJobs = (bool) => ({
    type: CHANGE_LOAD_JOBS,
    bool,
});

export const fetchThisJobOffer = (id) => ({
    type: FETCH_THIS_JOB_OFFER,
    id,
})

export const saveCurrentJobFiche = (data) => ({
    type: SAVE_CURRENT_JOB_FICHE,
    data,
})

export const setJobFicheId = (id) => ({
    type: SET_JOB_FICHE_ID,
    id,
})

export const fetchJobPostuler = (id) => ({
    type: FETCH_JOB_POSTULER,
    id,
})

export const fetchJobOffer = (id, nbOffer, nbPage) => ({
    type: FETCH_JOB_OFFER,
    id,
    nbOffer, 
    nbPage
})

export const fetchJobOfferPageCandidat = (id) => ({
    type: FETCH_JOB_OFFER_PAGE_CANDIDAT,
    id,
})

export const saveJobsEntreprise = (data, dataPage) => ({
    type: SAVE_JOBS_ENTREPRISE,
    data,
    dataPage
})

export const saveJobsEntreprisePageCandidat = (data) => ({
    type: SAVE_JOBS_ENTREPRISE_PAGE_CANDIDAT,
    data,
})

export const fetchJobFiches = () => ({
    type: FETCH_JOB_FICHES,
})

export const saveJobFiches = (data) => ({
    type: SAVE_JOB_FICHES,
    data
})

export const fetchPostJobOffer = (nomOfManager, prenomOfManager) => ({
    type: FETCH_POST_JOB_OFFER,
    nomOfManager,
    prenomOfManager
})

export const setSelectionJobOffer = (data) => ({
    type: SET_SELECTION_JOB_OFFER,
    data
})

export const fetchContactCandidat = (candidatId) => ({
    type: FETCH_CONTACT_CANDIDAT,
    candidatId
})

export const fetchDeleteJobOffer = (id) => ({
    type: FETCH_DELETE_JOB_OFFER,
    id
})

export const fetchGetDirection = (mode) => ({
    type: FETCH_GET_DIRECTION,
    mode
})

export const saveDistanceOnJobs = (array) => ({
    type: SAVE_DISTANCE_ON_JOBS,
    array,
})

export const setShowDistance = (bool) => ({
    type: SET_SHOW_DISTANCE,
    bool,
})

export const fetchPutJobOffer = (id, nomOfManager, prenomOfManager) => ({
    type: FETCH_PUT_JOB_OFFER,
    id,
    nomOfManager,
    prenomOfManager
})

export const changeJobsAccountLoading = (bool) => ({
    type: CHANGE_JOBS_ACCOUNT_LOADING,
    bool,
})

export const setFullPageLoaderDistance = (bool) => ({
    type: SET_FULL_PAGE_LOADER_DISTANCE,
    bool
})

export const setFullPageLoaderJobInsc = (bool) => ({
    type: SET_FULL_PAGE_LOADER_JOBINSC,
    bool
})

export const setFullPageLoaderJobOfferAction = (bool) => ({
    type: SET_FULL_PAGE_LOADER_JOB_OFFER_ACTION,
    bool
})

export const signConvention = (entrepriseId, urlImage, fileName) => ({
    type: SIGN_CONVENTION,
    entrepriseId,
    urlImage,
    fileName
})

export const fetchPutSignedConvention = (id, url) => ({
    type: FETCH_PUT_SIGNED_CONVENTION,
    id,
    url
})

export const fetchSendEmailConventionSigned = (nomE, url) => ({
    type: FETCH_SEND_EMAIL_CONVENTION_SIGNED,
    nomE,
    url
})

export const fetchDemandesJobFromJobOffer = (id) => ({
    type: FETCH_DEMANDES_JOB_FROM_JOB_OFFER,
    id
})

export const saveDemandesFromJobOffer = (data, name) => ({
    type: SAVE_DEMANDES_FROM_JOB_OFFER,
    data,
    name
})

export const fetchScoresFormation = (userId) => ({
    type: FETCH_SCORES_FORMATION,
    userId
})


export const saveMyScoresFormation = (data) => ({
    type: SAVE_MY_SCORES_FORMATION,
    data
})

export const toggleModalPostulerTuto = (bool) => ({
    type: TOGGLE_MODAL_POSTULER_TUTO,
    bool
})

export const setJobIdModalPostulerTuto = (id, name) => ({
    type: SET_JOB_ID_MODAL_POSTULER_TUTO,
    id,
    name
})

export const setCvPosted = (bool) => ({
    type: SET_CV_POSTER,
    bool
})

export const setDidTest = (bool) => ({
    type: SET_DID_TEST,
    bool
})

export const setDidOcean = (bool) => ({
    type: SET_DID_OCEAN,
    bool
})

export const setJobForModalPostuler = (data) => ({
    type: SET_JOB_FOR_MODAL_POSTULER,
    data
})

export const fetchCandidatFromJobOffer = (offerId, candidatNumber, candidatPage) => ({
    type: FETCH_CANDIDAT_FROM_JOB_OFFER,
    offerId, 
    candidatNumber, 
    candidatPage
})

export const saveCandidatsFromJob = (data, dataPage) => ({
    type: SAVE_CANDIDATS_FROM_JOB,
    data,
    dataPage
})

export const setFullPageLoaderCandidat = (bool) => ({
    type: SET_FULL_PAGE_LOADER_CANDIDAT,
    bool
})

export const setOffreIdHP = (id) => ({
    type: SET_OFFRE_ID_HP,
    id
})