export const FETCH_INSCRIPTION_USER_LMS = 'FETCH_INSCRIPTION_USER_LMS'
export const FETCH_CREATE_SESSION_LMS = 'FETCH_CREATE_SESSION_LMS'
export const FETCH_PUT_USER_ON_SESSION = 'FETCH_PUT_USER_ON_SESSION'
export const FETCH_USER_SCORE = 'FETCH_USER_SCORE'
export const SAVE_USER_SCORE = 'SAVE_USER_SCORE'
export const FETCH_POST_OR_PUT_SCORE = 'FETCH_POST_OR_PUT_SCORE'
export const SAVE_TEMPLATE_LMS = 'SAVE_TEMPLATE_LMS'
export const FETCH_GET_COURSES_LMS = 'FETCH_GET_COURSES_LMS'
export const SAVE_COURSES_LMS = 'SAVE_COURSES_LMS'
export const FETCH_GET_USER_LMS = 'FETCH_GET_USER_LMS'
export const SAVE_USER_LMS_DATA = 'SAVE_USER_LMS_DATA'

export const fetchInscriptionUserLms = () => ({
    type: FETCH_INSCRIPTION_USER_LMS,
})


export const fetchCreateSessionLms = (duree, templateId) => ({
    type: FETCH_CREATE_SESSION_LMS,
    duree,
    templateId
})

export const fetchPutUserOnSession = (sessionId) => ({
    type: FETCH_PUT_USER_ON_SESSION,
    sessionId
})

export const fetchUserScore = () => ({
    type: FETCH_USER_SCORE,
})

export const saveUserScore = (data) => ({
    type: SAVE_USER_SCORE,
    data
})

export const FetchPostOrPutScore = () => ({
    type: FETCH_POST_OR_PUT_SCORE
})

export const saveTemplatesLms = (data) => ({
    type: SAVE_TEMPLATE_LMS,
    data
})

export const fetchGetCoursesLms = () => ({
    type: FETCH_GET_COURSES_LMS,
})

export const saveCoursesLms = (data) => ({
    type: SAVE_COURSES_LMS,
    data
})

export const fetchGetUserLms = (data) => ({
    type: FETCH_GET_USER_LMS,
    data
})

export const saveUserLmsData = (data) => ({
    type: SAVE_USER_LMS_DATA,
    data
})