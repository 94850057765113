import styled from 'styled-components';

const StepOneStyled = styled.section`

h2 {
	text-align: center;
    margin: 50px 0 20px;
	font-size: 30px;
	color: #202D3C;
}

h3 {
	font-size: 20px;
	padding: 30px 0 20px 0;
}

.info-list {
	width: 100%;

	li {
		padding: 0 0 10px 0;
		display: flex;
		align-items: center;

		svg {
			color: #d47558;
			margin: 0 5px 0 0 ;

			&.number {
				font-size: 14px;
				margin: 0 5px 2px 0;
			}
		}

		span {
			margin: 0 0 0 5px;
		}

		a {
			margin: 0 0 0 5px;
		}
	}
}

.edof-link {
	border: 0;
	display: block;
	width: 360px;
	margin: 10px auto 0;
	border-radius: 10px;
	background-color: #6399ce;
	color: #fff;
	font-size: 18px;
	padding: 15px;
	box-shadow: 0px 1px 10px #00000029;
	cursor: pointer;
	transition: all .3s ease;

	&:hover {
		opacity: .7;
	}
}

.paiement-link {
	border: 0;
	display: block;
	width: 360px;
	margin: 10px auto 0;
	border-radius: 10px;
	background-color: #6aaf8b;
	color: #fff;
	font-size: 18px;
	padding: 15px;
	box-shadow: 0px 1px 10px #00000029;
	cursor: pointer;
	transition: all .3s ease;

	&:hover {
		opacity: .7;
	}
}

@media only screen and (max-width: 1400px) {
}

@media only screen and (min-width: 760px) and (max-width: 1079px) {
}

@media only screen and (min-width: 0px) and (max-width: 759px) {
}

`;

export default StepOneStyled;