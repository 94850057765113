import styled from 'styled-components';

const AppStyled = styled.div`
    overflow: hidden;
    scroll-behavior: smooth;

    .btn-orange {
        background-color: #d47558 !important;
        color: #fff !important;
        border: 0 !important;
        border-radius: 10px !important;
    }

    .capitalize {
        text-transform: capitalize;
    }

    .uppercase {
        text-transform: uppercase;
    }

    .toast-container {
        z-index: 1000000;
    }
`;

export default AppStyled;