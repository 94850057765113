/**** Import de modules ****/
import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import axios from 'axios'
import * as qs from 'qs'
import { Skeleton } from '@mui/material'

/**** import des components ****/
import ModalJobFiche from '../../containers/ModalJobFiche';

/**** Import d'images' ****/
import shield from '../../assets/img/picto/shield.png'
import shieldWhite from '../../assets/img/picto/shield-white.png'
import logoCPF from '../../assets/img/logo/WEFOR_CARRE_COULEUR.png'

/**** Import des css ****/
import CourseCardListLayoutStyled from './CourseCardListLayoutStyled'
import urlBackEnd from '../../assets/js/urlBackEnd'

const URL = urlBackEnd

const CourseCardListLayout = ({activCourseId, changeActivCourseId, courseId, openModal, formations, id, attributes, userRole, setJobFicheId, currentProfilUser}) => {
  const [modalJobFicheOpen, setModalJobFicheOpen] = useState(false);
  

  let location = useLocation()
  let euroFormat = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
  });

  return (
    <CourseCardListLayoutStyled
      className={` ${activCourseId === courseId & location.pathname === '/formation' ? "active-comp" : ""} ${location.pathname === '/formation' ? "pointer" : ""}`}
      onClick={() => changeActivCourseId(courseId)}
    >
      {modalJobFicheOpen &&
        <ModalJobFiche modalJobFicheOpen={modalJobFicheOpen} setModalJobFicheOpen={setModalJobFicheOpen} />
      }
        {activCourseId !== courseId &&
        <div className="shield-wrapper">
            <img src={logoCPF} className="shield-picto" alt="bouclier orange" />
        </div>
        }
        {(activCourseId === courseId) && ( location.pathname === '/formation') && 
        <div className="shield-wrapper orange">
          < img src={logoCPF} className="shield-picto" alt="bouclier orange" />
        </div>
        }
        <div className="title-wrapper">
            <h4 className="bold">{attributes.secteurs.data[0].attributes.nom} (E-learning)</h4>
            <div className="separator-orange"></div>
            <h4 className="bold first-title">{attributes.nom}</h4>
        </div>
        <div className="duree-wrapper">
          <p className="bold">Durée</p>
          <div className="separator-orange"></div>
          <p className="duree bold">{attributes.duree}h</p>
        </div>
        <div className="salary-wrapper">
            <span className="bold">Prix:</span>
            <div className="separator-green"></div>
            <span className="bold"><span className="bold small-txt">{currentProfilUser === 'Entreprise' ? euroFormat.format(attributes.prix / 1.20) : euroFormat.format(attributes.prix)}</span></span> 
        </div>
        <div className="btn-wrapper">
        {attributes.fiche_metier.data !== null &&
        <>
        {location.pathname === "/formation" &&
        <button 
          className="btn-lightorange mobile-none"
          onClick={() => {
            setJobFicheId(attributes.fiche_metier.data.id)
            setModalJobFicheOpen(true)
          }}
        >Fiche métiers</button>
        }
        </>
        }
        {userRole === "entreprise" ? 
        <button 
          className="btn-orange"
          onClick={() => openModal("inscription-collab")}
        >Inscrire un collaborateur</button>
        :
        <button 
          className="btn-orange"
          onClick={() => openModal("formation")}
        >S'inscrire</button>
        }
        </div>
        <div className="cpf-wrapper">
          <img src={logoCPF} alt="logo bleu cpf" />
          <div className="bold">Éligible CPF</div>
        </div>
    </CourseCardListLayoutStyled>
  )
}

CourseCardListLayout.propTypes = {
  activCourseId: PropTypes.number.isRequired, 
  changeActivCourseId: PropTypes.func.isRequired, 
  openModal: PropTypes.func.isRequired,
}

export default CourseCardListLayout