/**** Import des modules ****/
import React, { useState, useEffect } from 'react'
import Moment from 'react-moment'
import { FaGenderless } from 'react-icons/fa'
import { RiNumber1, RiNumber2, RiNumber3 } from 'react-icons/ri'

/**** Import des css ****/
import StepOneStyled from './StepOneStyled'


const StepOne = ({ thisFinancement }) => {

  let euroFormat = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
  });

  return (
    <StepOneStyled>
        <h2 className='bold'>Demande de financement n°{thisFinancement.id}</h2>
        <h3 className='bold'>Informations disponibles pour cette formation</h3>
        <ul className='info-list'>
          <li><FaGenderless /> Nom de la formation : <span className='bold'> {thisFinancement.attributes.nom_formation}</span></li>
          <li><FaGenderless /> Demande effectuée le :  <span className='bold'><Moment format="DD/MM/YYYY" className="bold">{thisFinancement.attributes.publishedAt}</Moment></span></li>
          <li><FaGenderless /> Formation éligible au CPF : <span className='bold'>Oui</span></li>
          <li><FaGenderless /> Type de formation : <span className='bold'>100% Elearning</span></li>
          <li><FaGenderless /> Prix de la formation : <span className='bold'>{euroFormat.format(Number(thisFinancement.attributes.Prix))}</span></li>
          <li><FaGenderless /> Emplois accessibles après formation : emplois du secteur <span className='bold'>{thisFinancement.attributes.formation.data.attributes.secteurs.data[0].attributes.nom}</span></li>
        </ul>
        <h3 className='bold'>Suivez les étapes de financement</h3>
        <ul className='info-list'>
          <li><RiNumber1 className='number bold'/> Faites votre demande de financement sur la plateform <a href={thisFinancement.attributes.formation.data.attributes.le_travail} target="_blank" rel='noreferrer'className='bold'> EDOF moncompteformation.gouv.fr</a></li>
          <li><RiNumber2 className='number bold'/>Nous traitons votre demande, les informations relatives à votre formation seront disponible ici ou dans votre espace personnel EDOF</li>
          <li><RiNumber3 className='number bold'/> Accedez à votre formation aux dates renseignées lors de l'étape 1</li>
        </ul>
        <a href={thisFinancement.attributes.formation.data.attributes.le_travail} target="_blank" rel='noreferrer'className='bold'> 
          <button className='edof-link'>Effectuez votre demande de financement</button>
        </a>
        <h3 className='bold'>Vous ne disposez pas de droit CPF</h3>
        <ul className='info-list'>
          <li><RiNumber1 className='number bold'/>Payez vous même votre formation par carte bleue et choisissez vos dates de formation</li>
          <li><RiNumber2 className='number bold'/>Nous traitons votre demande, les informations relatives à votre formation seront disponible ici</li>
          <li><RiNumber3 className='number bold'/>Accedez à votre formation aux dates renseignées lors de l'étape 1</li>
        </ul>
        <button className='paiement-link'>Payez par carte bleue</button>
    </StepOneStyled>
  )
}

export default StepOne