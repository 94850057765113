import { connect } from 'react-redux'

import CandidatCard from '../../components/CandidatCard'
import { fetchContactCandidat } from '../../actions/job'
import { fetchCategorieOcean } from '../../actions/ocean'

const mapStateToProps = (state) => ({
    categorieOcean: state.ocean.categorieOcean,
    selectionJobOffer: state.job.selectionJobOffer,
    userInfo: state.login.userInfo,
    myScoresFormation: state.job.myScoresFormation
})

const mapDispatchToProps = (dispatch) => ({
	fetchContactCandidat: (candidatId) => {
        dispatch(fetchContactCandidat(candidatId));
    },
    fetchCategorieOcean: () => {
        dispatch(fetchCategorieOcean());
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(CandidatCard)