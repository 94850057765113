import { connect } from 'react-redux'

import AccountHome from '../../pages/AccountHome'

import { fetchAccountFormations, redirectToAccount, fetchGetScoreRecrutement } from '../../actions/login'
import { setIsShowAccountFormMenu, setMenuDocument } from '../../actions/menu'
import { fetchUserScore } from '../../actions/lms'
import { fetchJobOffer, setOffreIdHP } from '../../actions/job'
import { fetchFactureE, fetchFormations, changeActivCourseId, changeClickedFacture } from '../../actions/course'

const mapStateToProps = (state) => ({
    currentProfilUser: state.menu.currentProfilUser,
    user: state.login.session.user,
    userInfo: state.login.userInfo,
    userRole: state.login.userRole,
    isLogged: state.login.isLogged,
    formationsTerminee: state.login.formationsTerminee,
    offre_emplois_postule: state.login.offre_emplois_postule,
    formations: state.course.formations,
    jobsEntreprise: state.job.jobsEntreprise,
    redirect: state.login.redirect,
    categorieOcean: state.ocean.categorieOcean,
    factureE: state.course.factureE,
    scoreRecrutementObject: state.login.scoreRecrutementObject
})

const mapDispatchToProps = (dispatch) => ({
    fetchAccountFormations: () => {
        dispatch(fetchAccountFormations());
    },
    redirectToAccount: (bool) => {
        dispatch(redirectToAccount(bool));
    },
    setIsShowAccountFormMenu: (bool) => {
        dispatch(setIsShowAccountFormMenu(bool));
    },
    fetchUserScore: () => {
        dispatch(fetchUserScore());
    },
    fetchJobOffer: (id) => {
        dispatch(fetchJobOffer(id))
    },
    fetchFactureE: () => {
        dispatch(fetchFactureE())
    },
    setMenuDocument: (value) => {
        dispatch(setMenuDocument(value));
    },
    fetchGetScoreRecrutement: () => {
        dispatch(fetchGetScoreRecrutement());
    },
    fetchFormations: (paramObj) => {
        dispatch(fetchFormations(paramObj));
    },
    changeActivCourseId: (id) => {
        dispatch(changeActivCourseId(id));
    },
    setOffreIdHP : (id) => {
        dispatch(setOffreIdHP(id));
    },
    changeClickedFacture: (factureId) => {
        dispatch(changeClickedFacture(factureId));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(AccountHome)