/**** Import des modules ****/
import React, {useRef, useEffect} from 'react'
import {AiOutlineClose} from "react-icons/ai";
import { Link, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

/**** Import des components et containers ****/
import ModalConnexion from '../../containers/ModalInscription/ModalConnexion'
import ModalAccountCreation from '../../containers/ModalInscription/ModalAccountCreation';
import ModalJobInscription from '../../containers/ModalInscription/ModalJobInscription';
import ModalCourseInscription from '../../containers/ModalInscription/ModalCourseInscription';
import ModalCourseInscriptionCollab from '../../containers/ModalInscription/ModalCourseInscriptionCollab';
import ModalLoginLost from '../../containers/ModalInscription/ModalLoginLost';
/**** Import des css ****/
import ModalInscriptionStyled from './ModalInscriptionStyled'
import { toggleModalMessage } from '../../actions/modal';

const ModalInscription = ({closeModal, modalName, isLogged, formModal, userRole, toggleModalMessage}) => {
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closeModal('')
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  return (
    <ModalInscriptionStyled>
      <section className="modal-content-container" ref={wrapperRef}>
        <div className="content-condition">
          {!isLogged && formModal === 'login' &&
            <ModalConnexion />
          }
          {!isLogged && formModal === 'signIn' &&
            <ModalAccountCreation />
          }
          {!isLogged && formModal === 'loginLost' &&
            <ModalLoginLost />
          }
          {isLogged && modalName === 'formation' &&
            <>
            {userRole === 'entreprise' ? 
              <ModalCourseInscriptionCollab />
              :
              <ModalCourseInscription />
            }
            </>
          }
          {isLogged && modalName === 'postuler' &&
            <ModalJobInscription />
          }
          {isLogged && modalName === 'inscription-collab' &&
            <ModalCourseInscriptionCollab />
          }
        </div>
        <div className="icon-wrapper">
          <AiOutlineClose 
            className="close-icon"
            onClick={() =>  {
              toggleModalMessage('', false)
              closeModal('')
            }}
          />
        </div>
      </section>
    </ModalInscriptionStyled>
  )
}

ModalInscription.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  isLogged: PropTypes.bool.isRequired,
  formModal: PropTypes.string.isRequired
}

export default ModalInscription

