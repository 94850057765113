import { connect } from 'react-redux'

import ModalMessage from '../../components/ModalMessage'

import { toggleModalMessage } from '../../actions/modal'

const mapStateToProps = (state) => ({
    modalMessageContent: state.modal.modalMessageContent,
    modalMessageIsOpen: state.modal.modalMessageIsOpen
})

const mapDispatchToProps = (dispatch) => ({
    toggleModalMessage: (message, bool) => {
        dispatch(toggleModalMessage(message, bool));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(ModalMessage)

