/**** Import des modules ****/
import React, {useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import AOS from 'aos'
import "aos/dist/aos.css"
import { scroller, Element } from "react-scroll"

/**** import des images ****/
import logo from '../../assets/img/illustration/header-homme.png'

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import BannerAccount from '../../components/Header/BannerAccount'
import Private from '../../containers/SignIn/Private'
import YouAre from '../../containers/SignIn/YouAre'
import Society from '../../containers/SignIn/Society'

/**** Import des css ****/
import SignInEntrepriseStyled from './SignInEntrepriseStyled'

const SignInEntreprise = ({signInStep}) => {
  useEffect(()=> {
    AOS.init({ duration: 1000 })
  }, [])

  const scrollToSection = () => {
    scroller.scrollTo("scroll-to", {
      duration: 0,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  useEffect(()=> {
    scrollToSection()
  }, [])

  
  return (
    <SignInEntrepriseStyled>
      <Header bcgColor={'rgba(100,154,206,1)'}/>
      <section className="top-illustration">
        <div className="opacity-light-section">
          <img src={logo} alt='logo wefor'/>
          <div className="left">
            <h1 className="bold">Inscription</h1>
            <p>Vous souhaitez créer un compte Wefor ? C’est simple. Cliquez sur <span className="bold">Un particulier</span> ou <span className="bold">Une entreprise</span> en fonction de votre statut et suivez le guide !</p>
            <Element name="scroll-to" className="scroll-to"></Element>
          </div>
        </div>
      </section>
      <main className="page-create-account scroll-to">   
      <Society />
      </main>
      <Footer />
    </SignInEntrepriseStyled>
  )
}

SignInEntreprise.propTypes = {
  signInStep: PropTypes.number.isRequired,  
}

export default SignInEntreprise