import styled from 'styled-components';
import bcgHeader from '../../assets/img/header/formation-ill.jpg'

const JobFicheStyled = styled.main`
    padding-top: 0px;

    .illustration-header {
        width: 100vw;
        height: 700px;
        padding-top: 125px;
        z-index: 10;
        position: relative;
        background-color: #fff;
    
        &::before {
            content:'';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width:100%;
            background: linear-gradient(180deg, #f7edea 0%, #247558 100%);
            clip-path: polygon(0% 0%,0% 80.6708%, 1.69492% 78.8616%, 3.38983% 77.0922%, 5.08475% 75.3821%, 6.77966% 73.75%, 8.47458% 72.2138%, 10.1695% 70.7902%, 11.8644% 69.495%, 13.5593% 68.3422%, 15.2542% 67.3446%, 16.9492% 66.513%, 18.6441% 65.8565%, 20.339% 65.3824%, 22.0339% 65.0959%, 23.7288% 65%, 25.4237% 65.0959%, 27.1186% 65.3824%, 28.8136% 65.8565%, 30.5085% 66.513%, 32.2034% 67.3446%, 33.8983% 68.3422%, 35.5932% 69.495%, 37.2881% 70.7902%, 38.9831% 72.2138%, 40.678% 73.75%, 42.3729% 75.3821%, 44.0678% 77.0922%, 45.7627% 78.8616%, 47.4576% 80.6708%, 49.1525% 82.5%, 50.8475% 84.3293%, 52.5424% 86.1385%, 54.2373% 87.9078%, 55.9322% 89.6179%, 57.6271% 91.25%, 59.322% 92.7862%, 61.017% 94.2098%, 62.7119% 95.505%, 64.4068% 96.6578%, 66.1017% 97.6555%, 67.7966% 98.487%, 69.4915% 99.1435%, 71.1864% 99.6176%, 72.8814% 99.9041%, 74.5763% 100%, 76.2712% 99.9041%, 77.9661% 99.6176%, 79.661% 99.1435%, 81.3559% 98.487%, 83.0509% 97.6555%, 84.7458% 96.6578%, 86.4407% 95.505%, 88.1356% 94.2098%, 89.8305% 92.7862%, 91.5254% 91.25%, 93.2203% 89.6179%, 94.9153% 87.9078%, 96.6102% 86.1385%, 98.3051% 84.3293%, 100% 82.5%, 100% 0%);
            shape-outside: polygon(0% 0%,0% 80.6708%, 1.69492% 78.8616%, 3.38983% 77.0922%, 5.08475% 75.3821%, 6.77966% 73.75%, 8.47458% 72.2138%, 10.1695% 70.7902%, 11.8644% 69.495%, 13.5593% 68.3422%, 15.2542% 67.3446%, 16.9492% 66.513%, 18.6441% 65.8565%, 20.339% 65.3824%, 22.0339% 65.0959%, 23.7288% 65%, 25.4237% 65.0959%, 27.1186% 65.3824%, 28.8136% 65.8565%, 30.5085% 66.513%, 32.2034% 67.3446%, 33.8983% 68.3422%, 35.5932% 69.495%, 37.2881% 70.7902%, 38.9831% 72.2138%, 40.678% 73.75%, 42.3729% 75.3821%, 44.0678% 77.0922%, 45.7627% 78.8616%, 47.4576% 80.6708%, 49.1525% 82.5%, 50.8475% 84.3293%, 52.5424% 86.1385%, 54.2373% 87.9078%, 55.9322% 89.6179%, 57.6271% 91.25%, 59.322% 92.7862%, 61.017% 94.2098%, 62.7119% 95.505%, 64.4068% 96.6578%, 66.1017% 97.6555%, 67.7966% 98.487%, 69.4915% 99.1435%, 71.1864% 99.6176%, 72.8814% 99.9041%, 74.5763% 100%, 76.2712% 99.9041%, 77.9661% 99.6176%, 79.661% 99.1435%, 81.3559% 98.487%, 83.0509% 97.6555%, 84.7458% 96.6578%, 86.4407% 95.505%, 88.1356% 94.2098%, 89.8305% 92.7862%, 91.5254% 91.25%, 93.2203% 89.6179%, 94.9153% 87.9078%, 96.6102% 86.1385%, 98.3051% 84.3293%, 100% 82.5%, 100% 0%);
            z-index: -10;
        }
    
    
        .opacity-light-section {
            height: 100%;
            top: 100%;
            max-width: 1300px;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            padding: 0px 0; 
    

            .left {
                width: calc(50%);
                margin-right: 5%;

                h1 {
                    font-size: 50px;
                    
                    text-align: center;
                }

                p {
                    font-size: 25px;
                    color: #fff;
                    line-height: 30px;
                    padding: 30px 0;
                }
            }
            img {
                    max-width: 45%;
                }
            }

            .title-wrapper-prog {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                align-items: center;
                margin-left: 40px;

                h2 {
                    color: #e1efe7;
                    padding: 0 0 5px;
                    font-size: 40px;
                    text-transform: uppercase;
                    text-align: center;

                    &.border {
                        padding: 0 0 20px;
                        margin: 0 0 20px;
                        border-bottom: 4px solid #6aaf8b;
                    }
                }

                h1 {
                    display: block;
                    font-size: 50px;
                    color: #fff;
                    text-transform: uppercase;
                    color: #4d4d4d;
                }
            }
        }
    }

    .section-job-def {
        display: flex;
        flex-direction: column;
        padding: 50px 0;
        background-color: #fff;

        .title-wrapper {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            img {
                width: 100px;
            }

            h2 {
                font-size: 45px;
                width: auto;
                display: inline;
                position: relative;
                color: #4d4d4d;
                margin-left: 40px;
                padding-bottom: 10px;
                text-transform: uppercase;
    
                .separator-blue {
                    background-color: #6399ce;
                    height: 6px;
                    width: 240px;
                    margin: 10px 0 0 auto;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }

        }

        .def-wrapper {
            width: 1200px;
            margin: 50px auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: stretch;

            .txt-left {
                color: #656464;
                font-size: 20px;
                line-height: 26px;
                width: 48%;
                text-align: justify;
            }

            .salary-wrapper {
                color: #fff;
                background-color: #649ACE;
                width: 48%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
                align-items: center;
                box-shadow: 0px 8px 8px #00000029;
                border-radius: 30px;
                position: relative;

                .disclaimer {
                    position: absolute;
                    bottom: 10px;
                    right: 50px;
                    color: #4d4d4d;

                    a {
                        color: #4d4d4d;
                    }
                }

                img { 
                    width: 120px;
                }

                .flex-box-wrapper {
                    display: flex;
                    align-items: stretch;
                    flex-direction: column;

                    h3 {
                        font-size: 26px;
                        text-transform: uppercase;
                        padding-bottom: 10px;
                        border-bottom: 2px solid #4d4d4d;
                        margin-bottom: 10px;
                    }

                    p {
                        font-size: 33px;

                        span {
                            color: #4d4d4d;
                        }
                    }
                }
            }
        }
    }

    .section-formation-list {
        text-align: center;
        padding: 50px 0;

        h2 {
            font-size: 45px;
            display: inline;
            margin: 0 auto;
            position: relative;
            color: #4d4d4d;
            padding-bottom: 10px;
            text-transform: uppercase;

            .separator-blue {
                background-color: #6399ce;
                height: 6px;
                width: 240px;
                margin: 10px 0 0 auto;
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }

        .card-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            max-width: 1200px;
            margin: 50px auto;
        }

        .btn-blue {
            width: 300px;
            color: #fff;
            background-color: #6399ce;
            text-decoration: none;
            padding: 10px;
            text-align: center;
            font-size: 18px;
            transition: background-color .5s;
            margin: 50px auto 0;
            border-radius: 10px;
            cursor: pointer;
            border: 0;
            font-size: 20px;
    
            &:hover {
                background-color: #202c3c;
            }
        }
    }

    .section-mission-list {
        padding: 50px 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        background-color: #E1EDF7;

        h2 {
            font-size: 45px;
            display: inline;
            margin: 0 auto;
            position: relative;
            color: #4d4d4d;
            padding-bottom: 10px;
            text-transform: uppercase;

            .separator-blue {
                background-color: #6399ce;
                height: 6px;
                width: 240px;
                margin: 10px 0 0 auto;
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }

        .list-wrapper {
            display: flex;
            flex-wrap: wrap;
            width: 96%;
            max-width: 1200px;
            align-items: stretch;
            justify-content: space-between;
            margin-top: 80px;

            .separator-blue {
                width: 3px;
                background-color: #325076;
                margin-top: 40px;
            }

            .left, right {
                width: 100%;
                margin: 0 auto;

                h3 {
                    color: #325076;
                    font-size: 24px;
                    text-transform: uppercase;
                    padding-bottom: 30px;
                    text-align: center;
                }

                ul {
                    list-style: disc;
                }

                ul li {
                    color: #656464;
                    font-size: 16px;
                    padding-bottom: 30px;
                }
            }
        }
    }

    .section-evolution-job {
        padding: 50px 0;
        display: flex;
        align-items: center;
        flex-direction: column;

        h2 {
            font-size: 45px;
            display: inline;
            margin: 0 auto;
            position: relative;
            color: #4d4d4d;
            padding-bottom: 10px;
            text-transform: uppercase;

            .separator-blue {
                background-color: #6399ce;
                height: 6px;
                width: 240px;
                margin: 10px 0 0 auto;
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }

        .evo-list {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            margin: 50px auto 0;
            max-width: 1200px;
            text-align: justify;

            img { 
                width: 200px;
            }

            ul {
                margin-left: 50px;
                color :#656464;
                font-size: 20px;
                line-height: 50px;
                list-style: disc;
            }
        }
    }

    .section-perspective-graph {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 50px 0;
        background-color: #F4F4F4;

        h2 {
            font-size: 45px;
            display: inline;
            margin: 0 auto;
            position: relative;
            color: #4d4d4d;
            padding-bottom: 10px;
            text-transform: uppercase;

            .separator-blue {
                background-color: #6399ce;
                height: 6px;
                width: 240px;
                margin: 10px 0 0 auto;
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }

        .txt-grey {
            color: #656464;
            font-size: 18px;
            line-height: 22px;
            text-align: justify;
            width: 96%;
            max-width: 1200px;
            margin-top: 50px;
        }

        .txt-blue {
            color: #757991;
            font-size: 18px;
            margin-top: 20px;

            span {
                color: #639ACD;
            }
        }

        .graph { 
            margin: 50px 10px 0px 10px;
            padding-right: 5px;
        }
    }

    .section-btn-bottom {
        padding: 100px 0;
        display: flex;
        align-items: stretch;
        justify-content: center;

        .btn-left, .btn-right {
            background-color: #639ACD;
            color: #fff;
            border-radius: 12px;
            padding: 20px 10px 20px 50px;
            position: relative;
            font-size: 24px;
            width: 340px;
            box-sizing: border-box;
            cursor: pointer;
            
            &:hover {
                background-color: #325076;
            }

            img {
                position: absolute;
                width: 80px;
                left: -35px;
                top: -8px;
            }
        }

        .btn-right {
            margin-left: 100px;
            padding: 20px 10px 20px 90px;
        }
    }

    @media only screen and (max-width: 1400px) {
        .illustration-header {
            .opacity-dark {
    
                .opacity-white {
                    margin: auto 30px;
                    padding: 40px 40px;
                }
    
                .title-wrapper-prog {
                    margin-left: 20px;
    
                    h2 {
                        font-size: 40px;
                    }
    
                    h1 {
                        font-size: 50px;
                    }
                }
            }
        }

        .section-job-def {
    
            .title-wrapper {
    
                img {
                    width: 100px;
                }
    
                h2 {
        
                    .separator-blue {
                    }
                }
    
            }
    
            .def-wrapper {
                width: 96%;
                max-width: 1200px;
                flex-direction: column;
    
                .txt-left {
                    width: 100%;
                }
    
                .salary-wrapper {
                    width: 100%;
                    max-width: 500px;
                    margin: 50px auto 0;
                }
            }
        }

        .section-formation-list {
            padding: 50px 20px;
    
            .card-wrapper {
                justify-content: space-evenly;
                width: 96%;
                max-width: 1200px;
                margin: 50px auto;
            }
        }
    }
    
    @media only screen and (max-width: 1079px) {
        .illustration-header {
            .opacity-light-section {
                height: auto;
                flex-direction: column-reverse;
    
                .left{
                    padding: 50px 0 0 0;
                    width: calc(96%);
                    margin: 0 auto;
                    height: 300px;
    
                    h1 {
                        font-size: 35px;
                        text-align: center;
                    }

                    .title-wrapper-prog {
                        margin: 0;
                    }

                    h2 {
                        font-size: 30px;
                        margin-top: 20px;
                        color: #fff;
                    }
    
                    p {
                        font-size: 18px;
                        line-height: 22px;
                        text-align: justify;
                    }
                }
                img {
                    width: 90%;
                    max-width: 400px;
                    margin-left: 0px;
                    margin: 0 auto;
                }
            }
        }

        .section-job-def {
    
            .def-wrapper {
                .salary-wrapper {
                    padding-bottom: 10px;
                    text-align: center;
                }
            }
        }

        .section-formation-list {
            .card-wrapper {
                article {
                    margin: 40px 20px;
                }
            }
        }

        .section-btn-bottom {

            .btn-right, .btn-left {
                font-size: 18px;
                width: 300px;
            }
        }
    }
    
    @media only screen and (min-width: 0px) and (max-width: 759px) {
        .illustration-header {

            height: 450px;

            &::before {
                clip-path:none;
            }

            .opacity-light-section {
                flex-direction: column-reverse;
    
                .left {
                    width: 96%;
                    margin: 0 auto;
                    height: 250px;
                    display: flex;
                    padding: 0;
                    justify-content: center;
                    flex-direction: column;
                    box-sizing: border-box;
    
                    h1 {
                        font-size: 35px;
                        text-align: center;
                    }
    
                    p {
                        font-size: 18px;
                        line-height: 22px;
                        text-align: justify;
                        padding: 20px 0;
                        width: 80%;
                        margin: 0 auto;
                    }
                }
                img {
                     max-width:300px;
                }
            }
        }

        .section-job-def {
            
            padding-top: 150px;

            .title-wrapper {
                h2 {
                    font-size:30px;
                    margin: 20px;


                    .separator-blue {
                        width: 100px;
                        right: calc(50% - 40px);
                    }
                }
    
            }
    
            .def-wrapper {
                .salary-wrapper {
                    padding-bottom: 40px;
                    text-align: center;
                }
            }
        }

        .section-formation-list {
            padding: 50px 0px 50px 0px;
            h2 {
                font-size:30px;
                margin: 20px 0;
                width: 100%;
                box-sizing: border-box;

                .separator-blue {
                    width: 100px;
                }
            }
        }

        .section-mission-list {

            .list-wrapper {
                flex-direction: column;

                .separator-blue {
                    display: none;
                }

                .left {
                    width: 100%;
                }
            }
            h2 {
                font-size:30px;
                margin: 20px;
                width: 100%;
                box-sizing: border-box;
                text-align: center;

                .separator-blue {
                    width: 100px;
                    right: calc(50% - 50px);
                }
            }
        }

        .section-evolution-job {

            .evo-list {
                margin-top: 0;

                img {
                    width: 150px;
                }
            }

            h2 {
                font-size:30px;
                margin: 20px;
                width: 100%;
                box-sizing: border-box;
                text-align: center;

                .separator-blue {
                    width: 100px;
                    right: calc(50% - 50px);
                }
            }
        }

        .section-perspective-graph {
            h2 {
                font-size:30px;
                margin: 20px;
                width: 100%;
                box-sizing: border-box;
                text-align: center;

                .separator-blue {
                    width: 100px;
                    right: calc(50% - 50px);
                }
            }
            
            .recharts-wrapper {
                width: 96% !important;
                text-align: center;

                svg {
                    width: 100% !important;
                    max-width: 500px !important;
                }
            }
        }

        .section-btn-bottom {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .btn-right {
                margin: 20px 0 0 0;
            }

            .btn-right, .btn-left {
                font-size: 18px;
                width: 300px;
            }
        }
    }
`;

export default JobFicheStyled;