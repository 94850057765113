/**** Import des modules ****/
import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { useNavigate, Link, Navigate } from 'react-router-dom'
import Pagination from '@mui/material/Pagination'
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  LabelList
} from "recharts";
import { RiSeparator } from "react-icons/ri";
import { FaInfoCircle, FaBrain } from "react-icons/fa";
import { GiReceiveMoney } from "react-icons/gi";
import { FaBook, FaFileAlt, FaDesktop, FaBriefcase, FaTrophy, FaPlus, FaUserTie, FaLightbulb, FaArrowsAlt, FaHandsHelping, FaAngry } from "react-icons/fa";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import { HiLightBulb} from "react-icons/hi";
import Skeleton from '@mui/material/Skeleton';

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import BannerAccount from '../../components/Header/BannerAccount'
import AccountMenu from '../../containers/AccountMenu'
import CourseDoneCard from '../../containers/CourseDoneCard'
import CourseCard from '../../containers/CourseCard'
import JobOnlineCard from '../../containers/JobOnlineCard'

/**** Import des images ****/
import greenpeaples from '../../assets/img/illustration/greenpeaples.png'
import bagred from '../../assets/img/illustration/progaccount.png'
import calendrier from '../../assets/img/illustration/suitcasemini.png'
import calc from '../../assets/img/illustration/calc.png'
import timeyellow from '../../assets/img/illustration/docgrey.png'
import suitcase from '../../assets/img/illustration/suitcase.png'
import homeaccount from '../../assets/img/illustration/homeaccount.png'

/**** Import des css ****/
import AccountHomeStyled from './AccountHomeStyled'

const AccountHome = ({
  currentProfilUser,
  user,
  userInfo,
  userRole,
  isLogged,
  formationsTerminee,
  fetchAccountFormations,
  offre_emplois_postule,
  formations,
  jobsEntreprise,
  redirectToAccount,
  redirect,
  setIsShowAccountFormMenu,
  categorieOcean,
  fetchUserScore,
  fetchJobOffer,
  fetchFactureE,
  factureE,
  setMenuDocument,
  fetchGetScoreRecrutement,
  scoreRecrutementObject,
  fetchFormations,
  changeActivCourseId,
  setOffreIdHP,
  changeClickedFacture
}) => {
  let navigate = useNavigate();

  const [menuOne, setMenuOne] = useState(false);
  const [menuTwo, setMenuTwo] = useState(false);
  const [menuThree, setMenuThree] = useState(false);
  const [menuFourth, setMenuFourth] = useState(false);
  const [menuFifth, setMenuFifth] = useState(false);
  const [menuSixth, setMenuSixth] = useState(false);
  const [menuSeventh, setMenuSeventh] = useState(false);

  useEffect(fetchAccountFormations, []);
  useEffect(() => {
    if(factureE.length === 0) {
      fetchFactureE()
    }
  }, []);
  useEffect(() => {
      //fetchUserScore()
  }, []);
  useEffect(() => {
    if(scoreRecrutementObject.length === 0 && userRole !== 'entreprise') {
      fetchGetScoreRecrutement()
    }
  }, []);

  useEffect(() => {
    if (isLogged) {
      //fetchJobOffer(userInfo.data[0].id)
    }
  }
  , [])

  useEffect(() => {
    if (formations.length === 0) {
      const paramObj = {page: 'app' ,intemNb: 10, pageNb: 1}
      fetchFormations(paramObj)
    }
  }, []);


  const sortAndfilterdFormations = () => {
    const sortFormations = formations.sort(function(a, b){return a.attributes.prix - b.attributes.prix})
    const sortAndfilterdForm = [];

    const lastElement = sortFormations.length - 1;
    const middleElement = Math.round(sortFormations.length / 2 - 1);
    sortAndfilterdForm.push(sortFormations[0])
    sortAndfilterdForm.push(sortFormations[lastElement])
    sortAndfilterdForm.push(sortFormations[middleElement])

    return sortAndfilterdForm
  }

  const data = [
    {
      subject: "Ouverture d\'ésprit",
      A: userInfo.data[0].attributes.test_ocean !== undefined && userInfo.data[0].attributes.test_ocean.data !== null && Math.round(userInfo.data[0].attributes.test_ocean.data.attributes.ouverture_esprit / (10 * 5) * 100),
      fullMark: 100
    },
    {
      subject: "Conscience",
      A: userInfo.data[0].attributes.test_ocean !== undefined && userInfo.data[0].attributes.test_ocean.data !== null && Math.round(userInfo.data[0].attributes.test_ocean.data.attributes.conscience / (9 * 5) * 100),
      fullMark: 100
    },
    {
      subject: "Extraversion",
      A: userInfo.data[0].attributes.test_ocean !== undefined && userInfo.data[0].attributes.test_ocean.data !== null && Math.round(userInfo.data[0].attributes.test_ocean.data.attributes.extraversion / (8 * 5) * 100),
      fullMark: 100
    },
    {
      subject: "Agréabilité",
      A: userInfo.data[0].attributes.test_ocean !== undefined && userInfo.data[0].attributes.test_ocean.data !== null && Math.round(userInfo.data[0].attributes.test_ocean.data.attributes.altruisme / (10 * 5) * 100),
      fullMark: 100
    },
    {
      subject: "Névrosisme",
      A: userInfo.data[0].attributes.test_ocean !== undefined && userInfo.data[0].attributes.test_ocean.data !== null && Math.round(userInfo.data[0].attributes.test_ocean.data.attributes.nevrosisme / (8 * 5) * 100),
      fullMark: 100
    }
  ];

  const userAttributes = userInfo.data[0].attributes

  const getJobFiche = () => {
    let listJobFich = []

    if (userAttributes.en_cours_formations.data.length > 0 ) {
      userAttributes.en_cours_formations.data.map((course) => 
        listJobFich.push(course.attributes.fiche_metier.data.attributes.nom)
      )
    }

    if (userAttributes.formations_termine.data.length > 0 ) {
      userAttributes.formations_termine.data.map((course) => 
        listJobFich.push(course.attributes.fiche_metier.data.attributes.nom)
      )
    }

    let uniqueList = [...new Set(listJobFich)]

    return uniqueList
  }

  redirectToAccount(false)

  if(!isLogged) {
    return (<Navigate to="/" replace />)
  }
 
  return (
    <AccountHomeStyled>
      <Header bcgColor={'#fff'}/>
      {!isLogged && 
        navigate("/")
      }
      {isLogged &&
      <main className="page-wrapper">
      <AccountMenu />
      <section className="right-wrapper">
      {/** Affichage pour un particulier **/}
      { userRole === 'particulier' && 
      <>
      { formationsTerminee !== '' ?
      <>
      {userRole === 'particulier' &&
        <section className="features-wrapper">
          <div className="course-wrapper">
            <h3 className="bold uppercase">Espace formation</h3>
            <div className="course-count">
              <div className="demandes">
              <div 
                className='drop-down-1' 
                onClick={() => {
                  setMenuOne(!menuOne)
                }}>
                <h4 className="bold">Demandes de formation</h4>
                <div className="bold info-dyna">{userAttributes.facturation_formations.data.length} en cours</div>
                <div className='arrow-wrapper' onClick={() => setMenuOne(!menuOne)}>
                  {menuOne ? 
                    <MdArrowDropUp className="arrow-down" />
                  :
                    <MdArrowDropDown className="arrow-down"/>
                  }
              </div>
              </div>
                {userAttributes.facturation_formations.data.length > 0 && menuOne &&
                <div className='formations-wrapper'>
                  {userAttributes.facturation_formations.data.map((course, index) => ( 
                    <div  key={index} onClick={() => changeClickedFacture(course.id)}>
                      <Link to="/account/mes-financements">
                        <p className="list-paragraphe"><FaFileAlt />{course.attributes.nom_formation}</p>
                        <span className="bold">Mon  financement</span>
                      </Link>
                    </div>
                  ))}
                </div>
                }
              </div>
              <div className="en-cours">
              <div className='drop-down-1' onClick={() => setMenuTwo(!menuTwo)}>
              <h4 className="bold">Vos sessions en cours</h4>
              <div className="bold info-dyna">{userAttributes.en_cours_formations.data.length} formation{userAttributes.en_cours_formations.data.length > 1 ? 's':''}</div>
                <div className='arrow-wrapper' onClick={() => setMenuTwo(!menuTwo)}>
                  {menuTwo ? 
                    <MdArrowDropUp className="arrow-down" />
                  :
                    <MdArrowDropDown className="arrow-down"/>
                  }
              </div>
              </div>
                {userAttributes.en_cours_formations.data.length > 0 && menuTwo &&
                <div className='formations-wrapper'>
                  {userAttributes.en_cours_formations.data.map((course, index) => (
                    <div key={index}>
                       <a href={`https://elearning.wefor.fr/session/${ userInfo.data[0].attributes.score_formations.data.find(score => score.attributes.formation.data.attributes.lms_formation_id === course.attributes.lms_formation_id).attributes.session_id}`} className="bold">
                    <p className="list-paragraphe"><FaFileAlt />{course.attributes.nom}</p>
                    <span className="bold">
                      Accéder à la formation
                    </span>
                    </a>
                    </div>
                  ))}
                </div>
                }
              </div>
              {/* 
              <div className="termine">
              <h4 className="bold">Formations terminée</h4>
              <div className="number-circle bold">{userAttributes.formations_termine.data.length}</div>
                {userAttributes.formations_termine.data.length > 0 ?
                <>
                  {userAttributes.formations_termine.data.map((course) => (
                    <p key={course.id} className="list-paragraphe"><FaFileAlt />{course.attributes.nom}</p>
                  ))}
                    <div className="picto-wrapper">
                      <Link to="/job" className='single'>
                      <div className="bold"><FaBriefcase /> RECHERCHER UN EMPLOI</div>
                      </Link>
                    </div>
                </>
                  :
                  <p>Vous n'avez pas de formation terminé</p> 
                }
              </div>
              <Link to="/formation" className='link-course'><button className="bold">Rechercher une formation</button></Link>
              */}
            </div>
          </div>
          <div className="job-wrapper">
            <h3 className="bold uppercase">Espace emploi</h3>
            <div className="job-count">
              <div className="offres">
                <div 
                  className='drop-down-2' 
                  onClick={() =>   setMenuThree(!menuThree)}>
                  <h4 className="bold">Vos demandes </h4>
                  <div className="bold info-dyna">{userAttributes.offre_emplois_postule.data === null ? '0' : userAttributes.offre_emplois_postule.data.length} offre{userAttributes.offre_emplois_postule.data !== null && userAttributes.offre_emplois_postule.data.length > 1 ? 's' : ''} d'emploi{userAttributes.offre_emplois_postule.data !== null && userAttributes.offre_emplois_postule.data.length > 1 ? 's' : ''}</div>
                  <div className='arrow-wrapper' onClick={() => setMenuThree(!menuThree)}>
                    {menuThree ? 
                      <MdArrowDropUp className="arrow-down" />
                    :
                      <MdArrowDropDown className="arrow-down"/>
                    }
                </div>
                </div>
                {userAttributes.offre_emplois_postule.data.length > 0 && menuThree &&
                <div className='formations-wrapper'>
                  {userAttributes.offre_emplois_postule.data.map((course, index) => (
                    <Link to="/job" className='single'>
                    <p className="list-paragraphe" key={index}><FaBriefcase /> {course.attributes.nom}</p>
                    <span className="bold">Plus d'offres</span>
                    </Link>
                  ))}
                </div>
                }
              </div>
              <div className="test">
                {userAttributes.test_ocean.data !== null ?
                <>
                  <div className='drop-down-2' onClick={() => setMenuFourth(!menuFourth)}>
                    <h4 className="bold">Votre test de personnalité</h4>
                    <div className="bold info-dyna">résultat</div>
                      <div className='arrow-wrapper' onClick={() => setMenuFourth(!menuFourth)}>
                        {menuFourth ? 
                          <MdArrowDropUp className="arrow-down" />
                        :
                          <MdArrowDropDown className="arrow-down"/>
                        }
                    </div>
                  </div>
                  <div className='formations-wrapper'>
                  {menuFourth &&
                    <div className="result-txt-wrapper">
                    {(userInfo.data[0].attributes.test_ocean.data.attributes.ouverture_esprit / (10 * 5) * 100) < 31 && 
                    <Link to="/account/ocean">
                    <div>
                      <p><FaBrain />Ouverture d’esprit</p>
                      <span className="bold">{Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.ouverture_esprit / (10 * 5) * 100))} / 100</span>
                    </div>
                    </Link>
                    }
                    {(userInfo.data[0].attributes.test_ocean.data.attributes.ouverture_esprit / (10 * 5) * 100) >= 31 &&  (userInfo.data[0].attributes.test_ocean.data.attributes.ouverture_esprit / (10 * 5) * 100) < 71 && 
                    <Link to="/account/ocean">
                    <div>
                      <p><FaBrain />Ouverture d’esprit </p>
                      <span className="bold">{Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.ouverture_esprit / (10 * 5) * 100))} / 100</span>
                    </div>
                    </Link>
                    }
                    {(userInfo.data[0].attributes.test_ocean.data.attributes.ouverture_esprit / (10 * 5) * 100) >= 71 && 
                    <Link to="/account/ocean">
                    <div>
                      <p><FaBrain />Ouverture d’esprit </p>
                      <span className="bold">{Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.ouverture_esprit / (10 * 5) * 100))} / 100</span>
                    </div>
                    </Link>
                    }
                    {(userInfo.data[0].attributes.test_ocean.data.attributes.conscience / (9 * 5) * 100) < 31 && 
                    <Link to="/account/ocean">
                    <div>
                      <p><FaLightbulb/>Conscience </p>
                      <span className="bold">{Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.conscience / (9 * 5) * 100))} / 100</span>
                    </div>
                    </Link>
                    }
                    {(userInfo.data[0].attributes.test_ocean.data.attributes.conscience / (9 * 5) * 100) >= 31 &&  (userInfo.data[0].attributes.test_ocean.data.attributes.conscience / (9 * 5) * 100) < 71 && 
                    <Link to="/account/ocean">
                    <div>
                      <p><FaLightbulb />Conscience</p>
                      <span className="bold">{Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.conscience / (9 * 5) * 100))} / 100</span>
                    </div>
                    </Link>
                    }
                    {(userInfo.data[0].attributes.test_ocean.data.attributes.conscience / (9 * 5) * 100) >= 71 && 
                    <Link to="/account/ocean">
                    <div>
                      <p><FaLightbulb />Conscience </p>
                      <span className="bold">{Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.conscience / (9 * 5) * 100))} / 100</span>
                    </div>
                    </Link>
                    }
                    {(userInfo.data[0].attributes.test_ocean.data.attributes.extraversion / (8 * 5) * 100) < 31 &&
                    <Link to="/account/ocean">
                    <div>
                      <p><FaArrowsAlt/>Extraversion</p>
                      <span className="bold">{Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.extraversion / (8 * 5) * 100))} / 100</span>
                    </div>
                    </Link>
                    }
                    {(userInfo.data[0].attributes.test_ocean.data.attributes.extraversion / (8 * 5) * 100) >= 31 &&  (userInfo.data[0].attributes.test_ocean.data.attributes.extraversion / (8 * 5) * 100) < 71 && 
                    <Link to="/account/ocean">
                    <div>
                      <p><FaArrowsAlt/> Extraversion </p>
                      <span className="bold">{Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.extraversion / (8 * 5) * 100))} / 100</span>
                    </div>
                    </Link>
                    }
                    {(userInfo.data[0].attributes.test_ocean.data.attributes.extraversion / (8 * 5) * 100) >= 71 && 
                    <Link to="/account/ocean">
                    <div>
                      <p><FaArrowsAlt/> Extraversion </p>
                      <span className="bold">{Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.extraversion / (8 * 5) * 100))} / 100</span>
                    </div>
                    </Link>
                    }
                    {(userInfo.data[0].attributes.test_ocean.data.attributes.altruisme / (10 * 5) * 100) < 31 && 
                    <Link to="/account/ocean">
                    <div>
                      <p><FaHandsHelping />Altruisme </p>
                      <span className="bold">{Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.altruisme / (10 * 5) * 100))} / 100</span>
                    </div>
                    </Link>
                    }
                    {(userInfo.data[0].attributes.test_ocean.data.attributes.altruisme / (10 * 5) * 100) >= 31 &&  (userInfo.data[0].attributes.test_ocean.data.attributes.altruisme / (10 * 5) * 100) < 71 && 
                    <Link to="/account/ocean">
                    <div>
                      <p><FaHandsHelping />Altruisme </p>
                      <span className="bold">{Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.altruisme / (10 * 5) * 100))} / 100</span>
                    </div>
                    </Link>
                    }
                    {(userInfo.data[0].attributes.test_ocean.data.attributes.altruisme / (10 * 5) * 100) >= 71 && 
                    <Link to="/account/ocean">
                    <div>
                      <p><FaHandsHelping />Altruisme </p>
                      <span className="bold">{Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.altruisme / (10 * 5) * 100))} / 100</span>
                    </div>
                    </Link>
                    }
                    {(userInfo.data[0].attributes.test_ocean.data.attributes.nevrosisme / (8 * 5) * 100) < 31 && 
                    <Link to="/account/ocean">
                    <div>
                      <p><FaAngry />Névrosisme </p>
                      <span className="bold">{Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.nevrosisme / (8 * 5) * 100))} / 100</span>
                    </div>
                    </Link>
                    }
                    {(userInfo.data[0].attributes.test_ocean.data.attributes.nevrosisme / (8 * 5) * 100) >= 31 &&  (userInfo.data[0].attributes.test_ocean.data.attributes.nevrosisme / (10 * 5) * 100) < 71 && 
                    <Link to="/account/ocean">
                    <div>
                      <p><FaAngry />Névrosisme </p>
                      <span className="bold">{Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.nevrosisme / (8 * 5) * 100))} / 100</span>
                    </div>
                    </Link>
                    }
                    {(userInfo.data[0].attributes.test_ocean.data.attributes.nevrosisme / (8 * 5) * 100) >= 71 && 
                    <Link to="/account/ocean">
                    <div>
                      <p><FaAngry />Névrosisme </p>
                      <span className="bold">{Math.round((userInfo.data[0].attributes.test_ocean.data.attributes.nevrosisme / (8 * 5) * 100))} / 100</span>
                    </div>
                    </Link>
                    }
                    </div>
                  }
                  </div>
                </>
                :
                <Link to="/account/ocean">
                  <div className='drop-down-2'>
                    <h4 className="bold">Passez le test OCEAN</h4>
                    <div className="bold info-dyna"></div>
                    <div className='arrow-wrapper'>
                      <FaPlus className="plus" />
                    </div>
                  </div>
                </Link>
                }
              </div>
            </div>
          </div>
        </section>
      }
      </>
      :
      <>
      <section className="skeleton-section">
        <Skeleton variant="rectangular" height={62} className="title-sk"/>
        <Skeleton variant="rectangular" height={60} className="btn-sk"/>
        <Skeleton variant="rectangular" height={60} className="btn-sk"/>
      </section>
      <section className="skeleton-section">
        <Skeleton variant="rectangular" height={62} className="title-sk"/>
        <Skeleton variant="rectangular" height={60} className="btn-sk"/>
        <Skeleton variant="rectangular" height={60} className="btn-sk"/>
      </section>
      </>
      }
      </>
      }
      {/** Affichage pour une entreprise **/}
      {userRole === 'entreprise' &&
      <>
      { formations.length > 0 && userRole === 'entreprise' ?
        <>
      {userRole === 'entreprise' &&
        <section className="features-wrapper">
        <div className="course-wrapper">
          <h3 className="bold uppercase">Espace formation</h3>
          <div className="course-count">
            <div className="demandes">
              <div 
                className='drop-down-1' 
                onClick={() => {
                  setMenuOne(!menuOne)
                }}>
                <h4 className="bold">Formez vos collaborateurs</h4>
                <div className="bold info-dyna">{formations.length} formation{formations.length > 1 ? 's' : ''} disponible{formations.length > 1 ? 's' : ''}</div>
                <div className='arrow-wrapper' onClick={() => setMenuOne(!menuOne)}>
                  {menuOne ? 
                    <MdArrowDropUp className="arrow-down" />
                  :
                    <MdArrowDropDown className="arrow-down"/>
                  }
              </div>
              </div>
              {formations.length > 0 && menuOne &&
              <div className='formations-wrapper'>
                {formations.map((course, index) => (
                  <div key={index} onClick={() => changeActivCourseId(course.id)}>
                    <Link to="/formation">
                      <p className="list-paragraphe"><FaFileAlt />{course.attributes.nom}</p>
                      <span className="bold">{course.attributes.secteurs.data[0].attributes.nom}</span>
                    </Link>
                  </div>
                ))}
              </div>
              }
            </div>
            <div className="en-cours">
            <div className='drop-down-1' onClick={() => setMenuTwo(!menuTwo)}>
              <h4 className="bold">Progression de vos collaborateurs</h4>
                <div className="bold info-dyna">{userAttributes.particuliers.data.length} collaborateur{userAttributes.particuliers.data.length > 1 ? 's':''}</div>
                <div className='arrow-wrapper' onClick={() => setMenuTwo(!menuTwo)}>
                  {menuTwo ? 
                    <MdArrowDropUp className="arrow-down" />
                  :
                    <MdArrowDropDown className="arrow-down"/>
                  }
              </div>
            </div>
              {userAttributes.particuliers.data.length > 0 && menuTwo &&
              <div className='formations-wrapper'>
                {userAttributes.particuliers.data.map((part, index) => (
                  <div key={index}>
                    <Link to="/account/course">
                      <p className="list-paragraphe"><FaFileAlt />{part.attributes.user.data.attributes.prenom} {part.attributes.user.data.attributes.nom} - {part.attributes.en_cours_formations.data.length > 0 && part.attributes.en_cours_formations.data.length} formation{part.attributes.en_cours_formations.data.length > 1 ? 's' : ''} en cours</p>
                      <span className="bold">Plus de details</span>
                    </Link>
                  </div>
                ))}
              </div>
              }
            </div>
          </div>
        </div>
        <div className="job-wrapper">
          <h3 className="bold uppercase">Espace emploi</h3>
          <div className="job-count">
            <div className="offres">
            <div className='drop-down-2' onClick={() => setMenuThree(!menuThree)}>
              <h4 className="bold">Recrutez des collaborateurs</h4>
                <div className="bold info-dyna">{userAttributes.offre_emplois.data === null ? '0' : userAttributes.offre_emplois.data.length} offre{userAttributes.offre_emplois.data !== null && userAttributes.offre_emplois.data.length > 1 ? 's' : ''} d'emploi{userAttributes.offre_emplois.data !== null && userAttributes.offre_emplois.data.length > 1 ? 's' : ''}</div>
                <div className='arrow-wrapper' onClick={() => setMenuThree(!menuThree)}>
                  {menuThree ? 
                    <MdArrowDropUp className="arrow-down" />
                  :
                    <MdArrowDropDown className="arrow-down"/>
                  }
              </div>
            </div>
              {userAttributes.offre_emplois.data !== null && userAttributes.offre_emplois.data.length > 0 && menuThree &&
              <div className='formations-wrapper'>
                {userAttributes.offre_emplois.data.map((job, index) => (
                  <div key={index} onClick={() => setOffreIdHP(job.id) }>
                    <Link to="/account/candidats">
                      <p className="list-paragraphe"><FaBriefcase /> {job.attributes.nom}</p>
                      <span className="bold">Voir les candidats</span>
                    </Link>
                  </div>
                ))}
              </div>
              }
            </div>
            <div className="test">
              {userAttributes.test_oceans !== undefined && userAttributes.test_oceans.data !== null &&
              <>
              <div className='drop-down-2' onClick={() => setMenuFourth(!menuFourth)}>
                <h4 className="bold">Test de personnalité de vos managers</h4>
                  <div className="bold info-dyna">{userAttributes.test_oceans.data.length} test{userAttributes.test_oceans.data.length > 1 ? 's' : ''} réalisé{userAttributes.test_oceans.data.length > 1 ? 's' : ''}</div>
                  <div className='arrow-wrapper' onClick={() => setMenuFourth(!menuFourth)}>
                    {menuFourth ? 
                      <MdArrowDropUp className="arrow-down" />
                    :
                      <MdArrowDropDown className="arrow-down"/>
                    }
                </div>
              </div>
              <div className='formations-wrapper'>
                {menuFourth && userAttributes.test_oceans.data.map((manager, index) => (
                  <div key={index}>
                    <Link to="/account/ocean">
                      <p className="list-paragraphe"><FaUserTie /> {manager.attributes.nom} {manager.attributes.prenom}</p>
                      <span className="bold">Voir les résultats</span>
                    </Link>
                  </div>
                  ))}
              </div>
              </>
              }
            </div>
          </div>
        </div>
        <div className="doc-wrapper">
          <h3 className="bold uppercase">Espace documentaire</h3>
          <div className="test">
            {userAttributes.test_oceans !== undefined && userAttributes.test_oceans.data !== null &&
            <>
            <div className='drop-down-2' onClick={() => setMenuFifth(!menuFifth)}>
              <h4 className="bold">Convention de recrutement</h4>
                <div className="bold info-dyna">{userInfo.data[0].attributes.convention.data === null ? '0' : '1'} document</div>
                <div className='arrow-wrapper' onClick={() => setMenuFifth(!menuFifth)}>
                  {menuFifth ? 
                    <MdArrowDropUp className="arrow-down" />
                  :
                    <MdArrowDropDown className="arrow-down"/>
                  }
              </div>
            </div>
            {menuFifth &&
            <div className='formations-wrapper'>
              <Link 
                  to="/account/documents"
                  onClick={() => setMenuDocument('recrutement')}
                >
                <p className="list-paragraphe"><FaFileAlt /> Convention WEFOR</p>
                <span className={userInfo.data[0].attributes.convention.data !== null ? 'statut green' : 'statut orange'}>{userInfo.data[0].attributes.convention.data !== null ? 'Signée' : 'Non signée'}</span>
              </Link>
              </div>
              }
            </>
            }
          </div>
          <div className="test">
            {userAttributes.test_oceans !== undefined && userAttributes.test_oceans.data !== null &&
            <>
            <div className='drop-down-2' onClick={() => setMenuSixth(!menuSixth)}>
              <h4 className="bold">Vos contrats</h4>
                <div className="bold info-dyna">{factureE.length} document{factureE.length > 1 ?"s" : ""}</div>
                <div className='arrow-wrapper' onClick={() => setMenuSixth(!menuSixth)}>
                  {menuSixth? 
                    <MdArrowDropUp className="arrow-down" />
                  :
                    <MdArrowDropDown className="arrow-down"/>
                  }
              </div>
            </div>
            {menuSixth &&
            <div className='formations-wrapper'>
              {factureE.map((facture, index) => (
                <Link 
                to="/account/documents"
                onClick={() => setMenuDocument('contrats')}
              >
                <p key={index} className="list-paragraphe"><FaFileAlt /> contrat n°{facture.id}</p>
                <span className={facture.attributes.signature ? 'statut green' : 'statut orange'}>{facture.attributes.signature ? 'Signé' : 'Non signé'}</span>
                </Link>
              ))}
              </div>
              }
            </>
            }
          </div>
          <div className="test">
            {userAttributes.test_oceans !== undefined && userAttributes.test_oceans.data !== null &&
            <>
            <div className='drop-down-2' onClick={() => setMenuSeventh(!menuSeventh)}>
              <h4 className="bold">Vos factures</h4>
                <div className="bold info-dyna">{factureE.length} document{factureE.length > 1 ?"s" : ""}</div>
                <div className='arrow-wrapper' onClick={() => setMenuSeventh(!menuSeventh)}>
                  {menuSeventh ? 
                    <MdArrowDropUp className="arrow-down" />
                  :
                    <MdArrowDropDown className="arrow-down"/>
                  }
              </div>
            </div>
            {menuSeventh &&
            <div className='formations-wrapper'>
              {factureE.map((facture, index) => (
                <Link 
                to="/account/documents"
                onClick={() => setMenuDocument('factures')}
              >
                <p key={index} className="list-paragraphe"><FaFileAlt /> Facture n°{facture.id}</p>
                </Link>
              ))}
              </div>
              }
            </>
            }
          </div>
        </div>
      </section>
      }
      </>
      :
      <>
      <section className="skeleton-section">
        <Skeleton variant="rectangular" height={62} className="title-sk"/>
        <Skeleton variant="rectangular" height={60} className="btn-sk"/>
        <Skeleton variant="rectangular" height={60} className="btn-sk"/>
      </section>
      <section className="skeleton-section">
        <Skeleton variant="rectangular" height={62} className="title-sk"/>
        <Skeleton variant="rectangular" height={60} className="btn-sk"/>
        <Skeleton variant="rectangular" height={60} className="btn-sk"/>
      </section>
      <section className="skeleton-section">
        <Skeleton variant="rectangular" height={62} className="title-sk"/>
        <Skeleton variant="rectangular" height={60} className="btn-sk"/>
        <Skeleton variant="rectangular" height={60} className="btn-sk"/>
        <Skeleton variant="rectangular" height={60} className="btn-sk"/>
      </section>
      </>
      }
      </>
      }
      </section>
      </main>
      }
      <Footer />
    </AccountHomeStyled>
  )
}

AccountHome.propTypes = {
  currentProfilUser: PropTypes.string.isRequired
}

export default AccountHome