/**** Import des modules ****/
import React, { useState, useEffect } from 'react'
import { useNavigate, Navigate, Link } from 'react-router-dom'
import { MdAddCircle } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import PropTypes from 'prop-types'
import PaginationMui from '@mui/material/Pagination'
import { toast } from 'react-toastify';
import Skeleton from '@mui/material/Skeleton';

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import BannerAccount from '../../components/Header/BannerAccount'
import AccountMenu from '../../containers/AccountMenu'
import JobOnlineCard from '../../containers/JobOnlineCard'

/**** Import des css ****/
import AccountJobsStyled from './AccountJobsStyled'
import { saveMyTestsOcean } from '../../actions/ocean';

const AccountJobs = ({
  changeValueNewJob,
  user,
  userInfo,
  userRole,
  isLogged,
  fetchJobOffer,
  jobsEntreprise,
  jobFiches,
  secteursData,
  domainesData,
  secteurJob,
  domaineJob,
  ficheJob,
  fetchPostJobOffer,
  saveJobsEntreprise,
  setSelectionJobOffer,
  setMenuDocument,
  setFullPageLoaderJobOfferAction,
  fetchGetMyTestsOcean,
  myTestsOcean,
  remunerationjob,
  jobsEntreprisePagination,
  jobsAccountLoading
}) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowModalModif, setIsShowModalModif] = useState(false);
  const [rerender, setRerender] = useState(false);
  const [page, setPage] = useState(1)
  const [indexOfLastCard, setIndexOfLastCard] = useState(5)
  const [indexOfFirstCard, setIndexOfFirstCard] = useState(0)
  const [indexOfManager, setIndexOfManager] = useState(0)
  const [nameOfManager, setNameOfManager] = useState('')
  const [nomOfManager, setNomOfManager] = useState('')
  const [prenomOfManager, setPrenomOfManager] = useState('')
  useEffect(()=>{
      setRerender(!rerender);
  }, [saveJobsEntreprise]);

  const sketeletonToMap = [1,2,3,4,5]


  useEffect(() => {
    if (isLogged) {
      if (jobsEntreprise.length === 0) {
        fetchJobOffer(userInfo.data[0].id, 5, 1);
      }
    }
  }, [])

  useEffect(() => {
    if (isLogged) {
      fetchGetMyTestsOcean(userInfo.data[0].id);
    }
  }, [])
  let navigate = useNavigate();

  const changePagesCards = (value) => {
    setIndexOfLastCard(value * 5)
    setIndexOfFirstCard(value * 5 - 5)
  }
 
   const handleChangePagination = (event, value) => {   
     setPage(value)
     changePagesCards(value)
     fetchJobOffer(userInfo.data[0].id, 5, value);
   };

   if(!isLogged) {
    return (<Navigate to="/" replace />)
  }

  return (
    <AccountJobsStyled>
      <Header bcgColor={'#fff'}/>
      {!isLogged && 
        navigate("/")
      }
      {isLogged && userRole === 'entreprise' &&
      <main className="page-wrapper">
      <AccountMenu />
      <section className="right-wrapper">
        <section className="mycards">
          {userInfo.data[0].attributes.convention_signed ?
          <div className="add-job" onClick={() => setIsShowModal(true)}>
            <MdAddCircle />
            <p className='bold'>Créez une offre d’emploi</p>
          </div>
          :
          <div className="add-job center">
            <p className='bold'>Pour pouvoir créer des offres d'emplois vous devez signer votre convention de recrutement</p>
          <Link 
            to='/account/documents'
            onClick={() => {setMenuDocument('recrutement')}}
            className="btn-link-convention"
          >
            Ma convention de recrutement
          </Link>
          </div>
          }
          <h2 className='bold'>Vos offre d'emploi</h2>
          <div className="cards-wrapper">
          {!jobsAccountLoading && jobsEntreprise.length > 0 && jobsEntreprise.map((job) => (
            <JobOnlineCard key={job.id} jobId={job.id} {...job} job={job}/>
          ))}
          {jobsAccountLoading && 
           <>
           {sketeletonToMap.map((item, index) => 
            <div className='cards-wrap-s' key={index}>
            <Skeleton variant="circular" width={80} height={80}/>
            <div className="text-wrapper">
            <div className="bottom-wrapper">
              <Skeleton variant="text"/>
              <Skeleton variant="text"/>
            </div>
            <div className="bottom-wrapper">
              <Skeleton variant="text"/>
              <Skeleton variant="text"/>
            </div>
            <div className="bottom-wrapper">
              <Skeleton variant="text"/>
              <Skeleton variant="text"/>
            </div>
            </div>
            </div>
            )}
            </>
          }
          {jobsEntreprise.length > 0 && Object.keys(jobsEntreprisePagination).length > 0 &&
            <PaginationMui page={jobsEntreprisePagination.page} className="pagination-pc" count={jobsEntreprisePagination.pageCount} variant="outlined" color="primary" onChange={handleChangePagination}/>
          }
          {jobsEntreprise.length === 0 && !jobsAccountLoading &&
            <p className="no-offer">Vous n'avez créé aucune offre</p>
          }
          </div>
        </section>
      </section>
      {isShowModal && 
      <section className="modal">
      <section className="modal-content-container">
        <div className="content-condition">
        <h3 className="bold">CRÉER UNE OFFRE D’EMPLOI</h3>
        <form 
          className="account-form-comp"
          onSubmit={(event) => {
            event.preventDefault();
            setFullPageLoaderJobOfferAction(true)
            fetchPostJobOffer(nomOfManager, prenomOfManager);
            setIsShowModal(false)
            setRerender(!rerender);
          }}
        >
        <div className="drow-wrapper">
          <label>
              Nom :
              <input 
                type="text" 
                name="nomjob"
                className='small-placeholder'
                placeholder="Intitulé de l'emploi"
                onChange={(event) => {
                  changeValueNewJob(event.target.value, event.target.name);
                }}
              />
            </label>
            <label>
              Rémunération (optionnel) :
              <input 
                type="text" 
                value={remunerationjob}
                name="remunerationjob"
                className='small-placeholder'
                placeholder="En K€ et en chiffre (exemple: 28)"
                onChange={(event) => {
                  if (Number.isInteger(Number(event.target.value))) {
                    changeValueNewJob(Number(event.target.value), event.target.name);
                  } else {
                    toast(`Merci de préciser votre rémunération seulement avec des chiffres/nombres`, {
                      position: "bottom-right",
                      type: "error",
                      theme:"colored",
                      autoClose: 3000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                    })
                  }
                }}
              />
            </label>
          </div>
          <div className="drow-wrapper">
          <label className="select-box">
              Type de contrat :
              <select
                name="contratjob" 
                onChange={(event) => {
                  changeValueNewJob(event.target.value, event.target.name);
                }}
              >
                <option value="">
                  Choisir un type de contrat
                </option>
                <option value="CDI">
                  CDI
                </option>
                <option value="CDD">
                  CDD
                </option>
              </select>
          </label>
            <label>
              Localisation :
              <input 
                type="text" 
                name="localisationjob"
                className='small-placeholder'
                placeholder="Nom de ville"
                onChange={(event) => {
                  changeValueNewJob(event.target.value, event.target.name);
                }}
              />
            </label>
          </div>
          <label>
            Descriptif du poste :
            <textarea 
              name="descriptifjob"
              className='small-placeholder'
              placeholder="Vous pouvez formater votre texte pour rendre l'offre plus attrayante: '# ' avant un titre, '**gras**' autour d'un mot pour le faire apparaitre en gras, '1 ou -' pour créer des listes."
              onChange={(event) => {
                changeValueNewJob(event.target.value, event.target.name);
              }}
            />
          </label>
          <label>
            Missions :
            <textarea
              name="missionjob"
              className='small-placeholder'
              placeholder="Vous pouvez formater votre texte pour rendre l'offre plus attrayante: '# ' avant un titre, '**gras**' autour d'un mot pour le faire apparaitre en gras, '1 ou -' pour créer des listes."
              onChange={(event) => {
                changeValueNewJob(event.target.value, event.target.name);
              }}
            />
          </label>
          <label>
            Profil :
            <textarea
              name="profiljob"
              className='small-placeholder'
              placeholder="Vous pouvez formater votre texte pour rendre l'offre plus attrayante: '# ' avant un titre, '**gras**' autour d'un mot pour le faire apparaitre en gras, '1 ou -' pour créer des listes."
              onChange={(event) => {
                changeValueNewJob(event.target.value, event.target.name);
              }}
            />
          </label>
          {myTestsOcean.length > 0 ?
            <label className="select-box">
            Manager
              <select
                id="demo-simple-select"
                value={nameOfManager}
                label="Résultat"
                onChange={(event) => {
                  setNameOfManager(event.target.value)
                  const str = event.target.value.split(/(\s+)/)
                  setNomOfManager(str[0])
                  setPrenomOfManager(str[2])
                }}
              >
                <option
                    value='Choisissez un manager'
                  >
                  Choisissez un manager
                  </option>
                {myTestsOcean.map((manager, index) => (
                  <option
                    key={index} 
                    value={manager.attributes.nom + ' ' + manager.attributes.prenom}
                    onClick={() => {
                      setIndexOfManager(index)
                      setNomOfManager(manager.attributes.nom)
                      setPrenomOfManager(manager.attributes.prenom)
                    }}
                  >
                    {manager.attributes.nom} {manager.attributes.prenom}
                  </option>
                ))}
              </select>
            </label>
            :
            <p className='btn-test-ocean bold'>Vous pouvez faire passer un <Link to='/account/ocean' className="bold"> test de personnalité</Link> à vos managers pour pouvoir les associer à cette offre. Si vous cliquez sur le lien, vous perdrez les données de l'offre en cours de création.</p>
          }
          <label className="select-box">
              Secteur d'activité:
              <select
                name="secteurJob" 
                value={secteurJob !== undefined ? secteurJob : ''}
                onChange={(event) => {
                  changeValueNewJob(event.target.value, event.target.name);
                }}
              >
                {secteursData.map(({ attributes, id }) => (
                  <option key={attributes.nom} value={id}>
                    {attributes.nom}
                  </option>
                ))}
              </select>
            </label>
            {/* 
            <label className="select-box">
              Domaine d'activité:
              <select
                name="domaineJob" 
                value={domaineJob !== undefined ? domaineJob : ''}
                onChange={(event) => {
                  changeValueNewJob(event.target.value, event.target.name);
                }}
              >
                {domainesData.map(({ attributes, id }) => (
                  <option key={attributes.nom} value={id}>
                    {attributes.nom}
                  </option>
                ))}
              </select>
            </label>
            */}
            {/* 
            <label className="select-box">
              Fiche métier (en relation avec l'offre):
              <select
                name="ficheJob" 
                value={ficheJob !== undefined ? ficheJob : ''}
                onChange={(event) => {
                  changeValueNewJob(event.target.value, event.target.name);
                }}
              >
                {jobFiches.map(({ attributes, id }) => (
                  <option key={attributes.nom} value={id}>
                    {attributes.nom}
                  </option>
                )).sort()}
              </select>
            </label>
            */}
          <input 
            type="submit" 
            value="Créer une offre d'emploi" 
            className="lightblue-btn"
          />
        </form>
        </div>
        <div className="icon-wrapper">
          <AiOutlineClose 
            className="close-icon"
            onClick={() => setIsShowModal(false)}
          />
        </div>
      </section>
    </section>
    }
      </main>
    }
      <Footer />
    </AccountJobsStyled>
  )
}

AccountJobs.propTypes = {
  changeValueNewJob: PropTypes.func.isRequired
}

export default AccountJobs