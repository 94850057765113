import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import App from './containers/App'
import {store, persistedStore } from './store';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react'
import HttpsRedirect from 'react-https-redirect';

import './assets/css/reset.css'

const rootReactElement = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistedStore}>
      <Router>
      <HttpsRedirect>
        <App />
      </HttpsRedirect>
      </Router>
    </PersistGate>
  </Provider>
);

// 2. La cible du DOM (là où la structure doit prendre vie dans le DOM)
const target = document.getElementById('root');
// 3. Déclenchement du rendu de React (virtuel) => DOM (page web)
render(rootReactElement, target);