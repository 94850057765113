import styled from 'styled-components';

const AccountFactureStyled = styled.div`

.page-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    max-width: 1300px;
    margin: 175px auto 0;

    .loader-section {
        width: 96%;
        margin: 0 auto 30px;
    }

    .green-bcg {
        background-color: #ECF3EF;
    }

    .right-wrapper {
        width: calc(100% - 250px);
        
        .full-width-tabs {
            width: 100%;

            .MuiTabs-flexContainer {
                width: 100%;

                button {
                    width: calc(100% / 3)
                }
            }
        }

        .facture-wrapper {
            width: 100%;
            padding: 50px 0;

            .facture-card {
                width: 96%;
                margin: 100px auto 150px;
                padding: 0px 0px 0px 0px;
                box-shadow: 0px 3px 10px #00000059;
                box-sizing: border-box;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                position: relative;

                .top-left-info {
                    position: absolute;
                    left: 0;
                    top: -50px;
                    height: 50px;
                    width: calc(25% + 1px);
                    border: 1px solid #d4d4d4;
                    background-color: #dbe8f4;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    text-align: center;
                }

                .top-right-info {
                    position: absolute;
                    right: 0;
                    top: -50px;
                    height: 50px;
                    width: calc(25% + 1px);
                    border: 1px solid #d4d4d4;
                    background-color: #dbe8f4;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &.active-facture {
                }

                .blue-txt {
                    color: #649ACE;
                    padding-bottom: 10px;
                }

                .grey-txt {
                    color: #4d4d4d;
                }

                .green-txt {
                    color: #7aaf8b;
                }

                .picto-wrapper {
                    width: 40%;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    color: #4d4d4d;
                    padding: 10px;
                    img {
                        width: 50px;
                    }

                    .facture-id {
                        color: #649ACE;
                        padding-left: 10px;
                    }

                    .btn-open-financement {
                        padding: 5px 10px;
                        font-size: 16px;
                        background-color: #6AAF8B;
                        color: #ECF3EF;
                        border: 0;
                        border-radius: 5px;
                        cursor: pointer;
                        margin: 5px 10px 0 10px;

                        &:hover {
                            background-color: #ECF3EF;
                            color: #6AAF8B;
                        }
                    }
                }

                .info-facture-wrapper {
                    width: 40%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                }

                .info-course-wrapper {
                    width: 20%;

                }

                .picto-drop-down {
                    color: #6399ce;
                    font-size: 22px;
                    margin-top: 5px;
                    cursor: pointer;
                    transition: all .3s ease;

                    &:hover {
                        opacity: .7;
                    }
                }

                .info-price-wrapper {
                    width: 20%;
                    box-sizing: border-box;
                    text-align: center;
                    display:flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px 10px 10px 10px;

                    .grey-txt {
                        padding-top: 10px;
                    }
                }

                .pdf-wrapper {
                    width: 15%;
                    text-align: center;
                    display: flex;
                    flex-wrap: wrap;
                    img {
                        width: 50px;
                        cursor: pointer;
                    }

                    .download-wrapper {
                        width: 50px;
                        margin: 0 auto;
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: column;
                        justify-content: space-evenly;

                        .download-svg {
                            font-size: 18px;
                            color: #6399ce;
                            cursor: pointer;
                        }
                        .signature-svg {
                            font-size: 18px;
                            color: #6399ce;
                            cursor: pointer;
                        }
                    }
                }

                .step-section {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    background-color: #eda4a2;
                    border-top-right-radius: 10px;
                    border-top-left-radius: 10px;
                    overflow: hidden;

                    div {
                        width: 25%;
                        display: flex;
                        align-items: center;
                        color: #fff;
                        justify-content: center;
                        padding: 5px 0;
                        position: relative;

                        .or-span {
                            position: absolute;
                            right: -10px;
                            background-color: #fff;
                            color: #d47558;
                            padding: 2px;
                            border-radius: 3px;
                            z-index: 2;
                        }

                        &.orange-bcg {
                            background-color: #e9baab;
                        }

                        &.green-bcg {
                            background-color: #B4D7C4;
                        }

                        svg {
                            margin-right: 10px;
                            font-size: 18px;
                            color: #fff;
                        }
                    }
                }
            }
            
            .pagination-pc {
                ul {
                    justify-content: center;
                }
            }

            .info-remake-wrapper {

                display: flex;
                flex-wrap: wrap;
                width: 100%;
                border-bottom: 1px solid #d4d4d4;
                height: 75px;

                .picto-wrapper {
                    width: 25%;
                    padding: 10px;
                    text-align: center;
                }

                .info-facture-wrapper {
                    width: 50%;
                    padding: 10px 10px;
                    box-sizing: border-box;
                    border-right: 1px solid #d4d4d4;
                    border-left: 1px solid #d4d4d4;
                    text-align: center;
                }

                .info-price-wrapper {
                    width: 25%;
                    padding: 10px 10px 10px 10px;
                }
            }

            .filter-wrapper {
                width: 96%;
                margin: 0 auto;
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                .nbf-wrapper {
                    width: 15%;
                    padding-left:10px;
                    box-sizing: border-box;
                    font-size: 18px;
                    color: #6399ce;
                        font-size: 22px;
                }

                .date-wrapper {
                    width: 25%;
                    padding-right: 7%;
                    box-sizing: border-box;

                    select {
                        padding: 10px;
                        border: 0;
                        box-shadow: 0px 3px 6px #00000029;

                        &:focus-visible {
                            border: 0;
                        }
                    }
                }

                .nomf-wrapper {
                    width: 27%;
                }

                .prix-wrapper {
                    width: 18%;
                }

                .filter-icon-wrapper {
                    width: 15%;
                    text-align: center;

                    svg {
                        color: #6399ce;
                        font-size: 25px;
                    }
                }
            }

            .toggle-button-statut {
                padding:10px 0 30px;
                width: 100%;
                justify-content: center;

                button {
                    width: 48%;
                    border-radius: 10px;

                    &.MuiToggleButtonGroup-grouped:not(:last-of-type) {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }

                    &.MuiToggleButtonGroup-grouped:not(:first-of-type) {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                }
            }
        }

        .facturation-wrapper {
            width: 100%;
            color: #4d4d4d;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;

            .full-btn-launch {
                text-align: center;
                padding: 20px 0;
                background-color: #d47558;
                transition: all .2s ease;
                color: #fff;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }
            }

            .facturation-choice {
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-sizing: border-box;
                width: 100%;
                border-bottom: 1px solid #d4d4d4;
                

                &.green-bcg {
                    background-color: #ECF3EF;
                }

                h3 {
                    font-size: 18px;

                    .txt-blue {
                        color: #649ACE;
                    }
                }

                p {
                    font-size: 16px;
                    width: 25%;
                    padding-left: 10px;

                }

                .popupi-on-hover {
                    background-color: #202c3c;
                    color: #fff;
                    padding: 10px;
                    position: absolute;
                    left: 100%;
                    width: 300px;
                    border-radius: 5px;
                    z-index: 1000;
                    display: none;
                }

                .choice-txt {
                    display: flex;
                    align-items: center;
                    position: relative;

                    svg {
                        color: #202c3c;
                        margin-left: 5px;
                        cursor: pointer;
                        position: absolute;
                        right: 10px;

                        &:hover + .popupi-on-hover{
                            
                            display: block;
                        }
                    }
                }

                .btn-wrapper {
                    text-align: center;
                    width: 50%;
                    border-right: 1px solid #d4d4d4;
                    border-left: 1px solid #d4d4d4;
                    padding: 10px 0;

                    div {
                        padding: 10px;
                    }

                    button {
                        background-color: #fff;
                        padding: 10px;
                        color: #649ACE;
                        border: 1px solid #649ACE;
                        border-radius: 10px;
                        box-sizing: border-box;
                        width: 48%;
                        margin: 5px;
                        cursor: pointer;
                        transition: all .2s ease;

                        &:hover {
                            background-color: #649ACE;
                            color: #fff;
                        }

                        &.active-btn {
                            background-color: #649ACE;
                            color: #fff;

                            &:hover {
                                opacity: 0.9;
                            }
                        }
                    }
                }



                .confirmation-statut-btn {
                    width: 24%;
                    padding: 10px 0;
                    box-sizing: border-box;
                    border: 1px solid #202c3c;
                    max-width: 330px;
                    border-radius: 10px;
                    background-color: #202c3c;
                    color: #fff;
                    margin: 0 .5%;
                    cursor: pointer;

                    &:hover {
                        opacity: .9;
                    }
                }

                .choosed-statut {
                    width: 25%;
                    text-align: right;
                    padding-right: 10px;


                    div {
                        margin: 0px auto;
                        color: #fff;
                        padding: 10px;
                        background-color: #6aaf8b;
                        display: inline-block;
                        border-radius: 5px;
                    }
                }
            }

            .confirmation-statut-btn-ent {
                width: 300px;
                padding: 15px 0;
                box-sizing: border-box;
                max-width: 330px;
                border-radius: 10px;
                background-color: #202c3c;
                color: #fff;
                border: 0;
                margin: 20px auto;
                display: block;
            }

            .financement-cpf-wrapper {
                display: flex;
                flex-wrap: wrap;
                align-items: stretch;
                box-sizing: border-box;
                border-bottom: 1px solid #d4d4d4; 

                .left {
                    width: 25%;
                    padding: 10px;
                    box-sizing: border-box;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    flex-direction: column;

                    img {
                        width: 50px;
                    }

                    div {
                        margin: 10px 0;
                    }

                    .popupi-on-hover {
                        background-color: #202c3c;
                        color: #fff;
                        padding: 10px;
                        position: absolute;
                        left: 100%;
                        width: 300px;
                        border-radius: 5px;
                        z-index: 1000;
                        display: none;
                    }

                    .next-step-btn {
                        padding: 5px 10px;
                        font-size: 16px;
                        background-color: #6AAF8B;
                        color: #ECF3EF;
                        border: 0;
                        border-radius: 5px;
                        cursor: pointer;
                        margin: 10px 0px 0 0px;
                        display: inline-block;

                        &:hover {
                            background-color: #ECF3EF;
                            color: #6AAF8B;
                        }
                    }

                    .info-text {
                        position: relative;
                        width: 100%;
    
                        svg {
                            color: #202c3c;
                            margin-left: 5px;
                            cursor: pointer;
                            position: absolute;
                            right: 0;
    
                            &:hover + .popupi-on-hover{
                                z-index: 100000000000000000000000;
                                display: block;
                            }
                        }

                        a {
                            -webkit-hyphens: auto;
                            hyphens: auto;
                            word-wrap: break-word;
                        }
                    }
                }

                .center {
                    width: 50%;
                    min-height: 50px;
                    box-sizing: border-box;
                    border-right: 1px solid #d4d4d4;
                    border-left: 1px solid #d4d4d4;
                    position: relative;
                    padding: 10px;

                    .info-msg {
                        display: flex;
                        align-items: center;

                        svg {
                            font-size: 20px;
                            padding-right: 10px;
                            color: #6399ce;
                        }
                    }

                    .option-list {

                        li {
                            display: flex;
                            align-items: center;
                            margin-left: 30px;

                            svg {
                                margin-right: 10px;
                                font-size: 14px;
                                color: #6399ce;
                            }
                        }
                    }

                    .disclaimer {
                        font-style: italic;
                        margin: 5px 0
                    }

                    .drop-file-wrapper {
                        margin: 0px 0 0;
                        height: 100%;
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;

                        label {
                            div {
                                display: flex;
                                align-items: center;
                            }

                            p {
                                color: #4d4d4d;
                                padding-bottom: 10px;
                                transition:all .3s;
                            }
                        }

                        .file-upload-btn {
                            color: #6399ce;
                            border-radius: 5px;
                            padding: 0px 0px 0px 0px;
                            background-color: #6aaf8b;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            box-sizing: border-box;
                            cursor: pointer;
                            transition:all .3s;
                            
                            .link-cpf {
                                width: 100%;
                                color: #fff;
                                line-height: 15px;
                                padding: 15px;
                                text-align: center;
                                transition:all .3s;

                                &:hover {
                                    opcity: .7;
                                    color: #fff;
                                }
                            }

                            &:hover {

                                p {
                                    color: #fff;
                                }
                            }

                            svg {
                                font-size: 20px;
                            }
                            

                            input {
                                display: none;
                            }
                        }

                        .next-step-btn {
                            padding: 10px;
                            border-radius: 5px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 45px;
                            box-sizing: border-box;
                            background-color: #eda4a2;
                            color: #fff;
                            border: 0;
                            cursor: pointer;
                            margin-top : 10px;
                            text-align: center;
                            transition: all .3s ease;

                            &:hover {
                                background-color: #fff;
                                color: #eda4a2;
                            }
                        }

                        .file-name {
                            text-align:center;
                            padding: 5px 0;
                        }

                        .send-file-btn {
                            width: auto;
                            margin: 0 auto;
                            display: block;
                            border: 1px solid #649ACE;
                            background-color: #649ACE;
                            color: #fff;
                            border-radius: 10px;
                            padding: 10px 40px;
                            cursor: pointer;
                            transition:all .3s;

                            &:hover {
                                color: #649ACE;
                                background-color: #fff;
                            }
                        }
                    }

                    .cash-div {
                        position: absolute;
                        top :0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 10;

                        &.none {
                            display: none;
                        }
                    }

                    .paiment-cb {
                        height: 100%;
                        width: 100%;
                        color: #6399ce;
                        border-radius: 5px;
                        padding: 5px 10px 10px 10px;
                        background-color: #dbe8f4;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: center;
                        transition: all .3s ease;

                        &:hover {
                            background-color: #4183c4; 
                            color: #fff;
                        }
                    }
                }

                .right {
                    width: 25%;
                    padding: 10px;
                    box-sizing: border-box;

                    .warnig-msg {
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;

                        svg {
                            font-size: 20px;
                            padding-right: 10px;
                            color: #dc4a46;
                        }
                    }

                    .wrapper-cell {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                        padding: 5px 0;

                        .green {
                            color: #6aaf8b; 
                        }
                    }

                    .can-click {
                        width: 100%;
                        width: auto;
                        margin: 20px auto 0;
                        display: block;
                        border: 1px solid #3f6953;
                        background-color: #3f6953;
                        color: #fff;
                        border-radius: 10px;
                        padding: 10px 40px;
                        cursor: pointer;
                        transition:all .3s;

                        &:hover {
                            color: #3f6953;
                            background-color: #fff;
                        }
                    }

                    .cannot-click {
                        width: 100%;
                        margin: 20px auto 0;
                        display: block;
                        border: 0;
                        background-color: rgba(220,220,220,.5);
                        color: #4d4d4d;
                        border-radius: 10px;
                        padding: 10px 40px;
                    }

                    .cb-btn {
                        border: 0;
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: center;
                        margin: 20px 0 0 0;
                        padding: 10px 0;
                        border-radius: 10px;
                        background-color: #dbe8f4;
                        cursor: pointer;

                        &:hover {
                            opacity: .5;
                        }

                        svg {
                            padding-right: 10px;
                            font-size: 20px;
                        }
                    }
                }
            }

            .total-wrapper {
                display: flex;
                .left {
                    width: 75%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .total {
                    width: calc(25% + 1px);
                    border-left: 1px solid #d4d4d4;
                    padding: 10px;
                    position: relative;

                    .btn-learning-wrapper {
                        position: absolute;
                        height: 75px;
                        right: 0;
                        bottom: -75px;
                        width: calc(100% + 1px);
                        border: 1px solid #d4d4d4;
                        padding: 10px;
                        box-sizing: border-box;
                        background-color: #fff;
                    }
                }

                .wrapper-cell {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 50px;

                    .green {
                        background-color: #6aaf8b;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        color: #fff;
                        text-align: center;
                        border-radius: 5px;
                    }
                }

                .can-click {
                    width: 100%;
                    height: 100%;
                    display: block;
                    border: 1px solid #3f6953;
                    background-color: #3f6953;
                    color: #fff;
                    border-radius: 5px;
                    padding: 10px 10px;
                    cursor: pointer;
                    transition:all .3s;

                    &:hover {
                        color: #3f6953;
                        background-color: #fff;
                    }
                }

                .cannot-click {
                    width: 100%;
                    height: 100%;
                    display: block;
                    border: 0;
                    background-color: rgba(220,220,220,.5);
                    color: #4d4d4d;
                    border-radius: 5px;
                    padding: 10px 10px;
                }
            }

            .loading-section {

                padding: 20px;
                .loader-wrapper {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    .validate-btn {
                        padding: 5px 10px;
                        background-color: #202c3c;
                        color: #fff;
                        border-radius: 5px;
                        cursor: pointer;

                        &:hover {
                            opacity: .9;
                        }
                    }

                    .icon-check {
                        font-size: 25px;
                        color: #6AAF8B;
                    }

                }

                .btn-go-wrapper {
                    text-align: center;

                }
                .formation-btn {
                    margin: 0 auto;
                    padding: 10px;
                    background-color: #D47558;
                    color: #F7EDEA;
                    border-radius: 5px;
                    cursor: pointer;
                    margin: 20px 0 0 0;
                    transition: all .2s ease;
                    display: inline-block;

                    &:hover {
                        opacity: .9;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1400px) {
    .page-wrapper {
        .right-wrapper {
            width: calc(100% - 250px);

            h1 {
                font-size: 45px;
            }

            h2 {
                font-size: 35px;
            }
        }
    }
}

@media only screen and (max-width: 1079px) {
    .page-wrapper {

        margin: 150px auto 0;
        .right-wrapper {
            width: calc(100% - 250px);

            h1 {
                font-size: 40px;
            }

            h2 {
                font-size: 30px;

                .separator-blue {
                    width: 100px;
                }
            }

            .facturation-wrapper, .info-remake-wrapper {
                .picto-wrapper {
                    font-size: 14px;

                    p {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 0px) and (max-width: 759px) {

    .page-wrapper {

        .right-wrapper {
            width: calc(100%);

            h1 {
                font-size: 30px;

                .separator-blue {
                    width: 100px;
                }
            }

            h2 {
                font-size: 25px;
                text-align: center;

                .separator-blue {
                    width: 50px;
                    position: static;
                    margin: 10px auto;
                }
            }

            .top-left-info {
                font-size: 14px;
                text-align: center;
            }

            .drop-file-wrapper {

                font-size: 14px;
                text-align: center;
                
                svg {
                    display: none;
                }
            }

            .facturation-wrapper, .info-remake-wrapper {

                .info-text {
                    font-size: 12px;
                    display: flex;
                    flex-direction: column;

                    svg {display:none}
                }
                .picto-wrapper {
                    font-size: 12px;
                    padding: 5px !important;

                    p {
                        -webkit-hyphens: auto;
                        hyphens: auto;
                        width: 100%;
                    }
                }
            }

            .facturation-wrapper .total-wrapper .can-click {
                padding: 5px;
                font-size: 12px;
            }

            .facturation-wrapper .total-wrapper .cannot-click {
                padding: 5px;
                font-size: 12px;
            }

            .filter-wrapper {

                .nbf-wrapper {
                    p {
                        font-size: 14px;
                    }
                }

                .date-wrapper, .nomf-wrapper {
                    padding-right: 5px !important;
                    font-size: 14px !important;

                    .MuiSelect-select {
                        font-size: 14px !important;
                    }
                }

                .filter-icon-wrapper {
                    svg {
                        font-size: 14px !important;
                    }
                }
            }
        }
    }

    .page-wrapper .right-wrapper .facturation-wrapper .financement-cpf-wrapper .center .paiment-cb {
        font-size: 14px;
    }
}
  
`;

export default AccountFactureStyled;