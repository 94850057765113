import { connect } from 'react-redux'

import ModalJobFiche from '../../components/ModalJobFiche'

import { fetchThisJobOffer } from '../../actions/job'

const mapStateToProps = (state) => ({
    jobFicheId: state.job.jobFicheId,
    currentJobFiche: state.job.currentJobFiche,
})

const mapDispatchToProps = (dispatch) => ({
    fetchThisJobOffer: (id) => {
        dispatch(fetchThisJobOffer(id));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(ModalJobFiche)

