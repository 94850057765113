/**** Import des modules ****/
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, Link, Navigate } from 'react-router-dom'
import { AiFillPlusCircle } from "react-icons/ai";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { MdArrowDropUp, MdArrowDropDown } from "react-icons/md";
import { BiLoaderAlt } from "react-icons/bi";
import { FaCalendarTimes, FaHandPaper } from "react-icons/fa";
/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import BannerAccount from '../../components/Header/BannerAccount'
import AccountMenu from '../../containers/AccountMenu'
import CourseDoneCard from '../../containers/CourseDoneCard'
import MenuItemS from '../../containers/AccountCourse/MenuItemS';

/**** Import des css ****/
import AccountCourseStyled from './AccountCourseStyled'

const AccountCourse = ({
  user,
  userInfo,
  userRole,
  isLogged,
  fetchAccountFormations,
  formationsEnCours,
  formationsTerminee,
  formationsDemande,
  fetchParticuliers,
  particuliers,
  addParticulierOnEntreprise,
  fetchUserScore,
  userScore,
  menuFormation,
  fetchMySalarie,
  myEntreprisePart,
  salarieClickedId,
  salarieClickedName,
  setSalarieClicked,
  setMenuFormation
}) => {
  let navigate = useNavigate();
  useEffect(() => {
    fetchAccountFormations()
  }, []);
  useEffect(() => {
    if (particuliers.length === 0 && userRole === 'entreprise') {
      fetchParticuliers()
    }
  }, []);
  useEffect(() => {
    fetchUserScore()
  }, []);
  const [changeSearchValue, setChangeSearchValue] = useState('');
  const [entrepriseSalarie, setEntrepriseSalarie] = useState([]);
  const [nameOfSalarie, setNameOfSalarie] = useState('');
  const [enCoursFormations, setEnCoursFormations] = useState([]);
  const [formationsTermine, setFormationsTermine] = useState([]);
  const [menuOne, setMenuOne] = useState(false);

  useEffect(() => {
    if (userRole === 'entreprise') {
      fetchMySalarie()
    }
  }, []);
  /*let filteredParticuliers = () => { 
    if ( particuliers !== undefined ) {
    let func = particuliers.data.filter((particulier) => particulier.attributes.user.data.attributes.prenom.toLowerCase().includes(changeSearchValue.toLowerCase()) || particulier.attributes.user.data.attributes.username.toLowerCase().includes(changeSearchValue.toLowerCase()) || particulier.attributes.user.data.attributes.email.toLowerCase().includes(changeSearchValue.toLowerCase()))
    return func
  }}*/

  if(!isLogged) {
    return (<Navigate to="/" replace />)
  }

  return (
    <AccountCourseStyled>
      <Header bcgColor={'#fff'}/>
      {!isLogged && 
        navigate("/")
      }
      {isLogged &&
      <main className="page-wrapper">
      <AccountMenu />
      { formationsEnCours !== '' &&
      <>
      {userRole === 'particulier' &&
        <section className="right-wrapper">
          <div className="secteur-choice">
            <div 
              className='drop-down-1' 
              onClick={() => {
                setMenuOne(!menuOne)
              }}>
              <h4 className="bold">Choisissez un statut</h4>
              <div className="bold info-dyna"></div>
              <div className='arrow-wrapper' onClick={() => setMenuOne(!menuOne)}>
                {menuOne ? 
                  <MdArrowDropUp className="arrow-down" />
                :
                  <MdArrowDropDown className="arrow-down"/>
                }
            </div>
            </div>
            {menuOne &&
            <div className='formations-wrapper'>
              <div onClick={() => {
                setMenuFormation('encours')
              }}>
                <p className="list-paragraphe"><BiLoaderAlt /> Formations en cours</p>
                <span className="bold">Afficher</span>
              </div>
              <div onClick={() => {
                setMenuFormation('terminée')
              }}>
                <p className="list-paragraphe"><FaCalendarTimes />Formations terminées</p>
                <span className="bold">Afficher</span>
              </div>
              <div onClick={() => {
                setMenuFormation('demandes')
              }}>
                <p className="list-paragraphe"><FaHandPaper />Demandes de formation</p>
                <span className="bold">Afficher</span>
              </div>
            </div>
            }
          </div>
          {menuFormation === 'encours' &&
          <>
          <h2>Vos formations en cours<span> <div className="separator-blue"></div></span></h2>
          {formationsEnCours !== undefined && formationsEnCours.data.length > 0 && formationsEnCours.data.map((formation) => (
            <CourseDoneCard key={formation.id} courseId={formation.id} {...formation} statut={menuFormation}/>
          ))}
          {formationsEnCours !== undefined && formationsEnCours.data.length === 0 && 
            <p>Vous n'avez aucune formation en cours !<Link to="/formation" className="btn-formation">Voir toutes les formations</Link></p>
          }
          </>
          }
          
          {menuFormation === 'terminée' &&
          <>
          <h2 className="padding-top-title">Vos formations terminées<span> <div className="separator-blue"></div></span></h2>
          {formationsTerminee !== undefined && formationsTerminee.data.length > 0 && formationsTerminee.data.map((formation) => (
            <CourseDoneCard key={formation.id} courseId={formation.id} {...formation}/>
          ))}
          {formationsTerminee !== undefined && formationsTerminee.data.length === 0 && 
            <p>Vous n'avez passé aucune formation.</p>
          }
          </>
          }
          {menuFormation === 'demandes' &&
          <>
          <h2 className="padding-top-title">Vos demandes de formation<span> <div className="separator-blue"></div></span></h2>
          {formationsDemande !== undefined && formationsDemande.data.length > 0 && formationsDemande.data.map((formation) => (
            <CourseDoneCard key={formation.id} courseId={formation.id} {...formation}/>
          ))}
          {formationsDemande !== undefined && formationsDemande.data.length === 0 && 
            <p>Vous n'avez fait aucune demande de formation.</p>
          }
          </>
          }
        </section>
      }
      </>
      }
      {userRole === 'entreprise' &&
        <section className="right-wrapper">
          {/**<div className="wrapper-searchpart">
            <h4 className="bold">Ajouter un salarié dans votre entreprise</h4>
            <p className="disclaimer">(il doit avoir créé son compte particulier au préalable)</p>
            <form>
              <input
                className="searchbar"
                icon="search"
                type="text"
                placeholder="Recherchez par nom, prénom ou email de votre salarié."
                onChange={(event) => {
                  setChangeSearchValue(event.target.value);
                }}
              />
            </form>
            {filteredParticuliers() !== undefined && changeSearchValue !== '' && filteredParticuliers().map((particulier) => (
              <div className="particulier-founded">
                <span>{particulier.attributes.user.data.attributes.prenom} </span>
                <span>{particulier.attributes.user.data.attributes.username} </span>
                <span>{particulier.attributes.user.data.attributes.email} </span>
                <AiFillPlusCircle 
                  onClick={() => addParticulierOnEntreprise(particulier.id)}
                />
              </div>
            ))}
            </div>**/}
        {myEntreprisePart.length > 0 ?
        <>
        {/* 
        <FormControl fullWidth className="input-top-salarie">
          <InputLabel id="demo-simple-select-label">Résultat</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={salarieClickedId}
              label="Vos salariés"
            >
              {myEntreprisePart.map((salarie, index) => (
                <MenuItem
                value={salarie.id}
                key={index}
                onClick={(event) => {
                  const salarieId = salarie.id
                  setSalarieClicked('', salarieId)
                }}
              >
                <MenuItemS salarie={salarie} />
              </MenuItem>
              ))}
            </Select>
          </FormControl>
          {salarieClickedId !== '' && 
            <div className="wrapper-salarie">
              <h3>Formations en cours</h3>
               ici on lance la requete avec les formations en cours
              <h3>Formations terminées</h3>
               ici on lance la requete avec les formations terminé
            </div>
          }
        */}
        </>
        :
        <>
        <p>Vous n'avez aucun salarié inscrit dans votre enteprise.</p>
        <p>Faites des demandes de formation pour vos salariés si vous souhaitez utiliser cette fonctionnalité.</p>
        <Link to='/formation' className='btn-formation'>
          Former mes collaborateurs
        </Link>
        </>
        }
        {userInfo !== undefined && userInfo.data[0].attributes.particuliers.data.length > 0 && userInfo.data[0].attributes.particuliers.data.map((salarie) => (
          <div key={salarie.id} className="wrapper-salarie">
            {salarie.attributes.en_cours_formations.data.length > 0 && salarie.attributes.en_cours_formations.data.map((formation) => (
              <div key={formation.id} className="card-wrapper">
                <CourseDoneCard key={formation.id} salarie={salarie} courseId={formation.id} {...formation}/>
              </div>
            ))}
            {salarie.attributes.en_cours_formations.data.length === 0 && 
              <div className="card-wrapper">
                <p>Ce salarié n'a aucune formation en cours</p>
              </div>
            }
            
            {salarie.attributes.formations_termine.data.length > 0 && salarie.attributes.formations_termine.data.map((formation) => (
              <div key={formation.id} className="card-wrapper">
                <CourseDoneCard key={formation.id} salarie={salarie} courseId={formation.id} {...formation}/>
              </div>
            ))}
            {salarie.attributes.demande_formations.data.length === 0 && 
              <div className="card-wrapper">
                <p>Ce salarié n'a fait aucune formation</p>
              </div>
            }
          </div>
        ))}
      </section>
      }
      </main>
      }
      <Footer />
    </AccountCourseStyled>
  )
}

AccountCourse.propTypes = {
}

export default AccountCourse