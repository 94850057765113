/**** Import de modules ****/
import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

/**** Import des css ****/
import JobLiStyled from './JobLiStyled'

const JobLi = ({
  setSubmenuJobSecteurActiv, 
  submenuJobSecteurActiv, 
  setSubmenuJobDomaineActiv, 
  submenuJobDomaineActiv,
  setSubmenuJobFormationActiv, 
  submenuJobFormationActiv,
  secteurs,
  currentProfilUser,
  secteursData,
  setFilter
}) => {
  const [isShowJobMenu, setIsShowJobMenu] = useState(false);
  const hasWindow = typeof window !== 'undefined';

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  return (
    <JobLiStyled
    currentProfilUser={currentProfilUser}
      className="emploie-btn bold"
      onClick={() => { /*
        if (windowDimensions.width > 1079) {
          setIsShowJobMenu(true)
        } */
      }}
      onMouseOver={() => {/*
        if (windowDimensions.width > 1079) {
          setIsShowJobMenu(true)
        }
      */}}
      // onMouseLeave={() => setIsShowJobMenu(false)}
      windowWidth={windowDimensions.width}
    >
      {currentProfilUser === 'Entreprise' ? "Recruter des collaborateurs" : "Nos offres d'emploi"}
      {isShowJobMenu && (
        <div className="job-submenu-wrapper">
          <div className="secteurs column-submenu">
            <h4 className="bold">Secteurs</h4>
            <ul>
              {secteursData.map((secteur) => (
                <Link to="/job" className="white-hover">
                  <li
                    key={secteur.id}
                    className={submenuJobSecteurActiv === secteur.id ? "active" : ""}
                    onClick={() => {
                      setFilter(secteur.attributes.nom, 'secteurJob') 
                      setFilter('', 'domaineJob')
                      setFilter('', 'formationJob')
                    }}
                    onMouseEnter={() => {
                      setSubmenuJobFormationActiv("")
                      setSubmenuJobDomaineActiv("")
                      setSubmenuJobSecteurActiv(secteur.id)
                    }}
                  >
                    {secteur.attributes.nom}
                  </li>
                </Link>
              ))}
            </ul>
          </div>
          <div className="domaines column-submenu">
            <h4 className="bold">Domaines</h4>
            <ul>
            {submenuJobSecteurActiv !== "" && secteursData.find(item => item.id === submenuJobSecteurActiv).attributes.domaines.data.map((domaine) => (
              <Link to="/job" className="white-hover">
                <li
                  key={domaine.id}
                  className={submenuJobDomaineActiv === domaine.id ? "active" : ""}
                  onClick={() => {
                    setFilter(domaine.attributes.nom, 'domaineJob') 
                    setFilter('', 'secteurJob')
                    setFilter('', 'formationJob')
                  }}
                  onMouseEnter={() => {
                    setSubmenuJobDomaineActiv("")
                    setSubmenuJobDomaineActiv(domaine.id)
                  }}
                >
                  {domaine.attributes.nom}
                </li>
                </Link>
              ))}
            </ul>
          </div>
          <div className="formations column-submenu">
            <h4 className="bold">Emploi</h4>
            <ul>
            {submenuJobDomaineActiv !== "" && secteursData.find(item => item.id === submenuJobSecteurActiv).attributes.domaines.data.find(item => item.id === submenuJobDomaineActiv).attributes.fiche_metiers.data.map((formation) => (
              <Link to="/job" className="white-hover">
                <li
                  key={formation.id}
                  className={submenuJobFormationActiv === formation.id ? "active" : ""}
                  onClick={() => {
                    setFilter(formation.attributes.nom, 'formationJob') 
                    setFilter('', 'secteurJob')
                    setFilter('', 'domaineJob')
                  }}
                  onMouseEnter={() => {
                    setSubmenuJobFormationActiv("")
                    setSubmenuJobFormationActiv(formation.id)
                  }}
                >
                  
                  {formation.attributes.nom}
                  
                </li>
                </Link>
              ))}
            </ul>
          </div>
        </div>
    )}
    </JobLiStyled>
  )
}

JobLi.propTypes = {
  setSubmenuJobSecteurActiv: PropTypes.func.isRequired, 
  submenuJobSecteurActiv: PropTypes.string.isRequired, 
  setSubmenuJobDomaineActiv: PropTypes.func.isRequired, 
  submenuJobDomaineActiv: PropTypes.string.isRequired,
  setSubmenuJobFormationActiv: PropTypes.func.isRequired, 
  submenuJobFormationActiv: PropTypes.string.isRequired,
}

export default JobLi