/**** Import des modules ****/
import React, { useEffect, useRef } from 'react'
import { Link, useNavigate, Navigate } from "react-router-dom"
import { AiOutlinePlusCircle } from "react-icons/ai";
import PropTypes from 'prop-types'
import Skeleton from '@mui/material/Skeleton';
import { ToastContainer, toast } from 'react-toastify';
import { FaHome, FaUsers, FaBriefcase, FaUserCircle, FaBook, FaFileAlt, FaChartPie, FaFileSignature, FaUserTie} from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import 'react-toastify/dist/ReactToastify.css';

/**** Import d'images ****/
import AccountMenu from '../../../containers/AccountMenu';

/**** Import d'images ****/
import homeaccount from '../../../assets/img/illustration/home.png'
import menaccount from '../../../assets/img/illustration/candidat.png'
import progaccount from '../../../assets/img/illustration/en-train-detudier.png'
import femme from '../../../assets/img/illustration/candidats.png'
import suitcasemini from '../../../assets/img/illustration/star.png'
import documentM from '../../../assets/img/illustration/document.png'

/**** Import des css ****/
import AccountFormStyled from './AccountFormStyled'

const AccountForm = ({changeIsLogged, changeValue, isLogged, currentProfilUser, fetchUser, userRole, saveUser, setIsShowAccountFormMenu, disconnectUser, setLoadConnexion, loadConnexion, redirect, changeFormModal, setFullPageLoaderConnexion}) => {
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsShowAccountFormMenu(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  let navigate = useNavigate();
  if(redirect) {
    return (<Navigate to="/account/home" replace />)
  }
  return (
    <AccountFormStyled ref={wrapperRef}>
    {!isLogged && <>
      <form 
        className="account-form-comp"
        onSubmit={(event) => {
          event.preventDefault();
          setFullPageLoaderConnexion(true)
          fetchUser();
        }}
      >
        <label>
          <input 
            type="email" 
            name="email" 
            placeholder="Saisissez votre Email"
            onChange={(event) => {
              changeValue(event.target.value, event.target.name);
            }}
          />
        </label>
        <label>
          <input 
            type="password" 
            name="password" 
            placeholder="Saisissez votre mot de passe"
            onChange={(event) => {
              changeValue(event.target.value, event.target.name);
            }}
          />
        </label>
        <label>
          <input 
            type="submit" 
            value="Se connecter" 
            className="darkblue-btn"
          />
        </label>
      </form>
      <section className="link-wrapper">
        <p onClick={() => changeFormModal('loginLost')}>Mot de passe oublié ?</p>
        <span>
          <AiOutlinePlusCircle />
          <Link to="/signin" onClick={() => setIsShowAccountFormMenu(false)}>
          <p className="bold">Créer un compte</p>
          </Link>
        </span>
      </section>
    </>}
    {isLogged && <>
      <nav className="menu-account-nav">
        {userRole === 'entreprise' && 
        <>
        <ul>
          <li className="li-connected">
            <MdDashboard />
            <Link 
              to="/account/home" 
              onClick={() => setIsShowAccountFormMenu(false)}
            >Tableau de bord</Link>
          </li>
          <li className="li-connected">
            <FaUsers />
            <Link 
              to="/account/candidats" 
              onClick={() => setIsShowAccountFormMenu(false)}
            >Vos candidats</Link>
          </li>
          <li className="li-connected">
            <FaBriefcase />
            <Link 
              to="/account/jobs" 
              onClick={() => setIsShowAccountFormMenu(false)}
            >Vos offres d'emploi</Link>
          </li>
          <li className="li-connected">
            <FaFileSignature />
            <Link 
              to="/account/mes-financements"
              onClick={() => setIsShowAccountFormMenu(false)}
            >Vos financements</Link>
          </li>
          <li className="li-connected">
            <FaFileAlt />
            <Link 
              to="/account/documents"
              onClick={() => setIsShowAccountFormMenu(false)}
            >Vos documents</Link>
          </li>
          <li className="li-connected">
            <FaBook />
            <Link 
              to="/account/course"
              onClick={() => setIsShowAccountFormMenu(false)}
            >Vos formations</Link>
          </li>
          <li className="li-connected">
            <FaChartPie />
            <Link 
              to="/account/ocean"
              onClick={() => setIsShowAccountFormMenu(false)}
            >Test de personnalité</Link>
          </li>
          <li className="li-connected">
            <FaUserCircle />
            <Link 
            to="/account/profil"
            onClick={() => setIsShowAccountFormMenu(false)}
            >Profil</Link>
          </li>
        </ul>
          <span className="btn-deconnexion"
          onClick={() => {
            navigate('/');
            disconnectUser()
          }}
        >Déconnexion</span>
        </>
        }
        {userRole === 'particulier' && 
        <>
        <ul>
          <li className="li-connected">
            <MdDashboard />
            <Link 
              to="/account/home" 
              onClick={() => setIsShowAccountFormMenu(false)}
            >Tableau de bord</Link>
          </li>
          <li className="li-connected">
            <FaFileSignature />
            <Link 
              to="/account/mes-financements"
              onClick={() => setIsShowAccountFormMenu(false)}
            >Vos financements</Link>
          </li>
          <li className="li-connected">
            <FaFileAlt />
            <Link 
              to="/account/documents"
              onClick={() => setIsShowAccountFormMenu(false)}
            >Vos documents</Link>
          </li>
          <li className="li-connected">
            <FaBook />
            <Link 
              to="/account/course"
              onClick={() => setIsShowAccountFormMenu(false)}
            >Vos formations</Link>
          </li>
          <li className="li-connected">
            <FaUserTie />
            <Link 
              to="/account/recrutement"
              onClick={() => setIsShowAccountFormMenu(false)}
            >Test de recrutement</Link>
          </li>
          <li className="li-connected">
            <FaChartPie />
            <Link 
              to="/account/ocean"
              onClick={() => setIsShowAccountFormMenu(false)}
            >Test de personnalité</Link>
          </li>
          <li className="li-connected">
            <FaUserCircle />
            <Link 
            to="/account/profil"
            onClick={() => setIsShowAccountFormMenu(false)}
            >Profil</Link>
          </li>
        </ul>
          <span className="btn-deconnexion"
          onClick={() => {
            navigate('/');
            disconnectUser()
          }}
        >Déconnexion</span>
        </>
        }
      </nav>
    </>}
    </AccountFormStyled>
  
  )
}

AccountForm.propTypes = {
  changeIsLogged: PropTypes.func.isRequired,
  changeValue: PropTypes.func.isRequired, 
  isLogged: PropTypes.bool.isRequired
}

export default AccountForm