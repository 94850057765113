import styled from 'styled-components';

const JobCardListLayoutStyled = styled.article`
    width: 100%;
    color: #6aaf8b;
    border-radius: 30px; 
    padding: 10px 10px 20px 10px;
    box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
    -webkit-box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
    -moz-box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
    position: relative;
    margin: 50px 0 0 0px;
    text-align: center;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    height: 130px;

    &.pointer {
        cursor: pointer;
    }

    &.pointer:hover {
        background-color: #e1efe7;
    }

    &.active-job {
        border: 3px solid #6AAF8B;
        background-color: #ECF3EF;
        padding: 7px 7px 17px 7px;
    }

    .black {
        color: #4d4d4d;
    }

    .txt-skeleton {
        font-size: 18px;
    }

    .title-card {
        width: 100%;
        height: 20px;
        color: #4d4d4d;
    }

    .title-wrapper {
        text-align: center;
        text-transform: Capitalize;
        font-size: 14px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 70px;
        width: calc(35% - 15px);

        .publication-date {
            span {
                margin-left: 5px;
            }
        }

        h4 {
            height: 35px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }

        .first-title {
            font-weight: bold;
            color: #4d4d4d;
            height: 35px;
            align-items: flex-start
        }

        .separator-green {
            margin: 4.5px 0;
            height: 1px;
            background-color: #4d4d4d;
        }
    }

    .lieu-wrapper {
        text-align: center;
        text-transform: Capitalize;
        font-size: 14px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 70px;
        width: calc(17% - 15px);

        p {
            color: #6aaf8b;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            box-sizing: border-box;

            &.lieu {
                align-items: flex-start;
                color: #4d4d4d;
            }
        }

        .separator-green {
            margin: 4.5px 0;
            height: 1px;
            background-color: #4d4d4d;
        }
    }
    
    .salary-wrapper {
        text-align: center;
        text-transform: Capitalize;
        font-size: 14px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 70px;
        width: calc(20% - 15px);

        span {
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            box-sizing: border-box;

            &.small-txt {
                align-items: flex-start;

                span {
                    align-items: flex-start;
                }
            }
        }

        .separator-green {
            margin: 4.5px 0;
            height: 1px;
            background-color: #4d4d4d;
        }
    }

    .btn-lightgreen {
        width: 90px;
        margin: 0 auto;
        display: block;
        padding: 5px;
        border: 1px solid #6aaf8b;
        margin-bottom: 0px;
        border-radius: 10px;
        color: #fff;
        background-color: #6aaf8b;
        cursor: pointer;
        font-size: 14px;

        &:hover {
            color: #6aaf8b;
            background-color: #fff;
        }
    }

    .btn-green {
        width: 150px;
        margin: 0 auto;
        display: block;
        padding: 5px;
        border: 1px solid #6aaf8b;
        border-radius: 10px;
        color: #fff;
        background-color: #6aaf8b;
        cursor: pointer;
        font-size: 14px;

        &:hover {
            color: #6aaf8b;
            background-color: #fff;
        }
    }

    .btn-darkblue {
        width: 100px;
        margin: 0 auto;
        display: block;
        padding: 5px;
        border: 1px solid #4d4d4d;
        border-radius: 10px;
        color: #fff;
        background-color: #4d4d4d;
        cursor: pointer;
        font-size: 14px;

        &:hover {
            color: #4d4d4d;
            background-color: #fff;
        }
    }

    .suitcase-wrapper {
        height: 70px;
        width: 70px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
        -webkit-box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
        -moz-box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);

        &.green {
            background-color: #6AAF8B;
        }

        img {
            width: 40px;
        }
    }

    .distance-wrapper {
        position: absolute;
        width: 60%;
        left: calc(20%);
        bottom: -20px;
        height: 40px;
        max-height: 50px;
        border: 1px solid #6aaf8b;
        border-radius: 10px;
        background-color: #fff;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        font-size: 14px;
        box-sizing: border-box;

        .voiture-wrapper, .velo-wrapper, .train-wrapper {
            width: 33%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;

            svg {
                font-size: 30px;
            }
        }
        
        .svg-small {
            font-size: 22px;
        }

        .svg-normal {
            font-size: 30px;
        }

        .train-wrapper {
            svg {
                font-size: 22px;
            }
        }
    }

    @media only screen and (max-width: 1400px) {
        margin: 50px 0 0 10px;
    }

    @media only screen and (min-width: 760px) and (max-width: 1079px) {
        .mobile-none {
            display: none;
        }
    }
  
    @media only screen and (min-width: 0px) and (max-width: 759px) {
      
    }

`;

export default JobCardListLayoutStyled;