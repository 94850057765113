/**** Import de modules ****/
import React, {useEffect, useState} from 'react'
import { Link } from "react-router-dom"
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import { Progress } from 'semantic-ui-react'
import axios from 'axios'
import * as qs from 'qs'
import { Skeleton } from '@mui/material'


import urlBackEnd from '../../assets/js/urlBackEnd';


/**** Import d'images ****/
import videoblue from '../../assets/img/illustration/videoblue.png'


/**** Import des css ****/
import CourseDoneCardStyled from './CourseDoneCardStyled'

const URL = urlBackEnd

const CourseDoneCard = ({ attributes, userInfo, courseId, salarie, statut }) => {

  const [cardScore, setCardScore] = useState([])
  const [secteurCart, setSecteurCart] = useState([]);

  useEffect(() => {
    const queryFilterPart = qs.stringify({
      filters: {  
        formation: {
          id: courseId
        },
        particulier: {
          id: userInfo.data[0].id
        },
      }
      }, {
        encodeValuesOnly: true,
      });

    axios.get(`${URL}score-formations?${queryFilterPart}`, {})
      .then((response) => {
       setCardScore(response.data.data)
      })
      .catch((error) => {
        console.error(error);
      });
  }
  , [])

  useEffect(() => {
    const queryFilterPart = qs.stringify({
      filters: {
        formations: {
          id: courseId
        },
      }
      }, {
        encodeValuesOnly: true,
      });

    axios.get(`${URL}secteurs?${queryFilterPart}`, {})
      .then((response) => {
       setSecteurCart(response.data.data)
      })
      .catch((error) => {
        console.error(error);
      });
  }
  , [])
  
  return (
    <CourseDoneCardStyled>
      {cardScore.length === 0 ? 
      <img src={videoblue} alt="picto d'un écran vidéo bleu" />
      :
      <>
      {statut === 'encours' ? 
      <>
        {cardScore.length > 0 ?
          <div className="circle-wrapper">
           <CircularProgress className="progress-circle" color='success' size={90} variant="determinate" value={cardScore[0].attributes.score} />
           <div className="score-div"><span className='top bold'>{cardScore[0].attributes.score}</span><span className='middle bold'>/</span><span className='bottom bold'>100</span></div>
          </div>
        :
          <img src={videoblue} alt="picto d'un écran vidéo bleu" />
        }
      </>
      
      :
      <div className="circle-wrapper">
      <CircularProgress className="progress-circle" color='success' size={90} variant="determinate" value={cardScore[0].attributes.score} />
      <div className="score-div"><span className='top bold'>{cardScore[0].attributes.score}</span><span className='middle bold'>/</span><span className='bottom bold'>100</span></div>
      </div>
      }
      </>
    }
      <div className="text-wrapper">
      {secteurCart.length > 0 ?
      <div className="title-wrapper">
        <h3 className="title-orange bold">{secteurCart[0].attributes.nom}</h3>
        <h4 className="bold">Formation {attributes.nom}</h4>
      </div>
        
      :  
        <Skeleton variant="text" className='txt-skeleton' />
      }
        <div className="bottom-wrapper">
          <div className="flex-bottom">
            <p>Durée : {attributes.duree}h</p>
            {cardScore.length > 0 &&
            <a href={`https://elearning.wefor.fr/session/${cardScore[0].attributes.session_id}`} target='_blank' rel='noreferrer'>
            <button className="btn-lightblue">Accéder à la formation</button>
            </a>
            }
          </div>
          {cardScore.length > 0 ? 
          <Progress percent={cardScore[0].attributes.progress} indicating progress size='small'/>
          :
          ''
          }
        </div>
      </div>
    </CourseDoneCardStyled>
  )
}

CourseDoneCard.propTypes = {
}

export default CourseDoneCard