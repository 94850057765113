import { connect } from 'react-redux'

import ModalCreateLMSSession from '../../components/ModalCreateLMSSession'
import { fetchGetUserLms } from '../../actions/lms'

const mapStateToProps = (state) => ({
    stepForModalLMSSession: state.lms.stepForModalLMSSession,
    userDataLms: state.lms.userDataLms
})

const mapDispatchToProps = (dispatch) => ({
    fetchGetUserLms: (data) => {
        dispatch(fetchGetUserLms(data));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(ModalCreateLMSSession)

