/**** Import des modules ****/
import React, {useEffect, useState} from 'react'

import hmacSHA256 from 'crypto-js/hmac-sha256'
import Base64 from 'crypto-js/enc-base64'
import { HashLink } from 'react-router-hash-link'
import PropTypes from 'prop-types'
import { useNavigate, Link, Navigate } from 'react-router-dom'
import { FaPenAlt, FaFileDownload, FaFilter , FaInfoCircle} from "react-icons/fa"
import { MdLooksOne, MdLooksTwo, MdLooks3, MdLooks4, MdWarning, MdInfo, MdDownload } from "react-icons/md" 
import { GoArrowDown, GoCreditCard } from "react-icons/go"  
import { VscCircleFilled} from "react-icons/vsc"
import { BsCheck2Circle } from "react-icons/bs"
import Moment from 'react-moment'
import { saveAs } from 'file-saver'
import PaginationMui from '@mui/material/Pagination'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Switch from '@mui/material/Switch'
import ReactLoading from 'react-loading'
import moment from 'moment'
import cryptyAxepta from '../../assets/js/cryptoAxepta'
import urlBackEndSave from '../../assets/js/urlBackEndSave'

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import AccountMenu from '../../containers/AccountMenu'
import FactureCourse from '../../containers/FactureCourse'
import ContratCourse from '../../containers/ContratCourse'
import ModalSignature from '../../containers/ModalSignature'
import ConventionJob from '../../containers/ConventionJob'
import ModalConvention from '../../containers/ModalConvention'

/**** Import des images ****/
import pdfPng from '../../assets/img/logo/pdf.png'
import documentM from '../../assets/img/illustration/document.png'




/**** Import des css ****/
import AccountDocStyled from './AccountDocStyled'
import cryptoAxepta from '../../assets/js/cryptoAxepta'


const MERCHANTIDAXEPTA = 'BNP_WEFORTEST_ECOM_t'

const AccountDoc = ({
  userRole,
  isLogged,
  fetchFactureE,
  factureE,
  fetchFactureP,
  factureP,
  userStatut,
  changeClickedFacture,
  clickedFacture,
  fetchPutModeFinancement,
  changeStepValue,
  fetchUploadEdofFile,
  menuDocument,
  fetchInscriptionUserLms,
  fetchCreateSessionLms,
  fetchStatutFormation,
  toggleModalSignature,
  modalSignatureIsOpen,
  modalConventionIsOpen
}) => {

  useEffect(fetchFactureE, []);
  useEffect(fetchFactureP, []);

  const [page, setPage] = useState(1)
  const [cardPerPage, setCardPerPage] = useState(5)
  const [indexOfLastCard, setIndexOfLastCard] = useState(cardPerPage)
  const [indexOfFirstCard, setIndexOfFirstCard] = useState(0)
  const [date, setDate] = React.useState('');
  const [price, setPrice] = React.useState('');
  const [formation, setFormation] = React.useState('');
  const [statutFacture, setStatutFacture] = React.useState('perso');
  const [statutForNewFin, setStatutForNewFin] = React.useState(userStatut);
  const [files, setFiles] = useState(false)
  const [filesPe, setFilesPe] = useState(false)
  const [value, setValue] = React.useState('one');

  const handleChangeValue = (event, newValue) => {
    setValue(newValue);
  };

  if(!isLogged) {
    return (<Navigate to="/" replace />)
  }

  const handleChange = (event) => {
    setPrice('')
    setDate(event.target.value)
  };

  const handleChangePrice = (event) => {
    setDate('')
    setPrice(event.target.value)
  };

  const handleChangeFormation = (event) => {
    setFormation(event.target.value)
  };

  const changePagesCards = (value) => {
    setIndexOfLastCard(value * cardPerPage)
    setIndexOfFirstCard(value * cardPerPage - cardPerPage)
  }
 
   const handleChangePagination = (event, value) => {   
     setPage(value)
     changePagesCards(value)
   };

   const factureFilters = () => {
     if (factureE.length > 0) {
      let factureEFiltered = factureE.filter((factureItem) => factureItem.attributes.nom_formation.includes(formation))
      if (price !== '' && price === 'asc') {
        factureEFiltered.sort(function(a, b){
          return a.attributes.Prix - b.attributes.Prix
        })
        return factureEFiltered
      } else if (price !== '' && price === 'desc'){
        factureEFiltered.sort(function(a, b){
          return b.attributes.Prix - a.attributes.Prix
        })
        return factureEFiltered
      } else if (date !== '' && date === 'asc'){
        factureEFiltered.sort(function(a, b){
          return new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt)
        })
        return factureEFiltered
      } else if (date !== '' && date === 'desc'){
        factureEFiltered.sort(function(a, b){
          return new Date(a.attributes.createdAt) - new Date(b.attributes.createdAt)
        })
        return factureEFiltered
      } else {
        return factureEFiltered
      }
    }
   }

   const facturePFilters = () => {
    if (factureP.length > 0) {
     let facturePFiltered = factureP.filter((factureItem) => factureItem.attributes.nom_formation.includes(formation))
     if (price !== '' && price === 'asc') {
       facturePFiltered.sort(function(a, b){
         return a.attributes.Prix - b.attributes.Prix
       })
       return facturePFiltered
     } else if (price !== '' && price === 'desc'){
       facturePFiltered.sort(function(a, b){
         return b.attributes.Prix - a.attributes.Prix
       })
       return facturePFiltered
     } else if (date !== '' && date === 'asc'){
       facturePFiltered.sort(function(a, b){
         return new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt)
       })
       return facturePFiltered
     } else if (date !== '' && date === 'desc'){
       facturePFiltered.sort(function(a, b){
         return new Date(a.attributes.createdAt) - new Date(b.attributes.createdAt)
       })
       return facturePFiltered
     } else {
       return facturePFiltered
     }
   }
  }

  const savePdf = (url, name) => {
    saveAs(
      `${urlBackEndSave}${url}`,
      `${name}`
    );
  };

  const facturePbyP = () => {
    let facturePbyParray = facturePFilters().filter(facture => facture.attributes.mode_financement !== 'Entreprise')
    return facturePbyParray
  }

  const facturePbyE = () => {
    let facturePbyEarray = facturePFilters().filter(facture => facture.attributes.mode_financement === 'Entreprise')
    return facturePbyEarray
  }

  const clickedFactureOjb = () => {
    let factureToShow = factureP.find( facture => facture.id === clickedFacture)
    return factureToShow  
  }

  const clickedFactureOjbE = () => {
    let factureToShow = factureE.find( facture => facture.id === clickedFacture)
    return factureToShow  
  }

  const isItTrueMode = () => {
    if (clickedFactureOjb().attributes.mode_financement === 'Salarié' || clickedFactureOjb().attributes.mode_financement === 'Demandeur d\'emploi') {
      return false
    } else {
      return true
    }
  }

  const isItTrueModeE = () => {
    if (clickedFactureOjbE().attributes.mode_financement === 'Entreprise') {
      return false
    } else {
      return true
    }
  }

  const differentStep = (step) => {
    if (step === 1) {
      return 2
    }
    if (step === 2) {
      return 1
    }
  }

  const calcRest = (coursePrice, cpf, pe) => {
    const rest = coursePrice - cpf - pe;
    return rest
  }

  let euroFormat = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
  });
  
  return (
    <AccountDocStyled>
      {isLogged &&
      <>
      <Header bcgColor={'#fff'}/>
      <main className="page-wrapper">
      <AccountMenu />
      <section className="right-wrapper">
      {/** Affichage factures **/}
      {menuDocument === 'factures' && 
      <FactureCourse />
      }
      {/** Affichage contrats **/}
      {menuDocument === 'contrats' && 
      <>
      <ContratCourse />
      </>
      }
      {/** Affichage convention **/}
      {userRole === 'entreprise' && menuDocument === 'recrutement' && 
      <>
      <ConventionJob />
      </>
      }
      </section>
      {modalSignatureIsOpen && 
      <ModalSignature />
      }
      {modalConventionIsOpen && 
      <ModalConvention />
      }
      </main>
      </>
      }
      <Footer />
    </AccountDocStyled>
  )
}

AccountDoc.propTypes = {
}

export default AccountDoc