/**** Import des modules ****/
import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import ModalVideo from 'react-modal-video'
import PropTypes from 'prop-types'
import AOS from 'aos';
import "aos/dist/aos.css"

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'

/**** Import des images ****/
import euro from '../../assets/img/picto/dollars.png'
import logo from '../../assets/img/illustration/financement.png'
import cpf from '../../assets/img/logo/thumbnail_cpf.png'
import poleEmploi from '../../assets/img/logo/pole-emploi.png'
import axepta from '../../assets/img/logo/axetpa2.png'
import visa from '../../assets/img/logo/visa.png'
import mastercard from '../../assets/img/logo/mastercard.png'
import amex from '../../assets/img/logo/amex.png'
import atlas from '../../assets/img/logo/atlas.svg'
import afdas from '../../assets/img/logo/afdas.svg'
import ocapiat from '../../assets/img/logo/ocapiat.png'
import uniformation from '../../assets/img/logo/uniformation.svg'
import constructys from '../../assets/img/logo/constructys.png'
import opcommerce from '../../assets/img/logo/opcommerce.svg'
import akto from '../../assets/img/logo/akto.png'
import opco2i from '../../assets/img/logo/opco2i.svg'
import opcoMobilites from '../../assets/img/logo/opco-mobilites.svg'
import opcoep from '../../assets/img/logo/opcoep.svg'
import opcoSante from '../../assets/img/logo/opco-sante.png'
import repfr from '../../assets/img/logo/republique-francaise.png'

/**** Import des css ****/
import FinancementStyled from './FinancementStyled'

const Financement = ({financements, currentProfilUser}) => {
  useEffect(()=> {
    AOS.init({ duration: 1000 })
  }, [])
  
  const [isOpen, setIsOpen] = useState(false);
  const filteredFinancements = financements.filter((financement) => financement.for.includes(currentProfilUser))

  return (
    <FinancementStyled>
      <Header bcgColor={'rgba(100,154,206,1)'}/>
      <section className="illustration-header">
      <div className="opacity-light-section">
        <img src={logo} alt='logo wefor'/>
        <div className="left">
          {currentProfilUser === 'notChoose' && 
            <h1 className="bold">Les financements</h1>
          }
          {currentProfilUser !== 'notChoose' && 
          <>
            <h1 className="bold">Les financements</h1>
            {currentProfilUser === "Demandeur d'emploi" && 
              <p>Vous souhaitez vous former pour décrocher rapidement le job de vos rêves ? <span className="bold">Plusieurs dispositifs existent</span> pour financer tout ou partie de votre projet de formation. Découvrez-les !</p>
            }
            {currentProfilUser === "Salarié" && 
              <p>Vous avez un projet de formation en vue d’une promotion, d’une mobilité interne ou d’une reconversion ? <span className="bold">Plusieurs dispositifs existent</span> pour financer tout ou partie de votre projet de formation. Découvrez-les !</p>
            }
            {currentProfilUser === "Entreprise" && 
              <p>Plusieurs dispositifs existent pour financer tout ou partie des projets de formation de vos collaborateurs. Découvrez-les !</p>
            }
            {currentProfilUser === "Autres" && 
              <p>Vous êtes indépendant ? Fonctionnaire ? Sans activité professionnelle ? <span className="bold">Plusieurs dispositifs existent</span> pour financer tout ou partie de votre projet de formation. Découvrez-les !</p>
            }
            <Link to="/contact"><button className="btn-contact">Contactez-nous</button></Link>
          </>
          }
        </div>
        </div>
      </section>
      {/* 
      <section className="description-financement">
        <p>Que vous soyez à la recherche d’emploi, en poste actuellement, indépendant ou même sans activité professionnelle des solutions existent pour financer la formation de vos rêves ! Découvrez le financement adapté à votre situation.</p>
      </section>
      */}
      {currentProfilUser === "Demandeur d'emploi" && 
      <>
        <section className="step-one-section">
          <div className="left-wrapper">
          <a href="https://www.moncompteformation.gouv.fr/">
            <div className="card-wrapper-step">
              <h3 className='bold uppercase'>{financements[0].step}</h3>
              <h2 className='bold'>{financements[0].name}</h2>
              <img src={cpf} alt="logo cpf bleu carré" />
            </div>
          </a>
          </div>
          <div className="right-wrapper">
            <div className="p-wrapper">
              {financements[0].text.map((p) => (
                <p key={p.length}>{p}</p>
              ))}
            </div>
            <div className="video-wrapper">
              <iframe width="" height="" src={`https://www.youtube.com/embed/${financements[0].videoId}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
          </div>
          <div className='and-or'>Et / Ou</div>
        </section>
        <div className="bcg-blue-wrapper">
          <section className="step-two-section inverted">
            <div className="left-wrapper">
            <a href="https://www.pole-emploi.fr/accueil/">
              <div className="card-wrapper-step">
                <h3 className='bold uppercase'>{financements[1].step}</h3>
                <h2 className='bold red'>{financements[1].name}</h2>
                <h2 className='bold'>{financements[1].subname}</h2>
                <img src={poleEmploi} alt="logo cpf bleu carré" />
              </div>
            </a>
            </div>
            <div className="right-wrapper">
              <div className="p-wrapper">
                {financements[1].text.map((p) => (
                  <p key={p.length}>{p}</p>
                ))}
              </div>
              <div className="video-wrapper">
                <iframe width="" height="" src={`https://www.youtube.com/embed/${financements[1].videoId}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>
            <div className='and-or'>Et - Ou</div>
          </section>
        </div>
        <section className="step-three-section">
          <div className="left-wrapper">
            <div className="card-wrapper-step">
              <h3 className='bold uppercase'>{financements[5].step}</h3>
              <h2 className='bold red'>{financements[5].name}</h2>
              <h2 className='bold'>{financements[5].subname}</h2>
              <img src={axepta} alt="logo cpf bleu carré" />
            </div>
          </div>
          <div className="right-wrapper two-grid">
            <div className="p-wrapper">
              <h3 className="case-title bold">Cas n°1</h3>
              <p>En cas de reste à charge total, payez par carte bleue directement sur notre site ou par virement bancaire après avoir choisi votre formation.</p>
              <p className='bold paiement'>Paiement 100% sécurisé !</p>
              <div className='cb-wrapper'>
                <img src={visa} alt="logo cpf bleu carré" />
                <img src={mastercard} alt="logo cpf bleu carré" />
                <img src={amex} alt="logo cpf bleu carré" />
              </div>
            </div>
            <div className="p-wrapper">
              <h3 className="case-title bold">Cas n°2</h3>
              <p>En cas de reste à charge partiel, payez le complement sur la plateforme EDOF CPF.</p>
              <img src={cpf} alt="logo cpf bleu carré" />
            </div>
          </div>
        </section>
      </>
      }
      {currentProfilUser === "Salarié" && 
      <>
        <section className="step-one-section">
          <div className="left-wrapper">
          <a href="https://www.moncompteformation.gouv.fr/">
            <div className="card-wrapper-step">
              <h3 className='bold uppercase'>{financements[0].step}</h3>
              <h2 className='bold'>{financements[0].name}</h2>
              <img src={cpf} alt="logo cpf bleu carré" />
            </div>
          </a>
          </div>
          <div className="right-wrapper">
            <div className="p-wrapper">
              {financements[0].text.map((p) => (
                <p key={p.length}>{p}</p>
              ))}
            </div>
            <div className="video-wrapper">
              <iframe width="" height="" src={`https://www.youtube.com/embed/${financements[0].videoId}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
          </div>
          <div className='and-or'>Et / Ou</div>
        </section>
        <div className="bcg-blue-wrapper">
          <section className="step-two-section inverted">
            <div className="left-wrapper">
              <div className="card-wrapper-step">
                <h3 className='bold uppercase'>{financements[2].step}</h3>
                <h2 className='bold red'>{financements[2].name}</h2>
                <h2 className='bold'>{financements[2].subname}</h2>
              </div>
            </div>
            <div className="right-wrapper">
              <div className="p-wrapper">
                {financements[2].text.map((p) => (
                  <p key={p.length}>{p}</p>
                ))}
              </div>
              <div className="video-wrapper">
                <iframe width="" height="" src={`https://www.youtube.com/embed/${financements[2].videoId}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>
            <div className='and-or'>Et - Ou</div>
          </section>
        </div>
        <section className="step-three-section">
          <div className="left-wrapper">
            <div className="card-wrapper-step">
              <h3 className='bold uppercase'>{financements[5].step}</h3>
              <h2 className='bold red'>{financements[5].name}</h2>
              <h2 className='bold'>{financements[5].subname}</h2>
              <img src={axepta} alt="logo cpf bleu carré" />
            </div>
          </div>
          <div className="right-wrapper two-grid">
            <div className="p-wrapper">
              <h3 className="case-title bold">Cas n°1</h3>
              <p>En cas de reste à charge total, payez par carte bleue directement sur notre site ou par virement bancaire après avoir choisi votre formation.</p>
              <p className='bold paiement'>Paiement 100% sécurisé !</p>
              <div className='cb-wrapper'>
                <img src={visa} alt="logo cpf bleu carré" />
                <img src={mastercard} alt="logo cpf bleu carré" />
                <img src={amex} alt="logo cpf bleu carré" />
              </div>
            </div>
            <div className="p-wrapper">
              <h3 className="case-title bold">Cas n°2</h3>
              <p>En cas de reste à charge partiel, payez le complement sur la plateforme EDOF CPF.</p>
              <img src={cpf} alt="logo cpf bleu carré" />
            </div>
          </div>
        </section>
      </>
      }
      {currentProfilUser === "Entreprise" && 
      <>
        <section className="step-one-section">
          <div className="left-wrapper">
            <div className="card-wrapper-step">
              <h3 className='bold uppercase'>Étape 1</h3>
              <h2 className='bold'>{financements[6].name}</h2>
              <h2 className='bold'>{financements[6].subname}</h2>
              <div className="logo-list">
                  <a href="https://www.afdas.com/"><img src={afdas} alt="logo cpf bleu carré" /></a>
                  <a href="https://www.opco-atlas.fr/"><img src={atlas} alt="logo cpf bleu carré" /></a>
                  <a href="https://www.ocapiat.fr/"><img src={ocapiat} alt="logo cpf bleu carré" /></a>
                  <a href="https://www.uniformation.fr/"><img src={uniformation} alt="logo cpf bleu carré" /></a>
                  <a href="https://www.constructys.fr/"><img src={constructys} alt="logo cpf bleu carré" /></a>
                  <a href="https://www.lopcommerce.com/"><img src={opcommerce} alt="logo cpf bleu carré" /></a>
                  <a href="https://www.akto.fr/"><img src={akto} alt="logo cpf bleu carré" /></a>
                  <a href="https://www.opco2i.fr/"><img src={opco2i} alt="logo cpf bleu carré" /></a>
                  <a href="https://www.opcomobilites.fr/"><img src={opcoMobilites} alt="logo cpf bleu carré" /></a>
                  <a href="https://www.opcoep.fr/"><img src={opcoep} alt="logo cpf bleu carré" /></a>
                  <a href="https://www.opco-sante.fr/"><img src={opcoSante} alt="logo cpf bleu carré" /></a>
                </div>
            </div>
          </div>
          <div className="right-wrapper">
            <div className="p-wrapper">
              {financements[6].text.map((p) => (
                <p key={p.length}>{p}</p>
              ))}
            </div>
            <div className="video-wrapper">
              <iframe width="" height="" src={`https://www.youtube.com/embed/${financements[2].videoId}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
          </div>
          <div className='and-or'>Et / Ou</div>
        </section>
        <div className="bcg-blue-wrapper">
          <section className="step-two-section inverted">
            <div className="left-wrapper">
            <a href="https://www.service-public.fr/particuliers/vosdroits/F2811">
              <div className="card-wrapper-step">
                <h3 className='bold uppercase'>{financements[3].step}</h3>
                <h2 className='bold'>{financements[3].name}</h2>
                <img src={repfr} alt="logo cpf bleu carré" />
              </div>
            </a>
            </div>
            <div className="right-wrapper">
              <div className="p-wrapper">
                {financements[3].text.map((p) => (
                  <p key={p.length}>{p}</p>
                ))}
              </div>
              <div className="video-wrapper">
                <iframe width="" height="" src={`https://www.youtube.com/embed/${financements[3].videoId}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>
            <div className='and-or'>Et - Ou</div>
          </section>
        </div>
        <section className="step-three-section">
          <div className="left-wrapper">
            <div className="card-wrapper-step">
              <h3 className='bold uppercase'>Étape 3</h3>
              <h2 className='bold red'>{financements[5].name}</h2>
              <h2 className='bold'>{financements[5].subname}</h2>
              <img src={axepta} alt="logo cpf bleu carré" />
            </div>
          </div>
          <div className="right-wrapper">
            <div className="p-wrapper">
              <p>En cas de financement total, payez par carte bleue directement sur notre site ou par virement bancaire après avoir choisi les formations pour vos collaborateurs.</p>
              <p className='bold paiement'>Paiement 100% sécurisé !</p>
              <div className='cb-wrapper'>
                <img src={visa} alt="logo cpf bleu carré" />
                <img src={mastercard} alt="logo cpf bleu carré" />
                <img src={amex} alt="logo cpf bleu carré" />
              </div>
            </div>
          </div>
        </section>
      </>
      }
      <Footer />
    </FinancementStyled>
  )
}

Financement.propTypes = {
  financements: PropTypes.array.isRequired
}

export default Financement