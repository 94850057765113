/**** Import des modules ****/
import React, { useState, useEffect } from 'react'
import { useNavigate, Navigate } from 'react-router-dom'
import Moment from 'react-moment';
import PaginationMui from '@mui/material/Pagination'
import axios from 'axios'
import * as qs from 'qs'
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import { FaList } from "react-icons/fa"
import { BsFillGridFill } from "react-icons/bs"

import urlBackEnd from '../../assets/js/urlBackEnd'

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import AccountMenu from '../../containers/AccountMenu'
import CandidatCard from '../../containers/CandidatCard'
import CandidatCardList from '../../containers/CandidatCardList'
import ModalConnexion from '../../containers/ModalInscription/ModalConnexion'
import FullPageLoader from '../../components/FullPageLoader';

/**** Import des css ****/
import AccountCandidatsStyled from './AccountCandidatsStyled'
import { Skeleton } from '@mui/material';

const URL = urlBackEnd

const AccountCandidats = ({
    currentProfilUser,  
    user,
    userInfo,
    userRole,
    isLogged,
    jobsEntreprisePageCandidat,
    setSelectionJobOffer,
    selectionJobOffer,
    fetchJobOfferPageCandidat,
    fullPageLoaderJobOffer,
    fetchDemandesJobFromJobOffer,
    demandesFromJobOffer,
    fetchScoresFormation,
    fetchCandidatFromJobOffer,
    candidatFromJob,
    candidatFromJobPagination,
    fullPageLoaderCandidatFromJob,
    offreIdHP,
    setOffreIdHP
  }) => {
  let navigate = useNavigate();

  const [showOfferMenu, setShowOfferMenu] = useState(false);
  const [page, setPage] = useState(1)
  const [indexOfLastCard, setIndexOfLastCard] = useState(6)
  const [indexOfFirstCard, setIndexOfFirstCard] = useState(0)
  const [testOceanManager, setTestOceanManager] = useState([])
  const [activLayout, setActivLayout] = useState('list')

  useEffect(() => {
    if (isLogged) {
      if (selectionJobOffer === true) {

        if(demandesFromJobOffer.length === jobsEntreprisePageCandidat.length) { 
          setSelectionJobOffer(jobsEntreprisePageCandidat[0])
        }
        
      } 
    }
  }
  , [isLogged, selectionJobOffer, jobsEntreprisePageCandidat, setSelectionJobOffer, demandesFromJobOffer, fetchCandidatFromJobOffer]);

  useEffect(() => {
    if(offreIdHP !== '' && jobsEntreprisePageCandidat.length > 0) {
      const offerToSelect = jobsEntreprisePageCandidat.find( item => item.id === offreIdHP)
      setSelectionJobOffer(offerToSelect)
      setOffreIdHP('')
    }
  }
  , [jobsEntreprisePageCandidat])

  useEffect(() => {
    if (isLogged && jobsEntreprisePageCandidat.length === 0) {
      fetchJobOfferPageCandidat(userInfo.data[0].id)
    }
  }
  , [isLogged])

  useEffect(() => {
    if(selectionJobOffer !== '') {
      fetchCandidatFromJobOffer(selectionJobOffer.id, 6, 1)
    }
  }
  , [isLogged, selectionJobOffer])

  useEffect(() => {
    if (isLogged) {
    const queryFilterPart = qs.stringify({
      filters: {
        entreprise: {
          id: userInfo.data[0].id
        },
      }
      }, {
        encodeValuesOnly: true,
      });

    axios.get(`${URL}test-oceans?${queryFilterPart}`, {})
      .then((response) => {
       setTestOceanManager(response.data.data)
      })
      .catch((error) => {
        console.error(error);
      });
    }
  }
  , [])

  const changePagesCards = (value) => {
    setIndexOfLastCard(value * 6)
    setIndexOfFirstCard(value * 6 - 6)
  }
 
   const handleChangePagination = (event, value) => {   
     setPage(value)
     changePagesCards(value)
     fetchCandidatFromJobOffer(selectionJobOffer.id, 6, value)
   };


  const mappedJobOffer = (offerId, demandes) => {
    const offerToReturn = demandes.find(demande => demande.demandesFromJobOffer === offerId)
    return offerToReturn
  }

  return (
    <AccountCandidatsStyled showOfferMenu={showOfferMenu} onClick={() => { if(showOfferMenu === true){setShowOfferMenu(false)}}}>
      <Header bcgColor={'#fff'}/>
      {!isLogged && 
        <div className="connexion">
          <ModalConnexion />
        </div>
      }
      {isLogged &&
      <main className="page-wrapper">
      {!userRole === 'entreprise' && 
        navigate("/")
      }
      <AccountMenu />
      <section className="right-wrapper">
      {userRole === 'entreprise' &&
      <>
      <section className="filters">
        {/* <h2>VOS OFFRES D'EMPLOI<span> <div className="separator-blue"></div></span></h2> */}
        {fullPageLoaderJobOffer ? 
        <Skeleton className="skeleton-candidat" />
        :
        <div className="filters-wrapper">
          <div className="offers-wrapper">
            <div className='arrow-wrapper' onClick={() => setShowOfferMenu(!showOfferMenu)}>
              {showOfferMenu ? 
                <MdArrowDropUp className="arrow-down" />
              :
                <MdArrowDropDown className="arrow-down"/>
              }
            </div>
            <div 
              className="choosed-offer bold" 
              onClick={() => setShowOfferMenu(!showOfferMenu)}
            >
              {selectionJobOffer ? selectionJobOffer.attributes.nom : "Choisissez une offre d'emploi"}
              <ul className="list-offer-wrapper">
                <span className='cache'></span>
              {jobsEntreprisePageCandidat.length > 0 && jobsEntreprisePageCandidat.map((offer) => (
                <li 
                  key={offer.id} 
                  className="bold"
                  onClick={() => {
                    setPage(1)
                    setShowOfferMenu(false)
                    setSelectionJobOffer(offer)
                  }}
                >
                  <div className='bold'>{offer.attributes.nom}</div> 
                  <div>Publiée le <Moment format="DD/MM/YYYY" className="bold">{offer.attributes.publishedAt}</Moment></div>
                  <div><span className='bold number'>{offer.attributes.particuliers_demande.data.length}</span> candidat{offer.attributes.particuliers_demande.data.length > 1 ? 's' : ''}</div>
                </li>
              ))}
            </ul>
            </div>
          </div>
        </div>
        }
      </section>
      {fullPageLoaderCandidatFromJob &&
        <FullPageLoader />
      }
      <section className="candidats">
      {candidatFromJob.length > 0 && selectionJobOffer ?
        <>
        <h2 className="bold">Liste des candidats</h2>
        <section className="user-filter-section">
        <BsFillGridFill 
          className={`layout-btn ${activLayout === 'grid' ? 'active-layout' : ''}`} 
          onClick={() => {
            setActivLayout('grid')
            setIndexOfLastCard(12)
            setIndexOfFirstCard(0)
            setPage(1)
          }}
        />
        <FaList 
          className={`layout-btn ${activLayout === 'list' ? 'active-layout' : ''}`}
          onClick={() => {
            setActivLayout('list')
            setIndexOfLastCard(12)
            setIndexOfFirstCard(0)
            setPage(1)
          }}
        />
      </section>
        <div key={selectionJobOffer.id} className={`card-wrapper ` + (activLayout === 'list' ? 'fullwidth' : '')}>
            {activLayout === 'list' && 
              <div className="card-wrapper">
              {candidatFromJob.length > 0 && selectionJobOffer && candidatFromJob.map((candidat) => (
                <>
                <CandidatCardList key={candidat.id} candidat={candidat} testOceanManager={testOceanManager} />
              </>
              ))}
              {candidatFromJob.length > 0 && selectionJobOffer && Object.keys(candidatFromJobPagination).length > 0 &&
                <PaginationMui page={candidatFromJobPagination.page} className="pagination-pc" count={candidatFromJobPagination.pageCount} variant="outlined" color="primary" onChange={handleChangePagination}/>
              }
            </div>
            }
            {activLayout === 'grid' && 
              <div className="card-wrapper">
              {candidatFromJob.length > 0 && selectionJobOffer && candidatFromJob.map((candidat) => (
                <>
                <CandidatCard key={candidat.id} candidat={candidat} testOceanManager={testOceanManager} />
              </>
              ))}
              {candidatFromJob.length > 0 && selectionJobOffer && Object.keys(candidatFromJobPagination).length > 0 &&
                <PaginationMui page={candidatFromJobPagination.page} className="pagination-pc" count={candidatFromJobPagination.pageCount} variant="outlined" color="primary" onChange={handleChangePagination}/>
              }
            </div>
            }
          </div>
        </>
        :
        <>
        {selectionJobOffer !== '' ?
          <p>Il n'y a aucun candidat pour cette offre.</p>
          :
          <p>Pour afficher la liste des candidats, veuillez sélectionner une offre d'emploi.</p>
        }
        </>
      }
      </section>
      </>
      }
      </section>
      </main>
      }
      <Footer />
    </AccountCandidatsStyled>
  )
}

AccountCandidats.propTypes = {
}

export default AccountCandidats