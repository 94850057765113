/**** Import des modules ****/
import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FaBicycle, FaCarSide, FaTrain } from "react-icons/fa"
import Moment from 'react-moment'
import { Skeleton } from '@mui/material'
import axios from 'axios'
import * as qs from 'qs'

/**** Import d'images ****/
import suitcase from '../../assets/img/picto/suitcase-green.png'
import suitcaseWhite from '../../assets/img/picto/suitcase-white.png'

/**** components ****/
import ModalJobFiche from '../../containers/ModalJobFiche';
import ModalCourseProg from '../../containers/ModalCourseProg';

/**** Import des css ****/
import JobCardListLayoutStyled from './JobCardListLayoutStyled'
import urlBackEnd from '../../assets/js/urlBackEnd'

const URL = urlBackEnd

const JobCardListLayout = ({activJobId, changeActivJobId, jobId, attributes, userRole, setJobFicheId, changeActivCourseId, isLogged, distanceV, distanceD, jobs, id, jobsWithDistances, userInfo, kmJobFilter, saveDistanceOnJobs, showDistance, openModal, fullPageLoaderDistance, toggleModalPostulerTuto, setJobIdModalPostulerTuto, setCvPosted, setDidTest, setJobForModalPostuler }) => {
  
  const [modalJobFicheOpen, setModalJobFicheOpen] = useState(false);
  const [modalCourseProgOpen, setModalCourseProgOpen] = useState(false);
  const [enCoursF, setEnCoursF] = useState([]);
  const [termineF, setTermineF] = useState([]);

  useEffect(() => {
    if (isLogged === true) {
    const queryFilterPart = qs.stringify({
      filters: {
        en_cours_particuliers: {
          id: userInfo.data[0].id
        },
      }
      }, {
        encodeValuesOnly: true,
      });

    axios.get(`${URL}formations?${queryFilterPart}`, {})
      .then((response) => {
       setEnCoursF(response.data.data)
      })
      .catch((error) => {
        console.error(error);
      });

    const queryFilterPartt = qs.stringify({
      filters: {
        particuliers_termine: {
          id: userInfo.data[0].id
        },
      }
      }, {
        encodeValuesOnly: true,
      });

    axios.get(`${URL}formations?${queryFilterPartt}`, {})
      .then((response) => {
        setTermineF(response.data.data)
      })
      .catch((error) => {
        console.error(error);
      });
    }
  }
  , [])

  useEffect(() => {

    if (isLogged === true) {
    const queryFilterPart = qs.stringify({
      filters: {  
        session_id: 'recrutement',
        particulier: {
          id: userInfo.data[0].id
        },
      }
      }, {
        encodeValuesOnly: true,
      });

    axios.get(`${URL}score-formations?${queryFilterPart}`, {})
      .then((response) => {
        if(response.data.data.length > 0) {
          setDidTest(true)
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }
  }
  , [])

  let location = useLocation()

  const thisJob = () => {
    if(jobsWithDistances !== []) {
    const jobToFind = jobsWithDistances.find( thisjob => thisjob.id === id)
    return jobToFind
    } else {
      return undefined
    }
  }

  useEffect(() => {
    thisJob()
  }, [saveDistanceOnJobs]);

  const verifsModalPostulerTuto = () => {
    toggleModalPostulerTuto(true)
    setJobIdModalPostulerTuto(activJobId, attributes.nom)
    if(isLogged) {
      if(userInfo.data[0].attributes.cv.data !== null){
        setCvPosted(true)
      }
    }
  }

  const secteurCart = attributes.secteurs.data[0]

  return (
    <JobCardListLayoutStyled
    className={`${activJobId === jobId & location.pathname === '/job' ? "active-job" : ""} ${location.pathname === '/job' ? "pointer" : ""}`}
      onClick={() => changeActivJobId(jobId)}
    >
      {modalJobFicheOpen &&
        <ModalJobFiche modalJobFicheOpen={modalJobFicheOpen} setModalJobFicheOpen={setModalJobFicheOpen} />
      }
      {modalCourseProgOpen &&
        <ModalCourseProg modalCourseProgOpen={modalCourseProgOpen} setModalCourseProgOpen={setModalCourseProgOpen} />
      }
      <h4 className="bold title-card">{attributes.nom}</h4>
      
        {activJobId !== jobId &&
          <div className="suitcase-wrapper">
            <img src={suitcase} className="suitcase-picto" alt="sac a main vert" />
          </div>
        }
        {activJobId === jobId && ( location.pathname === '/job') &&
          <div className="suitcase-wrapper green">
            <img src={suitcaseWhite} className="suitcase-picto" alt="sac a main vert" />
          </div>
        }
        <div className="title-wrapper">
          <h4 className="publication-date bold">Publiée le <span><Moment format="DD/MM/YYYY"  className="bold">{attributes.createdAt}</Moment></span></h4>
          <div className="separator-green"></div>
          <h4 className="first-title bold">{secteurCart.attributes.nom}</h4>
        </div>
        <div className="lieu-wrapper">
          <p className="bold">Lieu</p>
          <div className="separator-green"></div>
          <p className="lieu bold">{attributes.lieu}</p>
        </div>
        <div className="salary-wrapper">
            <span className="bold">Rémunération:</span>
            <div className="separator-green"></div>
            {attributes.remuneration === 0 ? 
            <span className="small-txt bold"><span className="black bold">à déterminer</span></span> 
            :
            <span className="small-txt bold"><span className="black bold">{attributes.remuneration}K€</span></span> 
            }
        </div>
        <div className="btn-wrapper">
        {userRole !== 'entreprise' &&
        <>
        { location.pathname === "/job" ?
        <button 
          className="btn-lightgreen mobile-none"
          onClick={() => {
            openModal('postuler')
            verifsModalPostulerTuto()
            let jobObj = {
              id: jobId,
              attributes: attributes
            }
            setJobForModalPostuler(jobObj)
          }}
        >Postuler</button>
        :
        <Link to={`/job/${jobId}`} onClick={() => {
            openModal('postuler')
            verifsModalPostulerTuto()
            let jobObj = {
              id: jobId,
              attributes: attributes
            }
            setJobForModalPostuler(jobObj)
          }}>
          <button className="btn-lightgreen">Postuler</button>
        </Link>
        }
        </>
        }
        {/* location.pathname === "/job" ?
        <button 
          className="btn-darkblue"
          onClick={() => {
            changeActivCourseId(attributes.formation.data.id)
            setModalCourseProgOpen(true)
          }}
        >Formation nécessaire</button>
        :
        <>
        {attributes.formation.data === null ? "" :
        <Link to={`/formation/${attributes.formation.data.id}`}>
        <button className="btn-darkblue">Formation nécessaire</button>
        </Link>
        }
        </>
      */}

        </div>
        {isLogged && userRole !== 'entreprise' && enCoursF.length > 0 | termineF.length > 0 ?
        <div className="distance-wrapper">
          { kmJobFilter === '' && 
          <>
            <div className="voiture-wrapper">
              <FaCarSide />
            </div>
            <div className="velo-wrapper">
              <FaBicycle />
            </div>
            <div className="train-wrapper">
              <FaTrain />
            </div>
          </>
          }
          { kmJobFilter === 'Temps à vélo' && 
          <>
          {!fullPageLoaderDistance ? thisJob().direction !== undefined &&
            <>
              <FaBicycle className="svg-normal"/>
              <div>Distance: {jobsWithDistances.find( thisjob => thisjob.id === id).direction.km.text}</div>
              <div>Temps: {jobsWithDistances.find( thisjob => thisjob.id === id).direction.time.text}</div>
            </>
            :
            <Skeleton width='340px' height='30px'/>
          }
          </>
          }
          { kmJobFilter === 'Temps en voiture' &&
            <>
            {!fullPageLoaderDistance ? thisJob().direction !== undefined &&
            <>
              <FaCarSide className="svg-normal"/>
              <div>Distance: {thisJob().direction.km.text}</div>
              <div>Temps: {thisJob().direction.time.text}</div>
            </>
              :
              <Skeleton width='340px' height='30px'/>
            }
            </>
          }
          { kmJobFilter === 'Temps en transport' && 
          <>
          {!fullPageLoaderDistance ? thisJob().direction !== undefined &&
            <>
              <FaTrain className="svg-small"/>
              <div>Distance: {jobsWithDistances.find( thisjob => thisjob.id === id).direction.km.text}</div>
              <div>Temps: {jobsWithDistances.find( thisjob => thisjob.id === id).direction.time.text}</div>
            </>
              :
              <Skeleton width='340px' height='30px'/>
            }
            </>
          }
        </div>
        :''
    }
    </JobCardListLayoutStyled>
  )
}

JobCardListLayout.propTypes = {
  activJobId: PropTypes.number.isRequired,
  changeActivJobId: PropTypes.func.isRequired, 
}

export default JobCardListLayout