/**** Import des modules ****/
import React, { useState, useEffect } from 'react'
import AOS from 'aos';
import "aos/dist/aos.css"

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'


/**** Import des images ****/
import contract from '../../assets/img/illustration/contratcheck.png'
import logo from '../../assets/img/illustration/header-homme.png'

/**** Import des css ****/
import CGVStyled from './CGVStyled'

const CGV = ({}) => {

  useEffect(()=> {
    AOS.init({ duration: 1000 })
  }, [])
  return (
    <CGVStyled>
      <Header bcgColor={'rgba(100,154,206,1)'}/>
      <section className="illustration-header">
        <div className="opacity-light-section">
        <img src={logo} alt='logo wefor'/>
          <div className="left">
            <h1 className="bold">Conditions générales de vente</h1>
          </div>
        </div>
      </section>
      <section className="full-page">

        <h2 className="bold">1. Définitions</h2>

        <h3 className="bold">1er cas de figure : dans le cadre de l’activité de formation</h3>
        <p>Client : co-contractant de WEFOR</p>
        <p>Contrat : convention de formation professionnelle conclue entre WEFOR et le Client. Cette convention peut prendre la forme d’un contrat en bonne et due forme, d’un bon de commande émis par le Client et validé par WEFOR ou une facture établie pour la réalisation des actions de formation professionnelle.</p>
        <p>Formation intra-entreprise : Formation réalisée sur mesure pour le compte du Client, réalisée dans les locaux du Client.</p>
        <p>Formation pour les particuliers en situation de salariat ou en tant que demandeur d’emploi : Formation dispensée en distanciel par le biais du site <a href="https://wefor.fr/">www.wefor.fr</a> et de son LMS pédagogique.</p>

        <h3 className="bold">2ème  cas de figure : dans le cadre de l’activité de recrutement</h3>

        <p>Client : co-contractant de WEFOR</p>
        <p>Contrat : convention de recrutement conclue entre WEFOR et le client pour la mise en relation de candidats ayant suivis une formation WEFOR et souhaitant postuler à des offres d’emplois mises en ligne sur le site<a href="https://wefor.fr/">www.wefor.fr</a>.</p>

        <h2 className="bold">2. Objet et champ d’application</h2>

        <p>Tout Contrat implique l’acceptation sans réserve par le Client et son adhésion pleine et entière aux présentes Conditions Générales qui prévalent sur tout autre document du Client, et notamment sur toutes conditions générales d’achat.</p>
        <p>Aucune dérogation aux présentes Conditions Générales n’est opposable à WEFOR si elle n’a pas été expressément acceptée par écrit par celle-ci.</p>

        <h2 className="bold">3. Documents contractuels</h2>
        
        <p>Le Contrat précisera l’intitulé de la formation, sa nature, sa durée, ses effectifs, les modalités de son déroulement et la sanction de la formation ainsi que son prix et les contributions financières éventuelles de personnes publiques.</p>
        <p>Tout Contrat sera établi selon les dispositions légales et réglementaires en vigueur et plus précisément suivant les articles L6353-1 et L6353-2 du Code du travail.</p>
        <p>Le Contrat établit dans le cadre de l’activité de recrutement, précisera le montant des honoraires calculés sur la base d’un forfait par candidat recruté.</p>

        <h2 className="bold">4. Report / annulation d’une formation par WEFOR</h2>
        
        <p>WEFOR se réserve la possibilité d’annuler ou de reporter des formations planifiées sans indemnités, sous réserve d’en informer le Client avec un préavis raisonnable.</p>

        <h2 className="bold">5. Annulation d’une formation par le Client</h2>
        
        <p>Toute formation ou cycle commencé est dû en totalité, sauf accord contraire exprès de WEFOR.</p>

        <h2 className="bold">6. Remplacement d’un participant</h2>
        
        <p>Quel que soit le type de la formation, sur demande écrite avant le début de la formation, le Client a la possibilité de remplacer un participant sans facturation supplémentaire.</p>

        <h2 className="bold">7. Dématérialisation des supports</h2>
        
        <p>Dans le cadre d’un engagement environnemental, toute la documentation relative à la formation est remise sur des supports dématérialisés.</p>

        <h2 className="bold">8. Refus de former</h2>
        
        <p>Dans le cas où un Contrat serait conclu entre le Client et WEFOR sans avoir procédé au paiement de la (des) formation(s) précédente(s), WEFOR pourra, sans autre motif et sans engager sa responsabilité, refuser d'honorer le Contrat et de délivrer les formations concernées, sans que le Client puisse prétendre à une quelconque indemnité, pour quelque raison que ce soit.</p>

        <h2 className="bold">9. Prix et règlements</h2>
        
        <p>Les factures sont émises à l'inscription et sont payables en totalité avant le démarrage de la formation.</p>
        <p>Ces factures sont accessibles depuis l’espace personnel du Client à l’adresse <a href="https://wefor.fr/">www.wefor.fr</a></p>
        <p>Aucune formation ne pourra démarrer avant prise en charge ou encaissement complet du prix de la formation par le Client.</p>
        <p>Tous les prix sont indiqués en euros toutes taxes comprises, comprenant 20% de taux de TVA.</p>
        <p>Toute somme non payée à l'échéance donnera lieu au paiement par le Client de pénalités de retard égales au taux d’intérêt appliqué par la Banque Centrale Européenne à son opération de refinancement la plus récente (minimum 0%) majoré de 10 points de pourcentage.</p>
        <p>Ces pénalités sont exigibles de plein droit, sans mise en demeure préalable, dès le premier jour de retard de paiement par rapport à la date d’exigibilité du paiement.</p>
        <p>En outre, conformément aux dispositions législatives et réglementaires en vigueur, toute somme non payée à l'échéance donnera lieu au paiement par le Client d’une indemnité forfaitaire pour frais de recouvrement d’un montant de quarante euros (40€). Cette indemnité est due de plein droit, sans mise en demeure préalable dès le premier jour de retard de paiement et pour chaque facture impayée à son échéance.</p>

        <h2 className="bold">10. Règlement par un Opérateur de Compétences</h2>
        
        <p>Si le Client souhaite que le règlement soit effectué par l’Opérateur de Compétences dont il dépend, il lui appartient :</p>
        <p>- de faire une demande de prise en charge avant le début de la formation et de s’assurer de la bonne fin de cette demande ;</p>
        <p>- de positionner la prise en charge dans son espace personnel dédié sur le site www.wefor.fr;</p>
        <p>- de s’assurer de la bonne fin du paiement par l’Opérateur de Compétences qu’il aura désigné.</p>
        <p>Si l’Opérateur de Compétences ne prend en charge que partiellement le coût de la formation, le reliquat sera facturé au Client.</p>
        <p>Si WEFOR n’a pas reçu la prise en charge de l’Opérateur de Compétences au 1er jour de la formation, le Client sera facturé de l’intégralité du coût de la formation concernée par ce financement, et la formation ne pourra commencer qu’après réception de l’intégralité du restant des fonds dus par le Client.</p>
        <p>En cas de non-paiement par l’Opérateur de Compétences, pour quelque motif que ce soit, le Client sera redevable de l’intégralité du coût de la formation et sera facturé du montant correspondant.</p>

        <h2 className="bold">11. Obligations et Responsabilité de WEFOR</h2>
        <p>WEFOR s’engage à fournir la formation avec diligence et soin raisonnables. S’agissant d’une prestation intellectuelle, WEFOR n’est tenue qu’à une obligation de moyens.</p>
        <p>WEFOR ne pourra être tenu pour responsable de tous dysfonctionnements liés à la délivrance de services par ses partenaires ou fournisseurs d’accès internet et serveurs.</p>
        <p>En conséquence, WEFOR sera responsable uniquement des dommages directs résultant d’une mauvaise exécution de ses prestations de formation, à l’exclusion de tout dommage immatériel ou indirect consécutifs ou non.</p>
        <p>En toutes hypothèses, la responsabilité globale de WEFOR, au titre ou à l'occasion de la formation, sera limitée au prix total de la formation.</p>

        <h2 className="bold">12. Obligations du Client</h2>
        <p>Le Client s'engage à :</p>
        <p>- payer le prix de la formation ;</p>
        <p>- n'effectuer aucune reproduction de matériel ou documents dont les droits d'auteur appartiennent à WEFOR, sans l'accord écrit et préalable de WEFOR ; et</p>
        <p>- ne pas utiliser de matériel d'enregistrement audio ou vidéo lors des formations, sans l'accord écrit et préalable de WEFOR.</p>

        <h2 className="bold">13. E-Learning</h2>
        <p>WEFOR accorde au Client une licence d’utilisation non exclusive, incessible et non transférable de ses contenus de formation à distance, ci-après le « Module ».</p>
        <p>L’ensemble des Modules seront mis à la disposition du Client, dans un portail e-learning, après la formation du Contrat.</p>
        <p>L’accès aux Modules est géré par les plateformes e-learning de WEFOR.</p>
        <p>Un identifiant et un mot de passe sont communiqués au Client pour chacun des apprenants sur la base des informations fournies par le Client (nom, prénom, e-mail). L’identifiant et le mot de passe sont confidentiels, personnels, incessibles et intransmissibles.</p>
        <p>Le Client est responsable de la gestion et de la conservation des identifiants et mots de passe. En conséquence, il appartient au Client de mettre en œuvre toutes mesures de précaution nécessaires à leur protection et à leur conservation. Le Client est responsable des conséquences de leur utilisation.</p>
        <p>WEFOR ne pourra en aucun cas être tenue pour responsable de toute utilisation frauduleuse de l’identifiant et du mot de passe du Client.</p>
        <p>Le Client s’engage à informer WEFOR de toute utilisation frauduleuse de l’identifiant et du mot de passe dès qu’il en a connaissance.</p>
        <p>Le Client disposera d’un délai communiqué par WEFOR pour consommer les licences d’utilisation des Modules. Passé ce délai, la(les) licence(s) d’utilisation consentie cessera(ont) immédiatement.</p>
        <p>L’hébergement des Modules sur les plateformes du Client et/ou la création de contenus digitaux sur mesure font l’objet de dispositions spécifiques à négocier entre les parties.</p>


        <h2 className="bold">14. Confidentialité et Propriété Intellectuelle</h2>
        <p>Il est expressément convenu que toute information divulguée par WEFOR au titre ou à l’occasion de la formation doit être considérée comme confidentielle (ci-après « Informations ») et ne peut être communiquée à des tiers ou utilisée pour un objet différent de celui de la formation, sans l’accord préalable écrit de WEFOR. Le droit de propriété sur toutes les Informations que WEFOR divulgue, quel qu’en soit la nature, le support et le mode de communication, dans le cadre ou à l’occasion de la formation, appartient exclusivement à WEFOR. En conséquence, le Client s’engage à conserver les Informations en lieu sûr et à y apporter au minimum, les mêmes mesures de protection que celles qu’il applique habituellement à ses propres informations. Le Client se porte fort du respect de ces stipulations de confidentialité et de conservation par les apprenants.</p>
        <p>La divulgation d’Informations par WEFOR ne peut en aucun cas être interprétée comme conférant de manière expresse ou implicite un droit quelconque (aux termes d’une licence ou par tout autre moyen) sur les Informations ou autres droits attachés à la propriété intellectuelle et industrielle, propriété littéraire et artistique (copyright), les marques ou le secret des affaires. Le paiement du prix n’opère aucun transfert de droit de propriété intellectuelle sur les Informations.</p>
        <p>Par dérogation, WEFOR accorde à l’apprenant, sous réserve des droits des tiers, une licence d’utilisation non exclusive, non-cessible et strictement personnelle du support de formation fourni, et ce quel que soit le support. L’apprenant a le droit d’effectuer une photocopie de ce support pour son usage personnel à des fins d’étude, à condition que la mention des droits d’auteur de WEFOR ou toute autre mention de propriété intellectuelle soient reproduites sur chaque copie du support de formation. L’apprenant et le Client n’ont pas le droit, sauf accord préalable de WEFOR :</p>
        <p>-d'utiliser, copier, modifier, créer une œuvre dérivée et/ ou distribuer le support de formation à l’exception de ce qui est prévu aux présentes Conditions Générales ;</p>
        <p>-de désassembler, décompiler et/ou traduire le support de formation, sauf dispositions légales contraires et sans possibilité de renonciation contractuelle ;</p>
        <p>-de sous licencier, louer et/ou prêter le support de formation ;</p>
        <p>-d’utiliser à d’autres fins que la formation le support associé.</p>

        <h2 className="bold">15. Ethique</h2>
        <h3 className="bold">15.1 Le Client garantit: </h3>
        <p>que ni lui ni aucune personne sous sa responsabilité ou agissant en son nom ou pour son compte n’a accordé ni n’accordera d’offre, de rémunération ou de paiement ou avantage d'aucune sorte, constituant ou pouvant constituer selon la règlementation applicable au Contrat et aux parties un acte ou une tentative de corruption ou de trafic d’influence, directement ou indirectement, en vue ou en contrepartie de l'attribution d’un avantage (ci-après les « Actes de Corruption »). Le Client s’assurera qu’une enquête sera menée avec diligence en cas de preuve ou soupçon relatif à la commission d’un Acte de Corruption et sera signalée à WEFOR.</p>
        <h3 className="bold">15.2 Le Client garantit:</h3>
        <p>- que lui-même et toutes les personnes sous sa responsabilité ou agissant en son nom ou pour son compte, ont connaissance et se conforment à toutes les lois et réglementations sur la lutte contre la corruption qui leur sont applicables.</p>
        <p>- qu’il a mis en œuvre des règles et procédures permettant de se conformer auxdites lois et réglementations;</p>
        <p>- qu’il a mis en place des règles et procédures appropriées visant à prévenir la commission d’Actes de Corruption, par lui-même et par les personnes sous sa responsabilité ou agissant en son nom ou pour son compte;</p>
        <p>- que les archives relatives à ses activités, y compris les documents comptables, sont tenues et conservées de manière à garantir leur intégrité.
        La preuve de l’existence de ces règles et procédures évoquées supra sera communiquée sur demande à WEFOR.</p>
        <h3 className="bold">15.3</h3> 
        <p>Tout manquement à une des obligations ci-dessus ouvrira le droit à WEFOR de résilier immédiatement le Contrat et/ou de demander le paiement de dommages et intérêts.</p>


        <h2 className="bold">16. Protection des données personnelles</h2>
        <p>Dans le cadre de la réalisation des formations, WEFOR est amenée à collecter des données à caractère personnel. Ces données peuvent être partagées avec des sociétés du groupe WEFOR et éventuellement avec des sociétés tierces (prestataires, sous-traitants…).</p>
        <p>Les données personnelles, récoltées dans le cadre des finalités ci-avant exposé, peuvent être divulguées à un tiers dans les cas suivants :</p>
        <p>• Pour la réalisation de la formation professionnelle, en communiquant par exemple les données aux consultants en charge de la formation,</p>
        <p>• Afin de permettre à une entreprise qui recrute de pouvoir contacter les candidats ayant postulés aux offres d’emploi, à savoir un curriculum vitae, les résultats obtenus dans le cadre de la formation WEFOR suivie, ainsi que toutes informations utiles pour le recruteur et en rapport avec le candidat;</p>
        <p>• Pour se conformer à toute loi en vigueur, réglementation, procédure juridique ou autre obligation légale ;</p>
        <p>• Pour détecter, enquêter et permettre de prévenir des problèmes de sécurité, de fraude ou des problèmes techniques ;</p>
        <p>• Pour protéger les droits, la propriété ou la sécurité du Groupe WEFOR, ses utilisateurs, employés ou autres ; et</p>
        <p>• Pour réaliser une transaction générale, telle qu’un transfert d’avoirs ou une acquisition par le biais d’une fusion avec une autre société.</p>
        <p>En outre les personnes concernées disposent sur les données personnelles les concernant d’un droit d’accès, de rectification, d’effacement, de limitation, de portabilité, et d’apposition et peuvent à tout moment révoquer les consentements aux traitements. Les personnes concernées seront susceptibles de faire valoir leurs droits directement auprès de WEFOR ou de l’éventuel prestataire ou sous-traitant, qui s’engage à y faire droit dans les délais règlementaires et à en informer WEFOR, en écrivant à l’adresse suivante : dpo@wefor.fr</p>
        <p>Conformément à l’exigence essentielle de sécurité des données personnelles, WEFOR s’engage dans le cadre de l’exécution de ses formations à prendre toutes mesures techniques et organisationnelles utiles afin de préserver la sécurité et la confidentialité des données à caractère personnel et notamment d’empêcher qu’elles ne soient déformées, endommagées, perdues, détournées, corrompues, divulguées, transmises et/ou communiquées à des personnes non autorisées. Par conséquent, WEFOR s’engage à :</p>
        <p>-Ne traiter les données personnelles que pour le strict besoin des formations et pour ses activités de recrutement ;</p>
        <p>-Conserver les données personnelles pendant trois (3) ans ou une durée supérieure pour se conformer aux obligations légales, résoudre d’éventuels litiges et faire respecter les engagements contractuels. ;</p>
        <p>-En cas de sous-traitance, WEFOR se porte fort du respect par ses sous-traitants de tous ses engagements en matière de sécurité et de protection des données personnelles.</p>
        <p>-Enfin, dans le cas où les données à caractère personnel seraient amenées à être transférées hors de l’union européenne, il est rappelé que cela ne pourra se faire sans l’accord du Client et/ou de la personne physique concernée.</p>

        <h2 className="bold">17. Communication</h2>
        <p>Le Client autorise expressément le groupe WEFOR à mentionner son nom, son logo et à faire mention à titre de références de la conclusion d’un Contrat et de toute opération découlant de son application dans l’ensemble de leurs documents commerciaux.</p>


        <h2 className="bold">18. Loi applicable et juridiction</h2>
        <p>Les Contrats et tous les rapports entre WEFOR et son Client relèvent de la Loi française. Tous litiges qui ne pourraient être réglés à l’amiable dans un délai de soixante (60) jours compté à partir de la date de la première présentation de la lettre recommandée avec accusé de réception, que la partie qui soulève le différend devra avoir adressée à l’autre, seront de la compétence exclusive du tribunal de commerce de Nanterre quel que soit le siège du Client, nonobstant pluralité de défendeurs ou appel en garantie.</p>

      </section>
      <Footer />
    </CGVStyled>
  )
}

CGV.propTypes = {
}

export default CGV