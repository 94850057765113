/**** Import de modules ****/
import React, {useEffect, useState} from 'react'
import { AiFillDownCircle } from "react-icons/ai"
import { Link } from "react-router-dom"
import PropTypes from 'prop-types'
import Moment from 'react-moment'
import { MdCancel } from "react-icons/md"
import { AiFillCheckSquare, AiFillCloseSquare, AiOutlineClose} from "react-icons/ai";

import axios from 'axios'
import * as qs from 'qs'

import urlBackEnd from '../../assets/js/urlBackEnd'

/**** Import d'images ****/
import suitcase from '../../assets/img/illustration/suitcase.png'
import femme from '../../assets/img/illustration/femme.png'
import menaccount from '../../assets/img/illustration/menaccount.png'
import usericon from '../../assets/img/picto/utilisateur.png'


/**** Import des css ****/
import JobOnlineCardStyled from './JobOnlineCardStyled'

const URL = urlBackEnd

const JobOnlineCard = ({ attributes, changeActivJobId, id, fetchDeleteJobOffer, setSelectionJobOffer, job, fetchPutJobOffer, changeValueModifJob, ficheJob, jobFiches, secteurJob, secteursData, jobsAccountLoading, setFullPageLoaderJobOfferAction, fetchJobOffer, myTestsOcean, isLogged, userInfo, descriptifjobPut, missionjobPut, profiljobPut, jobsEntreprise, secteurJobPut }) => {
  const [rerender, setRerender] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalModif, setOpenModalModif] = useState(false);
  const [indexOfManager, setIndexOfManager] = useState(0)
  const [nameOfManager, setNameOfManager] = useState('')
  const [nomOfManager, setNomOfManager] = useState('')
  const [prenomOfManager, setPrenomOfManager] = useState('')
  const [candidatList, setCandidatList] = useState('')
  useEffect(()=>{
      setRerender(!rerender);
      changeValueModifJob(attributes.description, 'descriptifjobPut')
      changeValueModifJob(attributes.missions, 'missionjobPut')
      changeValueModifJob(attributes.profil, 'profiljobPut')
  }, []);

  useEffect(() => {
    if (isLogged) {
      if (jobsEntreprise === 0) {
      fetchJobOffer(userInfo.data[0].id);
    }
    }
  }, [])

  return (
    <JobOnlineCardStyled>
      <>
      <img src={suitcase} alt="picto d'un écran vidéo bleu" className="left-img"/>
      <div className="text-wrapper">
        <div className="top-wrapper">
          <h3 className="title bold">{attributes.nom}</h3>
          <p><Moment format="DD/MM/YYYY" className="bold">{attributes.publishedAt}</Moment></p>
        </div>
        <div className="middle-wrapper">
          <div className="infos-left">
          {attributes.remuneration === 0 ? 
          <p className="bold salary">à déterminer - {attributes.lieu}</p>
          :
          <p className="bold salary">{attributes.remuneration}K€ - {attributes.lieu}</p>
          }
          </div>
          <button 
            className="btn-light-blue"
            to="/job" 
            onClick={() => {
              changeActivJobId(id)
              setOpenModalModif(true)
              changeValueModifJob(attributes.secteurs.data[0].id , 'secteurJobPut');
            }}>
            Modifier
          </button>
        </div>
        <div className="bottom-wrapper">
            <div className="top">
            <p className="candidats bold">{attributes.particuliers_demande.data !== null && attributes.particuliers_demande.data.length} candidat{attributes.particuliers_demande.data !== null && attributes.particuliers_demande.data.length > 1 ? "s" : ""}</p>
              <button className="btn-light-blue"><Link to="/account/candidats" onClick={() => setSelectionJobOffer(job)}>Candidats</Link></button>
            </div>
        </div>
      </div>
      <MdCancel 
        className="delete-card-icon" 
        onClick={() => {setOpenModal(true)
      }} />
      </>
      {openModal &&
        <div className="modalValidation">
          <p>Êtes vous sur de vouloir supprimer cette offre d'emploi ?</p> 
          <div className="decision-wrapper">
            <button 
              className="btn-yes"
              onClick={() => {
                setFullPageLoaderJobOfferAction(true)
                fetchDeleteJobOffer(id)
                setOpenModal(false)
                setRerender(!rerender);
              }}
            ><AiFillCheckSquare />Oui</button>
            <button 
              className="btn-no"
              onClick={() => setOpenModal(false)}
            ><AiFillCloseSquare />Non</button>
          </div>
        </div>
      }
      {openModalModif &&
        <section className="modal">
        <section className="modal-content-container">
        <div className="content-condition">
        <h3 className="bold">MODIFIER UNE OFFRE D’EMPLOI</h3>
        <form 
          className="account-form-comp"
          onSubmit={(event) => {
            event.preventDefault();
            setFullPageLoaderJobOfferAction(true)
            fetchPutJobOffer(id, nomOfManager, prenomOfManager);
            setOpenModalModif(false)
            setRerender(!rerender);
          }}
        >
        <div className="drow-wrapper">
          <label>
              Nom :
              <input 
                type="text" 
                name="nomjobPut" 
                className='small-placeholder'
                placeholder={attributes.nom}
                onChange={(event) => {
                  changeValueModifJob(event.target.value, event.target.name);
                }}
              />
            </label>
            <label>
              Rémunération :
              <input 
                type="text" 
                name="remunerationjobPut" 
                className='small-placeholder'
                placeholder={attributes.remuneration + 'K€'}
                onChange={(event) => {
                  changeValueModifJob(event.target.value, event.target.name);
                }}
              />
            </label>
          </div>
          <div className="drow-wrapper">
          <label className="select-box">
              Type de contrat :
              <select
                name="contratjobPut" 
                onChange={(event) => {
                  changeValueModifJob(event.target.value, event.target.name);
                }}
              >
                <option value="CDI">
                  CDI
                </option>
                <option value="CDD">
                  CDD
                </option>
              </select>
          </label>
            <label>
              Localisation :
              <input 
                type="text" 
                name="localisationjobPut" 
                className='small-placeholder'
                placeholder={attributes.lieu}
                onChange={(event) => {
                  changeValueModifJob(event.target.value, event.target.name);
                }}
              />
            </label>
          </div>
          <label>
            Descriptif du poste :
            <textarea 
              name="descriptifjobPut" 
              className='small-placeholder'
              placeholder={attributes.description}
              value={descriptifjobPut}
              onChange={(event) => {
                changeValueModifJob(event.target.value, event.target.name);
              }}
            />
          </label>
          <label>
            Missions :
            <textarea
              name="missionjobPut" 
              className='small-placeholder'
              placeholder={attributes.missions}
              value={missionjobPut}
              onChange={(event) => {
                changeValueModifJob(event.target.value, event.target.name);
              }}
            />
          </label>
          <label>
            Profil :
            <textarea
              name="profiljobPut" 
              className='small-placeholder'
              placeholder={attributes.profil}
              value={profiljobPut}
              onChange={(event) => {
                changeValueModifJob(event.target.value, event.target.name);
              }}
            />
          </label>
          {myTestsOcean.length > 0 ?
            <label className="select-box">
            Manager
              <select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={nameOfManager}
                label="Résultat"
                onChange={(event) => {
                  setNameOfManager(event.target.value)
                  const str = event.target.value.split(/(\s+)/)
                  setNomOfManager(str[0])
                  setPrenomOfManager(str[2])
                }}
              >
                <option
                    value='Choisissez un manager'
                  >
                  Choisissez un manager
                  </option>
                {myTestsOcean.map((manager, index) => (
                  <option
                    key={index} 
                    value={manager.attributes.nom + ' ' + manager.attributes.prenom}
                    onClick={() => {
                      setIndexOfManager(index)
                      setNomOfManager(manager.attributes.nom)
                      setPrenomOfManager(manager.attributes.prenom)
                    }}
                  >
                    {manager.attributes.nom} {manager.attributes.prenom}
                  </option>
                ))}
              </select>
            </label>
            :
            <p className='btn-test-ocean bold'>Vous pouvez faire passer un <Link to='/account/ocean' className="bold"> test de personnalité</Link> à vos managers pour pouvoir les associer à cette offre. Si vous cliquez sur le lien, vous perdrez les modifications en cours.</p>
          }
          <label className="select-box">
              Secteur d'activité:
              <select
                name="secteurJobPut" 
                value={secteurJobPut !== undefined ? secteurJobPut : ''}
                onChange={(event) => {
                  changeValueModifJob(event.target.value, event.target.name);
                }}
              >
                {secteursData.map(({ attributes, id }) => (
                  <option key={id} value={id}>
                    {attributes.nom}
                  </option>
                ))}
              </select>
            </label>
            {/* 
            <label className="select-box">
              Domaine d'activité:
              <select
                name="domaineJob" 
                value={domaineJob !== undefined ? domaineJob : ''}
                onChange={(event) => {
                  changeValueNewJob(event.target.value, event.target.name);
                }}
              >
                {domainesData.map(({ attributes, id }) => (
                  <option key={attributes.nom} value={id}>
                    {attributes.nom}
                  </option>
                ))}
              </select>
            </label>
            */}
            {/*
            <label className="select-box">
              Fiche métier (en relation avec l'offre):
              <select
                name="ficheJobPut" 
                value={ficheJob !== undefined ? ficheJob : ''}
                onChange={(event) => {
                  changeValueModifJob(event.target.value, event.target.name);
                }}
              >
                {jobFiches.map(({ attributes, id }) => (
                  <option key={attributes.nom} value={id}>
                    {attributes.nom}
                  </option>
                )).sort()}
              </select>
            </label>
            */}
          <input 
            type="submit" 
            value="Modifier cette offre" 
            className="lightblue-btn"
          />
        </form>
        <div className="icon-wrapper">
          <AiOutlineClose 
            className="close-icon"
            onClick={() => setOpenModalModif(false)}
          />
        </div>
        </div>
        </section>
        </section>
      }
    </JobOnlineCardStyled>
  )
}

JobOnlineCard.propTypes = {
}

export default JobOnlineCard