import { connect } from 'react-redux'

import MenuItemS from '../../../pages/AccountCourse/MenuItemS'

import { setSalarieClicked } from '../../../actions/course'

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({
    setSalarieClicked: (name, id) => {
        dispatch(setSalarieClicked(name, id));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(MenuItemS)