import { connect } from 'react-redux'

import ModalConnexion from '../../../components/ModalInscription/ModalConnexion'

import { 
    openModal,
    closeModal,
    changeFormModal,
    toggleModalMessage
} from '../../../actions/modal'

import { 
    changeIsLogged,
    changeValue,
    fetchUser,
} from '../../../actions/login'

const mapStateToProps = (state) => ({
    modalName: state.modal.modalName,
    isLogged: state.login.isLogged,
    modalMessageContent: state.modal.modalMessageContent,
    modalMessageIsOpen: state.modal.modalMessageIsOpen
})

const mapDispatchToProps = (dispatch) => ({
    openModal: (name) => {
        dispatch(openModal(name));
    },
    changeIsLogged: (bool) => {
        dispatch(changeIsLogged(bool));
    },
    changeValue: (value, name) => {
        dispatch(changeValue(value, name));
      },
      closeModal: (name) => {
        dispatch(closeModal(name));
    },
    changeFormModal: (string) => {
        dispatch(changeFormModal(string));
      },
    fetchUser: (data) => {
    dispatch(fetchUser(data));
    },
    toggleModalMessage: (message, bool) => {
        dispatch(toggleModalMessage(message, bool));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(ModalConnexion)

