/**** Import des modules ****/
import React, { useEffect, useRef, useState } from 'react'
import { PDFDocument } from 'pdf-lib'
import { saveAs } from 'file-saver'
import PropTypes from 'prop-types'
import {AiOutlineClose} from "react-icons/ai"
import SignatureCanvas from 'react-signature-canvas'

import urlBackEndSave from '../../assets/js/urlBackEndSave'


/**** Import des css ****/
import ModalSignatureStyled from './ModalSignatureStyled'

const ModalSignature = ({toggleModalSignature, modalSignatureDocId, modalSignatureDocType, modalSignatureIsOpen, sigPad, signContratPart, setFullPageLoader, userRole}) => {

  const [files, setFiles] = useState(false)

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          toggleModalSignature(false, 0, '')
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const savePdf = (url, name) => {
    saveAs(
      `${urlBackEndSave}${url}`,
      `${name}`
    );
  };

  const Download = (arrayBuffer, type, fileName) => {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    const blob = new Blob([arrayBuffer], { type: type });
    const url = URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  async function signContratPartFront(docId, imgUrl) {
    if(userRole !== 'entreprise') {
    const URLSITE = `https://www.master-7rqtwti-h5b47fjo5ugck.ovhcloud-fr-1.webpaas.ovh.net/uploads/contrat-WEFOR-particulier-${docId}.pdf`

    const response = await fetch(URLSITE)
    const jpgImageBytes = await fetch(imgUrl)
    const arrayBuffer = await response.arrayBuffer();
    const arrayBufferImg = await jpgImageBytes.arrayBuffer();
  
    const pdfDoc = await PDFDocument.load(arrayBuffer)
    const jpgImage = await pdfDoc.embedPng(arrayBufferImg);
  
    const pages = pdfDoc.getPages();
    const wantedPage = pages[3];

    const jpgDims = jpgImage.scale(0.5)

    const { width, height } = wantedPage.getSize();

    wantedPage.drawImage(jpgImage, {
      x: 50,
      y: 50,
      width: jpgDims.width,
      height: jpgDims.height,
    });
  
    const pdfFile = await pdfDoc.save();

    Download(pdfFile, 'application/pdf', `contrat-WEFOR-particulier-${docId}-signed.pdf`);

    const blob = new Blob([pdfFile], { type: 'application/pdf' });
    signContratPart(modalSignatureDocId, blob, `contrat-WEFOR-particulier-${docId}-signed.pdf`)
  } else {
    const URLSITE = `https://www.master-7rqtwti-h5b47fjo5ugck.ovhcloud-fr-1.webpaas.ovh.net/uploads/contrat-WEFOR-entreprise-${docId}.pdf`

    const response = await fetch(URLSITE)
    const jpgImageBytes = await fetch(imgUrl)
    const arrayBuffer = await response.arrayBuffer();
    const arrayBufferImg = await jpgImageBytes.arrayBuffer();
  
    const pdfDoc = await PDFDocument.load(arrayBuffer)
    const jpgImage = await pdfDoc.embedPng(arrayBufferImg);
  
    const pages = pdfDoc.getPages();
    const wantedPage = pages[1];

    const jpgDims = jpgImage.scale(0.5)

    const { width, height } = wantedPage.getSize();

    wantedPage.drawImage(jpgImage, {
      x: 100,
      y: 100,
      width: jpgDims.width,
      height: jpgDims.height,
    });
  
    const pdfFile = await pdfDoc.save();

    Download(pdfFile, 'application/pdf', `contrat-WEFOR-entreprise-${docId}-signed.pdf`);

    const blob = new Blob([pdfFile], { type: 'application/pdf' });
    signContratPart(modalSignatureDocId, blob, `contrat-WEFOR-entreprise-${docId}-signed.pdf`)
  }
  }

  return (
    <ModalSignatureStyled>
    <section className="modal-content-container">
      <div className="page-wrapper-message" ref={wrapperRef}>
        <h3>Utilisez votre souris pour signer le contrat n°{modalSignatureDocId}</h3>
      <SignatureCanvas penColor='black'canvasProps={{width: 300, height: 100, className: 'sigCanvas'}} ref={(ref) => { sigPad = ref }}/>
      <div className="btn-action-wrapper">
        <div className="btn-redo" onClick={() => sigPad.clear()}>Recommencer</div>
        <div className="btn-send" onClick={() => {
          setFullPageLoader(true)
          signContratPartFront(modalSignatureDocId, sigPad.toDataURL())
        }}>Signer définitivement</div>
      </div>
      <main>
      <div className="icon-wrapper">
            <AiOutlineClose 
            className="close-icon"
            onClick={() => toggleModalSignature(false, 0, '', '')}
            />
      </div>
      </main>
      </div>
    </section>
    </ModalSignatureStyled>
  )
}

ModalSignature.propTypes = {

}

export default ModalSignature