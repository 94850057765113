import { connect } from 'react-redux'

import AccountRecrutement from '../../pages/AccountRecrutement'

import { fetchScoreRecrutementParticulier, fetchPostScoreRecrutement , fetchGetScoreRecrutement} from '../../actions/login'
import { toggleModalRecrutement } from '../../actions/modal'
import { setDidTest } from '../../actions/job'

const mapStateToProps = (state) => ({
    isLogged: state.login.isLogged,
    userRole: state.login.userRole,
    timeRecrutement: state.login.timeRecrutement,
    modalRecrutement: state.modal.modalRecrutement,
    scoreRecrutementObject: state.login.scoreRecrutementObject,
    secteurs: state.menu.secteursData
})

const mapDispatchToProps = (dispatch) => ({
    fetchScoreRecrutementParticulier: (score,time) => {
        dispatch(fetchScoreRecrutementParticulier(score, time));
    },
    toggleModalRecrutement: (bool) => {
        dispatch(toggleModalRecrutement(bool));
    },
    fetchPostScoreRecrutement: () => {
        dispatch(fetchPostScoreRecrutement());
    },
    fetchGetScoreRecrutement: () => {
        dispatch(fetchGetScoreRecrutement());
    },
    setDidTest: (bool) => {
        dispatch(setDidTest(bool));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(AccountRecrutement)