/**** Import de modules ****/
import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import axios from 'axios'
import * as qs from 'qs'

/**** import des components ****/
import ModalJobFiche from '../../containers/ModalJobFiche';

/**** Import d'images' ****/
import shield from '../../assets/img/picto/shield.png'
import shieldWhite from '../../assets/img/picto/shield-white.png'
import logoCPF from '../../assets/img/logo/WEFOR_CARRE_COULEUR.png'

/**** Import des css ****/
import CourseCardStyled from './CourseCardStyled'

import urlBackEnd from '../../assets/js/urlBackEnd'
import { Skeleton } from '@mui/material'

const URL = urlBackEnd

const CourseCard = ({activCourseId, changeActivCourseId, courseId, openModal, formations, id, attributes, userRole, setJobFicheId, currentProfilUser, isActive}) => {
  const [modalJobFicheOpen, setModalJobFicheOpen] = useState(false);

  let location = useLocation()

  let euroFormat = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
  });

  useEffect(() => {
    if(isActive !== undefined && isActive === true) {
      changeActivCourseId(courseId)
    }
  }
  , [isActive])

  return (
    <CourseCardStyled 
      className={` ${activCourseId === courseId & location.pathname === '/formation' ? "active-comp" : ""} ${location.pathname === '/formation' ? "pointer" : ""}`}
      onClick={() => changeActivCourseId(courseId)}
    >
      {modalJobFicheOpen &&
        <ModalJobFiche modalJobFicheOpen={modalJobFicheOpen} setModalJobFicheOpen={setModalJobFicheOpen} />
      }
        <div className="title-wrapper">
            <h4 className="first-title">E-learning</h4>
            <div className="separator-orange"></div>
            <h4 className="bold">{attributes.secteurs.data[0].attributes.nom}</h4>
        </div>
        <p className="job-cat bold">{attributes.nom}</p>
        <h3>Durée: <span className="bold">{attributes.duree}h</span></h3>
        <div className="salary-wrapper">
            <span className="bold">{currentProfilUser === 'Entreprise' ? euroFormat.format(attributes.prix / 1.20) + ' HT' : euroFormat.format(attributes.prix)}</span>  
        </div>
        {userRole === "entreprise" ? 
        <button 
          className="btn-orange"
          onClick={() => openModal("inscription-collab")}
        >Inscrire un collaborateur</button>
        :
        <button 
          className="btn-orange"
          onClick={() => openModal("formation")}
        >S'inscrire</button>
        }
        {attributes.fiche_metier.data !== null &&
        <>
        { location.pathname === "/formation" ?
        <button 
          className="btn-lightorange mobile-none"
          onClick={() => {
            setJobFicheId(attributes.fiche_metier.data.id)
            setModalJobFicheOpen(true)
          }}
        >Fiche métiers</button>
        :
        <button 
        className="btn-lightorange"
        onClick={() => {
          setJobFicheId(attributes.fiche_metier.data.id)
          setModalJobFicheOpen(true)
        }}
        >Fiche métiers</button>
        }
        </> 
        }
        {!activCourseId !== courseId &&
        <div className="shield-wrapper">
          <a href="https://www.moncompteformation.gouv.fr/espace-prive/html/#/" target='_blank' rel='noreferrer'>
            <img src={logoCPF} className="shield-picto" alt="bouclier orange" />
          </a>
        </div>
        }
        {(activCourseId === courseId) && ( location.pathname === '/formation') && 
        <div className="shield-wrapper orange">
          <a href="https://www.moncompteformation.gouv.fr/espace-prive/html/#/" target='_blank' rel='noreferrer'>< img src={logoCPF} className="shield-picto" alt="bouclier orange" /></a>
        </div>
        }
        <div className="cpf-wrapper">
          <img src={logoCPF} alt="logo bleu cpf" />
          <div className="bold">Éligible CPF</div>
        </div>
    </CourseCardStyled>
  )
}

CourseCard.propTypes = {
  activCourseId: PropTypes.number.isRequired, 
  changeActivCourseId: PropTypes.func.isRequired, 
  openModal: PropTypes.func.isRequired,
}

export default CourseCard