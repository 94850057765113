import styled from 'styled-components';

const AccountMenuStyled = styled.div`
    width: 250px;
    box-shadow: 0px 0px 30px 10px #00000014;
    padding: 50px 0px 0px 0px;
    background-color: #fff;
    box-sizing: border-box;
    z-index: 2;

    .menu-account-nav {
        ul {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0;

        .menu-sub-doc {
            width: 220px !important;
        }

        .btn-deconnexion {
            display: flex;
            align-items: center;
            background-color: #fff;
            color: #dc4a46;
            border-radius: 25px;
            padding: 10px 0px;
            width: 220px;
            margin-top: 50px;
            margin-bottom: 50px;
            justify-content: center;
            margin-left: 5px;
            cursor: pointer;
            box-sizing: border-box;
            border: 1px solid #dc4a46;

            &:hover {
                color: #fff;
                background-color: #dc4a46 ;
            }
        }

        li.li-connected {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            width: 100% !important;
            align-items: center;
            justify-content: start !important;
            padding: 0;
            font-size: 16px;
            padding: 10px 0px 10px 10px;
            width: 100%;

            svg {
                color: #202c3c;
                font-size: 20px;
            }

            &.activ-link {
                background-color: #649ACE;

                a {
                    color: #fff;
                    width: calc(100% - 20px);
                    color: #202c3c;
                    font-family: 'gilroybold' !important;
                }
                
                &:hover {
                    cursor: pointer;
                }
            }

            .submenu-activ {
                div {
                    display: flex;
                    align-items: center;
                    padding: 5px 0 5px 30px;

                    svg {
                        font-size: 14px;
                        margin-right: 10px;
                    }

                    &:hover {
                        font-family: 'gilroySemibold' !important;
                    }
                }
            }

            a { 
                margin: 0;
                color: #707070;
                padding: 5px 0 5px 10px;
                margin-left: 0px;

                &:hover {
                    cursor: pointer;
                    color: #202c3c;
                    font-family: 'gilroybold' !important;
                }
            }

            img {
                width: 40px;
                z-index: 2;
            }
        }

        }
        .mobile-nav {
            display: none;
        }
    }


    @media only screen and (max-width: 1400px) {
        width: 250px;
    }
    
    @media only screen and (max-width: 1079px) {
        width: 250px;
    }
    
    @media only screen and (min-width: 0px) and (max-width: 759px) {
        width: 100%;
        position: fixed;
        bottom: 0;
        padding: 0px 0;
        z-index: 10;

        .menu-account-nav {
            .mobile-nav {
                display: flex;
                flex-direction: row;

                .btn-deconnexion {
                    display: none;
                }

                li.li-connected {

                    text-align: center;
                    padding: 0;
                    width: 100%;
                    padding: 10px 0;
        
                    a { 
                        padding: 0;
                        margin-left: 0px;
                        width: 100%;
        
                        &:hover {
                            cursor: pointer;
                            color: #649ACE;
                            font-family: 'gilroybold' !important;
                        }
                    }

                    &.activ-link {
                        border-radius: 0px;
    
                        &:hover {
                            cursor: pointer;
                            color: #fff !important;
                        }

                        a {
                            width: 100%;
                        }
                    }

                    svg {
                        font-size: 25px;
                    }
        
                    img {
                        width: 40px;
                        z-index: 2;
                    }
                }
            }

            .pc-nav {
                display: none;
            }
        }
    }
`;

export default AccountMenuStyled;