import { connect } from 'react-redux'

import CourseCard from '../../components/CourseCard'
import { changeActivCourseId } from '../../actions/course'
import { setJobFicheId } from '../../actions/job'
import { openModal } from '../../actions/modal'
import { HiLogin } from 'react-icons/hi'


const mapStateToProps = (state) => ({
    activCourseId: state.course.activCourseId,
    formations: state.course.formations,
    userRole: state.login.userRole,
    currentProfilUser: state.menu.currentProfilUser,
})

const mapDispatchToProps = (dispatch) => ({
    changeActivCourseId: (id) => {
        dispatch(changeActivCourseId(id));
    },
    openModal: (name) => {
      dispatch(openModal(name));
    },
    setJobFicheId: (id) => {
      dispatch(setJobFicheId(id));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(CourseCard)