import { connect } from 'react-redux'

import Private from '../../../components/SignIn/Private'

import { 
    changeSigninStep,
    changeValueSignin,
    fetchSearchAdresse
} from '../../../actions/signin'

import { 
    changeIsLogged,
    fetchPostUserParticulier,
    changeValue
} from '../../../actions/login'

import { 
    closeModal,
    openModal,
    changeFormModal
} from '../../../actions/modal'

const mapStateToProps = (state) => ({
    days: state.login.days, 
    mounths: state.login.mounths, 
    accountBdMounth: state.signin.accountBdMounthSignin, 
    accountBdDay: state.signin.accountBdDaySignin,
    formModal: state.modal.formModal,
    modalName: state.modal.modalName,
    openModal: state.modal.openModal,
    accountConfirmationPasswordSignin: state.signin.accountConfirmationPasswordSignin,
    accountPasswordSignin: state.signin.accountPasswordSignin,
    accountAdresseSignin: state.signin.accountAdresseSignin,
    adresseList: state.signin.adresseList,
    accountAdresseNumStreetSignin: state.signin.accountAdresseNumStreetSignin,
    accountAdresseStreetSignin: state.signin.accountAdresseStreetSignin ,
    accountAdresseCodeSignin: state.signin.accountAdresseCodeSignin ,
    accountAdresseCitySignin: state.signin.accountAdresseCitySignin,
    accountCvSignin: state.signin.accountCvSignin
})

const mapDispatchToProps = (dispatch) => ({
    changeValueSignin: (value, name) => {
        dispatch(changeValueSignin(value, name));
      },
      changeIsLogged: (bool) => {
        dispatch(changeIsLogged(bool));
    },
    closeModal: (name) => {
        dispatch(closeModal(name));
    },
    changeFormModal: (string) => {
        dispatch(changeFormModal(string));
      },
    openModal: (name) => {
        dispatch(openModal(name));
    },
    fetchPostUserParticulier: () => {
        dispatch(fetchPostUserParticulier());
    },
    fetchSearchAdresse: (adresse) => {
        dispatch(fetchSearchAdresse(adresse));
    },
    changeValue: (value, name) => {
        dispatch(changeValue(value, name));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(Private)