import styled from 'styled-components';
import bcgHeader from '../../assets/img/header/formation-ill.jpg'

const NewsFluxStyled = styled.main`
    padding-top: 0px;

    .full-page {
        padding-top: 175px;
        width: 100%;

        .result-wrapper {
            max-width: 1300px;
            width: 96%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            padding: 30px 0 50px;

            .article-wrapper {
                width: 100%;
                border-radius: 10px;
                box-shadow: 0px 3px 10px #00000059;
                margin: 20px 0 0 0;
                padding: 20px;
                position: relative;

                h3 {
                    font-size: 22px;
                    color: #202c3c;
                    width: calc(100% - 170px);
                }

                p {
                    width: calc(100% - 170px);
                }

                .auteur {
                    margin: 10px 0px 20px;
                }

                .published {
                    font-style: italic;
                    margin-top: 20px;
                }

                .img-article {
                    width: 150px;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    border-radius: 10px;
                    max-height: 100px;
                }
            }
        }
    }

    .search-wrapper {
        background-color: #202c3c;
        color: #fff;
        text-align: center;
        padding: 30px;

        h1 {
            font-size: 30px;
            padding-bottom: 20px;
        }

        .first-p {
            font-size: 20px;
        }

        .second-p {
            padding: 10px;
            font-style: italic;
        }

        .search-input {
            background-color: #fff;
            margin: 20px 20px 0 0;
            border-radius: 5px;
        }

        .search-btn {
            background-color: rgba(255, 255, 255, 0.9);
            margin: 20px 0px 0 0;
            border: 0;
            border-radius: 5px;
            color: #4c5662;
            height: 56px;
            cursor: pointer;
            transition: all .3s ease;

            &:hover {
                background-color: #4c5662;
                opacity: .7;
                color: #fff;
            }
        }
    }

    @media only screen and (max-width: 1400px) {

        .full-page {
            margin: 0 0px;
    
            h2 {
                font-size: 40px;
            }
        }
    }
        
    @media only screen and (max-width: 1079px) {
        .full-page {
    
            h2 {
                font-size: 35px;
            }
        }
    }


    @media only screen and (min-width: 0px) and (max-width: 759px) {
        .full-page {
            margin: 50px 0px;
            h2 {
                font-size: 30px;
            }

            .result-wrapper {
    
                .article-wrapper {
    
                    h3 {
                        width: calc(100%);
                    }
    
                    p {
                        width: calc(100%);
                    }
 
                    .img-article {
                        display: none;
                    }
                }
            }
        }
    }
`;

export default NewsFluxStyled;