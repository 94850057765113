import { connect } from 'react-redux'

import StepTwo from '../../../components/FinancementSteps/StepTwo'


const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({

})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(StepTwo)