import styled from 'styled-components';

import bcgHeader from '../../assets/img/header/business-people-at-the-conference.jpg'
import sinistresIll from '../../assets/img/illustration/sinistres.jpg'
import souscriptionIll from '../../assets/img/illustration/souscription.jpg'
import gestionContratIll from '../../assets/img/illustration/gestion-contrat.jpg'
import losange from '../../assets/img/illustration/parr-top-right.png'
import bcground from '../../assets/img/illustration/bcground.png'

const HomebisStyled = styled.div`
    padding-top: 0px;
    scroll-behavior: smooth;

    .bold {
        font-weight: 900;
    }

    .color-fff {
        color: #fff;
    }

    .btn-blue {
        width: 300px;
        color: #fff;
        background-color: #6399ce;
        text-decoration: none;
        padding: 10px;
        text-align: center;
        font-size: 18px;
        transition: background-color .5s;
        margin: 50px auto 0;
        border-radius: 10px;
        cursor: pointer;
        border: 0;
        font-size: 20px;

        &:hover {
            background-color: #202c3c;
        }
    }

    .btn-orange-section {
        width: 300px;
        color: #fff;
        background-color: #d47558;
        text-decoration: none;
        padding: 10px;
        text-align: center;
        font-size: 18px;
        transition: background-color .5s;
        margin: 50px auto 0;
        border-radius: 10px;
        cursor: pointer;
        border: 0;
        font-size: 20px;
        transition: all .3s ease;

        &:hover {
            opacity: .7;
        }
    }

    .btn-green-section {
        width: 300px;
        color: #fff;
        background-color: #6aaf8b;
        text-decoration: none;
        padding: 10px;
        text-align: center;
        font-size: 18px;
        transition: background-color .5s;
        margin: 50px auto 0;
        border-radius: 10px;
        cursor: pointer;
        border: 0;
        font-size: 20px;
        transition: all .3s ease;

        &:hover {
            opacity: .7;
        }
    }

   .top-illustration {
        width: 100vw;
        height: 700px;  
        padding-top: 125px;
        z-index: 10;
        position: relative;

        &::before {
            content:'';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width:100%;
            background: linear-gradient(180deg, rgba(214,231,245,1) 0%, rgba(100,154,206,1) 100%);
            clip-path: polygon(0% 0%,0% 80.6708%, 1.69492% 78.8616%, 3.38983% 77.0922%, 5.08475% 75.3821%, 6.77966% 73.75%, 8.47458% 72.2138%, 10.1695% 70.7902%, 11.8644% 69.495%, 13.5593% 68.3422%, 15.2542% 67.3446%, 16.9492% 66.513%, 18.6441% 65.8565%, 20.339% 65.3824%, 22.0339% 65.0959%, 23.7288% 65%, 25.4237% 65.0959%, 27.1186% 65.3824%, 28.8136% 65.8565%, 30.5085% 66.513%, 32.2034% 67.3446%, 33.8983% 68.3422%, 35.5932% 69.495%, 37.2881% 70.7902%, 38.9831% 72.2138%, 40.678% 73.75%, 42.3729% 75.3821%, 44.0678% 77.0922%, 45.7627% 78.8616%, 47.4576% 80.6708%, 49.1525% 82.5%, 50.8475% 84.3293%, 52.5424% 86.1385%, 54.2373% 87.9078%, 55.9322% 89.6179%, 57.6271% 91.25%, 59.322% 92.7862%, 61.017% 94.2098%, 62.7119% 95.505%, 64.4068% 96.6578%, 66.1017% 97.6555%, 67.7966% 98.487%, 69.4915% 99.1435%, 71.1864% 99.6176%, 72.8814% 99.9041%, 74.5763% 100%, 76.2712% 99.9041%, 77.9661% 99.6176%, 79.661% 99.1435%, 81.3559% 98.487%, 83.0509% 97.6555%, 84.7458% 96.6578%, 86.4407% 95.505%, 88.1356% 94.2098%, 89.8305% 92.7862%, 91.5254% 91.25%, 93.2203% 89.6179%, 94.9153% 87.9078%, 96.6102% 86.1385%, 98.3051% 84.3293%, 100% 82.5%, 100% 0%);
            shape-outside: polygon(0% 0%,0% 80.6708%, 1.69492% 78.8616%, 3.38983% 77.0922%, 5.08475% 75.3821%, 6.77966% 73.75%, 8.47458% 72.2138%, 10.1695% 70.7902%, 11.8644% 69.495%, 13.5593% 68.3422%, 15.2542% 67.3446%, 16.9492% 66.513%, 18.6441% 65.8565%, 20.339% 65.3824%, 22.0339% 65.0959%, 23.7288% 65%, 25.4237% 65.0959%, 27.1186% 65.3824%, 28.8136% 65.8565%, 30.5085% 66.513%, 32.2034% 67.3446%, 33.8983% 68.3422%, 35.5932% 69.495%, 37.2881% 70.7902%, 38.9831% 72.2138%, 40.678% 73.75%, 42.3729% 75.3821%, 44.0678% 77.0922%, 45.7627% 78.8616%, 47.4576% 80.6708%, 49.1525% 82.5%, 50.8475% 84.3293%, 52.5424% 86.1385%, 54.2373% 87.9078%, 55.9322% 89.6179%, 57.6271% 91.25%, 59.322% 92.7862%, 61.017% 94.2098%, 62.7119% 95.505%, 64.4068% 96.6578%, 66.1017% 97.6555%, 67.7966% 98.487%, 69.4915% 99.1435%, 71.1864% 99.6176%, 72.8814% 99.9041%, 74.5763% 100%, 76.2712% 99.9041%, 77.9661% 99.6176%, 79.661% 99.1435%, 81.3559% 98.487%, 83.0509% 97.6555%, 84.7458% 96.6578%, 86.4407% 95.505%, 88.1356% 94.2098%, 89.8305% 92.7862%, 91.5254% 91.25%, 93.2203% 89.6179%, 94.9153% 87.9078%, 96.6102% 86.1385%, 98.3051% 84.3293%, 100% 82.5%, 100% 0%);
            z-index: -10;
        }
        

        .opacity-light-section {
            height: 100%;
            top: 100%;
            width: 100%;
            max-width: 1200px;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: space-between;

            .left {
                padding: 150px 0 0 0px;
                width: 50%;
                box-sizing:border-box;
                

                h1 {
                    font-size: 45px;
                    color: #202c3c;
                    
                    letter-spacing: 2px;
                    padding-left: 0px;
                    padding-top: 100px;

                    span {
                        font-size: 45px;
                    }
                    
                    .top-h1 {
                        color: #fff;
                    }

                    .formplus {
                        font-size: 40px;
                        margin-top: 30px;
                    }

                    .toutca {
                        padding: 15px 0 10px;
                        border-bottom: 6px solid #6399ce;
                        display:inline-block;
                    }
                }

                p {
                    font-size: 22px;
                    color: #fff;
                    line-height: 25px;
                    padding: 20px 0;
                }
            }

            .left-a {
                padding: 80px 0 0 0px;
                width: 50%;
                box-sizing:border-box;

                h1 {
                    font-size: 45px;
                    color: #202c3c;
                    
                    .top-h1 {
                        color: #fff;
                    }

                    .formplus {
                        font-size: 40px;
                        margin-top: 30px;
                    }

                    .toutca {
                        padding: 15px 0 10px;
                        border-bottom: 6px solid #6399ce;
                        display:inline-block;
                    }
                }

                p {
                    font-size: 22px;
                    color: #fff;
                    line-height: 25px;
                    padding: 20px 0;
                }
            }

            img {
                margin-top: 50px;
                margin-left: -50px;
                box-sizing: border-box;
                width: 55%;
                max-width: 650px;
            }
        }

        .notchoose {
            align-items: flex-start;

            .left {
                padding-top: 0px;
                padding-bottom: 100px;
                h1 {
                    padding-bottom: 50px;
                    font-size: 55px;

                    span {
                        font-size: 46px;

                        &.blue {
                            color: #649ace;
                        }
                    }
                }
            }
        }
   }

   .section-explication {
           margin: 150px auto 0;
           width: 100%;
           max-width: 1300px;
           display: flex;
           align-items: center;


           p {
               max-width: 1300px;
               margin: 0 auto;
               font-size: 22px;
               color: #4d4d4d;
           }

           .logo-top-wrapper {
                display: flex;
                align-items: center;

                div {
                    display: flex;
                    align-items: center;
                }
           }

           .cpf-logo {
                height: 120px;
           }

           .datadock-logo {
                height: 150px;
                margin-top: 20px;
            }

           .tab-statut-choice {
               width: 50%;
               display: flex;
               flex-wrap: wrap;
               box-sizing: border-box;
               justify-content: flex-start;
               margin-left: 40px;

               li {
                   width: 260px;
                   max-width: 650px;
                   text-align: center;
                   padding: 30px 0;
                   font-size: 22px;
                    border-radius: 10px;
                   cursor: pointer;
               }

               .part {
                    position: relative;
                    box-sizing: border-box;
                    display: inline-block;
                    overflow: hidden;
                    padding: 20px 0;
                    margin: 0px 3px 6px;
                    text-align: center;
                    text-decoration: none;
                    color: rgb(255, 255, 255);
                    white-space: nowrap;
                    z-index: 0;
                    background-color: #649ACE;
               }

               .entr {
                    position: relative;
                    box-sizing: border-box;
                    display: inline-block;
                    overflow: hidden;
                    padding: 20px 0px;
                    margin: 0px 3px 6px;
                    text-align: center;
                    text-decoration: none;
                    color: rgb(255, 255, 255);
                    white-space: nowrap;
                    z-index: 0;
                    background-color: #202c3c;
                }

                .hb-fill-right-bg::before {
                    position: absolute;
                    content: "";
                    background: rgb(255, 255, 255);
                    transition-duration: 0.3s;
                    z-index: -1;
                    inset: 0px auto auto 0px;
                    width: 0px;
                    height: 100%;
                    opacity: 1;
                    
                } 
               
              
              .hb-fill-right-bg:hover::before {
                    width: 100%;
                    height: 100%;
                    opacity: 1;
                } 
               
              
              .hb-fill-right-bg:hover {
                    color: rgb(0, 0, 0);
                    background: rgb(255, 255, 255);
                    transition: all 0.3s ease 0s;
                } 

                .hb-fill-left-bg::before {
                    position: absolute;
                    content: "";
                    background: rgb(255, 255, 255);
                    transition-duration: 0.3s;
                    z-index: -1;
                    inset: 0px 0px auto auto;
                    width: 0px;
                    height: 100%;
                    opacity: 1;
              } 
               
              
              .hb-fill-left-bg:hover::before {
                    width: 100%;
                    height: 100%;
                    opacity: 1;
              } 
               
              
              .hb-fill-left-bg:hover {
                    color: rgb(0, 0, 0);
                    background: rgb(255, 255, 255);
                    transition: color 0.3s ease 0s, background 0s ease 0.3s;
              } 
            }
        }
    }

    div.bcg-gradient-wrapper {
        width 100%;
        background: linear-gradient(180deg, rgba(248, 253, 255, 0) 0%, rgba(241, 252, 255, 0.51782) 48.22%, #F6FDFF 100%);
        padding-bottom: 50px;
    }

    .section-home-not-choose {
        padding: 150px 0 50px !important;

        .whites-cards-shadow-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            width: 100%;
            max-width: 1300px;
            margin: 0 auto;

            .cards-shadow {
                background: #FFFFFF;
                box-shadow: 0px 30px 60px rgb(0 19 87 / 4%);
                border-radius: 15px;
                padding: 52px 50px 45px 50px;
                transition: all 0.3s ease-in-out;
                width: 31%;
                box-sizing: border-box;
                position: relative;
                text-align: left;

                svg {
                    font-size: 45px;
                    color: #649ace;
                }

                h3 {
                    font-size: 25px;
                    color: #202c3c;
                    margin: 30px 0;
                }

                p {
                    font-size: 19px;
                    line-height: 32px;
                }

                .know-more-link {
                    position: absolute;
                    bottom: 20px;
                    right: 40px;
                    a {
                        color: #649ace;
                        text-decoration: underline !important;
                    }
                }
            }
        }
        
        .left-home-entr {
            padding-right: 20px;
            padding-left: 40px;
            box-sizing: border-box;
            width: 50% !important;

            .text-entr {
                color: #202c3c;
                line-height: 30px;
                margin-bottom: 40px;
                font-size: 25px;
                text-transform: none;
                text-align: justify;
            }

            .list-entr {

                margin-bottom: 50px;
                li {
                    display: flex;
                    align-items: center;
                    margin: 0 0 20px;
                    font-size: 19px;
                    line-height: 25px;

                    span {
                        width: calc(100% - 20px);
                    }
                    

                    svg {
                        margin-right: 10px;
                        color: #649ace;
                        font-size: 14px;
                    }
                }
            }

            .link-entr {
                color: #649ACE;
                text-decoration: underline !important;
            }

            h2 {
                color: #202c3c;
                font-size: 55px;
                margin-bottom: 40px;

                & .blue-underline {
                    color: #649ace;
                }
            }
        }

        .left-home-wstatut {
            padding-right: 20px;
            padding-left: 40px;
            box-sizing: border-box;
            width: 50% !important;
            margin-top: -100px;

            img {
                width: 100%;
                max-width: 600px;
                margin: 0px 0 0px 25px;
            }

            h3 {
                font-size: 22px;
            }

            h2 {
                color: #202c3c;
                font-size: 55px;
                text-align: center;

                & .blue-underline {
                    color: #649ace;
                }
            }

            p {
                font-weight: 400 !important;
                font-size: 18px !important;
                line-height: 20px !important;
                text-transform: none !important;
                margin-top: 20px;
                padding-right: 195px;
                text-align: justify;
            }

            .point-wrapper {
                display: flex;
                flex-wrap: wrap;
                position: absolute;
                bottom: 150px;
                left: 202px;
                cursor: pointer;

                svg {
                    color: #d4d4d4;
                    font-size: 25px;

                    &.current-service {
                        color: #649ACE;
                    }
                }
            }
        }

        .img-parallax-home {
            position: absolute;
            top: 100px;
            left: -50px;
            z-index: -1;

            img {
                width: calc(100%);
            }
            
        }

        .right-img {
            width: 50%;
            img {
                width: 100%;
                margin: 0px 0 100px 0px;
            }
        }
        .right-cards {
            width: 50% !important;

            .white-card {
                max-width: 600px !important;
                margin-right: 0;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                min-height: 160px;
                box-sizing: border-box;
                box-shadow: -20px 20px 50px rgb(0 40 99 / 10%) !important;

                &:hover {
                    background-color: #fff !important;
                    color: #202c3c !important;
                }
                div {
                    width: calc(100% - 70px);

                    h5 {
                        font-size: 25px !important;
                        padding-bottom: 10px;
                    }

                    p {
                        padding-left: 0;
                        line-height: 25px !important;
                        font-size: 16px !important;
                    }
                }
            }
        }
    }

    .entreprise-section.section-home-not-choose {
        padding-top: 50px !important;
    }

    .info-strong-section {

        width: 96%;
        max-width: 1300px;
        margin: 50px auto;

        h3 {
            text-align: center;
            color: #202c3c;
            font-size: 40px;
            margin-bottom: 40px;

            & .blue-underline {
                color: #649ace;
            }
        }
        .strong-card {
            background-color: #202c3c;
            color: #fff;
            font-size: 40px;
            border-radius: 30px;
            min-height: 250px;
            padding: 30px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
                text-align: center;
                line-height: 50px;
            }
        }
    }

   .first-section-home {
       max-width: 1300px;
       margin:0px auto 0px;
       display: flex;
       flex-wrap: wrap;
       align-items: flex-end;
       color: #202c3c;
       padding: 50px 0px;
       position: relative;

       .right-cards {
        width: 50%;
        padding-left: 30px;
        box-sizing: border-box;

        .white-card {
            padding: 20px 20px 20px 30px;
            box-shadow:0 20px 30px 0 rgb(0 0 0 / 10%);
            background-color: #fff;
            margin:100px auto 30px;
            border-radius: 30px 0 30px 30px;
            display: flex;
            flex-wrap:wrap;
            align-items: center;
            max-width: 420px;

            &:hover {
                background-color: #dbe8f4;
                color: #fff;
            }

            img {
                width: 50px;
            }

            p {
                font-size: 18px;
                padding-left: 20px;
            }
        }
    }

       .left {
           width: 40%;
           padding-bottom: 100px;

           p {
               text-transform: uppercase;
               font-size: 45px;
               line-height: 55px;

               .wefor-txt {
                   color : #6399ce;
                   font-size: 60px;
               }
           }

           p:nth-child(2) {
            font-size: 43px;
       }

           p:nth-child(3) {
                font-weight: bold;
                font-size: 35.8px;
           }

           .separator-blue {
               width: 239px;
               background-color: #6399ce;
               height: 6px;
               margin-left: 170px;
               margin-top: 10px;
           }
       }
   }

   .img-parallax {
       position: absolute;
       top: 0;
       left: 0;
       z-index: -1;
   }

   .img-parallax-a {
        position: absolute;
        top: 0;
        left: -65px;
        z-index: -1;
    }

   .first-section-home-de {
    max-width: 1300px;
    width: 96%;
    margin:0px auto 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #202c3c;
    padding: 50px 0px;
    position: relative;

    .title-cards {
        text-align: center;
        margin-bottom: 50px;
    }

    .left {
        width: 50%;

        p {
            text-transform:uppercase;
            line-height: 50px;
            font-weight: 700;
            .wefor-txt {
                color : #6399ce;
            }
        }

        .one { 
            font-size: 36.5px; 
        }

        .two { 
            font-size: 31px; 
        }

        .three {
            font-size: 41.2px;
        }

        .one-s { 
            font-size: 33px; 
        }

        .two-s { 
            font-size: 31px; 
        }

        .three-s {
            font-size: 37px;
        }

        .one-a { 
            font-size: 34px; 
        }

        .two-a { 
            font-size: 45px; 
        }

        .three-a {
            font-size: 42px;
        }

        .separator-blue {
            width: 239px;
            background-color: #6399ce;
            height: 6px;
            margin-left: 292px;
            margin-top: 10px;
        }

        .separator-blue-s {
            width: 220px;
            background-color: #6399ce;
            height: 6px;
            margin-left: 345px;
            margin-top: 10px;
        }

        .separator-blue-a {
            width: 220px;
            background-color: #6399ce;
            height: 6px;
            margin-left: 225px;
            margin-top: 10px;
        }
    }

    .right-cards {
        width: 50%;
        padding-left: 30px;
        box-sizing: border-box;

        .white-card {
            padding: 20px 20px 20px 30px;
            box-shadow:0 20px 30px 0 rgb(0 0 0 / 10%);
            background-color: #fff;
            margin:0 auto 30px;
            border-radius: 30px 0 30px 30px;
            display: flex;
            flex-wrap:wrap;
            align-items: center;
            max-width: 420px;
            text-align: justify;

            &:hover {
                background-color: #dbe8f4;
                color: #fff;
            }

            img {
                width: 50px;
            }

            p {
                font-size: 18px;
                padding-left: 20px;
            }
        }
    }
}

.first-section-home-en {
    max-width: 1200px;
    margin:50px auto 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #202c3c;
    padding: 0px 0;
    position: relative;

    .img-parallax {
        position: absolute;
        top: -100px;
        left: -50px;
        z-index: -1;
    }
 

    .right-cards {
        width: 50%;
        padding-left: 30px;
        box-sizing: border-box;

        .white-card {
            padding: 30px 30px 30px 40px;
            box-shadow:0 20px 30px 0 rgb(0 0 0 / 10%);
            background-color: #fff;
            margin:0 auto 30px;
            border-radius: 30px 0 30px 30px;
            display: flex;
            flex-wrap:wrap;
            align-items: center;
            max-width: 420px;

            &:hover {
                background-color: #6399ce;
                color: #fff;
            }

            img {
                width: 50px;
            }

            p {
                font-size: 18px;
                padding-left: 20px;
            }
        }
    }

    .left {
        width: 50%;
        padding-bottom: 100px;
        display: flex;
        padding-left: 50px;
        flex-direction: column;
        align-items: flex-start;
        box-sizing: border-box;

        p {
            text-transform:uppercase;
            line-height: 58px;
            font-weight: 700;
            .wefor-txt {
                color : #6399ce;
            }
        }

        .one { 
            font-size: 34px; 
        }

        .two { 
            font-size: 50px; 
        }

        .three {
            font-size: 53px;
        }


        .separator-blue {
            width: 200px;
            background-color: #6399ce;
            height: 6px;
            margin-left: 166px;
            margin-top: 10px;
        }

        .separator-blue-s {
            width: 220px;
            background-color: #6399ce;
            height: 6px;
            margin-left: 345px;
            margin-top: 10px;
        }
    }
}

   .job-home-section, .course-home-section {
       max-width: 100%;
       margin: 0 auto;
       padding: 50px 0;
       text-align: center;

       .domaine-section-title {
        padding-bottom: 50px;
        display: inline-block;

            h2 {
                color: #202c3c;
                font-size: 55px;
                text-align: center;
                display: inline-block;

                & .blue {
                    color: #649ace;
                }
            }

            .separator-blue {
                background-color: #6399ce;
                height: 6px;
                width: 240px;
                margin: 10px 0 0 auto;
            }
        }

        .card-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            max-width: 1100px;
            margin: 0 auto;
        }
   }

   .course-home-section { 

        &.bcg-orange {
            background-color: #f8e8e3;
        }
   }

   .job-home-section { 

    &.bcg-orange {
        background-color:#e1efe7
    }
}

   .domaine-section {
       max-width: 900px;
       margin: 0 auto;
       padding: 50px 0;
       text-align: center;

       .domaine-section-title {
           padding-bottom: 50px;
           display: inline-block;
           overflow: hidden;

           h2 {
               color: #202c3c;
               text-transform: uppercase;
               font-size: 45px;
               text-align: center;
               display: inline-block;
           }

           .separator-blue {
                background-color: #6399ce;
                height: 6px;
                width: 240px;
                margin: 10px 0 0 auto;
           }
       }
       .bottom-domaine-wrapper {
           display: flex;
           flex-wrap: wrap;
           align-items: stretch;
           justify-content: space-between;
           width: 100%;
           
           .domaine-wrapper:nth-child(1) {
                background-image: url(${souscriptionIll});
                background-size: cover;
           }

           .domaine-wrapper:nth-child(2) {
                background-image: url(${gestionContratIll});
                background-size: cover;
            }

            .domaine-wrapper:nth-child(3) {
                background-image: url(${sinistresIll});
                background-size: cover;
            }

           .domaine-wrapper {
               width: 280px;
               height: 300px;
               border-radius: 30px;
               color: #fff;
               display: flex;
               flex-direction: column;
               box-sizing: border-box;
               position: relative;
               box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
                -webkit-box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
                -moz-box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);

                .opacity-black {
                    width: 100%;
                    height: 100%;
                    border-radius: 30px;
                    background-color: rgba(0,0,0, .5);
                    padding: 20px;
                    box-sizing: border-box;
                }

               div.domaine-title {
                   margin-top: 100px;
                   transition: margin-top .5s;
               }

               h3 {
                   font-size: 22px;
                   text-transform: uppercase;
               }

               .separator-white {
                   width: 140px;
                   height: 1px;
                   background-color: #fff;
                   margin: 10px auto 0;
               }

               p {
                   text-align: justify;
                   display: none;
                   position: absolute;
                   top: 80px;
                   left: 20px;
                   width: 240px;
                   line-height: 20px;
               }

               &:hover {
                   background-image: none;
                   background-color: #8D252B;

                   .opacity-black {
                    background-color: rgba(0,0,0,0);
                }

                   div.domaine-title {
                    margin-top: 0px;
                   }

                   p {
                       display: inline-block;
                   }
               }
           }
       }
   }

   @media only screen and (max-width: 1400px) {

    .top-illustration {

        .opacity-light-section {

            .left {
                padding: -100px 30px 0 0px;
                margin-right:0;
                width: 50%;
                padding-right: 20px;
                box-sizing: border-box;

                h1 {
                    font-size: 40px;
                    padding-left: 0;
                    padding-top: 100px;

                    .formplus {
                        font-size: 35px;
                    }
                }

                p {
                    font-size: 20px;
                }
            }

            .left-a {
                padding: 100px 0 0 0px;
                margin-right: 0;
                width: 50%;
                padding-right: 20px;
                box-sizing: border-box;

                h1 {
                    font-size: 40px;

                    .formplus {
                        font-size: 35px;
                    }
                }

                p {
                    font-size: 20px;
                    padding: 20px 0;
                }
            }

            img {
                margin-left: 5%;
                width: 50%;
                margin-left: 0px;
            }
        }

        .notchoose {

            .left {
                h1 {
                    padding-bottom: 50px;
                }
            }
        }
   }

   .section-explication {
        margin: 50px auto 0;
    }
    .first-section-home {
        padding: 50px 50px 50px;

        .left {
            p {
                text-transform: uppercase;
                font-size: 40px;
                line-height: 50px;
 
                .wefor-txt {
                    font-size: 55px;
                }
            }
 
            p:nth-child(2) {
             font-size: 38.5px;
        }
 
            p:nth-child(3) {
                 font-size: 32px;
            }
 
            .separator-blue {
                width: 200px;
                height: 6px;
                margin-left: 170px;
                margin-top: 10px;
            }
        }
        .right-cards {
            .white-card {
                margin:50px auto 30px;

                &:hover {
                    background-color: #6399ce;
                    color: #fff;
                }

                img {
                    width: 50px;
                }

                p {
                    font-size: 18px;
                    padding-left: 20px;
                }
            }
        }
    }

    .img-parallax-a {

        left: -25px;

    }

    .first-section-home-en {

        .img-parallax {
            left: 0;
        }

        .left {
           padding-left: 100px;
        }
    }

    .first-section-home-de {
        max-width: 1200px;
        margin:0px auto 0px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        color: #202c3c;
        padding: 50px 0px;
        position: relative;
    
        .left {
            padding: 0 0px 0 40px;
            box-sizing: border-box;
    
            p {
                text-transform:uppercase;
                line-height: 45px;
            }
    
            .one { 
                font-size: 32px; 
            }
    
            .two { 
                font-size: 27px; 
            }
    
            .three {
                font-size: 36px;
            }
    
            .one-s { 
                font-size: 29px; 
            }
    
            .two-s { 
                font-size: 27px; 
            }
    
            .three-s {
                font-size: 32.5px;
            }
    
            .one-a { 
                font-size: 34px; 
            }
    
            .two-a { 
                font-size: 45px; 
            }
    
            .three-a {
                font-size: 42px;
            }
    
            .separator-blue {
                width: 239px;
                background-color: #6399ce;
                height: 6px;
                margin-left: 250px;
                margin-top: 10px;
            }
    
            .separator-blue-s {
                width: 220px;
                background-color: #6399ce;
                height: 6px;
                margin-left: 278px;
                margin-top: 10px;
            }
    
            .separator-blue-a {
                width: 220px;
                background-color: #6399ce;
                height: 6px;
                margin-left: 225px;
                margin-top: 10px;
            }
        }
    }

    .job-home-section, .course-home-section {
        padding: 50px 50px;
            
        .card-wrapper {
            max-width: 950px;
        } 
    }

    .history-section {
        padding: 50px 50px;
 
        .right {
 
            h3 {
                 font-size: 25px;
            }
 
            p {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
  }

  @media screen and (max-width: 1079px) {

    h2 {
        font-size: 40px !important;
    }

    .top-illustration {
    
        .opacity-light-section {
            height: auto;
            flex-direction: column-reverse;

            .left, .left-a {
                padding: 50px 0 0 0;
                width: calc(96%);
                margin: 0 auto;

                h1 {
                    font-size: 35px;
                    text-align: center;
                    margin-top: 20px;
                    padding-top: 0;
                    span {
                        font-size: 35px;
                    }
                }

                p {
                    font-size: 18px;
                    line-height: 22px;
                    text-align: center;
                }
            }
            img {
                max-width: 400px;
                margin-left: 0px;
                margin: 0px auto 0;
            }
        }
   }

   .section-explication {

    .logo-top-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

        .tab-statut-choice {
            width: 100%;
            justify-content: center;
            margin-left: 0;
        }
   } 

   .section-home-not-choose.first-section-home-de {
       padding-top: 50px !important;
       flex-direction: column-reverse !important;

        .right-cards {
            width: 96% !important;
        }

        .left-home-wstatut.left {
            margin-top: 0;
            width: 100% !important;
        }
   }

    .first-section-home {
        flex-direction: column;


        .left, .right {
            width: 100%;

            p {
                display: inline-block;
            }
        }

        .right-cards {
            width: 96%;
            margin: 0 auto;
        }

        .left {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 50px;
        }
    }

    .job-home-section, .course-home-section {

         .card-wrapper {
             flex-direction: column;
             align-items: center;
         }
    }

    .domaine-section {

        .bottom-domaine-wrapper {
            flex-direction: column;
            align-items: center;

            .domaine-wrapper:nth-child(1) {
                margin-bottom: 30px;
           }

           .domaine-wrapper:nth-child(2) {
                margin-bottom: 30px;
            }
        }
    }

    .first-section-home-de, .first-section-home-en {
        flex-direction: column;
        .left, .right {
            width: 100%;

            p {
                display: inline-block;
            }
        }

        .right-cards {
            width: 96%;
            margin: 0 auto;
        }

        .left {
            display: flex;
            padding: 0;
            flex-direction: column;
            align-items: center;
            margin-bottom: 50px;
            margin-top: 100px;
        }
    }

    .img-parallax-a, .img-parallax {

        display: none;

    }

    .history-section {
        align-items: center;
        flex-direction: column;
        padding: 0 50px 50px 50px;

        .left, .right {
            width: 100%;
        }
    }
    .job-home-section, .domaine-section, .course-home-section {
        .domaine-section-title {
            .separator-blue {
                width: 100px;
            }
        }
    }

    .info-strong-section {

        h3 {
            font-size: 30px;
        }

        .strong-card {
            font-size: 30px;
            min-height: 0;

            p {
                text-align: center;
                line-height: 40px;
            }
        }
    }

    .entreprise-section {
        .right-img {
            order: 2;
            width: 100%;
            text-align: center;

            img {
                margin: 0 auto;
            }
        }

        .left-home-entr {
            order: 3;
            margin: 0 auto;
            width: 96% !important;

            ul {
                width: 100%;
            }
        }

        .whites-cards-shadow-wrapper {
            order: 1;
            flex-direction: column;
            align-items: center;

            .cards-shadow {
                width: 96%;
                max-width: 750px;
                margin-bottom: 20px;
            }
        }
    }
  }

  @media only screen and (min-width: 0px) and (max-width: 759px) {
        h2 {
            font-size: 30px !important;
        }

        .separator-blue {
            width: 200px;
            margin: 10px auto 0 auto !important;
        }

        .top-illustration {

            height: 450px;

            &::before {
                clip-path: none;
            }

            .opacity-light-section {

                &.padding-top-adjusted {
                    .left {
                        padding-top: 50px;
                    }
                }

                .left {
                    padding: 0px 0px 0 0px;
                    margin:0 auto;
                    width: 96%;
                    box-sizing: border-box;
                    height: 250px;
                    display: flex; 
                    justify-content: center;
                    flex-direction: column;

                    h1 {
                        font-size: 35px;
                        padding: 0;

                        .formplus {
                            margin-top: 10px;
                            font-size: 30px;
                        }
                    }

                    .toutca {
                        font-size: 30px;
                    }

                    p {
                        padding: 10px 0 ;
                        font-size: 20px;
                    }
                }

                .left-a {
                    padding: 40px 0 0 0px;
                    margin:0 auto;
                    width: 96%;
                    box-sizing: border-box;
                    height: 330px;
                    display: flex; 
                    justify-content: center;
                    flex-direction: column;

                    h1 {
                        font-size: 33px;

                        .formplus {
                            margin-top: 15px;
                            font-size: 26px;
                        }
                    }

                    p {
                        font-size: 20px;
                        padding: 10px 0;
                    }
                }


                &.padding-top-adjusted-de {
                    .left {
                        padding-top: 40px;
                    }
                }

                &.padding-top-adjusted-en {
                    .left {
                        padding-top: 43px;


                        h1 {
                            .formplus {
                            font-size: 26px;
                            }
                        }

                        p {
                            font-size: 19px;
                            line-height: 21px;
                        }
                    }
                }

                &.padding-top-adjusted-sa {
                    .left {
                        padding-top: 80px;

                        p {
                            padding-bottom: 31px;
                        }
                    }
                }

                img {
                    width: 80%;
                    margin: 0px auto 0;
                    max-width: 300px;
                }
            }
        }

        .section-explication {
            margin: 250px auto 0;
            flex-direction: column;
        }

        .first-section-home {
            padding: 50px 10px;

            .right-cards {
                width: 96%;
                padding-left: 0px;
                align-items:stretch;

                &:first-child {
                    margin-top: 150px;
                }
                
        
                .white-card {
                    padding: 10px;
                    margin:0px auto 30px;
                    display: flex;
                    flex-wrap:wrap;
                    text-align: left;
        
                    &:hover {
                        background-color: #6399ce;
                        color: #fff;
                    }
        
                    img {
                        width: 50px;
                        margin:0px auto 10px;
                    }
        
                    p {
                        font-size: 18px;
                        padding-left: 0px;
                        width:100%;
                    }
                }
            }
        
            .right {
                flex-direction: column;
                align-items: center;
                .picto-right, .picto-left {
                    width: 96%;
                }

                .picto-right {
                    .picto-top {
                        .client-picto { 
                            width: 170px;
                            margin-top: 20px;
                        }

                        p {
                            font-size: 22px;
                            margin-top: 10px;
                            width: 100%;
                        }
                    }

                    .picto-bottom {
                        flex-direction: column;

                        div {
                            width: 96%;

                            .compain-picto, .victory-picto { 
                                width: 170px;
                                margin-top: 20px;
                            }
    
                            p {
                                font-size: 22px !important;
                                width: 100%;
                            }
                        }
                    }
                }
            }
    
            .left {
                text-align: center;
            }
        }

        .first-section-home-en {

            .img-parallax {
                left: 0;
            }

            .right-cards {
                width: 96%;
                padding-left: 0px;
                align-items:stretch;
                margin-bottom: 50px;

                &:first-child {
                    margin-top: 150px;
                }
                
        
                .white-card {
                    padding: 30px 30px 30px 30px;
                    margin:0px auto 30px;
                    display: flex;
                    flex-wrap:wrap;
                    text-align: left;
        
                    &:hover {
                        background-color: #6399ce;
                        color: #fff;
                    }
        
                    img {
                        width: 50px;
                        margin:0px auto 10px;
                    }
        
                    p {
                        font-size: 18px;
                        padding-left: 0px;
                        width:100%;
                    }
                }
            }
    
            .left {
                padding: 0 0px 0 0px;
                width: 96%;
                margin: 130px auto 50px;

                p { 
                    line-height: 40px;
                }
        
                .one { 
                    font-size: 25.5px; 
                }
        
                .two { 
                    font-size: 38px; 
                }
        
                .three {
                    font-size: 40px;
                }

                .separator-blue {
                    width: 150px;
                    background-color: #6399ce;
                    height: 4px;
                    margin: 10px auto 0;
                }
            }
        }
    
        .first-section-home-de {
            padding:0;

            .right-cards {
                width: 96%;
                padding-left: 0px;
                align-items:stretch;
                margin-bottom: 50px;

                &:first-child {
                    margin-top: 0px;
                }
                
        
                .white-card {
                    padding: 10px;
                    margin:0px auto 30px;
                    display: flex;
                    flex-wrap:wrap;
                    text-align: left;
                    flex-direction: column;

                    h5 {
                        text-align: center;
                    }
        
                    &:hover {
                        background-color: #6399ce;
                        color: #fff;
                    }

                    div {
                        width: 95%;
                    }
        
                    img {
                        width: 50px;
                        margin:0px auto 10px;
                    }
        
                    p {
                        font-size: 18px;
                        padding-left: 0px;
                        width:100%;
                        text-align: justify;
                    }
                }
            }
        
            .left {
                padding: 0 0px 0 0px;
                width: 96%;
                margin: 0px auto 50px;

                p { 
                    line-height: 30px;
                }
        
                .one { 
                    font-size: 20px; 
                }
        
                .two { 
                    font-size: 17px; 
                }
        
                .three {
                    font-size: 22.5px;
                }
        
                .one-s { 
                    font-size: 20px; 
                }
        
                .two-s { 
                    font-size: 18.7px; 
                }
        
                .three-s {
                    font-size: 22.3px;
                }
        
                .one-a { 
                    font-size: 25px; 
                }
        
                .two-a { 
                    padding-top: 5px;
                    font-size: 33px; 
                }
        
                .three-a {
                    padding-top: 5px;
                    font-size: 30.5px;
                }
        
                .separator-blue {
                    width: 150px;
                    background-color: #6399ce;
                    height: 4px;
                    margin: 10px auto 0;
                }
        
                .separator-blue-s {
                    width: 150px;
                    background-color: #6399ce;
                    height: 4px;
                    margin: 10px auto 0;
                }
        
                .separator-blue-a {
                    width: 150px;
                    background-color: #6399ce;
                    height: 4px;
                    margin: 10px auto 0;
                }
            }
        }

        .info-strong-section {

            h3 {
                font-size: 28px;
            }
    
            .strong-card {
                font-size: 25px;
                min-height: 0;
    
                p {
                    text-align: center;
                    line-height: 30px;
                }
            }
        }

        .left-home-entr {
            margin-top: 0 !important;
        }

        .job-home-section, .domaine-section, .course-home-section {
            .domaine-section-title {
                .separator-blue {
                    width: 50px;
                }
            }
        }

        .section-home-not-choose {

            .left-home-entr {

                .text-entr {
                    font-size: 22px;
                }
            }
        }
    }

`;

export default HomebisStyled;