/**** Import de modules ****/
import React, { Component, useEffect } from 'react'
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

/**** Import des components ou containers ****/

/**** Import des css ****/
import FullPageLoaderStyled from './FullPageLoaderStyled'

const FullPageLoader = ({}) => {

  return (
    <FullPageLoaderStyled>
      <CircularProgress color="success" />
    </FullPageLoaderStyled>
  )
}

FullPageLoader.propTypes = {

}

export default FullPageLoader