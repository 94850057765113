import React from 'react'
import { useTimer } from 'react-timer-hook'


function MyTimer({ expiryTimestamp, setTimeRecrutement, toggleModalRecrutement, fetchScoreRecrutementParticulier, fetchGetScoreRecrutement }) {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });

  if(Math.abs(seconds + (minutes * 60) - 600) >= 600) {
    toggleModalRecrutement(false)
    fetchScoreRecrutementParticulier('skip', 600)
    fetchGetScoreRecrutement()
  }
  setTimeRecrutement(Math.abs(seconds + (minutes * 60) - 600))
  return (
    <div style={{fontSize: '20px', backgroundColor: '#D47558', padding: '10px', borderRadius: '5px', color: '#fff', textAlign: 'center', width: '80px', margin: '0 auto'}}>
      <span className="bold">{minutes}</span>:<span className="bold">{seconds}</span>
    </div>
  );
}
    
export default MyTimer;

