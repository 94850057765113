import { connect } from 'react-redux'

import CourseCardDomaine from '../../components/CourseCardDomaine'
import { changeActivCourseId } from '../../actions/course'

const mapStateToProps = (state) => ({
    activCourseId: state.course.activCourseId,
    currentDomaineClicked: state.menu.currentDomaineClicked,
    secteurs: state.menu.secteurs
})

const mapDispatchToProps = (dispatch) => ({
    changeActivCourseId: (id) => {
        dispatch(changeActivCourseId(id));
      },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(CourseCardDomaine)