import styled from 'styled-components';

const SignInStyled = styled.div`

.top-illustration {
    width: 100vw;
    height: 700px;
    padding-top: 125px;
    z-index: 10;
    position: relative;

    &::before {
        content:'';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width:100%;
        background: linear-gradient(180deg, rgba(214,231,245,1) 0%, rgba(100,154,206,1) 100%);
        clip-path: polygon(0% 0%,0% 80.6708%, 1.69492% 78.8616%, 3.38983% 77.0922%, 5.08475% 75.3821%, 6.77966% 73.75%, 8.47458% 72.2138%, 10.1695% 70.7902%, 11.8644% 69.495%, 13.5593% 68.3422%, 15.2542% 67.3446%, 16.9492% 66.513%, 18.6441% 65.8565%, 20.339% 65.3824%, 22.0339% 65.0959%, 23.7288% 65%, 25.4237% 65.0959%, 27.1186% 65.3824%, 28.8136% 65.8565%, 30.5085% 66.513%, 32.2034% 67.3446%, 33.8983% 68.3422%, 35.5932% 69.495%, 37.2881% 70.7902%, 38.9831% 72.2138%, 40.678% 73.75%, 42.3729% 75.3821%, 44.0678% 77.0922%, 45.7627% 78.8616%, 47.4576% 80.6708%, 49.1525% 82.5%, 50.8475% 84.3293%, 52.5424% 86.1385%, 54.2373% 87.9078%, 55.9322% 89.6179%, 57.6271% 91.25%, 59.322% 92.7862%, 61.017% 94.2098%, 62.7119% 95.505%, 64.4068% 96.6578%, 66.1017% 97.6555%, 67.7966% 98.487%, 69.4915% 99.1435%, 71.1864% 99.6176%, 72.8814% 99.9041%, 74.5763% 100%, 76.2712% 99.9041%, 77.9661% 99.6176%, 79.661% 99.1435%, 81.3559% 98.487%, 83.0509% 97.6555%, 84.7458% 96.6578%, 86.4407% 95.505%, 88.1356% 94.2098%, 89.8305% 92.7862%, 91.5254% 91.25%, 93.2203% 89.6179%, 94.9153% 87.9078%, 96.6102% 86.1385%, 98.3051% 84.3293%, 100% 82.5%, 100% 0%);
        shape-outside: polygon(0% 0%,0% 80.6708%, 1.69492% 78.8616%, 3.38983% 77.0922%, 5.08475% 75.3821%, 6.77966% 73.75%, 8.47458% 72.2138%, 10.1695% 70.7902%, 11.8644% 69.495%, 13.5593% 68.3422%, 15.2542% 67.3446%, 16.9492% 66.513%, 18.6441% 65.8565%, 20.339% 65.3824%, 22.0339% 65.0959%, 23.7288% 65%, 25.4237% 65.0959%, 27.1186% 65.3824%, 28.8136% 65.8565%, 30.5085% 66.513%, 32.2034% 67.3446%, 33.8983% 68.3422%, 35.5932% 69.495%, 37.2881% 70.7902%, 38.9831% 72.2138%, 40.678% 73.75%, 42.3729% 75.3821%, 44.0678% 77.0922%, 45.7627% 78.8616%, 47.4576% 80.6708%, 49.1525% 82.5%, 50.8475% 84.3293%, 52.5424% 86.1385%, 54.2373% 87.9078%, 55.9322% 89.6179%, 57.6271% 91.25%, 59.322% 92.7862%, 61.017% 94.2098%, 62.7119% 95.505%, 64.4068% 96.6578%, 66.1017% 97.6555%, 67.7966% 98.487%, 69.4915% 99.1435%, 71.1864% 99.6176%, 72.8814% 99.9041%, 74.5763% 100%, 76.2712% 99.9041%, 77.9661% 99.6176%, 79.661% 99.1435%, 81.3559% 98.487%, 83.0509% 97.6555%, 84.7458% 96.6578%, 86.4407% 95.505%, 88.1356% 94.2098%, 89.8305% 92.7862%, 91.5254% 91.25%, 93.2203% 89.6179%, 94.9153% 87.9078%, 96.6102% 86.1385%, 98.3051% 84.3293%, 100% 82.5%, 100% 0%);
        z-index: -10;
    }


    .opacity-light-section {
        height: 100%;
        top: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        padding: 0px 0;
        max-width: 1200px;
        margin: 0 auto;
        justify-content: space-between;

        .left {
            width: calc(50%);
            margin-top: 150px;
            padding-left: 80px;

            h1 {
                font-size: 50px;
                color: #202c3c;
            }

            p {
                font-size: 25px;
                color: #fff;
                line-height: 30px;
                padding: 30px 0;
                text-align: justify;
            }
        }
        img {
            margin-top: 50px;
            margin-left: -50px;
            box-sizing: border-box;
            width: 50%;
            max-width: 650px;
        }
    }
}

main.page-connexion {
    padding: 120px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .scroll-to {
        position: absolute;
        top: -100px;
        width: 10px;
        height: 10px;
    }

    h2 {
        font-size: 35px;
        width: auto;
        display: inline;
        margin: 0 auto 30px;
        position: relative;
        color: #649ACE;
        padding-bottom: 10px;
        text-transform: uppercase;

        .separator-blue {
            background-color: #649ACE;
            height: 4px;
            width: 80px;
            margin: 10px 0 0 auto;
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }
}

@media only screen and (max-width: 1400px) { 

    .top-illustration {
        .opacity-light-section {
            img {
                max-width: 550px;
                margin-left: 0px;
            }

            .left {
                margin-right:0;
                width: 50%;
                padding-right: 20px;
                box-sizing: border-box;
                padding-left: 0px;

                h1 {
                    text-align: left;
                }
            }
        }
    }
}

    @media only screen and (max-width: 1079px) {
            
        .top-illustration {
    
            .opacity-light-section {
                height: auto;
                flex-direction: column-reverse;
    
                .left{
                    padding: 50px 0 0 0;
                    width: calc(96%);
                    margin: 0 auto;
                    height: 226px;
    
                    h1 {
                        font-size: 35px;
                        text-align: center;
                        margin-top: 20px;
                    }
    
                    p {
                        font-size: 18px;
                        line-height: 22px;
                        text-align: center;
                    }
                }
                img {
                    max-width: 400px;
                    margin-left: 0px;
                    margin: 50px auto;
                }
            }
       }
    }
    
    @media only screen and (min-width: 0px) and (max-width: 759px) {
        .top-illustration {
    
            height: 450px;

            &::before {
                clip-path: none;
            }

            .opacity-light-section {
                flex-direction: column-reverse;
    
                .left {
                    margin:0 auto;
                    width: 96%;
                    box-sizing: border-box;
                    height: 250px;
                    display: flex; 
                    justify-content: center;
                    flex-direction: column;
                    padding-top: 0;
    
                    h1 {
                        font-size: 35px;
                        text-align: center;
                    }
    
                    p {
                        font-size: 18px;
                        line-height: 22px;
                        text-align: justify;
                        padding: 20px 0;
                        width: 80%;
                        margin: 0 auto;
                    }
                }
                img {
                    width: 80%;
                    margin: -50px auto 0;
                    max-width: 300px;
                }
            }
        }

        .page-create-account {
            padding-top: 50px;
        }
    }
`;

export default SignInStyled;