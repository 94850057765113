import axios from 'axios'
import * as qs from 'qs'
import { toast } from 'react-toastify';

import urlBackEnd from '../assets/js/urlBackEnd';

import 'react-toastify/dist/ReactToastify.css';

import { FETCH_FORMATIONS, saveFormations, changeLoadFormation, FETCH_DEMANDE_FORMATION, changeActivCourseId, CHANGE_ACTIV_COURSE_ID , sendEmailForEntrepriseCollab, SEND_EMAIL_FOR_ENTREPRISE_COLLAB, setInscCollabStepOne, setInscCollabStepTwo, setInscCollabStepThree, setInscCollabStepFour, setInscCollabError,fetchPostNewFacturation, FETCH_POST_NEW_FACTURATION, createNewFacturePdf, CREATE_NEW_FACTURE_PDF, updateFactureWithNewFile, UPDATE_FACTURE_WITH_NEW_FILE, FETCH_FACTURE_E, saveFactureE,createNewFacturePPdf, CREATE_NEW_FACTURE_P_PDF, fetchPostNewFacturationP, FETCH_POST_NEW_FACTURATION_P, updateFacturePWithNewFile, UPDATE_FACTURE_P_WITH_NEW_FILE, fetchFactureP, FETCH_FACTURE_P, saveFactureP, setModalInscLoaderOpen, FETCH_PUT_MODE_FINANCEMENT, CHANGE_STEP_VALUE, FETCH_UPLOAD_EDOF_FILE, fetchSendEmailDownloadEdofDocument, FETCH_SEND_EMAIL_DOWNLOAD_EDOF_DOCUMENT, fetchFactureE, fetchStatutFormation, FETCH_STATUT_FORMATION,fetchCreateNewContratParticulier, FETCH_CREATE_NEW_CONTRAT_PARTICULIER, updateContratPWithNewFile, UPDATE_CONTRAT_P_WITH_NEW_FILE, fetchCreateNewContratEntreprise, FETCH_CREATE_NEW_CONTRAT_ENTREPRISE, updateContratEWithNewFile, UPDATE_CONTRAT_E_WITH_NEW_FILE, fetchGetOneProgram, redirectToAccount, redirectToMyFinancement, changeClickedFacture, FETCH_PUT_CPF_TO_ZERO, SAVE_FACTURE_P, facturePCharged, SIGN_CONTRAT_PART, factureECharged, FETCH_PUT_OPCO_TO_ZERO, fetchPutModeFinancement, FETCH_PUT_SIGNED_FACTURATION, fetchPutSignedFacturation, FETCH_MY_SALARIE, saveMyEntreprisePart, saveFormationsHome, saveFormationsPagination} from '../actions/course';
import { fetchUserRole } from '../actions/login';
import { setFullPageLoader, setFullPageLoaderFacturation } from '../actions/actions'
import { FETCH_DEMANDE_FORMATION_COLLAB } from '../actions/course'
import { toggleModalSignature } from '../actions/modal'

//const URL = "http://localhost:1338/api/";
const URL = urlBackEnd;


  let euroFormat = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
  });

const formationsMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case FETCH_FORMATIONS: {
      changeLoadFormation(true)
      const state = store.getState();
      const paramObj = action.paramObj === undefined ? {page: 'app' ,intemNb: 10, pageNb: 1} : action.paramObj
      const isLogged = state.login.isLogged
      const secteurCoursefilter = state.menu.secteurCourseFilter
      const domaineCoursefilter = state.menu.domaineCourseFilter
      if (isLogged) {
        const objecttOm = {
          populate: '*',
          pagination: {
            page: paramObj.pageNb,
            pageSize: paramObj.intemNb,
          },
          filters: {
            secteurs: {},
            domaines: {
              nom: state.login.userRole === 'particulier' ? 'Particulier' : 'Entreprise'
            },
          },
          sort: ['publishedAt:desc']
          }

          if (secteurCoursefilter !== '') {
            objecttOm.filters.secteurs.id = secteurCoursefilter
          }
          if (domaineCoursefilter !== '') {
            objecttOm.filters.domaines.id = domaineCoursefilter
          }

          const queryFormations = qs.stringify(
            objecttOm
            , {
              encodeValuesOnly: true,
            });

        axios.get(`${URL}formations?${queryFormations}`)
        .then((response) => {
          store.dispatch(changeLoadFormation(true));
          if (paramObj.page === 'home') {
            store.dispatch(saveFormationsHome(response.data.data));
          } else {
            store.dispatch(saveFormations(response.data.data));
            store.dispatch(saveFormationsPagination(response.data.meta.pagination))
            if (response.data.data.length > 0) {
              store.dispatch(changeActivCourseId(response.data.data[0].id))
            }
          }
          store.dispatch(changeLoadFormation(false));
        })
        .catch((error) => {
          console.error(error);
          store.dispatch(changeLoadFormation(false));
        });
      } else {
        const objecttOm = {
          populate: '*',
          pagination: {
            page: paramObj.pageNb,
            pageSize: paramObj.intemNb,
          },
          filters: {
            secteurs: {},
            domaines: {},
          },
          sort: ['publishedAt:desc']
          }

          if (secteurCoursefilter !== '') {
            objecttOm.filters.secteurs.id = secteurCoursefilter
          }
          if (domaineCoursefilter !== '') {
            objecttOm.filters.domaines.id = domaineCoursefilter
          }
          
        const queryFormations = qs.stringify(
          objecttOm
          , {
            encodeValuesOnly: true,
          });
          
          axios.get(`${URL}formations?${queryFormations}`)
          .then((response) => {
            store.dispatch(changeLoadFormation(true));
            if (paramObj.page === 'home') {
              store.dispatch(saveFormationsHome(response.data.data));
            } else {
              store.dispatch(saveFormations(response.data.data));
              store.dispatch(saveFormationsPagination(response.data.meta.pagination))
              if (response.data.data.length > 0) {
                store.dispatch(changeActivCourseId(response.data.data[0].id))
              }
            }
            store.dispatch(changeLoadFormation(false));
          })
          .catch((error) => {
            console.error(error);
            store.dispatch(changeLoadFormation(false));
          });
      }
      next(action);
      break;
    }
    case FETCH_DEMANDE_FORMATION : {
      store.dispatch(setFullPageLoader(true))
      const state = store.getState();
      const courseId = action.id
      const dataF = []
      const particuliersA = state.login.userInfo.data[0].attributes.demande_formations.data.map((item) => dataF.push(item.id)) 
      const alreadyAsked = dataF.includes(courseId)
      dataF.push(courseId)
      const particulierId = state.login.userInfo.data[0].id
      if (alreadyAsked === false) {
        axios.put(`${URL}particuliers/${particulierId}`, {
          data: {
            demande_formations: dataF,
            userName: state.login.session.user.email,
            formationName: state.course.formations.find(form => form.id === courseId).attributes.nom,
            formationDuree: state.course.formations.find(form => form.id === courseId).attributes.duree,
            email: state.login.session.user.email,
            nom: state.login.session.user.nom,
            prenom: state.login.session.user.prenom,
            telephone: state.login.session.user.telephone,
            sendEmail: true
          }
        },
        {
          headers: {
            Authorization:
              `Bearer ${state.login.session.jwt}`,
            },
        })
        .then((response) => {
          store.dispatch(fetchUserRole())
          store.dispatch(fetchPostNewFacturationP())
          toast(`Votre demande a été envoyé ! Notre équipe va vous contacter.`, {
            position: "bottom-right",
            type: "success",
            theme:"colored",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
        .catch((error) => {
          console.error(error);
          store.dispatch(setFullPageLoader(false))
        });
      }  else {
        store.dispatch(setInscCollabStepOne(''))
        store.dispatch(setModalInscLoaderOpen(false))
        store.dispatch(setFullPageLoader(false))
        toast(`Vous avez déjà fait une demande pour cette formation !`, {
          position: "bottom-right",
          type: "error",
          theme:"colored",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
      next(action);
      break;
    }
    case FETCH_DEMANDE_FORMATION_COLLAB : {
      const state = store.getState();
      store.dispatch(setFullPageLoader(true))
      const courseId = action.id
      const entrepriseId = state.login.userInfo.data[0].id
      const entrepriseName = state.login.userInfo.data[0].attributes.Nom_entreprise
      const formationName = state.course.formations.find(form => form.id === courseId).attributes.nom

      const collabs = action.array

      collabs.map((collab) => (
          axios.post(`${URL}inscription-formations`, {
            data: {
              email: collab.email,
              nom: collab.name,
              prenom: collab.prenom,
              formation: courseId,
              entreprise: entrepriseId,
              entrepriseName: entrepriseName,
              formationName: state.course.formations.find(form => form.id === courseId).attributes.nom
            },
          },
          {
            headers: {
              Authorization:
                `Bearer ${state.login.session.jwt}`,
              },
          })
          .then((response) => {
            store.dispatch(fetchUserRole())
            if (collab.id === 1) {
              store.dispatch(sendEmailForEntrepriseCollab(courseId, collabs))
            }
          })
          .catch((error) => {
            console.error(error);
            toast(`Une erreur s'est produite ! ${error.message}`, {
              position: "bottom-right",
              type: "error",
              theme:"colored",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            })
          })
      ))
      next(action);
      break;
    }
    case SEND_EMAIL_FOR_ENTREPRISE_COLLAB : {
      const state = store.getState();
      const courseId = action.id
      const entrepriseId = state.login.userInfo.data[0].id
      const entrepriseName = state.login.userInfo.data[0].attributes.Nom_entreprise
      const entrepriseEmail = state.login.session.user.email
      const formationName = state.course.formations.find(form => form.id === courseId).attributes.nom
      const formationDuree = state.course.formations.find(form => form.id === courseId).attributes.duree
      const collabs = action.collabs

      axios.post(`${URL}inscMultipleCollabs`, {
        data: {
          collabs: collabs,
          formation: courseId,
          entreprise: entrepriseId,
          entrepriseName: entrepriseName,
          formationName: formationName,
          formationDuree: formationDuree,
          entrepriseEmail: entrepriseEmail
        },
      },
      {
        headers: {
          Authorization:
            `Bearer ${state.login.session.jwt}`,
          },
      })
      .then((response) => {
        store.dispatch(fetchUserRole())
        store.dispatch(setInscCollabStepOne('done'))
        store.dispatch(setInscCollabStepTwo('loading'))
        store.dispatch(fetchPostNewFacturation(collabs.length, collabs))
        toast(`Votre demande a été envoyé par email`, {
          position: "bottom-right",
          type: "success",
          theme:"colored",
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .catch((error) => {
        console.error(error);
        store.dispatch(setInscCollabError(true))
        toast(`Une erreur s'est produite ! ${error.message}`, {
          position: "bottom-right",
          type: "error",
          theme:"colored",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })

      next(action);
      break;
    }
    case FETCH_POST_NEW_FACTURATION : {
      const state = store.getState();
      const courseId = state.course.activCourseId
      const entrepriseId = state.login.userInfo.data[0].id
      const coursePrice  = state.course.formations.find(item => item.id === courseId).attributes.prix
      const formationName = state.course.formations.find(form => form.id === courseId).attributes.nom
      const collabNumber = action.collabNumber
      const collabs = action.collabs
      axios.post(`${URL}facturation-formations`, {
        data: {
          Prix: coursePrice,
          formation: courseId,
          entreprise: entrepriseId,
          nom_formation: formationName,
          nombre_particulier: collabNumber,
          mode_financement: 'Entreprise'
        },
      },
      {
        headers: {
          Authorization:
            `Bearer ${state.login.session.jwt}`,
          },
      })
      .then((response) => {
        const factureId = response.data.data.id
        store.dispatch(fetchUserRole())
        store.dispatch(fetchPutModeFinancement(factureId, 'Entreprise'))
        store.dispatch(fetchFactureE())
        store.dispatch(redirectToMyFinancement(true))
        store.dispatch(createNewFacturePdf(coursePrice, formationName, collabNumber, factureId))
        store.dispatch(fetchCreateNewContratEntreprise(factureId, collabs))
      })
      .catch((error) => {
        console.error(error);
        store.dispatch(setInscCollabError(true))
        toast(`Une erreur s'est produite ! ${error.message}`, {
          position: "bottom-right",
          type: "error",
          theme:"colored",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })

      next(action);
      break;
    }
    case FETCH_POST_NEW_FACTURATION_P : {
      const state = store.getState();
      const courseId = state.course.activCourseId
      
      const particulierId = state.login.userInfo.data[0].id
      const coursePrice  = state.course.formations.find(item => item.id === courseId).attributes.prix
      const formationName = state.course.formations.find(form => form.id === courseId).attributes.nom
      const collabNumber = 1
      axios.post(`${URL}facturation-formations`, {
        data: {
          Prix: coursePrice,
          formation: courseId,
          particulier: particulierId,
          nom_formation: formationName,
          nombre_particulier: collabNumber
        },
      },
      {
        headers: {
          Authorization:
            `Bearer ${state.login.session.jwt}`,
          },
      })
      .then((response) => {
        const factureId = response.data.data.id
        store.dispatch(fetchUserRole())
        store.dispatch(fetchFactureP())
        store.dispatch(redirectToMyFinancement(true))
        // store.dispatch(createNewFacturePPdf(coursePrice, formationName, collabNumber, factureId))
      })
      .catch((error) => {
        console.error(error);
        store.dispatch(setInscCollabError(true))
        toast(`Une erreur s'est produite ! ${error.message}`, {
          position: "bottom-right",
          type: "error",
          theme:"colored",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })

      next(action);
      break;
    }
    case CREATE_NEW_FACTURE_PDF: {
      const state = store.getState();
      const courseId = state.course.activCourseId
      const entrepriseId = state.login.userInfo.data[0].id
      const coursePrice  = action.coursePrice
      const formationName = action.formationName
      const collabNumber = action.collabNumber
      const factureId = action.factureId
      const userId = state.login.session.user.id 
      const createdAt = new Date().toLocaleDateString("fr-FR")
      const secteurId = state.course.formations.find(item => item.id === courseId).attributes.secteurs.data[0].id
      const adresseClient = state.login.userInfo.data[0].attributes.numero_voie + ' ' + state.login.userInfo.data[0].attributes.nom_voie
      const cpClient = state.login.userInfo.data[0].attributes.code_postal + ' ' + state.login.userInfo.data[0].attributes.ville
      const telClient = state.login.session.user.telephone
      const emailClient = state.login.session.user.email
      
      axios.post(`${URL}createNewFacturationEntreprise/${factureId}`, {
        data: {
          Prix: coursePrice,
          formation: courseId,
          entreprise: entrepriseId,
          nom_formation: formationName,
          nombre_particulier: collabNumber,
          userId: userId,
          createdAt: createdAt,
          refId: `${userId}-${secteurId}-${courseId}`,
          adresseClient: adresseClient,
          cpClient: cpClient,
          telClient: telClient,
          emailClient: emailClient
        },
      },
      {
        headers: {
          Authorization:
            `Bearer ${state.login.session.jwt}`,
          },
      })
      .then((response) => {
        store.dispatch(setInscCollabStepThree('done'))
        store.dispatch(setInscCollabStepFour('loading'))
        store.dispatch(updateFactureWithNewFile(factureId))
      })
      .catch((error) => {
        console.error(error);
        store.dispatch(setInscCollabError(true))
        toast(`Une erreur s'est produite ! ${error.message}`, {
          position: "bottom-right",
          type: "error",
          theme:"colored",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      });

      next(action);
      break;
    }
    case CREATE_NEW_FACTURE_P_PDF: {
      const state = store.getState();
      const courseId = state.course.activCourseId
      const entrepriseId = state.login.userInfo.data[0].id
      const coursePrice  = action.coursePrice
      const formationName = action.formationName
      const collabNumber = action.collabNumber
      const factureId = action.factureId
      const userId = state.login.session.user.id 
      const createdAt = new Date().toLocaleDateString("fr-FR")
      const secteurId = state.course.formations.find(item => item.id === courseId).attributes.secteurs.data[0].id
      const adresseClient = state.login.userInfo.data[0].attributes.numero_voie + ' ' + state.login.userInfo.data[0].attributes.nom_voie
      const cpClient = state.login.userInfo.data[0].attributes.code_postal + ' ' + state.login.userInfo.data[0].attributes.ville
      const telClient = state.login.session.user.telephone
      const emailClient = state.login.session.user.email
      
      axios.post(`${URL}createNewFacturationParticulier/${factureId}`, {
        data: {
          Prix: parseInt(coursePrice),
          formation: courseId,
          entreprise: entrepriseId,
          nom_formation: formationName,
          nombre_particulier: collabNumber,
          userId: userId,
          createdAt: createdAt,
          refId: `${userId}-${secteurId}-${courseId}`,
          adresseClient: adresseClient,
          cpClient: cpClient,
          telClient: telClient,
          emailClient: emailClient
        },
      },
      {
        headers: {
          Authorization:
            `Bearer ${state.login.session.jwt}`,
          },
      })
      .then((response) => {
        store.dispatch(updateFacturePWithNewFile(factureId))
      })
      .catch((error) => {
        console.error(error);
        store.dispatch(setInscCollabError(true))
        toast(`Une erreur s'est produite ! ${error.message}`, {
          position: "bottom-right",
          type: "error",
          theme:"colored",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      });

      next(action);
      break;
    }
    case UPDATE_FACTURE_WITH_NEW_FILE: {
      const state = store.getState();

      const factureId = action.factureId
      
      axios.put(`${URL}updateFactureToEntreprise/${factureId}`,
      {
        headers: {
          Authorization:
            `Bearer ${state.login.session.jwt}`,
          },
      })
      .then((response) => {
        store.dispatch(setInscCollabStepFour('done'))
        toast(`Votre facture a été enregistrée dans votre espace client`, {
          position: "bottom-right",
          type: "success",
          theme:"colored",
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .catch((error) => {
        console.error(error);
        store.dispatch(setInscCollabError(true))
        toast(`Une erreur s'est produite ! ${error.message}`, {
          position: "bottom-right",
          type: "error",
          theme:"colored",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      });

      next(action);
      break;
    }
    case UPDATE_FACTURE_P_WITH_NEW_FILE: {
      const state = store.getState();

      const factureId = action.factureId
      axios.put(`${URL}updateFactureToParticulier/${factureId}`,
      {
        headers: {
          Authorization:
            `Bearer ${state.login.session.jwt}`,
          },
      })
      .then((response) => {
        toast(`Votre facture a été enregistrée dans votre espace client`, {
          position: "bottom-right",
          type: "success",
          theme:"colored",
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .catch((error) => {
        console.error(error);
        store.dispatch(setInscCollabError(true))
        toast(`Une erreur s'est produite ! ${error.message}`, {
          position: "bottom-right",
          type: "error",
          theme:"colored",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      });

      next(action);
      break;
    }
    case FETCH_FACTURE_E: {
      const state = store.getState();
      store.dispatch(factureECharged(false))
      const userId = (state.login.isLogged ? state.login.userInfo.data[0].id : '')
      const userRole = state.login.userRole
      const queryFE = qs.stringify({
        populate: {       
          entreprise: {
            populate: '*',
          },
          formation: {
            populate: '*',
          },
          particulier: {
            populate: '*',
          },
          contrat_formation: {
            populate: '*',
          },
          facture: {
            populate: '*',
          },
          autofinancement: {
            populate: '*',
          },
          opco: {
            populate: '*',
          },
        },
        filters: {
          entreprise: {
            id: userId
          }
        },
        sort: ['publishedAt:desc']
        }, {
          encodeValuesOnly: true,
      });
      
      if (userRole === 'entreprise') {
        axios.get(`${URL}facturation-formations?${queryFE}`,
        {
          headers: {
            Authorization:
              `Bearer ${state.login.session.jwt}`,
            },
        })
        .then((response) => {
          store.dispatch(saveFactureE(response.data.data))
          store.dispatch(factureECharged(true))
          store.dispatch(setFullPageLoader(false))
          store.dispatch(setFullPageLoaderFacturation(false))
          const factureListe = response.data.data
          factureListe.map((factureItem) => {
            if (factureItem.attributes.autofinancement.data !== null && factureItem.attributes.autofinancement.data.attributes.montant_financement !== null) {
              if (factureItem.attributes.facture.data === null) {
                store.dispatch(createNewFacturePdf(factureItem.attributes.Prix , factureItem.attributes.nom_formation, factureItem.attributes.nombre_particulier ,factureItem.id))
              }
            }
          })
        })
        .catch((error) => {
          console.error(error);
          store.dispatch(setFullPageLoaderFacturation(false))
        });
      }
      next(action);
      break;
    }
    case FETCH_FACTURE_P: {
      const state = store.getState();
      store.dispatch(facturePCharged(false))
      const userRole = state.login.userRole
      const userId = (state.login.isLogged ?state.login.userInfo.data[0].id : '')
      const queryFP = qs.stringify({
        populate: {       
          entreprise: {
            populate: '*',
          },
          formation: {
            populate: '*',
          },
          particulier: {
            populate: '*',
          },
          facture: {
            populate: '*',
          },
          contrat_formation: {
            populate: '*',
          },
          cpf_edof: {
            populate: '*',
          },
          entreprise_edof: {
            populate: '*',
          },
          autfinancement_edof: {
            populate: '*',
          },
          autofinancement: {
            populate: '*',
          },
          opco: {
            populate: '*',
          },
          pole_emploi_edof: {
            populate: '*',
          },
        },
        filters: {
          particulier: {
            id: userId
          }
        },
        sort: ['publishedAt:desc']
        }, {
          encodeValuesOnly: true,
      });
      if (userRole === 'particulier') {
        axios.get(`${URL}facturation-formations?${queryFP}`,
        {
          headers: {
            Authorization:
              `Bearer ${state.login.session.jwt}`,
            },
        })
        .then((response) => {
          const factureListe = response.data.data
          store.dispatch(saveFactureP(factureListe))
          store.dispatch(facturePCharged(true))
          store.dispatch(setFullPageLoader(false))
          store.dispatch(setFullPageLoaderFacturation(false))
          factureListe.map((factureItem) => {
            if (factureItem.attributes.cpf_edof.data !== null && factureItem.attributes.cpf_edof.data.attributes.montant_financement !== null) {
              if (factureItem.attributes.contrat_formation.data === null) {
                store.dispatch(fetchCreateNewContratParticulier(factureItem.id))
              }
            
            }
            if (factureItem.attributes.autofinancement.data !== null && factureItem.attributes.autofinancement.data.attributes.montant_financement !== null) {
              if (factureItem.attributes.facture.data === null) {
                store.dispatch(createNewFacturePPdf(factureItem.attributes.Prix , factureItem.attributes.nom_formation, factureItem.attributes.nombre_particulier ,factureItem.id))
              }
            
            }
          })
          
        })
        .catch((error) => {
          console.error(error);
          store.dispatch(setFullPageLoaderFacturation(false))
        });
      }
      next(action);
      break;
    }
    case FETCH_PUT_MODE_FINANCEMENT: {
      const state = store.getState();
      const factureId = action.factureId
      const statut = action.statut

      if (statut === 'Demandeur d\'emploi' || statut === 'Salarié') {
        axios.put(`${URL}facturation-formations/${factureId}`, 
        {
          data: {
            mode_financement: statut,
            particulier: state.login.userInfo.data[0].id,
            facturation_formation: factureId,
            step: 1
          }
        },
        {
          headers: {
            Authorization:
            `Bearer ${state.login.session.jwt}`,
          }
        })
        .then((response) => {
          store.dispatch(fetchFactureP(response.data.data))
          store.dispatch(fetchFactureE(response.data.data))
        })
        .catch((error) => {
          console.error(error);
        });
      }
      if (statut === 'Entreprise') {
        axios.put(`${URL}facturation-formations/${factureId}`, 
        {
          data: {
            mode_financement: statut,
            entreprise: state.login.userInfo.data[0].id,
            facturation_formation: factureId,
            step: 1
          }
        },
        {
          headers: {
            Authorization:
            `Bearer ${state.login.session.jwt}`,
          }
        })
        .then((response) => {
          store.dispatch(fetchFactureP(response.data.data))
          store.dispatch(fetchFactureE(response.data.data))
        })
        .catch((error) => {
          console.error(error);
          store.dispatch(setFullPageLoaderFacturation(false))
        });
      }

      next(action);
      break;
    }
    case CHANGE_STEP_VALUE: {
      const state = store.getState();
      const factureId = state.course.clickedFacture

      axios.put(`${URL}facturation-formations/${factureId}`, 
      {
        data: {
          step: action.number
        }
      },
      {
        headers: {
          Authorization:
          `Bearer ${state.login.session.jwt}`,
        }
      })
      .then((response) => {
        store.dispatch(fetchFactureP(response.data.data))
        store.dispatch(fetchFactureE(response.data.data))
      })
      .catch((error) => {
        console.error(error);
      });
      next(action);
      break;
    }
    case FETCH_UPLOAD_EDOF_FILE: {
      const state = store.getState();
      const factureId = state.course.clickedFacture
      const formData = new FormData()
      
      if (action.mode === 'cpf') {
        formData.append('files', action.file[0])
        formData.append("refId", action.id);
        formData.append("ref", "api::cpf-edof.cpf-edof");
        formData.append("field", "preuve_financement");
      }

      if (action.mode === 'pe') {
        formData.append('files', action.file[0])
        formData.append("refId", action.id);
        formData.append("ref", "api::pole-emploi-edof.pole-emploi-edof");
        formData.append("field", "preuve_financement");
      }

      if (action.mode === 'opco') {
        formData.append('files', action.file[0])
        formData.append("refId", action.id);
        formData.append("ref", "api::opco.opco");
        formData.append("field", "preuve_financement");
      }

      axios.post(`${URL}upload`, formData,
      {
        headers: {
          Authorization:
          `Bearer ${state.login.session.jwt}`,
        }
      })
      .then((response) => {
        toast(`Votre document a été téléchargé. Un email vous sera envoyé à la création de vos accès à la plateform`, {
          position: "bottom-right",
          type: "success",
          theme:"colored",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        store.dispatch(fetchSendEmailDownloadEdofDocument(response.data))
        store.dispatch(fetchFactureP())
        store.dispatch(fetchFactureE())
      })
      .catch((error) => {
        console.error(error);
        store.dispatch(setFullPageLoaderFacturation(false))
      });
      next(action);
      break;
    }
    case FETCH_SEND_EMAIL_DOWNLOAD_EDOF_DOCUMENT : {
      const state = store.getState();
      const factureObj = state.course.factureP.find(item => item.id === state.course.clickedFacture) 
      const factureObjE = state.course.factureE.find(item => item.id === state.course.clickedFacture) 
      const email = state.login.session.user.email
      const prenom = state.login.session.user.prenom
      const nom = state.login.session.user.nom
      const factureId = state.course.clickedFacture
      const edofId = (factureObj && factureObj.attributes.cpf_edof.data !== null ? factureObj.attributes.cpf_edof.data.id : false)
      const peId = (factureObj && factureObj.attributes.pole_emploi_edof.data !== null ? factureObj.attributes.pole_emploi_edof.data.id : false)
      const opcoId = (factureObjE && factureObjE.attributes.opco.data !== null ? factureObjE.attributes.opco.data.id : false)
      const modeFinancement = (factureObj ? factureObj.attributes.mode_financement : factureObjE.attributes.mode_financement)
      const userInfo = state.login.userInfo.data[0].attributes

      axios.post(`${URL}sendEmailDownloadEdofDocument`,
      {
        data: {
          email,
          prenom,
          nom,
          factureId,
          edofId,
          peId,
          modeFinancement,
          userInfo,
          opcoId
        }
      },
      {
        headers: {
          Authorization:
          `Bearer ${state.login.session.jwt}`,
        }
      })
      .then((response) => {
        toast(`Notre equipe a bien pris connaissance de votre dépôt de document`, {
          position: "bottom-right",
          type: "success",
          theme:"colored",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .catch((error) => {
        console.error(error);
      });
      next(action);
      break;
    }
    case FETCH_STATUT_FORMATION : {
      const state = store.getState();
      const courseId = action.courseId
      const demandeFormation = state.login.userInfo.data[0].attributes.demande_formations.data
      const enCoursFormation = state.login.userInfo.data[0].attributes.en_cours_formations.data


      const arrayMinusCourseId = []
      
      demandeFormation.map((item) => {
        arrayMinusCourseId.push(item.id)
      })

      const arrayPlusCourseId = []
      
      enCoursFormation.map((item) => {
        arrayPlusCourseId.push(item.id)
      })

      arrayPlusCourseId.push(courseId)

      axios.put(`${URL}particuliers/${state.login.userInfo.data[0].id}`,
      {
        data: {
          demande_formations: arrayMinusCourseId.filter(item => item !== courseId),
          en_cours_formations: arrayPlusCourseId,
          sendEmail: false
        }
      },
      {
        headers: {
          Authorization:
          `Bearer ${state.login.session.jwt}`,
        }
      })
      .then((response) => {
      })
      .catch((error) => {
        console.error(error);
      });
      next(action);
      break;
    }
    case FETCH_CREATE_NEW_CONTRAT_PARTICULIER: {
      const state = store.getState();

      /* calcul du reste à charge de la facture */

     const factureToCalcul = state.course.factureP.find(item => item.id === action.factureId).attributes

      const calculRest = () => {
        const cpf = factureToCalcul.cpf_edof.data === null ? 0 : factureToCalcul.cpf_edof.data.attributes.montant_financement
        const pe = factureToCalcul.pole_emploi_edof.data === null ? 0 : factureToCalcul.pole_emploi_edof.data.attributes.montant_financement
        const total = factureToCalcul.Prix

        return total - (cpf === null ? 0 : cpf) - (pe  === null ? 0 : pe)
      }

      const courseId = state.course.activCourseId
      const factureId = action.factureId
      const nomClient = `${state.login.session.user.prenom} ${state.login.session.user.nom}`
      const contactClient = nomClient
      const rueAdresseClient = state.login.userInfo.data[0].attributes.numero_voie + ' ' + state.login.userInfo.data[0].attributes.nom_voie
      const villeAdresseClient = state.login.userInfo.data[0].attributes.code_postal + ' ' + state.login.userInfo.data[0].attributes.ville
      const siretClient = 'Ce champ est réservé pour les contractuels entreprise'
      const telClient = state.login.session.user.telephone
      const emailClient = state.login.session.user.email
      const formationId = state.course.factureP.find(item => item.id === factureId).attributes.formation.data.id
      const nomFormation = state.course.factureP.find(item => item.id === factureId).attributes.nom_formation
      const dureeFormation = state.course.factureP.find(item => item.id === factureId).attributes.formation.data.attributes.duree
      const modeleFormation = state.course.factureP.find(item => item.id === factureId).attributes.formation.data.attributes.model_formation
      const dateDebutFormation = new Date().toLocaleDateString("fr-FR")
      const accesPlateform = (state.course.factureP.find(item => item.id === factureId).attributes.formation.data.attributes.duree_acces_formation / 30)
      const collaborateur = nomClient
      const totalFactureTTC = state.course.factureP.find(item => item.id === factureId).attributes.formation.data.attributes.prix
      const restCharge = calculRest()
      
      axios.post(`${URL}createNewContratParticulier/${factureId}`, {
        data: {
          factureId: factureId,
          formationId: formationId,
          nomClient: nomClient,
          contactClient: contactClient,
          rueAdresseClient: rueAdresseClient,
          villeAdresseClient: villeAdresseClient,
          siretClient: siretClient,
          telClient: telClient,
          emailClient: emailClient,
          nomFormation: nomFormation,
          dureeFormation: dureeFormation,
          modeleFormation: modeleFormation,
          accesPlateform: accesPlateform,
          dateDebutFormation: dateDebutFormation,
          collaborateur: collaborateur,
          totalFactureTTC: totalFactureTTC,
          restCharge: restCharge,
          dateContrat: dateDebutFormation
        },
      },
      {
        headers: {
          Authorization:
            `Bearer ${state.login.session.jwt}`,
          },
      })
      .then((response) => {
        store.dispatch(updateContratPWithNewFile(factureId))
      })
      .catch((error) => {
        console.error(error);
        toast(`Une erreur s'est produite, votre contrat n'a pas été créer ! ${error.message}`, {
          position: "bottom-right",
          type: "error",
          theme:"colored",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      });

      next(action);
      break;
    }
    case UPDATE_CONTRAT_P_WITH_NEW_FILE: {
      const state = store.getState();

      const factureId = action.factureId
      
      axios.put(`${URL}updateContratToParticulier/${factureId}`,
      {
        headers: {
          Authorization:
            `Bearer ${state.login.session.jwt}`,
          },
      })
      .then((response) => {
        toast(`Votre contrat a été enregistrée dans votre espace client`, {
          position: "bottom-right",
          type: "success",
          theme:"colored",
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .catch((error) => {
        console.error(error);
        toast(`Une erreur s'est produite ! ${error.message}`, {
          position: "bottom-right",
          type: "error",
          theme:"colored",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      });

      next(action);
      break;
    }
    case FETCH_CREATE_NEW_CONTRAT_ENTREPRISE: {
      const state = store.getState();
      const courseId = state.course.activCourseId
      const factureId = action.factureId
      const nomClient = `${state.login.userInfo.data[0].attributes.Nom_entreprise}`
      const contactClient = `${state.login.session.user.prenom} ${state.login.session.user.nom}`
      const rueAdresseClient = state.login.userInfo.data[0].attributes.numero_voie + ' ' + state.login.userInfo.data[0].attributes.nom_voie
      const villeAdresseClient = state.login.userInfo.data[0].attributes.code_postal + ' ' + state.login.userInfo.data[0].attributes.ville
      const siretClient = `${state.login.userInfo.data[0].attributes.siren}`
      const telClient = state.login.session.user.telephone
      const emailClient = state.login.session.user.email
      const nomFormation = state.course.formations.find(item => item.id === courseId).attributes.nom
      const dureeFormation = state.course.formations.find(item => item.id === courseId).attributes.duree
      const modeleFormation = state.course.formations.find(item => item.id === courseId).attributes.model_formation
      const dateDebutFormation = new Date().toLocaleDateString("fr-FR")
      const accesPlateform = (state.course.formations.find(item => item.id === courseId).attributes.duree_acces_formation / 30)
      const collaborateur = action.collabs
      const totalFactureTTC = state.course.formations.find(item => item.id === courseId).attributes.prix * action.collabs.length

      const allCollabsString = () => {
        let str = ""
        collaborateur.map((item) => {
          str += `${item.id === 1 ? '' : ', '} ${item.name} ${item.prenom}`
        })
        return str
      }   
      
      axios.post(`${URL}createNewContratEntreprise/${factureId}`, {
        data: {
          factureId: factureId,
          nomClient: nomClient,
          contactClient: contactClient,
          rueAdresseClient: rueAdresseClient,
          villeAdresseClient: villeAdresseClient,
          siretClient: siretClient,
          telClient: telClient,
          emailClient: emailClient,
          nomFormation: nomFormation,
          dureeFormation: dureeFormation,
          modeleFormation: modeleFormation,
          accesPlateform: accesPlateform,
          dateDebutFormation: dateDebutFormation,
          collaborateur: allCollabsString(),
          totalFactureTTC: totalFactureTTC,
        },
      },
      {
        headers: {
          Authorization:
            `Bearer ${state.login.session.jwt}`,
          },
      })
      .then((response) => {
        store.dispatch(updateContratEWithNewFile(factureId))
      })
      .catch((error) => {
        console.error(error);
        toast(`Une erreur s'est produite, votre contrat n'a pas été créer ! ${error.message}`, {
          position: "bottom-right",
          type: "error",
          theme:"colored",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      });

      next(action);
      break;
    }
    case UPDATE_CONTRAT_E_WITH_NEW_FILE: {
      const state = store.getState();

      const factureId = action.factureId
      
      axios.put(`${URL}updateContratToEntreprise/${factureId}`,
      {
        headers: {
          Authorization:
            `Bearer ${state.login.session.jwt}`,
          },
      })
      .then((response) => {
        toast(`Votre contrat a été enregistrée dans votre espace client`, {
          position: "bottom-right",
          type: "success",
          theme:"colored",
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .catch((error) => {
        console.error(error);
        toast(`Une erreur s'est produite ! ${error.message}`, {
          position: "bottom-right",
          type: "error",
          theme:"colored",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      });

      next(action);
      break;
    }
    case FETCH_PUT_CPF_TO_ZERO: {

      const state = store.getState();
      // /cpf-edofs/:id

      axios.put(`${URL}cpf-edofs/${action.factureId}`, 
      {
        data: {
          montant_financement: '0'
        }
      },
      {
        headers: {
          Authorization:
          `Bearer ${state.login.session.jwt}`,
        }
      })
      .then((response) => {
        store.dispatch(fetchFactureP())
      })
      .catch((error) => {
        console.error(error);
        store.dispatch(setFullPageLoaderFacturation(false))
      });
      next(action);
      break;
    }
    case FETCH_PUT_OPCO_TO_ZERO: {

      const state = store.getState();
      // /cpf-edofs/:id

      axios.put(`${URL}opcos/${action.factureId}`, 
      {
        data: {
          montant_financement: '0'
        }
      },
      {
        headers: {
          Authorization:
          `Bearer ${state.login.session.jwt}`,
        }
      })
      .then((response) => {
        store.dispatch(fetchFactureP())
      })
      .catch((error) => {
        console.error(error);
        store.dispatch(setFullPageLoaderFacturation(false))
      });
      next(action);
      break;
    }
    case SIGN_CONTRAT_PART: {
      const state = store.getState();
      const pdfFile = action.urlImage
      const contratId = action.contratId
      const fileName = action.fileName

      const contratNotSigned = state.modal.modalSignatureDocObject.attributes.contrat_formation.data

      if (contratNotSigned !== null) {
        axios.delete(`${URL}upload/files/${contratNotSigned.id}`,
        {
          headers: {
            Authorization:
            `Bearer ${state.login.session.jwt}`,
          }
        })
        .then((response) => {
        })
        .catch((error) => {
          console.error(error);
        });
      }

      const formData = new FormData()
      formData.append('files', pdfFile, fileName)
      formData.append("refId", contratId);
      formData.append("ref", "api::facturation-formation.facturation-formation");
      formData.append("field", "contrat_formation");

      axios.post(`${URL}upload`, formData,
      {
        headers: {
          Authorization:
          `Bearer ${state.login.session.jwt}`,
        }
      })
      .then((response) => {
        store.dispatch(fetchPutSignedFacturation(state.modal.modalSignatureDocId))
        toast(`Votre contrat a été envoyé signé à notre équipe.`, {
          position: "bottom-right",
          type: "success",
          theme:"colored",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .catch((error) => {
        console.error(error);
        store.dispatch(setFullPageLoader(false))
      });

      next(action);
      break;
    }
    case FETCH_PUT_SIGNED_FACTURATION: {
      const state = store.getState();
      const factureId = action.id

      axios.put(`${URL}facturation-formations/${factureId}`, 
      {
        data: {
          signature: true
        }
      },
      {
        headers: {
          Authorization:
          `Bearer ${state.login.session.jwt}`,
        }
      })
      .then((response) => {
        store.dispatch(fetchFactureP(response.data.data))
        store.dispatch(fetchFactureE(response.data.data))
        store.dispatch(toggleModalSignature(false, 0, '', ''))
        store.dispatch(setFullPageLoader(false))
      })
      .catch((error) => {
        console.error(error);
        store.dispatch(setFullPageLoader(false))
      });
      next(action);
      break;
    }
    case FETCH_MY_SALARIE : {
      const state = store.getState();
      const userId = state.login.userInfo.data[0].id

      const queryFilterObj = qs.stringify({
        filters: {
          entreprise: {
            id: userId
          },
        },
        headers: {
          Authorization:
          `Bearer ${state.login.session.jwt}`,
        },
        sort: ['publishedAt:desc']
        }, {
          encodeValuesOnly: true,
        });

      axios.get(`${URL}particuliers?${queryFilterObj}`)
      .then((response) => {
        store.dispatch(saveMyEntreprisePart(response.data.data))
      })
      .catch((error) => {
        console.error(error);
        store.dispatch(setFullPageLoader(false))
      });
      next(action);
      break;
    }
    default:
      next(action);
  }
};

export default formationsMiddleware;