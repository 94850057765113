import { connect } from 'react-redux'

import ModalCourseProg from '../../components/ModalCourseProg'

import { fetchThisJobOffer } from '../../actions/job'
import { openModal } from '../../actions/modal'

const mapStateToProps = (state) => ({
    formations: state.course.formations,
    activCourseId: state.course.activCourseId,
    userRole: state.login.userRole,
    templatesLms: state.lms.templatesLms,
    coursesLms: state.lms.coursesLms,
})

const mapDispatchToProps = (dispatch) => ({
    fetchThisJobOffer: (id) => {
        dispatch(fetchThisJobOffer(id));
    },
    openModal: (name) => {
        dispatch(openModal(name));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(ModalCourseProg)

