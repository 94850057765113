/**** Import des modules ****/
import React, { useState, useEffect } from 'react'

/**** Import des css ****/
import StepTwoStyled from './StepTwoStyled'


const StepTwo = ({ }) => {

  return (
    <StepTwoStyled>
        Step Two
    </StepTwoStyled>
  )
}

export default StepTwo