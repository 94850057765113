/**** Import de modules ****/
import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import { AiOutlineArrowUp } from "react-icons/ai";
import PropTypes from 'prop-types'


/**** Import d'images ****/
import logo from '../../assets/img/logo/WEFOR_COULEUR_BLANC-ai.png'

/**** Import des css ****/
import FooterStyled from './FooterStyled'

const Footer = ({isLogged, changeInputValue, fetchPostNewsletter, openModal }) => {
  return (
    <FooterStyled>
      <section className="footer-top">
        <div>
          <h3>Navigations</h3>
          <ul>
            <li><Link to="/">Accueil</Link></li>
            {!isLogged &&
              <li onClick={() => openModal("connexion")}>Connexion</li>
            }
            {isLogged &&
              <li><Link to="/account/home">Connexion</Link></li>
            }
            <li><Link to="/contact">Nous contacter</Link></li>
            <li><Link to="/legal">Mentions légales</Link></li>
            <li><Link to="/conditions-generales-de-vente">Conditions Générales de Vente</Link></li>
          </ul>
        </div>
        <div>
          <h3>S'inscrire à la newsletter</h3>
          <p>Retrouvez toutes nos offres et notre actualités en vous inscrivant à notre newsletter</p>
          <form
            className="contact-form"
            onSubmit={(event) => {
              event.preventDefault();
              fetchPostNewsletter();
            }}>
            <input 
              type="text" 
              placeholder='Entrer votre Email'
              className="newsletter-txt"
              required
              name="emailnewsletter"
              onChange={(event) => {
                changeInputValue(event.target.value, event.target.name);
              }}
            />
            <input 
              type="submit" 
              value="Envoyer"
              className="newsletter-btn"
            />
          </form>
        </div>
        <div>
        <Link to="/">
          <img src={logo} className="footer-logo" alt="logo carré wefor" />
        </Link>
          <h3 className="border-top">Nous contacter</h3>
          <h4>Téléphone</h4>
          <a href="tel:+33972575262">09 72 57 52 62</a>
          <h4>Adresse</h4>
          <p>239 Ter, Avenue de la Division Leclerc</p>
          <p>92160 Antony</p>
        </div>
      </section>
      <section className="footer-bottom">Copyright ©2022 All rights reserved</section>
    </FooterStyled>
  )
}

Footer.propTypes = {
  isLogged: PropTypes.bool.isRequired,
}

export default Footer