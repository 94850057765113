import { connect } from 'react-redux'

import ModalSignature from '../../components/ModalSignature'

import { toggleModalSignature } from '../../actions/modal'
import { signContratPart } from '../../actions/course'
import { setFullPageLoader } from '../../actions/actions'

const mapStateToProps = (state) => ({
    modalSignatureIsOpen: state.modal.modalSignatureIsOpen,
    modalSignatureDocId:  state.modal.modalSignatureDocId,
    modalSignatureDocType: state.modal.modalSignatureDocType,
    sigPad: state.modal.sigPad,
    userRole: state.login.userRole
})

const mapDispatchToProps = (dispatch) => ({
    toggleModalSignature: (bool, docId, docType, docObject) => {
        dispatch(toggleModalSignature(bool, docId, docType, docObject));
    },
    signContratPart: (contratId, urlImage, fileName) => {
        dispatch(signContratPart(contratId, urlImage, fileName));
    },
    setFullPageLoader: (bool) => {
        dispatch(setFullPageLoader(bool));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(ModalSignature)

