/**** Import de modules ****/
import React from 'react'
import Skeleton from '@mui/material/Skeleton';

/**** import des components ****/

/**** Import d'images' ****/


/**** Import des css ****/
import CourseCardLoadingListStyled from './CourseCardLoadingListStyled'

const CourseCardLoadingList = ({typeCard}) => {


  return (
    <CourseCardLoadingListStyled typeCard={typeCard}>
      <Skeleton className="left-img" variant="rectangular" />
      <div className='long-txt-wrapper'>
        <Skeleton className="skeleton-txt" variant="text" />
        <Skeleton className="skeleton-txt" variant="text" />
      </div>
      <div className='short-txt-wrapper'>
        <Skeleton className="skeleton-txt" variant="text" />
        <Skeleton className="skeleton-txt" variant="text" />
      </div>
      <div className='short-txt-wrapper'>
        <Skeleton className="skeleton-txt" variant="text" />
        <Skeleton className="skeleton-txt" variant="text" />
      </div>
      <div className='btn-wrapper'>
        <Skeleton className="skeleton-btn" variant="rectangular" />
        <Skeleton className="skeleton-btn" variant="rectangular" />
      </div>
    </CourseCardLoadingListStyled>
  )
}

CourseCardLoadingList.propTypes = {

}

export default CourseCardLoadingList