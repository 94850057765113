import styled from 'styled-components';

const JobLiStyled = styled.li`
   color: #6aaf8b;
   border: 2px solid #6aaf8b;
   background-color: #e1efe7;
   position: relative;
   border-radius: 20px;

   a {
      color: #6aaf8b;
      display: flex;
      align-items: center;
      height: 20px;
   }

   &:hover{
      background-color: rgba(106, 175, 139, 0.9);
      color: #fff;

      a {
         color: #fff;
      }
   }

   .job-submenu-wrapper {
      position: absolute;
      width: max-content;
      height: max-content;
      top: 63px;
      left: ${ props => (props.currentProfilUser === 'Entreprise' ? "-20px" : "-90px;")};
      background-color: rgba(106, 175, 139, 0.9);
      padding: 20px 0;
      border-radius: 20px;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;

      h4 {
         font-size: 22px;
         text-transform: uppercase;
         padding-bottom: 20px;
      }

      .active {
         background-color: #fff;
         color: #6aaf8b;
         border-radius: 0;
         border-radius: 20px;

         a {
            color: #6aaf8b;
         }
      }

      .column-submenu {
         width: 333px;
         box-sizing: border-box;
      }

      .column-submenu ul {
         display: flex;
         flex-direction: column;
         align-items: center;
         height: max-content;
         box-sizing: border-box;

         li { 
            width: 90%;
            height: auto !important;
            padding: 10px 5px;
            cursor: pointer;

            a {
               width: 100%;
               height: 100%;
            }
         }

         li:hover {
            background-color: #fff;
            color: #6aaf8b;
            border-radius: 0;
            border-radius: 20px;
         }
      }

      .secteurs, .domaines {
         border-right: 2px solid #fff;
      }
   }

@media only screen and (max-width: 1400px) {

  }
  
@media only screen and (max-width: 1079px) {

  }
  
@media only screen and (min-width: 0px) and (max-width: 759px) {
     
  }
`;

export default JobLiStyled;