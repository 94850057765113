/**** Import des modules ****/
import React from 'react'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { BsFillExclamationTriangleFill } from "react-icons/bs";

/**** Import d'images ****/

/**** Import des css ****/
import ModalConnexionStyled from './ModalConnexionStyled'

const ModalConnexion = ({changeIsLogged, changeValue, openModal, closeModal, changeFormModal, modalName, fetchUser, isLogged, modalMessageContent, modalMessageIsOpen, toggleModalMessage}) => {
  return (
    <ModalConnexionStyled>
      {isLogged && 
      <Navigate to='/account/home' replace={true} />
      }
      {!isLogged &&
      <>
      {modalMessageIsOpen && 
        <p className='message-important'><BsFillExclamationTriangleFill /> <span>{modalMessageContent}</span></p>
      }
      <h2 className="bold">Se connecter<span className="separator-blue"></span></h2>
      <form 
        className="account-form-comp"
        onSubmit={(event) => {
          event.preventDefault();
          fetchUser();
          openModal(modalName);
          toggleModalMessage('', false)
        }}
      >
        <label>
          Adresse Email
          <input 
            type="email" 
            name="email" 
            placeholder=""
            onChange={(event) => {
              changeValue(event.target.value, event.target.name);
            }}
          />
        </label>
        <label>
          Mot de passe
          <input 
            type="password" 
            name="password" 
            placeholder=""
            onChange={(event) => {
              changeValue(event.target.value, event.target.name);
            }}
          />
        </label>
        <label>
          <input 
            type="submit" 
            value="Connexion" 
            className="lightblue-btn"
          />
        </label>
      </form>
      <section className="link-wrapper">
        <p className="password-lost">Mot de passe oublié ?</p>
        <p 
          className="account-creation bold"
          onClick={() => changeFormModal('signIn')}
        >Créer un compte</p>
      </section>
      </>
    }
    </ModalConnexionStyled>
  )
}

ModalConnexion.propTypes = {
  openModal: PropTypes.func.isRequired,
  changeIsLogged: PropTypes.func.isRequired,
  changeValue: PropTypes.func.isRequired,
}

export default ModalConnexion

