/**** Import des modules ****/
import React, {useEffect} from 'react'
import { Link } from "react-router-dom"
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import AOS from 'aos';
import "aos/dist/aos.css"

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import BannerAccount from '../../components/Header/BannerAccount'

/**** import des images ****/
import logo from '../../assets/img/illustration/header-homme.png'

/**** Import des css ****/
import LoginStyled from './LoginStyled'
import { changeAccountStatut } from '../../actions/login'

const Login = ({changeIsLogged, changeValue, fetchUser, isLogged}) => {
  useEffect(()=> {
    AOS.init({ duration: 1000 })
  }, [])
  return (
    <LoginStyled>
      <Header bcgColor={'rgba(100,154,206,1)'}/>
      {isLogged && 
      <Navigate to='/account/home' replace={true} />
      }
      {!isLogged &&
      <>
      <section className="top-illustration">
        <div className="opacity-light-section">
        <img src={logo} alt='logo wefor'/>
          <div className="left">
            <h1 className="bold">Connexion</h1>
          </div>
        </div>
      </section>
      <main className="page-connexion">
        <h2 className="bold">Se connecter<span className="separator-blue"></span></h2>
        <form 
          className="account-form-comp"
          onSubmit={(event) => {
            event.preventDefault();
            fetchUser();
          }}
        >
          <label>
            Adresse Email
            <input 
              type="email" 
              name="email" 
              placeholder=""
              onChange={(event) => {
                changeValue(event.target.value, event.target.name);
              }}
            />
          </label>
          <label>
            Mot de pass
            <input 
              type="password" 
              name="password" 
              placeholder=""
              onChange={(event) => {
                changeValue(event.target.value, event.target.name);
              }}
            />
          </label>
          <label>
            <input 
              type="submit" 
              value="Connexion" 
              className="lightblue-btn"
            />
          </label>
        </form>
        <section className="link-wrapper">
          <Link to="/mot-de-passe-oublie" className="password-lost">Mot de pass oublié ?</Link>
          <Link to="/signin" className="account-creation bold">Créer un compte</Link>
        </section> 
      </main>
      </>
      }
      <Footer />
    </LoginStyled>
  )
}

Login.propTypes = {
  changeIsLogged: PropTypes.func.isRequired, 
  changeValue: PropTypes.func.isRequired, 
}

export default Login