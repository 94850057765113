import styled from 'styled-components';


const HeaderStyled = styled.header`
   position: fixed;
   z-index: 1001;
   width: 100%;
   top: 0;
   background-color: ${props => props.windowTop ? props.bcgColor === '#fff' ? '#fff' : '' : '#fff'};

   .wrapper-header-top {
      width: 100%;
   }

   .header-top {
      width: 100%;
      max-width: 1300px;
      margin: 0 auto;
      height: 75px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      
      .header-logo {
         height: 75px;
      }

      .menu-wrapper {
         width: 80%;
         max-width: 1200px;
         color: #6399ce;

         display: flex;
         flex-wrap: wrap;
         justify-content: end;
         align-items: center;

         .menu-picto {
            height: 45px;
         }

         ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 85%;
            max-width: 780px;

            li {
               display: flex;
               flex-wrap: wrap;
               align-items: center;
               justify-content: center;
               font-size: 18px;
               width: 33%;
               padding: 10px 0px 10px 0px;
               box-sizing: border-box;

               .phone {
                  font-size: 20px;
               }

               a {
                  font-size: 18px;
                  color: ${props => (props.comboHeaderColor ? "#6399ce" : "#6399ce")};
                  text-decoration: none;
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;
               }

               .menu-picto {
                  font-size: 25px;
                  padding-right: 10px;
               }

               .menu-picto-contact {
                  font-size: 30px;
                  padding-right: 10px;
               }
            }

            .account-li {
               transition: padding-bottom .5s;
               height: 60px;
               padding-bottom: 25px;
               cursor: pointer;
               width: 236px;
               padding-right: 10px;
               position: relative;
               border-radius: 20px;
               color: ${props => (props.comboHeaderColor ? "#6399ce" : "#6399ce")};

               &.actif-class {
                  background-color: #6399ce;
                  color: #fff;
                  padding-bottom: 0px;
                  border-bottom-left-radius: 0px;
                  border-bottom-right-radius: 0px;
               }
               
               &:hover {
                  background-color: #6399ce;
                  color: #fff;
                  padding-bottom: 0px;
               }

               div {
                  position: absolute;
                  left: 0;
                  top: 60px;
                  width: 100%;
               }
            }
         }

         div {
            img:nth-child(1) {
               padding-right: 10px;
            }
         }
      }
   }

   @media only screen and (max-width: 1400px) {
      .header-top {

         .header-logo {
            margin-left: 20px;
         }

         justify-content: space-between;
         .menu-wrapper {
            width: 80%;

            ul {
               justify-content: flex-end;
            }

            li:nth-child(1), li:nth-child(2) {
               width: 25%;
            }

            .margin-left-20 {
               margin-left: 20px;
            }
         }
      }
    }
  
    @media only screen and (max-width: 1079px) {
      .header-top {
         .menu-wrapper {

            width: 77%;
            .menu-picto {
               height: 60px;
            }
   
            ul {
               justify-content: space-evenly;
               width: 100%;

               .account-li {
                  padding: 0;
               }

               li {
                  width: 170px;

                  .menu-picto-contact, .menu-picto-phone {
                     display: none;
                  }
               }
            }
         }
      }
   }
  
    @media only screen and (min-width: 0px) and (max-width: 759px) {
      .header-top {
         .menu-wrapper {
            width: calc(100% - 120px);
            .menu-picto {

            }
   
            ul.pictos-ul {
               justify-content: flex-end;

               .mobile-none {
                  display: none;
               }

               li {

                  &.margin-left-20 {
                     display: none;
                  }
                  span.mobile-none {
                     display: none;
                     padding: 0;
                  }
               }
            }

            .account-li {

               div {
                  position: absolute;
                  
                  top: 60px;
               }
            }
         }

         .header-logo {
            height: 50px;
         }
      }
    }
`;

export default HeaderStyled;