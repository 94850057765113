import styled from 'styled-components';

const JobCardStyled = styled.article`
    width: 100%;
    color: #d47558;
    border-radius: 30px; 
    padding: 10px;
    box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
    -webkit-box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
    -moz-box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
    position: relative;
    margin: 50px 0 0 0px;
    text-align: center;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left-img {
        width: 90px;
        height: 90px;
        border-radius:${props => props.typeCard === 'jobs' ? '50%' : '10px'}
    }

    .long-txt-wrapper {
        width: calc(60% - 90px);
        display: flex;
        flex-direction: column;

        .skeleton-txt {
            width: 100%;
            font-size: 25px;
        }
    }

    .short-txt-wrapper {
        display: flex;
        flex-direction: column;
        width: 8%;

        .skeleton-txt {
            width: 100%;
            font-size: 25px;
        }
    }

    .btn-wrapper {
        display: flex;
        flex-direction: column;
        width: 100px;

        .skeleton-btn {
            width: 100%;
            height: 25px;
            border-radius: 10px;
            margin: 0 auto 5px;

        }
    }


    @media only screen and (max-width: 1400px) {
        border-radius: 30px; 
        padding: 15px;
        margin: 40px 10px 0 10px;
    }

    @media only screen and (min-width: 760px) and (max-width: 1079px) {
        .mobile-none {
            display: none;
        }
    }
  
    @media only screen and (min-width: 0px) and (max-width: 759px) {
      
    }
`;

export default JobCardStyled;