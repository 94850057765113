import { connect } from 'react-redux'

import Formation from '../../pages/Formation'

import { changeActivCourseId, fetchFormations, fetchGetOneProgram } from '../../actions/course'
import { setFilter } from '../../actions/menu'
import { openModal } from '../../actions/modal'

const mapStateToProps = (state) => ({
  formations: state.course.formations,
  activCourseId: state.course.activCourseId,
  loadFormation: state.course.loadFormation,
  secteurs: state.menu.secteursData,
  domaines: state.menu.domainesData,
  secteurCourseFilter: state.menu.secteurCourseFilter,
  domaineCourseFilter: state.menu.domaineCourseFilter,
  dureeCourseFilter: state.menu.dureeCourseFilter,
  formationCourseFilter: state.menu.formationCourseFilter,
  userRole: state.login.userRole,
  currentProfilUser: state.menu.currentProfilUser,
  templatesLms: state.lms.templatesLms,
  coursesLms: state.lms.coursesLms,
  isLogged: state.login.isLogged,
  jobFiches: state.job.jobFiches,
  formationsPagination: state.course.formationsPagination
})

const mapDispatchToProps = (dispatch) => ({
    openModal: (name) => {
        dispatch(openModal(name));
    },
    fetchFormations: (paramObj) => {
      dispatch(fetchFormations(paramObj));
    },
    setFilter: (name, title) => {
      dispatch(setFilter(name, title));
    },
    changeActivCourseId: (id) => {
      dispatch(changeActivCourseId(id));
    },
    fetchGetOneProgram: () => {
      dispatch(fetchGetOneProgram());
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(Formation)