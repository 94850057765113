import styled from 'styled-components';

const FooterStyled = styled.footer`
    color: #fff;
    background-color: #202c3c;

    .footer-top {
        max-width: 1200px;
        margin: 0 auto;
        padding: 30px 0px;

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        div:nth-child(2) {
            width: 30%;
        }

        div {
            width: 20%;

            .border-top {
                border-top: 1px solid #fff;
                padding-top: 20px;
                width: 160px;
            }

            h3 {
                font-size: 22px;
                margin-bottom: 20px;
                color: #fff;
                font-weight: bold;
            }

            li {
                padding-bottom: 10px;
            }

            p {
                line-height: 26px;
            }

            a {
                
                display: inline-block;
                color: #fff;
                text-decoration: none;
            }

            h4 {
                margin-bottom: 10px;
                margin-top: 20px;
                color: #fff;
                font-weight: bold;
            }

            form {
                margin-top: 20px;
                

                .newsletter-txt {
                    padding: 10px;
                    border: 1px solid #fff;
                    border-right: 0px;
                    background-color: #202c3c;

                    &::placeholder {
                        color: #fff;
                    }

                    &:focus {
                        outline: none;
                        color: #fff;
                    }
                }

                .newsletter-btn {
                    border: 0;
                    border: 1px solid #fff;
                    padding: 10px;
                    background: #6399ce;
                    color: #fff;
                }
            }

            .footer-logo {
                height: 80px;
                margin: -10px 0 0px;
            }
        }
    }

    .footer-bottom {
        width: 100%;
        text-align: center;
        padding: 10px;
    }

    @media only screen and (max-width: 1400px) {
        .footer-top {
            padding: 30px 30px;
        }
      }
    
      @media only screen and (max-width: 1079px) {
        .footer-top {
            flex-direction: column;
            align-items: center;

            div {
                width: 100% !important;
                text-align: center;
                margin-top: 50px;

                h3 {
                    margin: 0 auto 20px;
                }
        }
      }
    
      @media only screen and (min-width: 0px) and (max-width: 759px) {
        .footer-top {
            
        }
      }
`;

export default FooterStyled;