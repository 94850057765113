import { connect } from 'react-redux'

import FullPageLoader from '../../components/FullPageLoader'


const mapStateToProps = (state) => ({
    fullPageLoader: state.actions.fullPageLoader,
})



const mapDispatchToProps = (dispatch) => ({
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(FullPageLoader)