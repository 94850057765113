import { connect } from 'react-redux'

import AccountForm from '../../../components/Header/AccountForm'

import { 
    changeIsLogged,
    changeValue,
    fetchUser,
    saveUser,
    disconnectUser,
    setLoadConnexion
} from '../../../actions/login'

import { changeFormModal } from '../../../actions/modal'

import { setIsShowAccountFormMenu } from '../../../actions/menu'

import { setFullPageLoaderConnexion } from '../../../actions/actions'

const mapStateToProps = (state) => ({
    prenom: state.login.prenom,
    isLogged: state.login.isLogged,
    currentProfilUser: state.menu.currentProfilUser,
    userRole: state.login.userRole,
    loadConnexion: state.login.loadConnexion,
    redirect: state.login.redirect
})

const mapDispatchToProps = (dispatch) => ({
    changeIsLogged: (bool) => {
        dispatch(changeIsLogged(bool));
    },
    changeValue: (value, name) => {
        dispatch(changeValue(value, name));
      },
    fetchUser: (data) => {
    dispatch(fetchUser(data));
    },
    saveUser: (data) => {
        dispatch(saveUser(data));
      },
    setIsShowAccountFormMenu: (bool) => {
        dispatch(setIsShowAccountFormMenu(bool));
    },
    disconnectUser: () => {
        dispatch(disconnectUser());
    },
    setLoadConnexion: (bool) => {
        dispatch(setLoadConnexion(bool));
    },
    changeFormModal: (string) => {
        dispatch(changeFormModal(string));
    },
    setFullPageLoaderConnexion: (bool) => {
        dispatch(setFullPageLoaderConnexion(bool));
    }
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(AccountForm)