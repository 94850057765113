import styled from 'styled-components';

const ModalConnexionStyled = styled.div`
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 50px 10px;
    box-sizing: border-box;

   .section {
       width: 100%;
   }


    h2 {
        font-size: 35px;
        width: auto;
        display: inline;
        margin: 0 auto 30px;
        position: relative;
        color: #649ACE;
        padding-bottom: 10px;
        text-transform: uppercase;

        .separator-blue {
            background-color: #649ACE;
            height: 4px;
            width: 80px;
            margin: 10px 0 0 auto;
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }

    .message-important {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        max-width: 500px;
        margin: 0 auto 50px;
        background-color: #fbecec;
        padding: 10px;
        border-radius: 5px;

        span {
            margin-left: 5px;
            text-align: justify;
            line-height: 20px;
        }

        svg {
            color: #dc4a46;
            font-size: 40px;

            margin-bottom: 10px;
        }
    }

    .account-form-comp {
        width: 500px;
        display: flex;
        flex-direction: column;
        font-size: 18px;
        color: #707070;

        label {
            width: 100%;
        }

        input {
            width: 100%;
            border: 0;
            border-radius: 10px;
            background-color: #F2F2F2;
            padding: 20px;
            margin: 5px 0px 10px;
            box-sizing: border-box;
        }

        .lightblue-btn {
            color: #fff;
            background-color: #649ACE;
            border: 2px solid #87B7E5;
            padding: 15px;
            font-size: 18px;
            box-sizing: border-box;
            cursor: pointer;

            &:hover {
                color: #649ACE;
                background-color: #fff;
            }
        }
    }

    .link-wrapper {
        color: #649ACE;
        text-align: center;
        display: flex;
        text-decoration: underline;
        flex-direction: column;

        p {
            color: #649ACE;
            font-size: 14px;
            padding: 0;
            cursor: pointer;

            &.account-creation {
                font-size: 20px;
                margin-top: 20px;
            }
        }
    }

    @media only screen and (min-width: 0px) and (max-width: 759px) {

        .account-form-comp {
            padding: 30px 0;
            margin: 0;
            max-width: 100%;
        }
    }
`;

export default ModalConnexionStyled;