import styled from 'styled-components';

const AccountCourseStyled = styled.div`
    .page-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;
        max-width: 1300px;
        margin: 175px auto 0;

        .right-wrapper {
            width: calc(96% - 250px);
            display: flex;
            align-items: center;

            flex-direction: column;
            margin: 20px auto;

            .secteur-choice, .test-choice {
                width: 100%;
                padding: 0px 0 0px;
                box-sizing: border-box;
                position: relative;
                border-radius: 15px;
                box-shadow: 0px 1px 10px #00000029;
                border-radius: 10px;
    
                &:nth-child(1) {
                    margin-bottom: 40px;
                    font-size: 16px;
                }
    
                .drop-down-1 {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 20px;
                    cursor: pointer;
                    font-size: 18px;
    
                    h4, .info-dyna {
                        width: calc(50% - 25px);
                        text-align: left;
                    }
    
                    .arrow-wrapper {
                        max-height: 20px;
                        .arrow-down {
                            font-size: 50px;
                            color: #D47558;
                            margin-top: -15px;
                            margin-right: -20px;
                        }
                    }
                }
    
                h4 {
                    color: #D47558;
                    text-align: center;
                    border-top-left-radius: 15px;
                    border-top-right-radius: 15px;
                }
    
                .formations-wrapper {
                    div {
                        color: #4d4d4d;
                        display: flex;
                        flex-wrap: wrap;
                        align-items:center;
                        justify-content: space-between;
                        font-size: 16px;
                        border-top: 1px solid #f8e8e3;
                        cursor: pointer;
    
                        span {
                            padding-right: 10px;
                        }
    
                        &:hover {
                            background-color: #f8e8e3;
                        }
                    }
                }
    
                .list-paragraphe {
                    text-align: left;
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    color: #D47558;
    
                    a {
                        color: #D47558;
                        margin-left: 20px;
                        transition: all .3s ease;
    
                        &:hover {
                            opacity: .7;
                        }
                    }
    
                    svg {
                        color: #D47558;
                        margin:0 10px 0 0;
                    }
                }
            }

            .wrapper-searchpart {
                width: 100%;
                padding: 50px 0px;
                background-color: #6399ce;

                .disclaimer {
                    text-align: center;
                }

                h4 {
                    color: #fff;
                    font-size: 30px;
                    text-align: center;
                }

                .particulier-founded {
                    margin: 0 auto;
                    width: 96%;
                    max-width: 600px;
                    text-align: center;
                    background-color: #202c3c;
                    color: #fff;
                    padding: 15px 20px;
                    box-sizing: border-box;
                    border-radius: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    svg {
                        color: green;
                        background-color: #fff;
                        border-radius: 50%;
                        font-size: 30px;
                    }
                }

                form {
                    text-align: center;
                }
                input {
                    width: 96%;
                    max-width: 600px;
                    border: 0;
                    background-color: #fff;
                    margin: 20px auto;
                    padding: 20px 20px;
                    box-sizing: border-box;
                    border-radius: 15px;
                }
            }

            .btn-formation {
                width: 250px;
                margin: 10px auto;
                display: block;
                padding: 10px;
                border: 1px solid #d47558;
                border-radius: 10px;
                color: #fff;
                background-color: #d47558;
                cursor: pointer;
                font-size: 16px;
                text-align: center;

                &:hover {
                    background-color: #fff;
                    color: #d47558;
                }
            }
        }

        .wrapper-salarie {
            width: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            .salarie-name {
                font-size: 30px;
                padding-bottom: 5px;
                border-bottom: 1px solid #d47558;
                margin-bottom: 20px;
                margin-top: 30px;
            }

            .card-wrapper {
                width: 96%;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
            }
        }

        h2 {
            font-size: 30px;
            display: inline-block;
            margin: 0 auto 50px;
            text-transform: uppercase;
            color: #202D3C;
            padding-top: 50px;

            &.padding-top-title {
                padding-top: 50px;
            }

            span {
                position: relative;
            }

            .separator-blue {
                background-color: #6399ce;
                height: 0px;
                width: 240px;
                margin: 10px 0 0 auto;
                position: absolute;
                right: 0;
            }
        }
    }

    @media only screen and (max-width: 1400px) {
        .page-wrapper {
            .right-wrapper {
                width: calc(96% - 250px);
    
                h1 {
                    font-size: 45px;
                }
    
                h2 {
                    font-size: 35px;
                }
            }
        }
    }
    
    @media only screen and (max-width: 1079px) {
        .page-wrapper {
            margin: 150px auto 0;
            .right-wrapper {
                width: calc(96% - 250px);
    
                h1 {
                    font-size: 45px;
                }
    
                h2 {
                    font-size: 35px;
                }

                .separator-blue {
                    width: 100px;
                }
            }
        }
    }
    
    @media only screen and (min-width: 0px) and (max-width: 759px) {

        .page-wrapper {
            .right-wrapper {
                width: calc(96%);

                .wrapper-searchpart {
                    .particulier-founded {
                        flex-direction: column;
                        align-items: center;
                        span {
                            margin: 5px 0;
                        }
                    }
                }
    
                h1 {
                    font-size: 45px;
                }
    
                h2 {
                    font-size: 30px;
                    text-align: center;s
                }

                .separator-blue {
                    width: 50px;
                    margin: 10px auto;
                    position: static;
                }
            }
        }
    }
`;

export default AccountCourseStyled;