export const FETCH_QUESTION_OCEAN = 'FETCH_QUESTION_OCEAN'
export const FETCH_REPONSE_OCEAN = 'FETCH_REPONSE_OCEAN'
export const SAVE_QUESTION_OCEAN = 'SAVE_QUESTION_OCEAN'
export const SAVE_REPONSE_OCEAN = 'SAVE_REPONSE_OCEAN'
export const CHANGE_VALUE_OCEAN_Q = 'CHANGE_VALUE_OCEAN_Q'
export const FETCH_POST_OCEAN_RESULT = 'FETCH_POST_OCEAN_RESULT'
export const FETCH_CATEGORIE_OCEAN = 'FETCH_CATEGORIE_OCEAN'
export const SAVE_CATEGORIE_OCEAN = 'SAVE_CATEGORIE_OCEAN'
export const CHANGE_MANAGER_NAME = 'CHANGE_MANAGER_NAME'
export const SET_FULL_PAGE_LOADER_OCEAN = 'SET_FULL_PAGE_LOADER_OCEAN'
export const FETCH_GET_MY_TESTS_OCEAN = 'FETCH_GET_MY_TESTS_OCEAN'
export const SAVE_MY_TESTS_OCEAN = 'SAVE_MY_TESTS_OCEAN'

export const fetchQuestionOcean = () => ({
    type: FETCH_QUESTION_OCEAN,
})

export const fetchReponseOcean = () => ({
    type: FETCH_REPONSE_OCEAN,
})

export const saveQuestionOcean = (data) => ({
    type: SAVE_QUESTION_OCEAN,
    data
})

export const saveReponseOcean = (data) => ({
    type: SAVE_REPONSE_OCEAN,
    data
})

export const changeValueOceanQ = (value, index, score, categorie) => ({
    type: CHANGE_VALUE_OCEAN_Q,
    value,
    index,
    score,
    categorie
})

export const fetchPostOceanResult = () => ({
    type: FETCH_POST_OCEAN_RESULT,
})

export const fetchCategorieOcean = () => ({
    type: FETCH_CATEGORIE_OCEAN,
})

export const saveCategorieOcean = (data) => ({
    type: SAVE_CATEGORIE_OCEAN,
    data
})

export const changeManagerName = (name, value) => ({
    type: CHANGE_MANAGER_NAME,
    name, value
})

export const setFullPageLoaderOcean = (bool) => ({
    type: SET_FULL_PAGE_LOADER_OCEAN,
    bool
})

export const fetchGetMyTestsOcean = (id) => ({
    type: FETCH_GET_MY_TESTS_OCEAN,
    id
})

export const saveMyTestsOcean = (data) => ({
    type: SAVE_MY_TESTS_OCEAN,
    data
})