/**** Import des modules ****/
import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { PDFDocument, StandardFonts } from 'pdf-lib'
import { saveAs } from 'file-saver'
import PropTypes from 'prop-types'
import {AiOutlineClose, AiOutlineRight, AiOutlineLeft} from "react-icons/ai"
import SignatureCanvas from 'react-signature-canvas'
import TextField from '@mui/material/TextField';
import { toast } from 'react-toastify';
import { Document, Page, pdfjs, Text } from "react-pdf";


/**** Import des css ****/
import ModalConventionStyled from './ModalConventionStyled'

/**** ****/
import pdfContrat from '../../assets/pdf/convention-recrutement.pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const ModalConvention = ({toggleModalConvention, modalConventionEntrepriseId, modalConventionDocType, modalConventionIsOpen, sigPad, signConvention, setFullPageLoader, userRole, userInfo, session}) => {

  const [files, setFiles] = useState(false)
  const [type, setType] = useState('')
  const [villeRcs, setVilleRcs] = useState('')
  const [numRcs, setNumRcs] = useState('')
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [dayDo, setDayDo] = useState('');

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          toggleModalConvention(false, 0, '')
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const handlePreviousPage = () => {
    
    if(pageNumber === 1) {
      setPageNumber(1)
    } else {
      let number = pageNumber - 1
      setPageNumber(number)
    }
  }

  const handleNextPage = () => {
    if(numPages === pageNumber) {
      setPageNumber(numPages)
    } else {
      let number = pageNumber + 1
      setPageNumber(number)
    }
  }

  const savePdf = (url, name) => {
    saveAs(
      `https://www.master-7rqtwti-h5b47fjo5ugck.ovhcloud-fr-1.webpaas.ovh.net${url}`,
      `${name}`
    );
  };

  const Download = (arrayBuffer, type, fileName) => {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    const blob = new Blob([arrayBuffer], { type: type });
    const url = URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  const date = new Date()

  async function signContratPartFront(entrepriseId, imgUrl) {
    const URLSITE = pdfContrat

    const response = await fetch(URLSITE)
    const jpgImageBytes = await fetch(imgUrl)
    const arrayBuffer = await response.arrayBuffer();
    const arrayBufferImg = await jpgImageBytes.arrayBuffer();
  
    const pdfDoc = await PDFDocument.load(arrayBuffer)
    const jpgImage = await pdfDoc.embedPng(arrayBufferImg);

    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
  
    const pages = pdfDoc.getPages();
    const wantedPage = pages[1];
    const pageOne = pages[0];

    const jpgDims = jpgImage.scale(0.5)

    // const { width, height } = wantedPage.getSize();

    const { width, height } = pageOne.getSize();

    wantedPage.drawImage(jpgImage, {
      x: 65,
      y: 500,
      width: jpgDims.width,
      height: jpgDims.height,
    });

    pageOne.drawText(`${userInfo.data[0].attributes.Nom_entreprise} constituée sous forme d'une société ${type} est immatriculée au RCS de ${villeRcs}`, {
      x: 72,
      y: height - 144,
      size: 10,
      font: helveticaFont,
    })

    pageOne.drawText(`sous le numéro ${numRcs}.`, {
      x: 72,
      y: height - 155,
      size: 10,
      font: helveticaFont,
    })

    pageOne.drawText(`${userInfo.data[0].attributes.adresse}`, {
      x: 72,
      y: height - 195,
      size: 10,
      font: helveticaFont,
    })

    wantedPage.drawText(`Fait à Antony le ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`, {
      x: 72,
      y: height - 235,
      size: 10,
      font: helveticaFont,
    })

    wantedPage.drawText(`${session.user.prenom} ${session.user.nom}`, {
      x: 149,
      y: height - 286,
      size: 10,
      font: helveticaFont,
    })
  
    const pdfFile = await pdfDoc.save();

    Download(pdfFile, 'application/pdf', `convention-recrutement-${entrepriseId}-signed.pdf`);

    const blob = new Blob([pdfFile], { type: 'application/pdf' });
    signConvention(modalConventionEntrepriseId, blob, `convention-recrutement-${entrepriseId}-signed.pdf`)
  }

  return (
    <ModalConventionStyled>
    <section className="modal-content-container">
      <div className="page-wrapper-message" ref={wrapperRef}>
        <div className="scroll-zone">
        <p>Merci de remplir les information ci-dessous avant de signer le contrat :</p>
        <div className='textfield-wrapper'>
        <TextField 
          label="Type d'entreprise*" 
          placeholder='(SARL, SAS etc...)' 
          variant="filled" 
          value={type} 
          onChange={(event) => setType(event.target.value)}
        />
        <TextField 
          label="Ville du Rcs*" 
          variant="filled" 
          value={villeRcs} 
          onChange={(event) => setVilleRcs(event.target.value)}
        />
        <TextField 
          label="Numéro du Rcs*" 
          variant="filled" 
          value={numRcs} 
          onChange={(event) => setNumRcs(event.target.value)}
        />
        </div>
        <div className="pdf-wrapper">
          <Document file={pdfContrat} onLoadSuccess={onDocumentLoadSuccess} onLoadError={console.error}>
            <Page pageNumber={pageNumber} className="page-wrapper-txt">
            {pageNumber === 1 &&
              <>
                <p className="first-txt">{`${userInfo.data[0].attributes.Nom_entreprise} constituée sous forme d'une société ${type} est immatriculée au RCS ${villeRcs} sous le numéro ${numRcs}.`}</p>
                <p className="second-txt">{`${userInfo.data[0].attributes.adresse}`}</p>
              </>
            }
            {pageNumber === 2 &&
              <>
                <p className="third-txt">{`Fait à Antony le ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`}</p>
                <p className="fourth-txt">{`${session.user.prenom} ${session.user.nom}`}</p>
              </>
            }
            </Page>
          </Document>
          <div className="page-controller">
            <AiOutlineLeft onClick={handlePreviousPage}/>
            Page {pageNumber} / {numPages}
            <AiOutlineRight onClick={handleNextPage}/>
          </div>
        </div>
        <h3>Utilisez votre souris pour signer votre convention.</h3>
      <SignatureCanvas penColor='black'canvasProps={{width: 300, height: 100, className: 'sigCanvas'}} ref={(ref) => { sigPad = ref }}/>
      <div className="btn-action-wrapper">
        <div className="btn-redo" onClick={() => sigPad.clear()}>Recommencer</div>
        <div className="btn-send" onClick={() => {
          if(type !== '' && villeRcs !== '' && numRcs !== '' && sigPad.isEmpty() === false) {
          setFullPageLoader(true)
          signContratPartFront(modalConventionEntrepriseId, sigPad.toDataURL())
          } else {
            toast(`Merci de remplir tout les champs`, {
              position: "bottom-right",
              type: "error",
              theme:"colored",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            })
          }
        }}>Signer définitivement</div>
      </div>
      <main>
      <div className="icon-wrapper">
            <AiOutlineClose 
            className="close-icon"
            onClick={() => toggleModalConvention(false, 0, '', '')}
            />
      </div>
      </main>
      </div>
      </div>
    </section>
    </ModalConventionStyled>
  )
}

ModalConvention.propTypes = {

}

export default ModalConvention