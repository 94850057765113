import { connect } from 'react-redux'

import JobOnlineCard from '../../components/JobOnlineCard'
import { changeActivJobId, fetchDeleteJobOffer, setSelectionJobOffer, changeValueNewJob, fetchPutJobOffer, changeValueModifJob, setFullPageLoaderJobOfferAction, fetchJobOffer } from '../../actions/job'

const mapStateToProps = (state) => ({
  jobFiches: state.job.jobFiches,
  secteursData: state.menu.secteursData,
  userInfo: state.login.userInfo,
  isLogged: state.login.isLogged,
  domainesData: state.menu.domainesData,
  secteurJob: state.job.secteurJob,
  domaineJob: state.job.domaineJob,
  ficheJob: state.job.ficheJob,
  jobsAccountLoading: state.job.jobsAccountLoading,
  myTestsOcean: state.ocean.myTestsOcean,
  descriptifjobPut: state.job.descriptifjobPut,
  missionjobPut: state.job.missionjobPut,
  profiljobPut: state.job.profiljobPut,
  jobsEntreprise: state.job.jobsEntreprise,
  secteurJobPut: state.job.secteurJobPut
})

const mapDispatchToProps = (dispatch) => ({
    changeActivJobId: (id) => {
        dispatch(changeActivJobId(id));
    },
    fetchDeleteJobOffer: (id) => {
      dispatch(fetchDeleteJobOffer(id));
    },
    setSelectionJobOffer: (data) => {
      dispatch(setSelectionJobOffer(data));
    },
    changeValueNewJob: (value, name) => {
      dispatch(changeValueNewJob(value, name));
    },
    fetchPutJobOffer: (id, nomOfManager, prenomOfManager) => {
      dispatch(fetchPutJobOffer(id, nomOfManager, prenomOfManager));
    },
    changeValueModifJob: (value, name) => {
      dispatch(changeValueModifJob(value, name));
    },
    setFullPageLoaderJobOfferAction: (bool) => {
      dispatch(setFullPageLoaderJobOfferAction(bool));
    },
    fetchJobOffer: (id) => {
      dispatch(fetchJobOffer(id));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(JobOnlineCard)