/**** Import de modules ****/
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { saveAs } from 'file-saver'
import { MdDownloadForOffline } from "react-icons/md"
import { RiBallPenFill } from "react-icons/ri";

import urlBackEndSave from '../../assets/js/urlBackEndSave'

/**** import des components ****/
import ModalJobFiche from '../../containers/ModalJobFiche';

/**** Import d'images' ****/


/**** Import des css ****/
import ConventionJobStyled from './ConventionJobStyled'

const ConventionJob = ({factureP, factureE, userRole, toggleModalConvention, userInfo}) => {

  const savePdf = (url, name) => {
    saveAs(
      `${urlBackEndSave}${url}`,
      `${name}`
    );
  };

  const convention = userInfo.data[0].attributes.convention_signed
  const conventionObj = userInfo.data[0].attributes.convention.data
  return (
    <ConventionJobStyled>
      {convention ? 
      <section className="signed-section">
        <div className='bottom'>
          <button 
            className="dl-btn"
            onClick={() => savePdf(conventionObj.attributes.url, conventionObj.attributes.name)}
          >
            <RiBallPenFill />
            Télécharger ma convention de recrutement
          </button> 
        </div>
      </section>

        :
        <section className="not-signed-section">
          <div className='top'>
            Signer notre convention de recrutement vous permettera d'acceder à des fonctionnalitées supplémentaires sur notre application:
            <ul>
              <li>Poster des offres d'emploi</li>
              <li>Acceder aux profils des candidats postulant avec leurs formations et resultats</li>
              <li>Comparer les personnalitées de vos managers avec celui des postulants</li>
              <li>Recruter des collaborateurs</li>
            </ul>
          </div>
          <div className='bottom'>
          <button 
            className="dl-btn"
            onClick={() => toggleModalConvention(true, userInfo.data[0].id, 'convention')}
          >
            <RiBallPenFill />
            Signer ma convention de recrutement
          </button>  
          </div>
        </section>
      }
    </ConventionJobStyled>
  )
}

ConventionJob.propTypes = {
}

export default ConventionJob