import styled from 'styled-components';

const AccountHomeStyled = styled.div`
.page-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    max-width: 1300px;
    margin: 175px auto 0;

    .right-wrapper {
        width: calc(100% - 250px);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .skeleton-section {
            display: flex;
            width: 96%;
            margin: 50px auto 0;
            flex-wrap: wrap;
            justify-content: space-between;

            .title-sk {
                width: 100%;
                border-radius: 10px;
                margin-bottom: 40px;
            }

            .btn-sk {
                width: 100%;
                border-radius: 10px;
                margin-bottom: 40px;
            }
        }

        .features-wrapper {
            width: 96%;
            margin: 0px auto 50px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;

            h2 {
                font-size: 30px;
                text-align: center;
                margin: 50px 0;
            }

            .course-wrapper {
                width: 100%;
                border-radius: 15px;
                padding: 20px 0px 0px;
                box-sizing: border-box;
                display: flex;
                flex-wrap: wrap;
                color: #4d4d4d;

                h3 {
                    text-align: center;
                    background-color: #D47558;
                    color: #fff;
                    font-size: 18px;
                    width: 100%;
                    margin: 0px 0 30px;
                    padding: 15px;
                    border-radius: 10px;
                    box-shadow: 0px 1px 10px #00000029;
                }

                .course-count {
                    display:flex;
                    flex-wrap: wrap;
                    width: 100%;
                    justify-content: space-between;
                }

                .demandes, .en-cours, .termine {
                    width: 100%;
                    padding: 0px 0 0px;
                    box-sizing: border-box;
                    position: relative;
                    border-radius: 15px;
                    box-shadow: 0px 1px 10px #00000029;
                    border-radius: 10px;

                    &:nth-child(1) {
                        margin-bottom: 40px;
                        font-size: 16px;
                    }

                    .drop-down-1 {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 20px;
                        cursor: pointer;
                        font-size: 18px;

                        h4, .info-dyna {
                            width: calc(50% - 25px);
                            text-align: left;
                        }

                        .arrow-wrapper {
                            max-height: 20px;
                            .arrow-down {
                                font-size: 50px;
                                color: #D47558;
                                margin-top: -15px;
                                margin-right: -20px;
                            }
                        }
                    }

                    h4 {
                        color: #D47558;
                        text-align: center;
                        border-top-left-radius: 15px;
                        border-top-right-radius: 15px;
                    }

                    .link-cards {
                        margin: 0px 0px;
                        text-align: center;
                        background-color: #fff;
                        transition: font-size .2s ease;
                        cursor: pointer;
                        color: #D47558;

                        p {
                            height: 30px;
                            margin: 50px 0 0 0px;
                        }

                        &:hover {
                            font-size: 17px;
                        }
                    }

                    .formations-wrapper {
                        a {
                            color: #4d4d4d;
                            display: flex;
                            flex-wrap: wrap;
                            align-items:center;
                            justify-content: space-between;
                            font-size: 16px;
                            border-top: 1px solid #f8e8e3;
                            cursor: pointer;

                            span {
                                padding-right: 10px;
                            }

                            &:hover {
                                background-color: #f8e8e3;
                            }
                        }
                    }

                    .list-paragraphe {
                        text-align: left;
                        padding: 10px;
                        display: flex;
                        align-items: center;
                        text-transform: capitalize;

                        a {
                            color: #D47558;
                            margin-left: 20px;
                            transition: all .3s ease;

                            &:hover {
                                opacity: .7;
                            }
                        }

                        svg {
                            color: #D47558;
                            margin:0 10px 0 0;
                        }
                    }

                    .separator {
                        margin: 0 auto;
                        font-size: 30px;
                        color: #D47558;
                        width: 100%;
                    }
                }

                .link-course {
                    margin: 0 auto;
                    width: 35%;
                }

                .picto-wrapper {
                    margin-top: 20px;
                    background-color: #D47558;
                    color: #fff;
                    display: flex;
                    flex-wrap: wrap;
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    border-bottom-left-radius: 15px;
                    border-bottom-right-radius: 15px;

                    a {
                        width: 100%;
                        padding: 10px 0;
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: all .2s ease;
                        color: #fff;
                        border-bottom-left-radius: 15px;
                        border-bottom-right-radius: 15px;

                        div {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        &.single {
                            width: 100%;
                        }

                        &:hover {
                            background-color: #dc9079;
                            cursor: pointer;
                        }

                         svg {
                            margin-right: 10px;
                         }
                    }
                }

                button {
                    margin: 0 auto;
                    width: 100%;
                    margin: 50px 0 30px;
                    padding: 10px 0;
                    border-radius: 15px;
                    border: 0;
                    background-color: #D47558;
                    color:  #faf1ee;
                    transition: all .2s ease;

                    &:hover {
                        cursor: pointer;
                        opacity: .8;
                    }
                }
            }

            .job-wrapper {
                width: 100%;
                border-radius: 15px;
                padding: 20px 0 0 0;
                box-sizing: border-box;
                display: flex;
                flex-wrap: wrap;
                color: #4d4d4d;
                margin-top: 20px;

                h3 {
                    text-align: center;
                    background-color: #6AAF8B;
                    color: #fff;
                    font-size: 18px;
                    width: 100%;
                    margin: 20px 0 30px;
                    padding: 15px;
                    border-radius: 10px;
                    box-shadow: 0px 1px 10px #00000029;
                }

                .job-count {
                    display:flex;
                    flex-wrap: wrap;
                    width: 100%;
                    justify-content: space-between;
                }

                .offres, .test, .secteurs {
                    width: 100%;
                    box-sizing: border-box;
                    position: relative;
                    border-radius: 15px;
                    box-shadow: 0px 1px 10px #00000029;
                    border-radius: 10px;
                    font-size: 18px;

                    &:nth-child(1) {
                        margin-bottom: 40px;
                        font-size: 18px;
                    }

                    h4 {
                        text-align: center;
                        color: #6aaf8b;
                    }

                    .drop-down-2 {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 20px;
                        cursor: pointer;
                        font-size: 18px;

                        h4, .info-dyna {
                            width: calc(50% - 25px);
                            text-align: left;
                        }

                        .arrow-wrapper {
                            max-height: 20px;
                            .arrow-down {
                                font-size: 50px;
                                color: #6aaf8b;
                                margin-top: -15px;
                                margin-right: -20px;
                            }
                            .plus {
                                color: #6aaf8b;
                                margin-right: -5px;
                            }
                        }
                    }

                    .number-circle {
                        margin: 10px auto 30px;
                        height: 50px;
                        width: 50px;
                        font-size: 30px;
                        border-radius: 50%;
                        background-color: #6aaf8b;
                        color: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .score-wrapper {
                        width: 98%;
                        margin: 20px auto;
                        background-color: #EAF3EE ;
                        padding: 10px;
                        border-radius: 10px;

                        h5 {
                            font-size: 18px;
                            padding-bottom: 10px;
                            text-align: center;
                        }

                        div {
                            display: flex;
                            align-items: center;

                            .score-recrutement {
                                font-size: 20px;
                                margin: 0 10px;
                                padding: 10px;
                                background-color: #6AAF8B;
                                color: #fff;
                                border-radius: 10px;
                            } 

                            .temps-recrutement {
                                font-size: 20px;
                                margin: 0 10px;
                                padding: 10px;
                                background-color: #fff;
                                color: #6AAF8B;
                                border-radius: 10px;
                            } 
                        }

                        .test-btn {
                            margin: 10px auto;
                            padding: 10px 20px;
                            border-radius: 15px;
                            border: 0;
                            background-color: #6AAF8B;
                            color:  #F0F7F3;
                            display: block;
                            transition: all .2s ease;

                            &:hover {
                                cursor: pointer;
                                opacity: .8;
                            }
                        }

                        svg {
                            color: #6AAF8B;
                            margin-right: 10px;
                        }
                    }

                    .link-test {
                        margin: 0 auto;
                        width: 35%;
    
                        button {
                            margin: 0 auto;
                            margin: 10px;
                            padding: 10px 20px;
                            border-radius: 15px;
                            border: 0;
                            background-color: #6AAF8B;
                            color:  #F0F7F3;
                            transition: all .2s ease;
        
                            &:hover {
                                cursor: pointer;
                                opacity: .8;
                            }
                        }
                    }

                    .formations-wrapper {
                        a {
                            color: #4d4d4d;
                            display: flex;
                            flex-wrap: wrap;
                            align-items:center;
                            justify-content: space-between;
                            font-size: 16px;
                            border-top: 1px solid #e1efe7;
                            cursor: pointer;

                            span {
                                padding-right: 10px;
                            }

                            &:hover {
                                background-color: #e1efe7;
                            }
                        }
                    }

                    .list-paragraphe {
                        text-align: left;
                        padding: 10px;
                        display: flex;
                        align-items: center;
                        text-transform: capitalize;

                        a {
                            color: #6aaf8b;
                            margin-left: 20px;
                            transition: all .3s ease;

                            &:hover {
                                opacity: .7;
                            }
                        }

                        svg {
                            color: #6aaf8b;
                            margin:0 10px 0 0;
                        }
                    }

                    .result-txt-wrapper {

                        text-align: left;

                        h3 {
                            text-align: left;
                            font-size: 16px;
                            color: #4d4d4d;
                            margin: 10px 0 10px;
                            padding: 0 10px;
                            display: flex;
                            justify-content: space-between;
                            box-sizing: border-box;
                            background-color: #fff;
                            box-shadow: none;
                        }

                        div {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                            border-top: 1px solid #e1efe7;
                            cursor: pointer;

                            &:hover {
                                background-color: #e1efe7;
                            }
                            p {
                                display: flex;
                                font-size: 16px;
                                padding: 10px;

                                svg {
                                    color: #6aaf8b;
                                    margin-right: 10px;
                                }
                            }
                            span {
                                font-size: 16px;
                                padding-right: 10px;
                            }
                        }
                    }

                    .picto-wrapper {
                        margin-top: 20px;
                        background-color: #6aaf8b;
                        color: #fff;
                        display: flex;
                        flex-wrap: wrap;
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        border-bottom-left-radius: 15px;
                        border-bottom-right-radius: 15px;
    
                        a {
                            width: 50%;
                            padding: 10px 0;
                            box-sizing: border-box;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            transition: all .2s ease;
                            color: #fff;
                            border-bottom-left-radius: 15px;
                            border-bottom-right-radius: 15px;
    
                            div {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
    
                            &.single {
                                width: 100%;
                            }
    
                            &:hover {
                                background-color: #96C7AD;
                                cursor: pointer;
                            }
    
                             svg {
                                margin-right: 10px;
                             }
                        }
                    }
                }

                .test-link-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                }

                .link-job {
                    margin: 0 auto;
                    width: 35%;

                    button {
                        margin: 0 auto;
                        width: 100%;
                        margin: 50px 0 30px;
                        padding: 10px 0;
                        border-radius: 15px;
                        border: 0;
                        background-color: #6AAF8B;
                        color:  #F0F7F3;
                        transition: all .2s ease;
    
                        &:hover {
                            cursor: pointer;
                            opacity: .8;
                        }
                    }
                }
            }

            .doc-wrapper {
                width: 100%;
                border-radius: 15px;
                padding: 20px 0px 0px;
                box-sizing: border-box;
                display: flex;
                flex-wrap: wrap;
                color: #4d4d4d;
                margin-top: 20px;

                h3 {
                    text-align: center;
                    background-color: #6399ce;
                    color: #fff;
                    font-size: 18px;
                    width: 100%;
                    margin: 20px 0 30px;
                    padding: 15px;
                    border-radius: 10px;
                    box-shadow: 0px 1px 10px #00000029;
                }

                .formations-wrapper {
                    a {
                        color: #4d4d4d;
                        display: flex;
                        flex-wrap: wrap;
                        align-items:center;
                        justify-content: space-between;
                        font-size: 18px;
                        border-top: 1px solid #e1efe7;
                        cursor: pointer;

                        span {
                            padding-right: 10px;
                        }

                        &:hover {
                            background-color: #e1efe7;
                        }
                    }
                }

                .offres, .test, .secteurs {
                    width: 100%;
                    box-sizing: border-box;
                    position: relative;
                    border-radius: 15px;
                    box-shadow: 0px 1px 10px #00000029;
                    border-radius: 10px;
                    font-size: 18px;
                    margin-bottom: 40px;

                    &:nth-child(1) {
                        margin-bottom: 40px;
                        font-size: 18px;
                    }

                    h4 {
                        text-align: center;
                        color: #6399ce;
                    }

                    .drop-down-2 {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 20px;
                        cursor: pointer;
                        font-size: 18px;

                        h4, .info-dyna {
                            width: calc(50% - 25px);
                            text-align: left;
                        }

                        .arrow-wrapper {
                            max-height: 20px;
                            .arrow-down {
                                font-size: 50px;
                                color: #6399ce;
                                margin-top: -15px;
                                margin-right: -20px;
                            }
                        }
                    }
                }

                .statut {
                    width: 100px;
                    box-sizing: border-box;
                    text-align: center;
                    padding: 5px;
                    color: #fff;
                    margin-right: 10px;
                    border-radius: 5px;

                    &.green {
                        background-color: #6aaf8b;
                    }

                    &.orange {
                        background-color: #D47558;
                    }
                }

                .list-paragraphe {
                    text-align: left;
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    text-transform: capitalize;

                    a {
                        color: #6399ce;
                        margin-left: 20px;
                        transition: all .3s ease;

                        &:hover {
                            opacity: .7;
                        }
                    }

                    svg {
                        color: #6399ce;
                        margin:0 10px 0 0;
                    }
                }

                .doc-card {
                    display:flex;
                    flex-wrap: wrap;
                    width: 100%;
                    box-shadow: 0px 3px 10px #00000069;
                    box-sizing: border-box;
                    position: relative;
                    border-radius: 15px;

                    .card-section {
                        width: calc(100% / 3);
                        position: relative;
                        padding-bottom: 60px;

                        .number-circle {
                            margin: 10px auto 30px;
                            height: 50px;
                            width: 50px;
                            font-size: 30px;
                            border-radius: 50%;
                            background-color: #6399ce;
                            color: #fff;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        &.left {
                            
                            h4 {
                                border-top-left-radius: 15px;
                            }

                            button {
                                border-bottom-left-radius: 15px;
                            }
                        }

                        &.right {
                            h4 {
                                border-top-right-radius: 15px;
                            }

                            button {
                                border-bottom-right-radius: 15px;
                            }
                        }

                        &.center {
                            border-left: 1px solid #dbe8f4;
                            border-right: 1px solid #dbe8f4; 
                        }

                        h4 {
                            width: 100%;
                            background-color: #dbe8f4;
                            color: #4d4d4d;
                            font-size: 18px;
                            padding: 10px 20px;
                            text-align: center;
                        }

                        .list-paragraphe {
                            text-align: left;
                            padding-bottom: 15px;
                            display: flex;
                            align-items: center;
                            width: 100%;
                            position: relative;
    
                            svg {
                                color: #6399ce;
                                margin:0 10px;
                            }

                            .statut {
                                width: 100px;
                                box-sizing: border-box;
                                text-align: center;
                                padding: 5px;
                                color: #fff;
                                position: absolute;
                                right: 10px;
                                top: -5px;

                                &.green {
                                    background-color: #6aaf8b;
                                }

                                &.orange {
                                    background-color: #D47558;
                                }
                            }
                        }

                        button {
                            margin: 0 auto;
                            width: 100%;
                            margin: 50px 0 0px;
                            padding: 10px 0;
                            border: 0;
                            background-color: #6399ce;
                            color:  #fff;
                            transition: all .2s ease;
                            position: absolute;
                            bottom: 0;
                            left: 0;
        
                            &:hover {
                                cursor: pointer;
                                opacity: .8;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1400px) {
    .page-wrapper {
        .right-wrapper {
            width: calc(100% - 250px);

            h1 {
                font-size: 45px;
            }

            h2 {
                font-size: 35px;
            }
        }
    }
}

@media only screen and (max-width: 1079px) {
    .page-wrapper {

        margin: 150px auto 0;
        .right-wrapper {
            width: calc(100% - 250px);

            h1 {
                font-size: 40px;
            }

            h2 {
                font-size: 30px;

                .separator-blue {
                    width: 100px;
                }
            }

            .course-wrapper {
                .course-count {
                    .demandes {
                        width: 100%;
                        margin: 0 0 40px 0;
                    }

                    .en-cours {
                        width: 100%;
                    }
                }
            }

            .features-wrapper {

                .job-wrapper {
                    .job-count {
                        .offres {
                            width: 100%;
                            margin: 0 0 40px 0;
                        }

                        .test {
                            width: 100%;
                        }
                    }
                }

                .doc-wrapper {

                    .doc-card {
                        box-shadow: none;
                        border-radius: 0px;

                        .card-section {
                            width: calc(100%);
                            box-shadow: 0px 3px 10px #00000069;
                            margin: 0 0 40px 0;
                            border-radius: 15px;

                            h4 {
                                border-top-left-radius: 15px;
                                border-top-right-radius: 15px;
                            }
                            button {
                                border-bottom-left-radius: 15px;
                                border-bottom-right-radius: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 0px) and (max-width: 759px) {

    .page-wrapper {

        .right-wrapper {
            width: calc(100%);

            h1 {
                font-size: 30px;

                .separator-blue {
                    width: 100px;
                }
            }

            h2 {
                font-size: 25px;
                text-align: center;

                .separator-blue {
                    width: 50px;
                    position: static;
                    margin: 10px auto;
                }
            }

            .entreprise-details {
    
                .infos-wrapper {
                    padding: 60px 5px 30px;

                    .rows-details {
                        .txt-wrapper {
                            margin-left:5px;
                            font-size: 15px;
                        }
                    }
                }
            }

            .courses-recommended {

                padding: 30px 0px 30px 10px;
                
                .card-wrapper {
                    padding: 0 0px 30px;
                }
            }
        }
    }
}
  
`;

export default AccountHomeStyled;