/**** Import des modules ****/
import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import {AiOutlineClose} from "react-icons/ai"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'
import ReactMarkdown from 'react-markdown'
import axios from 'axios'
import * as qs from 'qs'


/**** Import des images ****/
import contrat from '../../assets/img/illustration/contrat.png'
import euroyellow from '../../assets/img/illustration/euroyellow.png'
import graphgreen from '../../assets/img/illustration/graphgreen.png'
import contratcheck from '../../assets/img/illustration/contratcheck.png'
import logo from '../../assets/img/illustration/offre_emploi.png'

/**** Import des components et containers ****/
import CourseCard from '../../containers/CourseCard'

/**** Import des css ****/
import ModalJobFicheStyled from './ModalJobFicheStyled'
import { Skeleton } from '@mui/material'
import urlBackEnd from '../../assets/js/urlBackEnd'

const URL = urlBackEnd

const ModalJobFiche = ({setModalJobFicheOpen, modalJobFicheOpen, jobFicheId, fetchThisJobOffer, currentJobFiche}) => {

useEffect((() => {
    fetchThisJobOffer(jobFicheId)
    }), []);

    function useOutsideAlerter(ref) {
      useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setModalJobFicheOpen(false)
          }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [ref]);
    }
    
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

  return (
    <ModalJobFicheStyled>
      {/** last version  
      <section className="modal-content-container">
      {currentJobFiche !== undefined && 
      <div className="page-wrapper">
      <section className="illustration-header">
        <div className="opacity-light-section">
        <img src={logo} alt='logo wefor'/>
          <div className="left">
          <div className="title-wrapper-prog">
              <h2 className="bold border">Fiche métier {currentJobFiche.attributes.secteur.data !== null ? currentJobFiche.attributes.secteur.data.attributes.nom : ''}</h2>
              <h1 className="bold">{currentJobFiche.attributes.nom}</h1>
          </div>
        </div>
        </div>
      </section>
      <main>
        <section className="section-job-def">
          <div className="title-wrapper">
            <img src={contrat} alt="contrat avec fond jaune" />
            <h2>DÉFINITION DU MÉTIER<span className="separator-blue"></span></h2>
          </div>
          <div className="def-wrapper">
            <p className="txt-left">{currentJobFiche.attributes.definition}</p>
            <div className="salary-wrapper">
              <img src={euroyellow} alt="contrat aevc fond jaune" />
              <div className="flex-box-wrapper">
                <h3 className="bold">Rémunération moyenne</h3>
                <p className="bold">{currentJobFiche.attributes.remuneration} € <span className="bold">brut annuel</span></p>
              </div>
              <p className="disclaimer">*sources: site <a href="https://fr.talent.com/" target="_blank" rel="noreferrer" className="bold">talent.com</a></p>
            </div>
          </div>
        </section>
        <section className="section-formation-list">
          <h2>FORMATIONS WEFOR POUR ACCÉDER À CE MÉTIER<span className="separator-blue"></span></h2>
          <div className="card-wrapper">
          {currentJobFiche !== undefined && currentJobFiche.attributes.formations.data.map((formation) => (
            <CourseCard key={formation.id} courseId={formation.id} {...formation}/>
          ))}
          </div>
          <Link to="/formation">
            <button className="btn-blue">Voir toutes nos formations</button>
          </Link>
        </section>
        <section className="section-mission-list">
          <h2>MISSIONS / COMPÉTENCES-CLÉS<span className="separator-blue"></span></h2>
          <div className="list-wrapper">
            <div className="left">
              <ReactMarkdown>{currentJobFiche.attributes.missions}</ReactMarkdown>
            </div>
          </div>
        </section>
        <section className="section-evolution-job">
          <h2>ÉVOLUTIONS POSSIBLES<span className="separator-blue"></span></h2>
          <div className="evo-list">
  
            <ReactMarkdown>{currentJobFiche.attributes.evolution}</ReactMarkdown>
          </div>
        </section>
        <section className="section-perspective-graph">
          <h2>PERSPECTIVES DE RÉMUNÉRATION<span className="separator-blue"></span></h2>
          <p className="txt-blue bold">Courbe de progression de salaire d’un <span className="bold">{currentJobFiche.attributes.nom}</span></p>
          <LineChart
            width={500}
            height={300}
            data={[
              {
                name: 'Junior',
                salaire: `${currentJobFiche.attributes.salaire_junior}`,
              },
              {
                name: 'Senior',
                salaire: `${currentJobFiche.attributes.salaire_senior}`,
              },
            ]}
            className='graph bold'
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="5 5" />
            <XAxis dataKey="name" stroke="#757991" />
            <YAxis label={{ value: 'K€', position: 'insideTopLeft' }} stroke="#757991"/>
            <Tooltip />
            <Line type="monotone" dataKey="salaire" stroke="#639ACD" />
          </LineChart>
        </section>
        <section className="section-btn-bottom">
            <Link to="/formation">
              <div className="btn-right">
                <img src={contratcheck} alt="contrat avec fond jaune" />
                <p>Les formations</p>
              </div>
            </Link>
        </section>
      </main>
      </div>
      }
        <div className="icon-wrapper">
            <AiOutlineClose 
            className="close-icon"
            onClick={() => setModalJobFicheOpen(false)}
            />
        </div>
      </section>
    **/}
    <section className="modal-content-container">
    {currentJobFiche !== undefined && 
      <div className="page-wrapper" ref={wrapperRef}>
      <section className="illustration-header">
        <div className="opacity-light-section">
        <img src={logo} alt='logo wefor'/>
          <div className="left">
          <div className="title-wrapper-prog">
              <h2 className="bold border">Fiche métier</h2>
              <h1 className="bold">{currentJobFiche.attributes.nom}</h1>
          </div>
        </div>
        </div>
      </section>
      <main>
        {currentJobFiche.attributes.description !== "" && 
        <div className="info-job-wrapper">
        <ReactMarkdown>{currentJobFiche.attributes.description}</ReactMarkdown>
        </div>
        }
      <div className="icon-wrapper">
            <AiOutlineClose 
            className="close-icon"
            onClick={() => setModalJobFicheOpen(false)}
            />
      </div>
      </main>
      </div>
      }
    </section>
    </ModalJobFicheStyled>
  )
}

ModalJobFiche.propTypes = {

}

export default ModalJobFiche