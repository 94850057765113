import { connect } from 'react-redux'

import AccountCourse from '../../pages/AccountCourse'

import { fetchAccountFormations, fetchParticuliers, addParticulierOnEntreprise } from '../../actions/login'
import { fetchUserScore } from '../../actions/lms'
import { fetchMySalarie, setSalarieClicked } from '../../actions/course'
import { setMenuFormation } from '../../actions/menu'

const mapStateToProps = (state) => ({
    user: state.login.session.user,
    userInfo: state.login.userInfo,
    userRole: state.login.userRole,
    isLogged: state.login.isLogged,
    formationsEnCours: state.login.formationsEnCours,
    formationsTerminee: state.login.formationsTerminee,
    formationsDemande: state.login.formationsDemande,
    particuliers: state.login.particuliers,
    userScore: state.lms.userScore,
    menuFormation: state.menu.menuFormation,
    myEntreprisePart: state.course.myEntreprisePart,
    salarieClickedId: state.course.salarieClickedId,
    salarieClickedName:  state.course.salarieClickedName
})

const mapDispatchToProps = (dispatch) => ({
    fetchAccountFormations: () => {
        dispatch(fetchAccountFormations());
    },
    fetchParticuliers: () => {
        dispatch(fetchParticuliers());
    },
    addParticulierOnEntreprise: (id) => {
        dispatch(addParticulierOnEntreprise(id));
    },
    addParticulierOnEntreprise: (id) => {
        dispatch(addParticulierOnEntreprise(id));
    },
    fetchUserScore: () => {
        dispatch(fetchUserScore());
    },
    fetchMySalarie: () => {
        dispatch(fetchMySalarie());
    },
    setSalarieClicked: (name, id) => {
        dispatch(setSalarieClicked(name, id));
    },
    setMenuFormation: (value) => {
        dispatch(setMenuFormation(value));
      },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(AccountCourse)