import axios from 'axios'
import * as qs from 'qs'

import urlBackEnd from '../assets/js/urlBackEnd';

import { FETCH_SECTEURS, saveSecteurs, FETCH_DOMAINES, saveDomaines, fetchFiche, saveFiche, FETCH_FICHE } from '../actions/menu';

//const URL = "http://localhost:1338/api/";

// d9c884135fab4d0a8c8dabf61e6041cf

const URL = urlBackEnd;
const querySections = qs.stringify({
    populate: {
      domaines: {
        populate: '*',
      }
    },
    sort: ['nom:asc'] 
  }, {
    encodeValuesOnly: true,
  });

const queryDomaines = qs.stringify({
  populate: '*'
}, {
  encodeValuesOnly: true,
});

const secteursMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case FETCH_SECTEURS: {
      axios.get(`${URL}secteurs?${querySections}`)
        .then((response) => {
          store.dispatch(saveSecteurs(response.data.data));
        })
        .catch((error) => {
          console.error(error);
        });
      next(action);
      break;
    }
    case FETCH_DOMAINES: {
      axios.get(`${URL}domaines?${queryDomaines}`)
        .then((response) => {
          store.dispatch(saveDomaines(response.data.data));
        })
        .catch((error) => {
          console.error(error);
        });
      next(action);
      break;
    }
    case FETCH_FICHE: {
      axios.get(`${URL}fiche-metiers?${queryDomaines}`)
        .then((response) => {
          store.dispatch(saveFiche(response.data.data));
        })
        .catch((error) => {
          console.error(error);
        });
      next(action);
      break;
    }
    default:
      next(action);
  }
};

export default secteursMiddleware;