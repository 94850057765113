import axios from 'axios'
import * as qs from 'qs'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';

import urlBackEnd from '../assets/js/urlBackEnd';

import { FETCH_USER, 
        saveUser, 
        changeIsLogged, 
        fetchUserRole, 
        FETCH_USER_ROLE, 
        saveUserRole, 
        FETCH_ACCOUNT_FORMATIONS, 
        saveAccountFormations, 
        FETCH_PUT_ACCOUNT_P, 
        FETCH_PUT_ACCOUNT_E,
        fetchUser, 
        FETCH_PARTICULIERS, 
        saveParticuliers, 
        ADD_PARTICULER_ON_ENTREPRISE,
        FETCH_POST_USER_PARTICULIER,
        fetchPostParticulier,
        FETCH_POST_PARTICULIER,
        FETCH_POST_USER_ENTREPRISE,
        fetchPostEntreprise,
        FETCH_POST_ENTREPRISE,
        FETCH_SIREN,
        saveSirenData,
        FETCH_ACCOUNT_INFO,
        FETCH_LOST_PASSWORD,
        FETCH_POST_NEW_PASSWORD,
        setLoadConnexion,
        redirectToAccount,
        DISCONNECT_USER,
        setFullPageLoaderPutAccount,
        FETCH_SCORE_RECRUTEMENT_PARTICULIER,
        FETCH_POST_SCORE_RECRUTEMENT,
        saveScoreRecrutement,
        FETCH_GET_SCORE_RECRUTEMENT,
        saveScoreRecrutementObject,
        setFullPageLoaderRecrutementObj,
        fetchGetScoreRecrutement
      } from '../actions/login';

import { FETCH_SEND_EMAIL_CONTACT, FETCH_POST_NEWSLETTER, setFullPageLoaderConnexion, setFullPageLoaderContact } from '../actions/actions';
import { changeCurrentProfilUser, setIsShowAccountFormMenu } from '../actions/menu';
import { FETCH_SEARCH_ADRESSE, saveAdresseList, FETCH_CV_PARTICULIER } from '../actions/signin';
import { closeModal, toggleModalMessage, openModal } from '../actions/modal'
import session from 'redux-persist/lib/storage/session';
import { fetchFormations, changeLoadFormation } from '../actions/course';
import { setCvPosted } from '../actions/job';

const URL = urlBackEnd

const queryConnectUser = qs.stringify({
  populate: {
    entreprise: {
      populate: '*',
    },
    particulier: {
      populate: '*',
    }
  }
  }, {
    encodeValuesOnly: true,
  });

const accountMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case FETCH_USER: {
      const state = store.getState();
      store.dispatch(changeLoadFormation(true));
      store.dispatch(setLoadConnexion(true))
      const email = state.login.email
      const password = state.login.password
      axios.post(`${URL}auth/local?${queryConnectUser}`, {
        identifier: email,
        password: password,
      })
        .then((response) => {
          store.dispatch(saveUser(response.data));
          store.dispatch(fetchUserRole(response.data.user.id))
          store.dispatch(toggleModalMessage('', false))
        })
        .catch((error) => {
          console.error(error);
          store.dispatch(setLoadConnexion(false))
          store.dispatch(changeLoadFormation(false));
          store.dispatch(setFullPageLoaderConnexion(false))
          toast(`Email ou mot de passe incorrect`, {
            position: "bottom-right",
            type: "error",
            theme:"colored",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        });
      next(action);
      break;
    }
    case FETCH_USER_ROLE: {
      const state = store.getState();
      const queryGetUserRole = qs.stringify({
        populate: {
          user: {
            populate: '*',
          },
          demande_formations: {
            populate: '*',
          },
          en_cours_formations: {
            populate: {
              demande_particuliers:{
                populate: '*'
              },
              domaines: {
                populate: '*'
              },
              en_cours_particuliers: {
                populate: '*'
              },
              fiche_metier: {
                populate: '*'
              },
              modules_formations: {
                populate: '*'
              },
              particuliers_termine: {
                populate: '*'
              },
              score_formations: {
                filters: {
                  id: state.login.session.user.id
                },
                populate: '*'
              },
              secteurs: {
                populate: '*'
              },
              sous_domaines: {
                populate: '*'
              },
            },
          },
          formations_termine: {
            populate: '*',
          },
          offre_emplois_postule: {
            populate: '*'
          },
          test_ocean: {
            populate: '*'
          },
          test_oceans: {
            populate: '*'
          },
          score_formations: {
            populate: '*'
          },
          cv: {
            populate: '*'
          },
          particuliers: {
            populate: {
              informations_particuliers: {
                populate: '*'
              },
              demande_formations: {
                populate: '*'
              },
              en_cours_formations: {
                populate: '*'
              },
              formations_termine: {
                populate: '*'
              },
              entreprise: {
                populate: '*'
              },
              offre_emplois_postule: {
                populate: '*'
              },
              user: {
                populate: '*'
              },
              score_formations: {
                populate: '*'
              },
            },
          },
          offre_emplois: '*',
          convention: {
            populate: '*'
          },
          facturation_formations: {
            populate: '*'
          }
        },
        filters: {
          user: {
            id: state.login.session.user.id
          }
        },
        headers: {
          Authorization:
            `Bearer ${state.login.session.jwt}`,
          },
        }, {
          encodeValuesOnly: true,
        });     
        axios.get(`${URL}entreprises?${queryGetUserRole}`)
          .then((response) => {
            if (response.data.data.length > 0 ) {
              store.dispatch(saveUserRole(response.data, 'entreprise'))
              store.dispatch(changeCurrentProfilUser('Entreprise'))
              if (state.login.isLogged === false) {
                toast(`Bonjour ${state.login.session.user.prenom}. Vous êtes connecté !`, {
                  position: "bottom-right",
                  type: "success",
                  theme:"colored",
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                })
                store.dispatch(changeIsLogged(true));
                store.dispatch(fetchFormations())
                store.dispatch(setLoadConnexion(false))
                store.dispatch(redirectToAccount(true))
                store.dispatch(setIsShowAccountFormMenu(false))
                store.dispatch(closeModal(''))
                store.dispatch(changeLoadFormation(false));
                store.dispatch(setFullPageLoaderConnexion(false))
              }
            }
          })
          .catch((error) => {
            console.error(error);
          });
        axios.get(`${URL}particuliers?${queryGetUserRole}`)
        .then((response) => {
          if (response.data.data.length > 0 ) {
            store.dispatch(saveUserRole(response.data, 'particulier'))
            store.dispatch(changeCurrentProfilUser(response.data.data[0].attributes.statut))
            if (state.login.isLogged === false) {
              toast(`Bonjour ${state.login.session.user.prenom}. Vous êtes connecté !`, {
                position: "bottom-right",
                type: "success",
                theme:"colored",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              })
            }
            store.dispatch(changeIsLogged(true))
            store.dispatch(fetchFormations())
            store.dispatch(setLoadConnexion(false))
            store.dispatch(redirectToAccount(true))
            store.dispatch(setIsShowAccountFormMenu(false))
            store.dispatch(closeModal(''))
            store.dispatch(changeLoadFormation(false));
            store.dispatch(setFullPageLoaderConnexion(false))
          }
        })
      .catch((error) => {
        console.error(error);
      });
      next(action);
      break;
    }
    case FETCH_ACCOUNT_INFO: {
      const state = store.getState();
      axios.get(`${URL}users/${state.login.session.user.id}`)
      .then((response) => {
        store.dispatch(saveUser(response.data));
        store.dispatch(fetchUserRole(response.data.user.id));
      })
      .catch((error) => {
        console.error(error);
      })
      next(action);
      break;
    }
    case FETCH_ACCOUNT_FORMATIONS: {
      const state = store.getState();
      
      const queryGetAccountFormations = qs.stringify({
        populate: {
          en_cours_formations: {
            populate: '*',
          },
          formations_termine: {
            populate: '*',
          },
          demande_formations: {
            populate: '*',
          },
          offre_emplois_postule: {
            populate: '*',
          },
        },
        filters: {
          user: {
            id: (state.login.isLogged ? state.login.session.user.id : '')
          }
        }
        }, {
          encodeValuesOnly: true,
        });  
      if (state.login.userRole === "particulier") { 
      axios.get(`${URL}particuliers?${queryGetAccountFormations}`)
        .then((response) => {
          store.dispatch(saveAccountFormations(response.data, 'particulier'))
        })
        .catch((error) => {
          console.error(error);
        });
      } 
      next(action);
      break;
    }
    case FETCH_PUT_ACCOUNT_P: {
      const state = store.getState();

      let isEmptyInput = []
      let data = {}

      if (state.login.accountEmail !== undefined && state.login.accountEmail !== '') {
        data.email = state.login.accountEmail
      } 
      if (state.login.accountTel !== undefined && state.login.accountTel !== '') {
        data.telephone = state.login.accountTel
      } 
      if (state.login.accountFirstName !== undefined && state.login.accountFirstName !== '') {
        data.prenom = state.login.accountFirstName
      } 
      if (state.login.accountLastName !== undefined && state.login.accountLastName !== '') {
        data.nom = state.login.accountLastName
      } 
      if (state.login.accountBdDay !== undefined && state.login.accountBdMounth !== undefined && state.login.accountBdYear !== undefined) {
        data.age = `${state.login.accountBdYear}-${state.login.accountBdMounth}-${state.login.accountBdDay}`
      } 
      if (state.login.accountPassword !== undefined && state.login.accountConfirmationPassword !== undefined) {
        if (state.login.accountPassword === state.login.accountConfirmationPassword ) {
        data.password = state.login.accountPassword
        data.passwordConfirmation = state.login.accountConfirmationPassword
      }
      }

      if (Object.keys(data).length > 0) {
      axios.put(`${URL}users/${state.login.session.user.id}`, 
      data,
      {
        headers: {
          Authorization:
            `Bearer ${state.login.session.jwt}`,
          },
      }
      )
        .then((response) => {
          store.dispatch(fetchUser())
        })
        .catch((error) => {
          store.dispatch(setFullPageLoaderPutAccount(false))
          isEmptyInput.push('error')
          toast(`Une erreur s'est produite ${error.message}!`, {
            position: "bottom-right",
            type: "error",
            theme:"colored",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        });

        for (let prop in data) {
          delete data[prop];
        }
      } else {
        isEmptyInput.push('data')
      }
      let dataP = {}
      if (state.login.accountSearchFor !== undefined && state.login.accountSearchFor !== '') {
      dataP.souhait = state.login.accountSearchFor
      }
      if (state.login.accountAdresseNumStreet !== '') {
        dataP.numero_voie = state.login.accountAdresseNumStreet
      }
      if (state.login.accountStatut !== '') {
        dataP.statut = state.login.accountStatut
      }
      if (state.login.accountAdresseStreet !== '') {
        dataP.nom_voie = state.login.accountAdresseStreet
      }
      if (state.login.accountAdresseCode !== '') {
        dataP.code_postal = state.login.accountAdresseCode
      }
      if (state.login.accountAdresseCity !== '') {
        dataP.ville = state.login.accountAdresseCity
      }
      if (state.signin.accountAdresseSignin !== '') {
        dataP.adresse_postale = state.signin.accountAdresseSignin
      }

      if (Object.keys(dataP).length > 0) {

      axios.put(`${URL}particuliers/${state.login.userInfo.data[0].id}`, {
        data: dataP
      },
      {
        headers: {
          Authorization:
            `Bearer ${state.login.session.jwt}`,
          },
      }
      )
        .then((response) => {
          store.dispatch(fetchUser())
          store.dispatch(setFullPageLoaderPutAccount(false))
          toast(`Votre profil a été modifié.`, {
            position: "bottom-right",
            type: "success",
            theme:"colored",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
        .catch((error) => {
          store.dispatch(setFullPageLoaderPutAccount(false))
          isEmptyInput.push('error')
          toast(`Une erreur s'est produite ${error.message}!`, {
            position: "bottom-right",
            type: "error",
            theme:"colored",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        });
        for (let prop in dataP) {
          delete dataP[prop];
        }
      } else {
        isEmptyInput.push('dataP')
      }

      if (isEmptyInput.length  === 2){
        store.dispatch(setFullPageLoaderPutAccount(false))
        toast(`Vous n'avez apporté aucune modification!`, {
          position: "bottom-right",
          type: "error",
          theme:"colored",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        isEmptyInput = []
      } else {
        store.dispatch(setFullPageLoaderPutAccount(false))
        toast(`Votre profil a été modifié !`, {
          position: "bottom-right",
          type: "success",
          theme:"colored",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
      next(action);
      break;
    }
    case FETCH_PUT_ACCOUNT_E: {
      const state = store.getState();
      store.dispatch(setFullPageLoaderPutAccount(true))
      let isEmptyInput = []
      let data = {}
      if (state.login.accountEmail !== undefined && state.login.accountEmail !== '') {
        data.email = state.login.accountEmail
      } 
      if (state.login.accountTel !== undefined && state.login.accountTel !== '') {
        data.telephone = state.login.accountTel
      } 
      if (state.login.accountFirstName !== undefined && state.login.accountFirstName !== '') {
        data.prenom = state.login.accountFirstName
      } 
      if (state.login.accountLastName !== undefined && state.login.accountLastName !== '') {
        data.nom = state.login.accountLastName
      }
      if (state.login.accountPassword !== undefined && state.login.accountConfirmationPassword !== undefined) {
        if (state.login.accountPassword === state.login.accountConfirmationPassword ) {
        data.password = state.login.accountPassword
        data.passwordConfirmation = state.login.accountConfirmationPassword
      }
      }
      if (Object.keys(data).length > 0) {
      axios.put(`${URL}users/${state.login.session.user.id}`, 
      data,
      {
        headers: {
          Authorization:
            `Bearer ${state.login.session.jwt}`,
          },
      }
      )
        .then((response) => {
          store.dispatch(fetchUser())
        })
        .catch((error) => {
          store.dispatch(setFullPageLoaderPutAccount(false))
          isEmptyInput.push('error')
          toast(`Une erreur s'est produite ${error.message}!`, {
            position: "bottom-right",
            type: "error",
            theme:"colored",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        });
        for (let prop in data) {
          delete data[prop];
        }
      } else {
        isEmptyInput.push('data')
      }
      let dataP = {}
      if (state.login.accountSiren !== undefined && state.login.accountSiren !== '') {
        dataP.siren = state.login.accountSiren
      } 
      if (state.login.accountSearchFor !== undefined && state.login.accountSearchFor !== '') {
        dataP.souhait = state.login.accountSearchFor
      }
      if (state.login.accountFonction !== undefined && state.login.accountFonction !== '') {
        dataP.fonction_dans_entreprise = state.login.accountFonction
      } 
      if (state.login.accountSecteur !== undefined && state.login.accountSecteur !== '') {
        dataP.secteur_activite = state.login.accountSecteur
      } 
      if (state.login.accountEntreprise !== undefined && state.login.accountEntreprise !== '') {
        dataP.Nom_entreprise = state.login.accountEntreprise
      }
      if (state.login.accountAdresse !== undefined && state.login.accountAdresse !== '') {
        dataP.adresse = state.login.accountAdresse
      }
      if (state.login.accountAdresseNumStreet !== '') {
        dataP.numero_voie = state.login.accountAdresseNumStreet
      }
      if (state.login.accountAdresseStreet !== '') {
        dataP.nom_voie = state.login.accountAdresseStreet
      }
      if (state.login.accountAdresseCode !== '') {
        dataP.code_postal = state.login.accountAdresseCode
      }
      if (state.login.accountAdresseCity !== '') {
        dataP.ville = state.login.accountAdresseCity
      }
      if (state.signin.accountAdresseSignin !== '') {
        dataP.adresse = state.signin.accountAdresseSignin
      }
      if (Object.keys(dataP).length > 0) {
      axios.put(`${URL}entreprises/${state.login.userInfo.data[0].id}`, {
        data: dataP
      },
      {
        headers: {
          Authorization:
            `Bearer ${state.login.session.jwt}`,
          },
      }
      )
        .then((response) => {
          store.dispatch(fetchUser())
        })
        .catch((error) => {
          store.dispatch(setFullPageLoaderPutAccount(false))
          isEmptyInput.push('error')
          toast(`Une erreur s'est produite ${error.message}!`, {
            position: "bottom-right",
            type: "error",
            theme:"colored",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        });
        for (let prop in dataP) {
          delete dataP[prop];
        }
      } else {
        isEmptyInput.push('dataP')
      }
      if (isEmptyInput.length  === 2){
        store.dispatch(setFullPageLoaderPutAccount(false))
        toast(`Vous n'avez apporté aucune modification!`, {
          position: "bottom-right",
          type: "error",
          theme:"colored",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        isEmptyInput = []
      } else {
        toast(`Votre profil a été modifié !`, {
          position: "bottom-right",
          type: "success",
          theme:"colored",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        store.dispatch(setFullPageLoaderPutAccount(false))
      }
        next(action);
        break;
    }
    case FETCH_PARTICULIERS: {
      const state = store.getState();
      const queryParticuliers = qs.stringify({
        populate:'*'
      }, 
        {
          encodeValuesOnly: true,
        });
      axios.get(`${URL}particuliers?${queryParticuliers}`,
      {
        headers: {
          Authorization:
            `Bearer ${state.login.session.jwt}`,
          },
      }
      )
        .then((response) => {
          store.dispatch(saveParticuliers(response.data))
        })
        .catch((error) => {
          console.error(error);
        });
      next(action);
      break;
    }
    case ADD_PARTICULER_ON_ENTREPRISE: {
      const state = store.getState();
      let particulierId = action.id
      let entrepriseId = state.login.userInfo.data[0].id

      axios.put(`${URL}entreprises/${entrepriseId}`, {
        data: {
          particuliers: particulierId
        }
      },
      {
        headers: {
          Authorization:
            `Bearer ${state.login.session.jwt}`,
          },
      }
      )
        .then((response) => {
          store.dispatch(fetchUser())
        })
        .catch((error) => {
          console.error(error);
          toast(`Une erreur s'est produite ${error.message}!`, {
            position: "bottom-right",
            type: "error",
            theme:"colored",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        });
      next(action);
      break;
    }
    case FETCH_POST_USER_PARTICULIER: {
      const state = store.getState();

      if(state.signin.accountConfirmationPasswordSignin === state.signin.accountPasswordSignin) {
      axios.post(`${URL}auth/local/register`, {
          email: state.signin.accountEmailSignin,
          username: state.signin.accountEmailSignin,
          nom: state.signin.accountLastNameSignin,
          password: state.signin.accountPasswordSignin,
          telephone: state.signin.accountTelSignin,
          prenom: state.signin.accountFirstNameSignin,
          confirmed: false,
      },
      )
        .then((response) => {
          store.dispatch(fetchPostParticulier(response.data.user.id))
        })
        .catch((error) => {
          console.error(error.message, error);
          toast(`Une erreur s'est produite ${error.message}!`, {
            position: "bottom-right",
            type: "error",
            theme:"colored",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        });
      } else {
        alert('les mots de passe ne corréspondent pas')
      }
      next(action);
      break;
    }
    case FETCH_POST_PARTICULIER: {
      const state = store.getState();
      
      let userId = action.userId
      axios.post(`${URL}particuliers`, {
          data: {
            user: userId,
            souhait: state.signin.accountSearchForSignin,
            niveau: state.signin.accountCoursesSignin,
            dernier_emploi: state.signin.accountLastJobSignin,
            adresse_postale: state.signin.accountAdresseSignin,
            statut: state.signin.accountOnJobSignin,
            numero_voie: state.signin.accountAdresseNumStreetSignin,
            nom_voie: state.signin.accountAdresseStreetSignin,
            code_postal: state.signin.accountAdresseCodeSignin,
            ville: state.signin.accountAdresseCitySignin,
          }
      },
      )
        .then((response) => {
          store.dispatch(toggleModalMessage('Votre compte a été créé, un e-mail vous a été envoyé pour valider votre adresse (Merci de verifier dans vos SPAM).', true))
          store.dispatch(openModal('login'))
        /* axios.post(`${URL}auth/send-email-confirmation`, {
                email: state.signin.accountEmailSignin, // user's email
              })
              .then((response) => {
                
                toast(`Vous allez recevoir un email pour valider votre compte !`, {
                  position: "bottom-right",
                  type: "success",
                  theme:"colored",
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                }) 
              })
              .catch((error) => {
                toast(`Une erreur s'est produite ${error.message}!`, {
                  position: "bottom-right",
                  type: "error",
                  theme:"colored",
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                })
              });*/
        })
        .catch((error) => {
          console.error(error.message);
          toast(`Une erreur s'est produite ${error.message}!`, {
            position: "bottom-right",
            type: "error",
            theme:"colored",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        });
      next(action);
      break;
    }
    case FETCH_POST_USER_ENTREPRISE: {
      const state = store.getState();
      if(state.signin.accountConfirmationPasswordSignin === state.signin.accountPasswordSignin) {
//        if (state.signin.accountSirenSignin.length === 9 ) {
          axios.post(`${URL}auth/local/register`, {
              email: state.signin.accountEmailSignin,
              username: state.signin.accountEmailSignin,
              nom: state.signin.accountLastNameSignin,
              password: state.signin.accountPasswordSignin,
              telephone: state.signin.accountTelSignin,
              prenom: state.signin.accountFirstNameSignin,
              confirmed: false,
          },
          )
            .then((response) => {
              store.dispatch(fetchPostEntreprise(response.data.user.id))
            })
            .catch((error) => {
              console.error(error.message);
              toast(`Une erreur s'est produite ${error.message}!`, {
                position: "bottom-right",
                type: "error",
                theme:"colored",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              })
            });
/*        } else {
          toast('Le numéro de siren doit comporter 9 caractères sans éspaces', {
            position: "bottom-right",
            type: "error",
            theme:"colored",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        }*/
     } else {
        toast('Les mots de passe ne correspondent pas', {
          position: "bottom-right",
          type: "error",
          theme:"colored",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
      next(action);
      break;
    }
    case FETCH_POST_ENTREPRISE: {
      const state = store.getState();
      let userId = action.userId
      axios.post(`${URL}entreprises`, {
          data: {
            user: userId,
            Nom_entreprise: state.signin.accountEntrepriseSignin,
            siren: state.signin.accountSirenSignin,
            fonction_dans_entreprise: state.signin.accountFonctionSignin,
            secteur_activite: state.signin.accountSecteurSignin,
            souhait: state.signin.accountSearchForSignin,
            adresse: state.signin.accountAdresseSignin,
            numero_voie: state.signin.accountAdresseNumStreetSignin,
            nom_voie: state.signin.accountAdresseStreetSignin,
            code_postal: state.signin.accountAdresseCodeSignin,
            ville: state.signin.accountAdresseCitySignin
          }
      },
      )
        .then((response) => {
          store.dispatch(toggleModalMessage('Votre compte a été créé, un e-mail vous a été envoyé pour valider votre adresse (Merci de verifier dans vos SPAM).', true))
          store.dispatch(openModal('login'))
        })
        .catch((error) => {
          console.error(error.message);
          toast('Une erreur s\'est produite', {
            position: "bottom-right",
            type: "error",
            theme:"colored",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        });
      next(action);
      break;
    }
    case FETCH_SIREN: {
      let siren = action.siren
      axios.get(`https://entreprise.data.gouv.fr/api/sirene/v3/unites_legales/${siren}`)
        .then((response) => {
          store.dispatch(saveSirenData(response.data));
          toast(`Certaines informations ont été récuperées !`, {
            position: "bottom-right",
            type: "success",
            theme:"colored",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
        .catch((error) => {
          console.error(error.message);
          toast(`Aucune entreprise n'a été trouvée !`, {
            position: "bottom-right",
            type: "error",
            theme:"colored",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        });
      next(action);
      break;
    }
    case FETCH_SEND_EMAIL_CONTACT: {
      const state = store.getState();
      axios.post(`${URL}sendemailcontact`, {
          data: {
            nom: state.actions.nom,
            prenom: state.actions.prenom,
            email: state.actions.email,
            tel: state.actions.tel,
            message: state.actions.message,
            objet: state.actions.objet,
          }
      },
      )
        .then((response) => {
          store.dispatch(setFullPageLoaderContact(false))
          toast(`Votre message a été envoyé !`, {
            position: "bottom-right",
            type: "success",
            theme:"colored",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
        .catch((error) => {
          store.dispatch(setFullPageLoaderContact(false))
          toast('Le message n\'a pas été envoyé ! Une erreur s\'est produite', {
            position: "bottom-right",
            type: "error",
            theme:"colored",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        });
      next(action);
      break;
    }
    case FETCH_POST_NEWSLETTER: {
      const state = store.getState();
      axios.post(`${URL}newsletters`, {
          data: {
            email: state.actions.emailnewsletter,
          }
      },
      )
        .then((response) => {
          toast(`Vous êtes inscrit à la newsletter !`, {
            position: "bottom-right",
            type: "success",
            theme:"colored",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
        .catch((error) => {
          console.error(error.message);
          toast('Une erreur s\'est produite, veuillez réesseyer plus tard', {
            position: "bottom-right",
            type: "error",
            theme:"colored",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        });
      next(action);
      break;
    }
    case FETCH_LOST_PASSWORD: {
      const state = store.getState();

      axios.post(`${URL}auth/forgot-password`, {
          email: state.login.emailLost,
          url: 'https://www.wefor/nouveau-mot-de-passe'
      },
      )
        .then((response) => {
          toast(`Un email vous a été envoyé pour changer votre mot de passe!`, {
            position: "bottom-right",
            type: "success",
            theme:"colored",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
        .catch((error) => {
          console.error(error.message, error);
          toast(`Le compte avec cette adresse email ${state.login.emailLost} n'éxiste pas !`, {
            position: "bottom-right",
            type: "error",
            theme:"colored",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        });
      next(action);
      break;
    }
    case FETCH_POST_NEW_PASSWORD: {
      const state = store.getState();

      axios.post(`${URL}auth/reset-password`, {
          code: state.login.codeResetPassword,
          password: state.login.newPassword,
          passwordConfirmation: state.login.newPasswordConfirmation
      },
      )
        .then((response) => {
          toast(`Vous avez changé votre mot de passe !`, {
            position: "bottom-right",
            type: "success",
            theme:"colored",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
        .catch((error) => {
          console.error(error.message, error);
          toast(`Une erreur s'est produite, veuillez réesseyer plus tard !`, {
            position: "bottom-right",
            type: "error",
            theme:"colored",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        });
      next(action);
      break;
    }
    case FETCH_SEARCH_ADRESSE: {
      const state = store.getState();

      const searchValue = state.signin.accountAdresseSignin

      if (searchValue.length > 10) {
      axios.get(`https://api-adresse.data.gouv.fr/search/?q=${searchValue}`)
        .then((response) => {
          store.dispatch(saveAdresseList(response.data.features))
        })
        .catch((error) => {
          console.error(error.message, error);
        });
      } else {
        store.dispatch(saveAdresseList([]))
      }
      next(action);
      break;
    }
    case FETCH_CV_PARTICULIER: {
      const state = store.getState();

      const CV = state.login.userInfo.data[0].attributes.cv.data

      if (CV !== null) {
        axios.delete(`${URL}upload/files/${CV.id}`,
        {
          headers: {
            Authorization:
            `Bearer ${state.login.session.jwt}`,
          }
        })
        .then((response) => {
          store.dispatch(setFullPageLoaderPutAccount(false))
          toast(`Votre ancien CV a été supprimé.`, {
            position: "bottom-right",
            type: "success",
            theme:"colored",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
        .catch((error) => {
          store.dispatch(setFullPageLoaderPutAccount(false))
          toast(`Une erreur s'est produite ${error.message}!`, {
            position: "bottom-right",
            type: "error",
            theme:"colored",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        });
      }
      const formData = new FormData()
      formData.append('files', action.file[0])
      formData.append("refId", action.particulierId);
      formData.append("ref", "api::particulier.particulier");
      formData.append("field", "cv");

      axios.post(`${URL}upload`, formData,
      {
        headers: {
          Authorization:
          `Bearer ${state.login.session.jwt}`,
        }
      })
      .then((response) => {
        store.dispatch(setFullPageLoaderPutAccount(false))
        store.dispatch(setCvPosted(true))
        store.dispatch(fetchUser())
        toast(`Votre CV a été téléchargé. Il sera envoyé au recruteurs interressés par votre profil`, {
          position: "bottom-right",
          type: "success",
          theme:"colored",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .catch((error) => {
        store.dispatch(setFullPageLoaderPutAccount(false))
      });
      next(action);
      break;
    }
    case FETCH_SCORE_RECRUTEMENT_PARTICULIER: {
      const state = store.getState();
      const scoreRecrutement = action.score
      const timeRecrutement = action.time
      const scoreId = state.login.scoreRecrutementId

      scoreRecrutement === 'skip' ?
      axios.put(`${URL}score-formations/${scoreId}`, {
        data: {
          global_time: timeRecrutement,
        }
      },
      {
        headers: {
          Authorization:
          `Bearer ${state.login.session.jwt}`,
        }
      })
      .then((response) => {
        store.dispatch(fetchGetScoreRecrutement())
      })
      .catch((error) => {
        store.dispatch(setFullPageLoaderPutAccount(false))
      })
      :
      axios.put(`${URL}score-formations/${scoreId}`, {
        data: {
          score: scoreRecrutement,
          global_time: timeRecrutement,
        }
      },
      {
        headers: {
          Authorization:
          `Bearer ${state.login.session.jwt}`,
        }
      })
      .then((response) => {
      })
      .catch((error) => {
        store.dispatch(setFullPageLoaderPutAccount(false))
      })

      next(action);
      break;
    }
    case FETCH_POST_SCORE_RECRUTEMENT: {
      const state = store.getState();
      const particulierId = state.login.userInfo.data[0].id

      axios.post(`${URL}score-formations`, {
        data: { 
        particulier: particulierId,
        score: 0,
        global_time: 0,
        session_id: 'recrutement'
        }
      },
      {
        headers: {
          Authorization:
          `Bearer ${state.login.session.jwt}`,
        }
      })
      .then((response) => {
        store.dispatch(saveScoreRecrutement(response.data.data.id))
      })
      .catch((error) => {
      });

      next(action);
      break;
    }
    case FETCH_GET_SCORE_RECRUTEMENT: {
      store.dispatch(setFullPageLoaderRecrutementObj(true))
      const state = store.getState();
      const particulierId = state.login.userInfo.data[0].id

      const queryGetScoreRecrutement = qs.stringify({
        filters: {
          session_id: 'recrutement',
          particulier: state.login.isLogged ? particulierId : ''
        }
        }, {
          encodeValuesOnly: true,
        });  

      axios.get(`${URL}score-formations?${queryGetScoreRecrutement}`,
      {
        headers: {
          Authorization:
          `Bearer ${state.login.session.jwt}`,
        }
      })
      .then((response) => {
        store.dispatch(saveScoreRecrutementObject(response.data.data))
      })
      .catch((error) => {
        store.dispatch(setFullPageLoaderRecrutementObj(false))
      });

      next(action);
      break;
    }
    default:
      next(action);
  }
};

export default accountMiddleware;