/**** Exemple d'action ****/
export const CHANGE_INPUT_VALUE = 'CHANGE_INPUT_VALUE'
export const SET_WINDOW_TOP = 'SET_WINDOW_TOP'
export const FETCH_SEND_EMAIL_CONTACT = 'FETCH_SEND_EMAIL_CONTACT'
export const FETCH_POST_NEWSLETTER = 'FETCH_POST_NEWSLETTER'
export const SET_FULL_PAGE_LOADER = 'SET_FULL_PAGE_LOADER'
export const SET_FULL_PAGE_LOADER_CONNEXION = 'SET_FULL_PAGE_LOADER_CONNEXION'
export const SET_FULL_PAGE_LOADER_JOBOFFER = 'SET_FULL_PAGE_LOADER_JOBOFFER'
export const SET_FULL_PAGE_LOADER_CONTACT = 'SET_FULL_PAGE_LOADER_CONTACT'
export const SET_FULL_PAGE_LOADER_FACTURATION = 'SET_FULL_PAGE_LOADER_FACTURATION'

export const changeInputValue = (value, name) => ({
    type: CHANGE_INPUT_VALUE,
    value,
    name
})

export const setWindowTop = (bool) => ({
    type: SET_WINDOW_TOP,
    bool
})

export const fetchSendEmailContact = () => ({
    type: FETCH_SEND_EMAIL_CONTACT,
})

export const fetchPostNewsletter = () => ({
    type: FETCH_POST_NEWSLETTER,
})

export const setFullPageLoader = (bool) => ({
    type: SET_FULL_PAGE_LOADER,
    bool
})

export const setFullPageLoaderConnexion = (bool) => ({
    type: SET_FULL_PAGE_LOADER_CONNEXION,
    bool
})

export const setFullPageLoaderJobOffer = (bool) => ({
    type: SET_FULL_PAGE_LOADER_JOBOFFER,
    bool
})

export const setFullPageLoaderContact = (bool) => ({
    type: SET_FULL_PAGE_LOADER_CONTACT,
    bool
})

export const setFullPageLoaderFacturation = (bool) => ({
    type: SET_FULL_PAGE_LOADER_FACTURATION,
    bool
})