import { combineReducers } from 'redux'

import financement from './financement'
import menu from './menu'
import course from './course'
import job from './job'
import login from './login'
import signin from './signin'
import modal from './modal'
import actions from './actions'
import ocean from './ocean'
import lms from './lms'
import news from './news'
import maintenance from './maintenance'


export default combineReducers({
    financement,
    menu,
    course,
    job,
    login,
    signin,
    modal,
    actions,
    ocean,
    lms,
    news,
    maintenance
  });