/**** Import des modules ****/
import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import {AiOutlineClose, AiOutlineDown } from "react-icons/ai"
import ReactMarkdown from 'react-markdown'
import axios from 'axios'
import * as qs from 'qs'


/**** Import des images ****/
import docgrey from '../../assets/img/illustration/docgrey.png'
import graphpink from '../../assets/img/illustration/graphpink.png'
import poucegreen from '../../assets/img/illustration/poucegreen.png'
import programme from '../../assets/img/illustration/programme.png'
import loupegreen from '../../assets/img/illustration/loupegreen.png'
import trophe from '../../assets/img/illustration/trophe.png'
import timeyellow from '../../assets/img/illustration/timeyellow.png'
import arrowyellowgreen from '../../assets/img/illustration/arrowyellowgreen.png'
import logoformateur from '../../assets/img/logo/logo-qualiopi.png'
import logo from '../../assets/img/illustration/femme-desk.png'


/**** Import des components et containers ****/
import { Skeleton } from '@mui/material'
import urlBackEnd from '../../assets/js/urlBackEnd'



/**** Import des css ****/
import ModalCourseProgStyled from './ModalCourseProgStyled'

const URL = urlBackEnd

const ModalCourseProg = ({openModal, formations, setModalCourseProgOpen, activCourseId, userRole, templatesLms, coursesLms}) => {

  const [secteurCart, setSecteurCart] = useState([]);

  useEffect(() => {
    const queryFilterPart = qs.stringify({
      filters: {
        formations: {
          id: activCourseId
        },
      }
      }, {
        encodeValuesOnly: true,
      });

    axios.get(`${URL}secteurs?${queryFilterPart}`, {})
      .then((response) => {
       setSecteurCart(response.data.data)
      })
      .catch((error) => {
        console.error(error);
      });
  }
  , [])

  const currentFormationId = activCourseId;

  let currentFormation = () => {
    if (formations !== undefined) {
      let currentFormation = formations.find(x => x.id === currentFormationId)
      return currentFormation;
    }
  }
  
  let euroFormat = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
  });

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setModalCourseProgOpen(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  
  return (
    <ModalCourseProgStyled>
      <section className="modal-content-container">
      {formations !== undefined &&
      <div className="page-wrapper" ref={wrapperRef}>
      <section className="illustration-header">
        <div className="opacity-light-section">
        <img src={logo} alt='logo wefor'/>
          <div className="left">
          <div className="title-wrapper-prog">
                <h2 className="bold">E-learning {currentFormation().attributes.duree} H</h2>
                {secteurCart.length > 0 ? 
                <>
                  <h3 className="bold top">{secteurCart[0].attributes.nom}</h3>
                  </>
                :
                  <Skeleton variant="text" className='txt-skeleton' />
                }
                <h1 className="bold">{currentFormation().attributes.nom}</h1>
              </div>
          </div>
        </div>
      </section>
      <main>
        <section className="section-programme-formation">
          {/*<h2>Prix de la formation<span className="separator-blue"></span></h2>*/}
          <div className="tarif-wrapper">
            <div className="tarif-formation bold">{euroFormat.format(currentFormation().attributes.prix)} TTC</div>
            {userRole === "entreprise" ? 
            <button 
            className="btn-lightblue"
              onClick={() => openModal("inscription-collab")}
            >Inscrire un collaborateur</button>
            :
            <button 
              className="btn-lightblue"
              onClick={() => openModal('formation')}
            >S'inscrire</button>
            }
          </div>
          {/* <h2 className="no-margin">Programme de la formation<span className="separator-blue"></span></h2> */}
          <article className="article-programme-description">
            <img src={poucegreen} className="illustration-article-left" alt="icon pouce en l'air" />
            <div className="article-list-wrapper">
              <h3 className="bold">Prérequis</h3>
              <ReactMarkdown>{currentFormation().attributes.prerequis}</ReactMarkdown>
            </div>
          </article>
        </section>
        <section className="section-module-formation">
          {/*
          <div className="design-green-top">
            <div className="separator-green"></div>
            <img src={loupegreen} alt="loupe avec fond vert" />
            <div className="separator-green"></div>
          </div>
          */}
          <article className="module-formation-list">
            <div className="left-prog">
              <h3 className="bold">Programme de la formation</h3>
              {templatesLms.length > 0 && templatesLms.find(template => template._id === currentFormation().attributes.lms_formation_id).elements.map((module, index) => (
              <>
              { !module.title.includes('entraînement') && !module.title.includes('approfondissement') && !module.title.includes('Validation') &&
              <div key={module.id}>
                <h4 className="module-title bold">{module.title.substr(9)}</h4>
                <div className="modules-wrapper" dangerouslySetInnerHTML={ {__html :coursesLms.find(moduleLms => module._id === moduleLms._id) !== undefined ? coursesLms.find(moduleLms => module._id === moduleLms._id).description : ''} }></div>
              </div>
              }
              </>
            ))}
            <div className="programme-disclaimer bold">Vous allez découvrir des pages comportant des apports de connaissances, qui seront suivies, page après page, d'activités pédagogiques de validation de vos acquis (une page de contenu, suivie d'une activité pédagogique, suivie d'une nouvelle page de contenu, suivi d'une autre activité pédagogique, etc.) </div>
            </div>
          </article>
          <AiOutlineDown className="arrow-bottom"/>
          {/*
          <div className="design-blue-bottom">
            <div className="separator-blue"></div>
            <img src={trophe} alt="trophé avec fond bleu" />
            <div className="separator-blue"></div>
          </div>
          */}
        </section>
        <section className="section-modalité-formation">
            <article className="time-prog-course">
              <img src={timeyellow} alt="ampoule ronde jaune" />
              <div className="time-wrapper">
                <h3 className="bold">Durée de la formation :</h3>
                <p className="date bold">{currentFormation().attributes.duree}H</p>
                <h3 className="bold">Modalités et délais d'accès :</h3>
                <ReactMarkdown className="txt">{currentFormation().attributes.prerequis}</ReactMarkdown>
              </div>
            </article>
            {/* 
            <article className="modalite-prog-course">
              <img src={arrowyellowgreen} alt="flêche jaune et vert" />
              <div className="evaluation-wrapper">
                <h3 className="bold">Modalités d'évaluation</h3>
                <ul className="list-eval">
                  <li className="bold">{currentFormation().attributes.modalites_evaluation}</li>
                </ul>
              </div>
            </article>
            */}
            <article className="formateur-prog-course">
            <h3 className="bold">WEFOR</h3>
              <h3 className="bold">Organisme certifié QUALIOPI</h3>
              <div className="formateur-wrapper">
                <div className="img-wrapper">
                  <img src={logoformateur} alt="logo du formateur" />
                </div>
                <div className="txt-formateur">
                  <h4 className="bold">QUALIOPI</h4>
                  <p>Seule la certification Qualiopi permet d’être référencé auprès des financeurs publics et paritaires : OPCO, Pôle emploi, Caisse des Dépôts, Régions et Collectivités. Qualiopi sera l’unique moyen d’accéder au fichier national des organismes référencés par les financeurs publics et paritaires en lieu et place du système de référencement mis en place avec la réforme de 2014 composé notamment du Datadock.</p>
                </div>
              </div>
            </article>
        </section>
      </main>
      </div>
      }
        <div className="icon-wrapper">
            <AiOutlineClose 
            className="close-icon"
            onClick={() => setModalCourseProgOpen(false)}
            />
        </div>
      </section>
    </ModalCourseProgStyled>
  )
}

ModalCourseProg.propTypes = {

}

export default ModalCourseProg