export const FETCH_GET_NEWS = 'FETCH_GET_NEWS'
export const CHANGE_SEARCH_NEWS = 'CHANGE_SEARCH_NEWS'
export const SAVE_NEWS = 'SAVE_NEWS'
export const TOGGLE_FULL_PAGE_LOADER_NEWS = 'TOGGLE_FULL_PAGE_LOADER_NEWS'

export const fetchGetNews = (search) => ({
    type: FETCH_GET_NEWS,
	search
})

export const changeSearchNews = (value) => ({
    type: CHANGE_SEARCH_NEWS,
	value
})

export const saveNews = (data) => ({
    type: SAVE_NEWS,
	data
})

export const toggleFullPageLoaderNews = (bool) => ({
    type: TOGGLE_FULL_PAGE_LOADER_NEWS,
	bool
})