import { connect } from 'react-redux'

import Society from '../../../components/SignIn/Society'

import { 
    changeSigninStep,
    changeValueSignin,
    fetchPostUserParticulier,
    fetchSearchAdresse
} from '../../../actions/signin'

import { 
    changeIsLogged,
    fetchPostUserEntreprise,
    fetchSiren,
    changeValue
} from '../../../actions/login'

import { 
    closeModal,
    openModal,
    changeFormModal
} from '../../../actions/modal'

const mapStateToProps = (state) => ({
    JobSectors: state.signin.JobSectors,
    accountJobSector: state.signin.accountJobSector,
    formModal: state.modal.formModal,
    modalName: state.modal.modalName,
    openModal: state.modal.openModal,
    accountSecteurSignin: state.signin.accountSecteurSignin,
    sirenData: state.login.sirenData,
    accountEntrepriseSignin: state.signin.accountEntrepriseSignin,
    accountSirenSignin: state.signin.accountSirenSignin,
    accountAdresseSignin: state.signin.accountAdresseSignin,
    accountConfirmationPasswordSignin: state.signin.accountConfirmationPasswordSignin,
    accountPasswordSignin: state.signin.accountPasswordSignin,
    adresseList: state.signin.adresseList,
    accountAdresseNumStreetSignin: state.signin.accountAdresseNumStreetSignin,
    accountAdresseStreetSignin: state.signin.accountAdresseStreetSignin ,
    accountAdresseCodeSignin: state.signin.accountAdresseCodeSignin ,
    accountAdresseCitySignin: state.signin.accountAdresseCitySignin
})

const mapDispatchToProps = (dispatch) => ({
    changeValueSignin: (value, name) => {
        dispatch(changeValueSignin(value, name));
      },
      changeIsLogged: (bool) => {
        dispatch(changeIsLogged(bool));
    },
    closeModal: (name) => {
        dispatch(closeModal(name));
    },
    changeFormModal: (string) => {
        dispatch(changeFormModal(string));
      },
    openModal: (name) => {
        dispatch(openModal(name));
    },
    fetchPostUserEntreprise: () => {
        dispatch(fetchPostUserEntreprise());
    },
    fetchSiren: (siren) => {
        dispatch(fetchSiren(siren));
    },
    fetchSearchAdresse: (adresse) => {
        dispatch(fetchSearchAdresse(adresse));
    },
    changeValue: (value, name) => {
        dispatch(changeValue(value, name));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(Society)