/**** Import des modules ****/
import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { useNavigate, Link, Navigate } from 'react-router-dom'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { toast } from 'react-toastify'
import CircularProgress from '@mui/material/CircularProgress'
import { MdArrowDropUp, MdArrowDropDown } from "react-icons/md";
import { FaFileAlt } from "react-icons/fa";

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import AccountMenu from '../../containers/AccountMenu'
import MyTimer from '../../containers/MyTimer'

/**** Import des images ****/


/**** Import des css ****/
import AccountRecrutementStyled from './AccountRecrutementStyled'
import { setDidTest } from '../../actions/job'

const tabNum = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]

const AccountRecrutement = ({isLogged, userRole, fetchScoreRecrutementParticulier, timeRecrutement, toggleModalRecrutement, modalRecrutement,fetchPostScoreRecrutement, fetchGetScoreRecrutement, scoreRecrutementObject, setDidTest, secteurs}) => {

  useEffect(fetchGetScoreRecrutement, []);

  const [modalTest, setModalTest] = useState(false)
  const [pageTest, setPageTest] = useState(1)
  const [isClicked, setIsClicked] = useState(false)
  const [valueQ, setValueQ] = useState(3)
  const [scoreQ, setScoreQ] = useState(0)
  const [menuOne, setMenuOne] = useState(false);
  const [menuTwo, setMenuTwo] = useState(false);
  const [secteurId, setSecteurId] = useState(false);
  const [testId, setTestId] = useState(false);

  const time = new Date();
  
  time.setSeconds(time.getSeconds() + 600);

  const handleValidQ = (value, reponse) => {
    if (isClicked === true) {
      if (pageTest !== 20) {
        setPageTest(pageTest + 1)

        if (parseInt(valueQ, 10) === 1) {
          toast(`Bravo ! Bonne réponse.`, {
            position: "bottom-right",
            type: "success",
            theme:"colored",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
          setScoreQ(scoreQ + 1)
          let scoreToAdd = scoreQ + 1
          fetchScoreRecrutementParticulier(scoreToAdd, timeRecrutement)
        } else {
          toast(`Mauvaise réponse. La bonne réponse est : ${reponse}`, {
            position: "bottom-right",
            type: "error",
            theme:"colored",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
          fetchScoreRecrutementParticulier(scoreQ, timeRecrutement)
        }
        setIsClicked(false)
        setValueQ(3)
      } else {
        toggleModalRecrutement(false)
      }
    } else {
      toast(`Merci de répondre à la question`, {
        position: "bottom-right",
        type: "error",
        theme:"colored",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    }
  }

  if(!isLogged) {
    return (<Navigate to="/" replace />)
  }

  console.log(testId)
  
  return (
    <AccountRecrutementStyled>
      <Header bcgColor={'#fff'}/>
      {isLogged && userRole !== 'entreprise' &&
      <main className="page-wrapper">
      <AccountMenu />
      <section className="right-wrapper w-score">
        <div className="secteur-choice">
          <div 
            className='drop-down-1' 
            onClick={() => {
              setMenuOne(!menuOne)
            }}>
            <h4 className="bold">Réalisez un test</h4>
            <div className="bold info-dyna">{secteurs.length} secteur{secteurs.length > 1 ? 's' : ''} disponible{secteurs.length > 1 ? 's' : ''}</div>
            <div className='arrow-wrapper' onClick={() => setMenuOne(!menuOne)}>
              {menuOne ? 
                <MdArrowDropUp className="arrow-down" />
              :
                <MdArrowDropDown className="arrow-down"/>
              }
          </div>
          </div>
          {secteurs.length > 0 && menuOne &&
          <div className='formations-wrapper'>
            {secteurs.map((secteur, index) => (
              <div key={index} onClick={() => {
                setSecteurId(secteur.id)
                setMenuOne(false)
              }}>
                <p className="list-paragraphe"><FaFileAlt />{secteur.attributes.nom}</p>
                <span className="bold">Passer ce test</span>
              </div>
            ))}
          </div>
          }
        </div>
        {secteurId !== 1 ?
        <div>Il n'existe pas encore de test pour ce secteur, merci de réessayer ultérieurement.</div>
        :
        <div className='test-cond-wrapper'>
          {scoreRecrutementObject.length === 0 ?
          <>
          <h3 className="bold">Test de recrutement</h3>
          <p>Merci de lire les conditions du test avant de commencer</p>
          <div className="condition-wrapper"> 
          <h4 className="bold">Conditions du test :</h4>
          <ul>
            <li>Il comporte 20 questions.</li>
            <li>Il dure maximum 10 minutes. Attention, si vous commencez le test, les résultats sont directement enregistrés.</li>
            <li>Si vous quittez ou ne finissez pas celui-ci, votre score sera quand même enregistré.</li>
            <li>Vous ne pouvez le passer qu'une seule fois.</li>
            <li>Nous enregistrons votre resultat qui comporte une note sur 20 et votre temps de réalisation.</li>
            <li>Les employeurs pour qui vous avez postulé seront au courant de ces résultats.</li>
            <li>Le test commence lorque vous cliquez sur "commencer le test".</li>
          </ul>
          <button 
            className='btn-show-test'
            onClick={()=> {
              toggleModalRecrutement(true)
              fetchPostScoreRecrutement()
            }}
          >
            Commencer le test
          </button>
          </div>
          </>
          :
          <div className='center'>Vous avez déjà passé ce test. Il faut attendre 3 mois pour pouvoir le repasser.</div>
          }
        </div>
        }
        {scoreRecrutementObject.length > 0 &&
        <div className="test-choice">
          <div 
            className='drop-down-1' 
            onClick={() => {
              setMenuTwo(!menuTwo)
            }}>
            <h4 className="bold">Consultez les résultats de vos tests</h4>
            <div className="bold info-dyna">{scoreRecrutementObject.length} test{scoreRecrutementObject.length > 1 ? 's' : ''} disponible{scoreRecrutementObject.length > 1 ? 's' : ''}</div>
            <div className='arrow-wrapper' onClick={() => setMenuTwo(!menuTwo)}>
              {menuTwo ? 
                <MdArrowDropUp className="arrow-down" />
              :
                <MdArrowDropDown className="arrow-down"/>
              }
          </div>
          </div>
          {scoreRecrutementObject.length > 0 && menuTwo &&
          <div className='formations-wrapper'>
            {scoreRecrutementObject.map((test, index) => (
              <div key={index} onClick={() => {
                setTestId(index)
                setMenuTwo(false)
              }}>
                <p className="list-paragraphe"><FaFileAlt />Assurance</p>
                <span className="bold">Voir le résultat</span>
              </div>
            ))}
          </div>
          }
        </div>
        }
        {scoreRecrutementObject.length > 0 && testId !== false &&
          <section className="right-wrapper w-score">
          <h2>Assurance</h2>
          <div className='w-score'>
            <div className="circle-wrapper">
              <CircularProgress className="progress-circle" color='success' size={180} variant="determinate" value={scoreRecrutementObject[testId].attributes.score / 20 * 100} />
              <div className="score-div">
                <span className='top bold'>{scoreRecrutementObject[testId].attributes.score}</span>
                <span className='middle bold'>/</span>
                <span className='bottom bold'>20</span>
              </div>
            </div>
            <div className="temps-wrapper">
              <span className='temps-recrutement bold'>{Math.trunc(scoreRecrutementObject[testId].attributes.global_time / 60)}.{scoreRecrutementObject[testId].attributes.global_time - (Math.trunc(scoreRecrutementObject[testId].attributes.global_time / 60) * 60)}min</span>
            </div>
          </div>
        </section>
        }
      </section>
      {modalRecrutement &&
      <section className="modal-wrapper">
        <div className="test-wrapper">
        <MyTimer expiryTimestamp={time}/>
        <div className="num-wrapper">
          {tabNum.map((number, index) => (
            <div key={index} className={number < pageTest ? 'number-circle plain' : 'number-circle'}>{number}</div>
          ))}
        </div>
        <section className="question-section-wrapper">
          {pageTest === 1 &&
          <FormControl className="question-wrapper">
            <FormLabel id="question-one">Question 1 : Comment peut-on définir l’ACPR ?</FormLabel>
            <RadioGroup
              aria-labelledby="question-one"
              defaultValue=""
              name="question-one"
              onChange={(event) => {
                setIsClicked(true)
                setValueQ(event.target.value)
              }}
            >
              <FormControlLabel value={0} control={<Radio />} label="C’est l’organe de supervision français de l’ensemble des professionnels du secteur financier" />
              <FormControlLabel value={2} control={<Radio />} label="C’est l’organe de supervision européen des secteurs de la banque et de l’assurance" />
              <FormControlLabel value={1} control={<Radio />} label="C’est l’organe de supervision français des secteurs de la banque et de l’assurance" />
            </RadioGroup>
            <button 
              className="validate-btn" 
              onClick={() => {handleValidQ(valueQ, 'C’est l’organe de supervision français des secteurs de la banque et de l’assurance')}}
            >
              Valider
            </button>
          </FormControl>
          }
          {pageTest === 2 &&
          <FormControl className="question-wrapper">
            <FormLabel id="question-two">Question 2 : Le CCSF est un organisme de contrôle des assureurs et des banquiers</FormLabel>
            <RadioGroup
              aria-labelledby="question-two"
              defaultValue=""
              name="question-two"
              onChange={(event) => {
                setIsClicked(true)
                setValueQ(event.target.value)
              }}
            >
              <FormControlLabel value={0} control={<Radio />} label="Vrai" />
              <FormControlLabel value={1} control={<Radio />} label="Faux" />
            </RadioGroup>
            <button 
              className="validate-btn" 
              onClick={() => {handleValidQ(valueQ, 'Faux')}}
            >
              Valider
            </button>
          </FormControl>
          }
          {pageTest === 3 &&
          <FormControl className="question-wrapper">
            <FormLabel id="question-three">Question 3 : Comment peut-on définir l’AMF ?</FormLabel>
            <RadioGroup
              aria-labelledby="question-three"
              defaultValue=""
              name="question-three"
              onChange={(event) => {
                setIsClicked(true)
                setValueQ(event.target.value)
              }}
            >
              <FormControlLabel value={1} control={<Radio />} label="C’est le régulateur des acteurs et des produits de la place financière française" />
              <FormControlLabel value={0} control={<Radio />} label="C’est le régulateur des acteurs et des produits des places financières européennes" />
              <FormControlLabel value={2} control={<Radio />} label="C’est le régulateur des acteurs et des produits des places financières mondiales" />
            </RadioGroup>
            <button 
              className="validate-btn" 
              onClick={() => {handleValidQ(valueQ, 'C’est le régulateur des acteurs et des produits de la place financière française')}}
            >
              Valider
            </button>
          </FormControl>
          }
          {pageTest === 4 &&
          <FormControl className="question-wrapper">
            <FormLabel id="question-four">Question 4 : L’ORIAS n’est pas un organisme de contrôle de l'activité des intermédiaires d’assurance</FormLabel>
            <RadioGroup
              aria-labelledby="question-four"
              defaultValue=""
              name="question-four"
              onChange={(event) => {
                setIsClicked(true)
                setValueQ(event.target.value)
              }}
            >
              <FormControlLabel value={1} control={<Radio />} label="Vrai" />
              <FormControlLabel value={0} control={<Radio />} label="Faux" />
            </RadioGroup>
            <button 
              className="validate-btn" 
              onClick={() => {handleValidQ(valueQ, 'Vrai')}}
            >
              Valider
            </button>
          </FormControl>
          }
          {pageTest === 5 &&
          <FormControl className="question-wrapper">
            <FormLabel id="question-five">Question 5 : L’assuré ne connait pas le réassureur de son assureur</FormLabel>
            <RadioGroup
              aria-labelledby="question-five"
              defaultValue=""
              name="question-five"
              onChange={(event) => {
                setIsClicked(true)
                setValueQ(event.target.value)
              }}
            >
              <FormControlLabel value={1} control={<Radio />} label="Vrai" />
              <FormControlLabel value={0} control={<Radio />} label="Faux" />
            </RadioGroup>
            <button 
              className="validate-btn" 
              onClick={() => {handleValidQ(valueQ, 'Vrai')}}
            >
              Valider
            </button>
          </FormControl>
          }
          {pageTest === 6 &&
          <FormControl className="question-wrapper">
            <FormLabel id="question-six">Question 6 : C’est le réassureur qui indemnise directement l’assuré qui a subi un sinistre</FormLabel>
            <RadioGroup
              aria-labelledby="question-six"
              defaultValue=""
              name="question-six"
              onChange={(event) => {
                setIsClicked(true)
                setValueQ(event.target.value)
              }}
            >
              <FormControlLabel value={0} control={<Radio />} label="Vrai" />
              <FormControlLabel value={1} control={<Radio />} label="Faux" />
            </RadioGroup>
            <button 
              className="validate-btn" 
              onClick={() => {handleValidQ(valueQ, 'Faux')}}
            >
              Valider
            </button>
          </FormControl>
          }
          {pageTest === 7 &&
          <FormControl className="question-wrapper">
            <FormLabel id="question-seven">Question 7 : Définissez ce qu’est Solvabilité II</FormLabel>
            <RadioGroup
              aria-labelledby="question-seven"
              defaultValue=""
              name="question-seven"
              onChange={(event) => {
                setIsClicked(true)
                setValueQ(event.target.value)
              }}
            >
              <FormControlLabel value={0} control={<Radio />} label="Réforme réglementaire française" />
              <FormControlLabel value={1} control={<Radio />} label="Réforme réglementaire européenne" />
              <FormControlLabel value={2} control={<Radio />} label="Réforme réglementaire mondiale" />
            </RadioGroup>
            <button 
              className="validate-btn" 
              onClick={() => {handleValidQ(valueQ, 'Réforme réglementaire européenne')}}
            >
              Valider
            </button>
          </FormControl>
          }
          {pageTest === 8 &&
          <FormControl className="question-wrapper">
            <FormLabel id="question-eight">Question 8 : Quel terme est habituellement utilisé comme équivalent de « cotisation » ?</FormLabel>
            <RadioGroup
              aria-labelledby="question-eight"
              defaultValue=""
              name="question-eight"
              onChange={(event) => {
                setIsClicked(true)
                setValueQ(event.target.value)
              }}
            >
              <FormControlLabel value={0} control={<Radio />} label="Quotité" />
              <FormControlLabel value={1} control={<Radio />} label="Prime" />
              <FormControlLabel value={2} control={<Radio />} label="Contribution" />
            </RadioGroup>
            <button 
              className="validate-btn" 
              onClick={() => {handleValidQ(valueQ, 'Prime')}}
            >
              Valider
            </button>
          </FormControl>
          }
          {pageTest === 9 &&
          <FormControl className="question-wrapper">
            <FormLabel id="question-nine">Question 9 : Un assureur peut refuser d’assurer un risque dit « aggravé »</FormLabel>
            <RadioGroup
              aria-labelledby="question-nine"
              defaultValue=""
              name="question-nine"
              onChange={(event) => {
                setIsClicked(true)
                setValueQ(event.target.value)
              }}
            >
              <FormControlLabel value={1} control={<Radio />} label="Vrai" />
              <FormControlLabel value={0} control={<Radio />} label="Faux" />
            </RadioGroup>
            <button 
              className="validate-btn" 
              onClick={() => {handleValidQ(valueQ, 'Vrai')}}
            >
              Valider
            </button>
          </FormControl>
          }
          {pageTest === 10 &&
          <FormControl className="question-wrapper">
            <FormLabel id="question-ten">Question 10 : Une entreprise subit un incendie qui entraîne une perte de marchandises pour un montant de 50 000 €. L’entreprise était assurée pour un montant maximum de marchandises de 50 000 €. Or, l’expertise a démontré que l’entreprise disposait d’un stock d’une valeur de 80 000 € au moment du sinistre. Quel sera le montant de l’indemnisation après application de la règle proportionnelle de capitaux ?</FormLabel>
            <RadioGroup
              aria-labelledby="question-ten"
              defaultValue=""
              name="question-ten"
              onChange={(event) => {
                setIsClicked(true)
                setValueQ(event.target.value)
              }}
            >
              <FormControlLabel value={1} control={<Radio />} label="31 250 euros" />
              <FormControlLabel value={0} control={<Radio />} label="50 000 euros" />
              <FormControlLabel value={2} control={<Radio />} label="80 000 euros" />
            </RadioGroup>
            <button 
              className="validate-btn" 
              onClick={() => {handleValidQ(valueQ, '31 250 euros')}}
            >
              Valider
            </button>
          </FormControl>
          }
          {pageTest === 11 &&
          <FormControl className="question-wrapper">
            <FormLabel id="question-eleven">Question 11 : Comment calcule t-on l’indemnisation en cas d’application de la règle proportionnelle de primes ?</FormLabel>
            <RadioGroup
              aria-labelledby="question-eleven"
              defaultValue=""
              name="question-eleven"
              onChange={(event) => {
                setIsClicked(true)
                setValueQ(event.target.value)
              }}
            >
              <FormControlLabel value={1} control={<Radio />} label="Montant des dommages x Prime payée / Prime due" />
              <FormControlLabel value={0} control={<Radio />} label="Montant des dommages x Prime due / Prime payée" />
              <FormControlLabel value={2} control={<Radio />} label="Prime due x Prime payée / Montant des dommages" />
            </RadioGroup>
            <button 
              className="validate-btn" 
              onClick={() => {handleValidQ(valueQ, 'Montant des dommages x Prime payée / Prime due')}}
            >
              Valider
            </button>
          </FormControl>
          }
          {pageTest === 12 &&
          <FormControl className="question-wrapper">
            <FormLabel id="question-twelve">Question 12 : Un courtier qui n’encaisse aucun fonds doit quand même avoir une garantie financière</FormLabel>
            <RadioGroup
              aria-labelledby="question-twelve"
              defaultValue=""
              name="question-twelve"
              onChange={(event) => {
                setIsClicked(true)
                setValueQ(event.target.value)
              }}
            >
              <FormControlLabel value={0} control={<Radio />} label="Vrai" />
              <FormControlLabel value={1} control={<Radio />} label="Faux" />
            </RadioGroup>
            <button 
              className="validate-btn" 
              onClick={() => {handleValidQ(valueQ, 'Faux')}}
            >
              Valider
            </button>
          </FormControl>
          }
          {pageTest === 13 &&
          <FormControl className="question-wrapper">
            <FormLabel id="question-thirteen">Question 13 : Un courtier doit analyser plusieurs contrats pour un même risque à assurer avant sa proposition au client</FormLabel>
            <RadioGroup
              aria-labelledby="question-thirteen"
              defaultValue=""
              name="question-thirteen"
              onChange={(event) => {
                setIsClicked(true)
                setValueQ(event.target.value)
              }}
            >
              <FormControlLabel value={1} control={<Radio />} label="Vrai" />
              <FormControlLabel value={0} control={<Radio />} label="Faux" />
            </RadioGroup>
            <button 
              className="validate-btn" 
              onClick={() => {handleValidQ(valueQ, 'Vrai')}}
            >
              Valider
            </button>
          </FormControl>
          }
          {pageTest === 14 &&
          <FormControl className="question-wrapper">
            <FormLabel id="question-fourteen">Question 14 : Le consentement du prospect doit s’exprimer par un comportement actif, par oral ou par écrit </FormLabel>
            <RadioGroup
              aria-labelledby="question-fourteen"
              defaultValue=""
              name="question-fourteen"
              onChange={(event) => {
                setIsClicked(true)
                setValueQ(event.target.value)
              }}
            >
              <FormControlLabel value={0} control={<Radio />} label="Vrai" />
              <FormControlLabel value={1} control={<Radio />} label="Faux" />
            </RadioGroup>
            <button 
              className="validate-btn" 
              onClick={() => {handleValidQ(valueQ, 'Faux')}}
            >
              Valider
            </button>
          </FormControl>
          }
          {pageTest === 15 &&
          <FormControl className="question-wrapper">
            <FormLabel id="question-fifteen">Question 15 : La proposition d’assurance n’est signée que par l’intermédiaire</FormLabel>
            <RadioGroup
              aria-labelledby="question-fifteen"
              defaultValue=""
              name="question-fifteen"
              onChange={(event) => {
                setIsClicked(true)
                setValueQ(event.target.value)
              }}
            >
              <FormControlLabel value={0} control={<Radio />} label="Vrai" />
              <FormControlLabel value={1} control={<Radio />} label="Faux" />
            </RadioGroup>
            <button 
              className="validate-btn" 
              onClick={() => {handleValidQ(valueQ, 'Faux')}}
            >
              Valider
            </button>
          </FormControl>
          }
          {pageTest === 16 &&
          <FormControl className="question-wrapper">
            <FormLabel id="question-sixteen">Question 16 : La proposition d’assurance engage l’assureur à accepter le risque</FormLabel>
            <RadioGroup
              aria-labelledby="question-sixteen"
              defaultValue=""
              name="question-sixteen"
              onChange={(event) => {
                setIsClicked(true)
                setValueQ(event.target.value)
              }}
            >
              <FormControlLabel value={0} control={<Radio />} label="Vrai" />
              <FormControlLabel value={1} control={<Radio />} label="Faux" />
            </RadioGroup>
            <button 
              className="validate-btn" 
              onClick={() => {handleValidQ(valueQ, 'Faux')}}
            >
              Valider
            </button>
          </FormControl>
          }
          {pageTest === 17 &&
          <FormControl className="question-wrapper">
            <FormLabel id="question-seventeen">Question 17 : Une note de couverture est une attestation</FormLabel>
            <RadioGroup
              aria-labelledby="question-seventeen"
              defaultValue=""
              name="question-seventeen"
              onChange={(event) => {
                setIsClicked(true)
                setValueQ(event.target.value)
              }}
            >
              <FormControlLabel value={0} control={<Radio />} label="De garantie définitive" />
              <FormControlLabel value={1} control={<Radio />} label="De garantie provisoire" />
              <FormControlLabel value={2} control={<Radio />} label="D’assurance habitation" />
            </RadioGroup>
            <button 
              className="validate-btn" 
              onClick={() => {handleValidQ(valueQ, 'De garantie provisoire')}}
            >
              Valider
            </button>
          </FormControl>
          }
          {pageTest === 18 &&
          <FormControl className="question-wrapper">
            <FormLabel id="question-eighteen">Question 18 : Pour recevoir une note de couverture, il faut</FormLabel>
            <RadioGroup
              aria-labelledby="question-eighteen"
              defaultValue=""
              name="question-eighteen"
              onChange={(event) => {
                setIsClicked(true)
                setValueQ(event.target.value)
              }}
            >
              <FormControlLabel value={1} control={<Radio />} label="Régler une partie de la cotisation au comptant" />
              <FormControlLabel value={0} control={<Radio />} label="Régler la totalité de la cotisation annuelle d’avance" />
              <FormControlLabel value={2} control={<Radio />} label="Régler la cotisation à date échue" />
            </RadioGroup>
            <button 
              className="validate-btn" 
              onClick={() => {handleValidQ(valueQ, 'Régler une partie de la cotisation au comptant')}}
            >
              Valider
            </button>
          </FormControl>
          }
          {pageTest === 19 &&
          <FormControl className="question-wrapper">
            <FormLabel id="question-nineteen">Question 19 : Quel document faisant partie du contrat est personnalisé</FormLabel>
            <RadioGroup
              aria-labelledby="question-nineteen"
              defaultValue=""
              name="question-nineteen"
              onChange={(event) => {
                setIsClicked(true)
                setValueQ(event.target.value)
              }}
            >
              <FormControlLabel value={0} control={<Radio />} label="Les dispositions générales" />
              <FormControlLabel value={1} control={<Radio />} label="Les dispositions particulières" />
              <FormControlLabel value={2} control={<Radio />} label="La notice d’information" />
            </RadioGroup>
            <button 
              className="validate-btn" 
              onClick={() => {handleValidQ(valueQ, 'Les dispositions particulières')}}
            >
              Valider
            </button>
          </FormControl>
          }
          {pageTest === 20 &&
          <FormControl className="question-wrapper">
            <FormLabel id="question-twenty">Question 20 : En cas de non respect de la loi Chatel par l’assureur, l’assuré peut</FormLabel>
            <RadioGroup
              aria-labelledby="question-twenty"
              defaultValue=""
              name="question-twenty"
              onChange={(event) => {
                setIsClicked(true)
                setValueQ(event.target.value)
              }}
            >
              <FormControlLabel value={0} control={<Radio />} label="Résilier son contrat au 1er janvier prochain" />
              <FormControlLabel value={1} control={<Radio />} label="Résilier son contrat à tout moment" />
              <FormControlLabel value={2} control={<Radio />} label="Résilier son contrat à la prochaine échéance annuelle" />
            </RadioGroup>
            <button 
              className="validate-btn" 
              onClick={() => {
                handleValidQ(valueQ, 'Résilier son contrat à tout moment')
                setDidTest(true)
                fetchGetScoreRecrutement()
              }}
            >
              Valider
            </button>
          </FormControl>
          }
        </section>
        </div>
      </section>
      }
      </main>
      }
      <Footer />
    </AccountRecrutementStyled>
  )
}

AccountRecrutement.propTypes = {
}

export default AccountRecrutement