import { 
    OPEN_MODAL,
    CLOSE_MODAL,
    CHANGE_FORM_MODAL,
    TOGGLE_MODAL_MESSAGE,
    TOGGLE_MODAL_SIGNATURE,
    TOGGLE_MODAL_CONVENTION,
    TOGGLE_MODAL_RECRUTEMENT
  } from "../actions/modal";
  
  export const initialState = {
    modalName:"",
    viewModal: false,
    formModal: 'login',
    modalMessageIsOpen: false,
    modalMessageContent: '',
    modalSignatureIsOpen: false,
    modalSignatureDocId: 0,
    modalSignatureDocType: '',
    modalSignatureDocObject: '',
    sigPad: {},
    modalConventionIsOpen: false,
    modalConventionEntrepriseId: 0,
    modalConventionDocType: '',
    modalRecrutement: false
  };
  
  // reducer = traducteur d'une intention/action vers une modification du state
  const modal = (state = initialState, action = {}) => {
    switch (action.type) {
      case OPEN_MODAL:
        return {
          ...state,
          viewModal: true,
          formModal: 'login',
          modalName: action.name
        };
      case CLOSE_MODAL:
        return {
          ...state,
          viewModal: false,
          modalName: action.name
        };
      case CHANGE_FORM_MODAL:
        return {
          ...state,
          viewModal: true,
          formModal: action.string,
        };
      case TOGGLE_MODAL_MESSAGE:
        return {
          ...state,
          modalMessageIsOpen: action.bool,
          modalMessageContent: action.message
      };
      case TOGGLE_MODAL_SIGNATURE:
        return {
          ...state,
          modalSignatureIsOpen: action.bool,
          modalSignatureDocId: action.docId,
          modalSignatureDocType: action.docType,
          modalSignatureDocObject: action.docObject
      }
      case TOGGLE_MODAL_CONVENTION:
        return {
          ...state,
          modalConventionIsOpen: action.bool,
          modalConventionEntrepriseId: action.entrepriseId,
          modalConventionDocType: action.docType
      }
      case TOGGLE_MODAL_RECRUTEMENT:
        return {
          ...state,
          modalRecrutement: action.bool,
      }
      default:
        return state;
    }
  };
  
  export default modal;