import styled from 'styled-components';

const JobCardStyled = styled.article`
    border: 1px solid #649ACE;
    width: 300px;
    color: #d47558;
    border-radius: 30px; 
    padding: 20px;
    box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
    -webkit-box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
    -moz-box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
    position: relative;
    margin: 50px 0;
    text-align: center;
    box-sizing: border-box;
    background-color: #fff;


    h3 {
        text-align: center;
        width: 100%;
        font-size: 20px;
        color: #202D3C;
        margin: 70px 0 0 0;
    }  
    
    .level-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        padding: 20px 0;
        color: #649ACE;
        font-size: 22px;

        p {
            margin-bottom: 10px;
        }
    }

    .btn-blue {
        width: 150px;
        margin: 0 auto;
        display: block;
        padding: 5px;
        border: 1px solid #649ACE;
        border-radius: 10px;
        margin-bottom: 20px;
        color: #649ACE;
        background-color: #fff;
        cursor: pointer;
        font-size: 16px;

        &:hover {
            color: #fff;
            background-color: #649ACE;
        }
    }

    .shield-wrapper {
        height: 120px;
        width: 120px;
        border-radius: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        background-color: #fff;
        top: -60px;
        left: 90px;
        box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
        -webkit-box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);
        -moz-box-shadow: 0px 16px 50px 0px rgba(219,219,219,0.75);

        &.orange {
            background-color: #d47558;
        }

        img {
            width: 120px;
        }
    }

    &.grue, &.pelleteuse {

        margin-left: 80px;

        h4 {
            color: #202D3C;
            font-size: 20px;
            max-width: 180px;
            margin: 0 auto;
            padding-bottom: 30px;
        }

        .separator {
            height: 1px;
            width: 220px;
            margin: 0 auto;
            background-color: #202D3C;
        }

        h3 {
            margin-top: 30px;
        }

        .level-wrapper {
            color: #202D3C;
        }

        .btn-blue {

            border: 1px solid #202D3C;
            color: #202D3C;
    
            &:hover {
                background-color: #202D3C;
                color: #fff;
            }
        }

        .shield-wrapper {
            top: -50px;
            left: -50px;
            height: 100px;
            width: 100px;
            border-radius: 50px;

            img {
                width: 80px;
            }
        }
    }

    &.pelleteuse {
        h4 {
            color: #8D252B;

        }

        .separator {

            background-color: #8D252B;
        }

        .level-wrapper {
            color: #8D252B;
        }

        .btn-blue {
            border: 1px solid #8D252B;
            color: #8D252B;
    
            &:hover {
                background-color: #8D252B;
            }
        }
    }
`;

export default JobCardStyled;