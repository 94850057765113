/**** Import des modules ****/
import React from 'react'
import { Link } from 'react-router-dom'

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'


/**** Import des images ****/
import notFoundImg from '../../assets/img/illustration/notfound.jpg'
import menuC from '../../assets/img/illustration/menu-c.png'
import jobC from '../../assets/img/illustration/liste-jobs-c.png'
import formationC from '../../assets/img/illustration/formation-c.png'

/**** Import des css ****/
import NotFoundStyled from './NotFoundStyled'

const NotFound = ({}) => {

  return (
    <NotFoundStyled>
      <Header />
      <main className="page-wrapper">
        <section className="intro-notfound">
          <img src={notFoundImg} />
        </section>
        <div className="txt-wrapper">
        <p className="intro-txt bold">Vous êtes perdu ? On peut vous aider !</p>
          <section className="job-search">
            <img src={jobC} alt="" />
            <p className="bold">Besoin d'un emploi ?</p>
            <Link to="/job" className="bold">Voir nos offres</Link>
          </section>
          <section className="course-search">
            <Link to="/formation" className="bold">Nos formations</Link>
            <p className="bold">Envie de monter en compétence ?</p>
            <img src={formationC} alt="" />
          </section>
          <section className="account-search">
            <img src={menuC} alt="" />
            <p className="bold">Encore plus de fonctionnalitées</p>
            <Link to="/signIn" className="bold">S'inscrire</Link>
          </section>
        </div>
      </main>
      <Footer />
    </NotFoundStyled>
  )
}



export default NotFound