import styled from 'styled-components';

const FullPageLoaderStyled = styled.div`
    z-index: 10000000000;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .3);
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
`

export default FullPageLoaderStyled;