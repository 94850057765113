import styled from 'styled-components';

const ModalSignatureStyled = styled.div`
    width: 100vw;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, .4);
    z-index: 100000;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-content-container {
        width: 92%;
        max-width: 1000px;
        min-height: 300px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 5px 15px 10px #00000066;
        position: relative;
        box-sizing: border-box;

        .icon-wrapper {
            position: absolute;
            top: -35px;
            right: -35px;
            width: 40px;
            height: 40px;
            background-color: #649ACE;
            border-radius: 17.5px;
            overflow: hidden;
            display:flex;
            align-items: center;
            justify-content: center;

            .close-icon {
                color: #fff;
                font-size: 30px;
                cursor: pointer;
                border-radius: 20px;
            }
        }

        .page-wrapper-message {

            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            width: 100%;
            height: 100%;
            min-height: 300px;

            h3 {
                font-size: 20px;
            }

            .btn-action-wrapper {
                display: flex;
                justify-content: center;
                align-items: stretch;

                .btn-redo {
                    background-color: #D47558;
                    width: 145px;
                    margin-right: 10px;
                    color: #fff;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    transition: all .3s ease;
                    padding: 10px;
                    box-sizing: border-box;
                    cursor: pointer;

                    &:hover {
                        opacity: .7;
                    }
                }

                .btn-send {
                    background-color: #6AAF8B;
                    width: 145px;
                    color: #fff;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    border-radius: 5px;
                    transition: all .3s ease;
                    padding: 10px;
                    box-sizing: border-box;
                    cursor: pointer;

                    &:hover {
                        opacity: .7;
                    }
                }
            }

            .sigCanvas {
                border: 1px solid #000;
                margin: 0 auto;
                cursor: crosshair
            }
        }
    }

    @media only screen and (max-width: 1079px) {
        .modal-content-container {
            .icon-wrapper {
                position: absolute;
                top: -20px;
                right: -8px;
            }
        }
    }

    @media only screen and (min-width: 0px) and (max-width: 759px) {
        .modal-content-container {
            width: 96%;

            .icon-wrapper {
                position: absolute;
                top: -20px;
                right: -8px;
            }
        }
    }
`;

export default ModalSignatureStyled;