/**** Import des modules ****/
import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper";
import { AiFillCaretDown, AiOutlineDoubleRight } from "react-icons/ai"
import { MdDeleteForever } from "react-icons/md"
import { FaList } from "react-icons/fa"
import { BsFillGridFill } from "react-icons/bs"
import PaginationMui from '@mui/material/Pagination'
import PropTypes from 'prop-types'
import AOS from 'aos';
import "aos/dist/aos.css"
import { StickyContainer, Sticky } from 'react-sticky'
import ReactMarkdown from 'react-markdown'
import { Helmet } from "react-helmet"

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import CourseCard from '../../containers/CourseCard'
import CourseCardListLayout from '../../containers/CourseCardListLayout'
import ModalCourseProg from '../../containers/ModalCourseProg';
import ModalJobFiche from '../../containers/ModalJobFiche';
import CourseCardLoading from '../../components/CourseCardLoading';
import CourseCardLoadingList from '../../components/CourseCardLoadingList';
import shield from '../../assets/img/picto/shield.png'

/**** Import des images ****/
import poucegreen from '../../assets/img/illustration/poucegreen.png'
import timeyellow from '../../assets/img/illustration/timeyellow.png'
import euroblue from '../../assets/img/illustration/euroblue.png'
import logo from '../../assets/img/illustration/femme-desk.png'
import cpficon from '../../assets/img/logo/WEFOR_CARRE_COULEUR.png'

/**** Import des css ****/
import FormationStyled from './FormationStyled'
import axios from 'axios'
import * as qs from 'qs'

import urlBackEnd from '../../assets/js/urlBackEnd'
import { Skeleton } from '@mui/material';

const URL = urlBackEnd

const Formation = ({ openModal, formations, activCourseId, loadFormation, secteurs, domaines, setFilter, secteurCourseFilter, domaineCourseFilter, dureeCourseFilter, userRole, currentProfilUser, changeActivCourseId, formationCourseFilter, fetchGetOneProgram, templatesLms, coursesLms, isLogged, jobFiches, fetchFormations, formationsPagination}) => {

  const hasWindow = typeof window !== 'undefined';

  useEffect(() => {
    if (formations.length === 0) {
      const paramObj = {page: 'app' ,intemNb: 10, pageNb: 1}
      fetchFormations(paramObj)
    }
  }, []);

  const [page, setPage] = useState(1)
  const [activLayout, setActivLayout] = useState('list')
  const [cardPerPage, setCardPerPage] = useState(10)
  const [indexOfLastCard, setIndexOfLastCard] = useState(cardPerPage)
  const [indexOfFirstCard, setIndexOfFirstCard] = useState(0)
  const [filterSecteurId, setFilterSecteurId] = useState('')
  const [filterDomaineId, setFilterDomaineId] = useState('')
  const [filterCourseId, setFilterCourseId] = useState('')

  useEffect(()=> {
    AOS.init({ duration: 1000 })
  }, [])

  const [modalJobFicheOpen, setModalJobFicheOpen] = useState(false);
  const [modalCourseProgOpen, setModalCourseProgOpen] = useState(false);
  
  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const handleChildElementClick = (e) => {
    e.stopPropagation()
    // Do other stuff here
 }
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  let formation = () => {
    if (loadFormation === false) {
      let formation = formations.find(item => item.id === activCourseId);
      return formation;
    }
  }

  const changePagesCards = (value) => {
    setIndexOfLastCard(value * cardPerPage)
    setIndexOfFirstCard(value * cardPerPage - cardPerPage)
  }
 
  const handleChangePagination = (event, value) => {   
    setPage(value)
    changePagesCards(value)
    const paramObj = {page: 'formations' ,intemNb: 10, pageNb: value}
    fetchFormations(paramObj)
    window.scrollTo(0, 250);
  };
  
  let nbOfCard = Math.round(windowDimensions.width / 450 + 0);

  const [btnFilterSecteurOnOff, setBtnFilterSecteurOnOff] = useState(false);
  const [btnFilterDomaineOnOff, setBtnFilterDomaineOnOff] = useState(false);
  const [btnFilterFormationOnOff, setBtnFilterFormationOnOff] = useState(false);
  const [btnFilterNiveauOnOff, setBtnFilterNiveauOnOff] = useState(false);

  let euroFormat = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
  });

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <FormationStyled
    onClick={() => {
      if (btnFilterSecteurOnOff === true) {
        setBtnFilterSecteurOnOff(false)
      }
      if (btnFilterDomaineOnOff === true) {
        setBtnFilterDomaineOnOff(false)
      }
      if (btnFilterFormationOnOff === true) {
        setBtnFilterFormationOnOff(false)
      }
      if (btnFilterNiveauOnOff === true) {
        setBtnFilterNiveauOnOff(false)
      }
    }}
    >
      {modalCourseProgOpen &&
        <ModalCourseProg modalCourseProgOpen={modalCourseProgOpen} setModalCourseProgOpen={setModalCourseProgOpen} />
      }
      {modalJobFicheOpen &&
        <ModalJobFiche modalJobFicheOpen={modalJobFicheOpen} setModalJobFicheOpen={setModalJobFicheOpen} />
      }
      <Header bcgColor={'#d47558'}/>
      <Helmet>
          <title>WEFOR</title>
          <meta name='description' content="Liste des formations WEFOR. Montez en compétence."/>
          <meta name='keywords' content='Wefor, formation, cabinet de recrutement'/>
      </Helmet>
      <section className="top-illustration">
        <div className="opacity-light-section">
        <img src={logo} alt='logo wefor'/>
          <div className="left">
            <h1 className="bold">Nos formations</h1>
            {currentProfilUser === "Demandeur d'emploi" && 
            <>
              <p>Vous recherchez une formation très opérationnelle avec des <span className="bold">offres d’emploi à la clé</span> ? Découvrez les formations Wefor !</p>
              <p>Wefor vous propose des formations en ligne, accessibles 7 jours sur 7 et 24h sur 24, sur une <span className="bold">plateforme pédagogique</span> ludique et performante.
              </p>
            </>
            }
            {currentProfilUser === "Salarié" && 
            <>
              <p>Vous visez une promotion, une mobilité interne ou une reconversion ? Découvrez les formations Wefor !</p>
              <p>Wefor s’adapte aux contraintes des salariés en leur proposant des <span className="bold">formations flexibles</span>, accessibles 7 jours sur 7 et 24h sur 24, sur une plateforme pédagogique ludique et performante.</p>
            </>
            }
            {currentProfilUser === "Entreprise" && 
            <>
              <p>Vous souhaitez former vos collaborateurs ? Découvrez les formations Wefor. Régulièrement adaptées pour être en phase avec le marché et vos besoins en compétences, elles vous permettent de <span className="bold">booster l’efficacité de vos collaborateurs</span> rapidement !</p>
              <p>Pour la garantie d’une prestation de qualité, nos formations sont éligibles aux dispositifs de financement.</p>
            </>
            }
            {currentProfilUser === "Autres" && 
            <>
              <p>Vous recherchez une formation très opérationnelle pour vous reconvertir ou <span className="bold">évoluer professionnellement</span> ? Découvrez les formations Wefor !</p>
              <p>Wefor vous propose des formations compatibles avec une activité professionnelle, accessibles 7 jours sur 7 et 24h sur 24, sur une <span className="bold">plateforme pédagogique</span> ludique et performante.</p>
            </>
            }

          </div>
        </div>
      </section>
      <section className="filter-formation">
        <div className="wrapper-filter">
          {!isLogged &&
          <div 
            className={btnFilterDomaineOnOff ? "border-r-off btn-filter bold" : "btn-filter bold"}
            onClick={() => setBtnFilterDomaineOnOff(!btnFilterDomaineOnOff)}
          >          
          {domaineCourseFilter !== "" ?
          <span className='filter'>
            {filterDomaineId} <MdDeleteForever onClick={(e) => {
              setFilter('', 'domaineCourse') 
              setFilterDomaineId('')
              handleChildElementClick(e)
              fetchFormations()
              }} />
          </span>
          :
          <>
          Vous êtes ?  
          <AiFillCaretDown/>
          </>
        }
            <ul className={btnFilterDomaineOnOff ? "show-menu" : ""}>
            {domaines.map((domaine) => (
              <li 
                key={domaine.id}
                onClick={() => {
                  setFilter(domaine.id, 'domaineCourse') 
                  setFilterDomaineId(domaine.attributes.nom)
                  fetchFormations()
                }}
                >
                  {domaine.attributes.nom}
                </li>
              ))}
            </ul>
          </div>
          }
          <div 
            className={btnFilterSecteurOnOff ? "border-r-off btn-filter bold" : "btn-filter bold"}
            onClick={() => setBtnFilterSecteurOnOff(!btnFilterSecteurOnOff)}
          >
          {secteurCourseFilter !== "" ?
            <span className='filter'>
              {filterSecteurId} <MdDeleteForever onClick={(e) => {
                setFilter('', 'secteurCourse') 
                setFilterSecteurId('')
                handleChildElementClick(e)
                fetchFormations()
                }} />
            </span>
            :
            <>
            Secteurs 
            <AiFillCaretDown/>
            </>
          }
            <ul className={btnFilterSecteurOnOff ? "show-menu" : ""}>
              {secteurs.map((secteur) => (
                <li 
                  key={secteur.id}
                  onClick={() => {
                    setFilter(secteur.id, 'secteurCourse') 
                    setFilterSecteurId(secteur.attributes.nom)
                    fetchFormations()
                  }}
                >
                  {secteur.attributes.nom}
                </li>
              ))}
            </ul>
          </div>
          {/*secteurCourseFilter !== "" &&
          <div
            className={btnFilterNiveauOnOff ? "border-r-off btn-filter bold" : "btn-filter bold"}
            onClick={() => setBtnFilterNiveauOnOff(!btnFilterNiveauOnOff)}
          >          
          {dureeCourseFilter !== "" ?
          <span className='filter'>
            {dureeCourseFilter} <MdDeleteForever onClick={(e) => {
              setFilter('', 'dureeCourse') 
              handleChildElementClick(e)
              }} />
          </span>
          :
          <>
          Métiers 
          <AiFillCaretDown/>
          </>
        }
            <ul className={btnFilterNiveauOnOff ? "show-menu" : ""}>
            {isLogged && jobFiches && jobFiches.filter(fiche => fiche.attributes.domaine.data.attributes.nom === (isLogged ? capitalizeFirstLetter(userRole) : '')).map((fiche) => (
                <li 
                  key={fiche.id}
                  onClick={() => setFilter(fiche.attributes.nom, 'dureeCourse') }
                >
                  {fiche.attributes.nom}
                </li>
              ))}
            {!isLogged && jobFiches && jobFiches.filter(fiche => fiche.attributes.domaine.data.attributes.nom === (currentProfilUser === 'Entreprise' ? capitalizeFirstLetter(currentProfilUser) : 'Particulier')).map((fiche) => (
              <li 
                key={fiche.id}
                onClick={() => setFilter(fiche.attributes.nom, 'dureeCourse') }
              >
                {fiche.attributes.nom}
              </li>
            ))}
            </ul>
            </div>
            */}
        </div>
      </section>
      <section className="user-filter-section">
        <BsFillGridFill 
          className={`layout-btn ${activLayout === 'grid' ? 'active-layout' : ''}`} 
          onClick={() => {
            setActivLayout('grid')
            setCardPerPage(10)
            setIndexOfLastCard(10)
            setIndexOfFirstCard(0)
            setPage(1)
          }}
        />
        <FaList 
          className={`layout-btn ${activLayout === 'list' ? 'active-layout' : ''}`}
          onClick={() => {
            setActivLayout('list')
            setCardPerPage(10)
            setIndexOfLastCard(10)
            setIndexOfFirstCard(0)
            setPage(1)
          }}
        />
        {/* 
        {secteurCourseFilter !== "" &&
          <span className='filter'>
            {secteurCourseFilter} <MdDeleteForever onClick={(e) => {
              setFilter('', 'secteurCourse') 
              handleChildElementClick(e)
              }} />
          </span>
        }
        {domaineCourseFilter !== "" &&
          <span className='filter'>
            {domaineCourseFilter} <MdDeleteForever onClick={(e) => {
              setFilter('', 'domaineCourse') 
              handleChildElementClick(e)
              }} />
          </span>
        }
        {formationCourseFilter !== "" &&
          <span className='filter'>
            {formationCourseFilter} <MdDeleteForever onClick={(e) => {
              setFilter('', 'formationCourse') 
              handleChildElementClick(e)
              }} />
          </span>
        }
        {dureeCourseFilter !== "" &&
          <span className='filter'>
            {dureeCourseFilter} <MdDeleteForever onClick={(e) => {
              setFilter('', 'dureeCourse') 
              handleChildElementClick(e)
              }} />
          </span>
        }
        */}
      </section>
      <main className="course-main">
      <section className="course-list-left mobile">
        <Swiper
          slidesPerView={nbOfCard}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper slider-mobile"
        >
        {!loadFormation && formations.length === 0 && 
          <p>Votre recherche ne comporte aucun resultat, merci de changer les filtres</p>
        }
          {!loadFormation && formations.map((formation) => (
            <SwiperSlide key={formation.id}>
              {({ isActive }) => (
                <>
                  <CourseCard className="display-buttons" isActive={isActive} courseId={formation.id} {...formation}/>
                </>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
        </section>
        <section className={`course-list-left computer ${activLayout === 'list' ? 'column' : ''}`}>
          {loadFormation && activLayout === 'grid' && 
            <>
              <CourseCardLoading />
              <CourseCardLoading />
              <CourseCardLoading />
              <CourseCardLoading />
            </>
          }
          {loadFormation && activLayout === 'list' && 
            <>
              <CourseCardLoadingList />
              <CourseCardLoadingList />
              <CourseCardLoadingList />
              <CourseCardLoadingList />
            </>
          }
        {!loadFormation && formations.length === 0 && 
          <p>Votre recherche ne comporte aucun resultat, merci de changer les filtres</p>
        }
        {!loadFormation && formations.map((formation) => (
          <div key={formation.id} className={`card-wrapper ` + (activLayout === 'list' ? 'fullwidth' : '')}>
            {activLayout === 'list' && 
              <CourseCardListLayout key={formation.id} courseId={formation.id} {...formation}/>
            }
            {activLayout === 'grid' && 
              <CourseCard key={formation.id} courseId={formation.id} {...formation}/>
            }
          </div>
        ))
        }
        {!loadFormation &&
          <PaginationMui page={formationsPagination.page} className="pagination-pc" count={Math.ceil(formationsPagination.pageCount)} variant="outlined" color="primary" onChange={handleChangePagination}/>
        }
        </section>
        <StickyContainer className="course-info-right">
        {!loadFormation && formations.length > 0 &&
          <Sticky>
            {({
              style,
              isSticky,
            }) => (
          <div className="sticky-container" style={{...style, marginTop: isSticky ? '230px' : '0px', transition: 'margin-top .2s ease'}}>
          <div className="info-wrapper">
            <div className="card-orange">
              <div className="row bold">
              E-LEARNING
              </div>
              <div className="row">
                <h3 className="bold">{formation().attributes.nom}</h3>
                <h6 className="bold">{formation().attributes.objectifs}</h6>
              </div>
            </div>
            <div className="comp-wrapper">
              <img src={poucegreen} alt="picto euro bleu" />
              <div className="txt-wrapper">
                <h3 className="bold">Prérequis</h3>
                <ReactMarkdown>{formation().attributes.prerequis}</ReactMarkdown>
              </div>
            </div>
            <div className="price-time-wrapper">
              <div className="time-wrapper">
                <img src={timeyellow} alt="picto euro bleu" />
                <div className="paragraphe-wrapper">
                  <p className="bold txt">Durée de la formation :</p>
                  <p className="bold time">{formation().attributes.duree}h</p>
                </div>
              </div>
              <div className="separator-orange"></div>
              <div className="price-wrapper">
                <img src={cpficon} alt="picto mon compte de formation cpf" />
                <p className="bold">{currentProfilUser === 'Entreprise' ? euroFormat.format(formation().attributes.prix / 1.20) + ' HT' : euroFormat.format(formation().attributes.prix) + ' TTC'}</p>
              </div>
            </div>
            {userRole === "entreprise" ? 
            <button 
            className="btn-orange-fullwidth bold"
              onClick={() => openModal("inscription-collab")}
            >Inscrire un collaborateur</button>
            :
            <button 
            className="btn-orange-fullwidth bold"
              onClick={() => openModal("formation")}
            >S'inscrire</button>
            }
            {/* ici les modales */}
            <div className="programme-disclaimer">Vous allez découvrir des pages comportant des apports de connaissances, qui seront suivies, page après page, d'activités pédagogiques de validation de vos acquis (une page de contenu, suivie d'une activité pédagogique, suivie d'une nouvelle page de contenu, suivi d'une autre activité pédagogique, etc.)<div className="bold">Modalités d'évaluation des objectifs : quizz, vidéo de présentation, études de cas avec questionnaires, examen final.</div></div>
            {templatesLms.length > 0 && templatesLms.find(template => template._id === formation().attributes.lms_formation_id).elements.map((module, index) => (
              <div key={index}>
              { !module.title.includes('entraînement') && !module.title.includes('approfondissement') && !module.title.includes('Validation') && !module.title.includes('Exercices pratiques') && !module.title.includes(formation().attributes.nom.toLowerCase().substring(1, 20).toLowerCase()) &&
              <div>
                <h4 className="module-title bold">{module.title.substr(9)}</h4>
                <div className="modules-wrapper" dangerouslySetInnerHTML={ {__html :coursesLms.find(moduleLms => module._id === moduleLms._id) !== undefined ? coursesLms.find(moduleLms => module._id === moduleLms._id).description : ''} }></div>
              </div>
              }
              </div>
            ))}
            <div className="full-width">
            <button 
              className="btn-blue-orange bold"
              onClick={() => {
                changeActivCourseId(formation().id)
                setModalCourseProgOpen(true)
              }}
            >Voir le programme de formation</button>
            </div>
            <div className="button-wrapper">
            {userRole === "entreprise" ? 
            <button 
              className="btn-orange"
              onClick={() => openModal("inscription-collab")}
            >Inscrire un collaborateur</button>
            :
            <button 
              className="btn-orange"
              onClick={() => openModal("formation")}
            >S'inscrire</button>
            }
            <button className="btn-bluelight" onClick={() => setModalJobFicheOpen(true)}>Fiche métier</button>
            </div>
          </div>
          </div>
           )}
          </Sticky>
        }
        </StickyContainer>
      </main>
      <Footer />
    </FormationStyled>
  )
}

Formation.propTypes = {
  openModal: PropTypes.func.isRequired
}

export default Formation