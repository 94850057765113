/**** Import de modules ****/
import React from 'react'
import Skeleton from '@mui/material/Skeleton';

/**** import des components ****/

/**** Import d'images' ****/

/**** Import des css ****/
import CourseCardLoadingStyled from './CourseCardLoadingStyled'

const CourseCardLoading = ({typeCard}) => {


  return (
    <CourseCardLoadingStyled typeCard={typeCard}>
      <Skeleton className="absolute-img" variant="rectangular" />
      <div className='top-wrapper'>
        <Skeleton className="skeleton-txt" variant="text" />
        <Skeleton className="skeleton-txt" variant="text" />
      </div>
      <div className='center-wrapper'>
        <Skeleton className="skeleton-rect" variant="rectangular" />
        <Skeleton className="skeleton-txt" variant="text" />
        <Skeleton className="skeleton-txt" variant="text" />
      </div>
      <div className='bottom-wrapper'>
        <Skeleton className="skeleton-btn" variant="rectangular" />
        <Skeleton className="skeleton-btn" variant="rectangular" />
      </div>
    </CourseCardLoadingStyled>
  )
}

CourseCardLoading.propTypes = {

}

export default CourseCardLoading