import styled from 'styled-components';

const AccountJobsStyled = styled.div`
    .page-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;
        max-width: 1300px;
        margin: 175px auto 0;

        .right-wrapper {
            width: calc(100% - 250px);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            h2 {
                font-size: 30px;
                display: inline-block;
                margin: 0 auto 18px;
                color: #202D3C;
            }

            .btn-link-convention {
                padding: 15px 20px;
                margin: 10px auto;
                color: #fff;
                background-color: #202D3C;
                border-radius: 10px;
                cursor: pointer;
                display: inline-block;
                transition:all .3s ease;

                &:hover {
                    opacity: .7;
                }
            }

            .mycards {
                padding: 20px 0 50px;
                width: 100%;
                text-align: center;

                .cards-wrapper {
                    padding: 50px 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }

            .add-job {
                color: #202D3C;
                width: 96%;
                background-color: #fff;
                color: #649ACE;
                box-shadow: 0px 1px 10px #00000029;
                border-radius: 10px;
                position: relative;
                margin: 0 auto;
                padding: 20px 50px 20px 20px;
                margin-bottom: 50px;
                font-size: 18px;
                text-align: left;
                background-color: #fff;
                cursor: pointer;
                transition: height 1s ease;

                &.center {
                    text-align: center;
                }

                svg {
                    font-size: 30px;
                    color: #649ACE;
                    cursor: pointer;
                    position: absolute;
                    right: 10px;
                    top: 15px;
                }
            }

            .cards-wrap-s {
                width: 96%;
                max-width: 750px;
                background-color: #F5F5F5;
                border-radius:27px;
                padding: 15px 15px 15px 15px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                box-sizing: border-box;
                box-shadow: 0px 3px 6px #00000029;
                margin-bottom: 30px;
                position: relative;
            }

            .text-wrapper {
                width: calc(98% - 80px);
                display: flex;
                align-items: center;
                justify-content: space-between;
        
                .top-wrapper {
                    width: 30%;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
        
                    h3 {
                        color: #4d4d4d;
                        font-size: 20px;
                        display: inline-block;
                        border-bottom: 2px solid #b4cfe8;
                        width: 100%;
                        padding-bottom: 10px;
                        text-align: center;
                    }
                    
                    p {
                        color: #649ACE;
                        padding: 10px 0 5px 0;;
                    }
                    .btn-blue {
                        width: 125px;
                        background-color: #4d4d4d;
                        border-radius: 12px;
                        padding: 5px 20px;
                        border: 0;
                        color: #fff;
                        margin-right: 20px;
                    }
                }
        
                .middle-wrapper {
                    width: 30%;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
        
                    .infos-left {
                        color: #4d4d4d;
                        display: flex;
                        flex-direction: column;
                        text-align: left;
                        padding-bottom: 10px;
        
                        .salary, .city {
                            display: inline-block;
                        }
        
                        .salary {
                        }
                    }
        
                    .no-offer {
                        text-align: center;
                    }
        
                    .btn-light-blue {
                        width: 125px;
                        background-color: #649ACE;
                        border-radius: 12px;
                        padding: 5px 20px;
                        border: 0;
                        color: #fff;
                        margin: 10px 0 0 0;
                        cursor: pointer;
                        transition: all .3s ease;
        
                        &:hover {
                            opacity: .7;
                        }
        
                        a {
                            color: #fff;
                        }
                    }
                }
        
                .bottom-wrapper {
        
                    width: 30%;
        
                    .top {
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
        
                        .candidats {
                            color: #4d4d4d;
                            padding-bottom: 10px;
                        }
        
                        svg {
                            color: #649ACE;
                            font-size: 30px;
                        }
                    }
        
                    .btn-light-blue {
                        width: 125px;
                        background-color: #649ACE;
                        border-radius: 12px;
                        padding: 5px 20px;
                        border: 0;
                        color: #fff;
                        margin: 10px 0 0 0;
                        cursor: pointer;
                        transition: all .3s ease;
        
                        &:hover {
                            opacity: .7;
                        }
        
                        a {
                            color: #fff;
                        }
                    }
                    .img-wrapper {
                        display: flex;
                        flex-wrap: wrap;
        
                        img {
                            width: 40px;
                        }
                    }
                }
            }
        }

        .modal {
            width: 100vw;
            min-height: 100vh;
            background-color: rgba(0, 0, 0, .4);
            z-index: 10000;
            position: fixed;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
        
            .modal-content-container {
                width: 96%;
                max-width: 620px;
                min-height: 300px;
                background-color: #fff;
                border-radius: 20px;
                box-shadow: 5px 15px 10px #00000066;
                position: relative;

                .content-condition {
                    padding: 40px 20px 40px 20px;
                    max-height: 80vh;
                    overflow-y: auto;
                    overflow-x: hidden;
                    box-sizing: border-box;
                }

                h3 {
                    color: #649ACE;
                    font-size: 30px;
                    text-align: center;
                    margin: 0 auto;
                    padding: 20px 0 20px 0;
                }

                .account-form-comp {
                    overflow: visible;
            
                    label {
                        width: 100%;
                    }

                    textarea {
                        height: 150px;
                    }

                    .drow-wrapper {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        label {
                            width: 48%;
                        }
                    }
            
                    input, textarea {
                        width: 100%;
                        border: 0;
                        border-radius: 10px;
                        background-color: #F2F2F2;
                        padding: 20px;
                        margin: 5px 0px 10px;
                        box-sizing: border-box;
                    }

                    .small-placeholder::placeholder {
                        font-size: 14px;
                    }

                    #mui-rte-container {
                        min-height: 200px;
                        background-color: #f2f2f2;
                        border-radius: 10px;
                        padding: 10px;
                        margin: 5px 0 20px;
                        z-index: 10000000000000;
                    }

                    .select-box {
                        select {
                            width: 100%;
                            margin: 5px 0 20px;
                            border: 0;
                            border-radius: 10px;
                            background-color: #F2F2F2;
                            padding: 20px;
                        }
                    }

                    .btn-test-ocean {
                        padding: 20px 20px;
                        box-shadow: 0px 1px 10px #00000029;
                        margin-bottom: 20px;
                        border-radius: 10px;
                        text-align: center;
                    }
            
                    .lightblue-btn {
                        color: #fff;
                        background-color: #649ACE;
                        border: 2px solid #87B7E5;
                        padding: 15px;
                        font-size: 18px;
                        box-sizing: border-box;
                        cursor: pointer;
            
                        &:hover {
                            color: #649ACE;
                            background-color: #fff;
                        }
                    }
                }
        
                .icon-wrapper {
                    position: absolute;
                    top: -35px;
                    right: -35px;
                    width: 40px;
                    height: 40px;
                    background-color: #649ACE;
                    border-radius: 17.5px;
                    overflow: hidden;
                    display:flex;
                    align-items: center;
                    justify-content: center;
        
                    .close-icon {
                        color: #fff;
                        font-size: 30px;
                        cursor: pointer;
                        border-radius: 20px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1400px) {
        .page-wrapper {
            .right-wrapper {
                width: calc(100% - 250px);
    
                h2 {
                    font-size: 35px;
                }
            }
        }
    }
    
    @media only screen and (max-width: 1079px) {
        .page-wrapper {
            margin: 150px auto 0;
            .right-wrapper {
                width: calc(100% - 250px);
    
                h2 {
                    font-size: 30px;
    
                    .separator-blue {
                        width: 100px;
                    }
                }

                .mycards {
                    padding: 30px 0px;
                }
            }
        }
    }
    
    @media only screen and (min-width: 0px) and (max-width: 759px) {
        .page-wrapper {
            .right-wrapper {
                width: calc(100%);
    
    
                h2 {
                    font-size: 25px;
    
                    .separator-blue {
                        width: 50px;
                        margin: 10px auto;
                        position: static;
                    }
                }
            }

            .modal {
                .modal-content-container {
                    .content-condition {
                        margin: 10px 0px 10px 10px;
                        padding: 0 0px 0 0;

                        .account-form-comp {
                            padding: 30px 0;
                            margin: 0;
                            max-width: 100%;
                        }
                    }

                    .icon-wrapper {
                        position: absolute;
                        top: -20px;
                        right: -8px;
                    }
                }
            }
        }
    }
`;

export default AccountJobsStyled;