export const CHANGE_VALUE_SIGNIN = 'CHANGE_VALUE_SIGNIN'
export const CHANGE_SIGNIN_STEP = 'CHANGE_SIGNIN_STEP'
export const FETCH_SEARCH_ADRESSE = 'FETCH_SEARCH_ADRESSE'
export const SAVE_ADRESSE_LIST = 'SAVE_ADRESSE_LIST'
export const FETCH_CV_PARTICULIER = 'FETCH_CV_PARTICULIER'

export const changeValueSignin = (value, name) => ({
    type: CHANGE_VALUE_SIGNIN,
    value,
    name,
})


export const changeSigninStep = (number) => ({
    type: CHANGE_SIGNIN_STEP,
    number
})

export const fetchSearchAdresse = (adresse) => ({
  type: FETCH_SEARCH_ADRESSE,
  adresse
})

export const saveAdresseList = (data) => ({
  type: SAVE_ADRESSE_LIST,
  data
})

export const fetchCvParticulier = (file, particulierId) => ({
  type: FETCH_CV_PARTICULIER,
  file,
  particulierId
})