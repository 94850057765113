import styled from 'styled-components';

const YouAreStyledStyled = styled.section`

    max-width: 1200px;
    margin: 0 auto;
    padding: 150px 0px 100px;
    text-align: center;

    h2 {
        font-size: 40px;
        width: auto;
        display: inline;
        margin: 0 auto;
        position: relative;
        color: #6399ce;
        padding-bottom: 10px;
        margin-bottom: 40px;
        text-transform: uppercase;

        .separator-blue {
            background-color: #6399ce;
            height: 6px;
            width: 80px;
            margin: 10px 0 0 auto;
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }

    .choices-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;

        .left, .right {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 40%;

            .btn-blue {
                background-color: #649ACE;
                color: #fff;
                font-size: 30px;
                border-radius: 10px;
                box-shadow: 0px 16px 16px #00000029;
                border: 0;
                width: 320px;
                margin: 20px 0 60px;
                padding: 10px 0;
                cursor: pointer;

                &:hover {
                    background-color: #202c3c;
                }
            }

            p {
                color:#707070;
                font-size: 18px;
                margin-bottom: 40px;
            }

            ul {
                width: 320px;
                text-align: left;
                color:#707070;
                font-size: 18px;
                line-height: 24px;
            }
        }

        .right {

            img {
                width: 400px;
                margin: 25px;
            }
        }
    }

    @media only screen and (max-device-width: 1079px) {

        .choices-wrapper {
            justify-content: space-evenly;

            .right {

                img {
                    width: 380px;
                }
            }

            .left {

                img {
                    width: 482px;
                }
            }
        }
    }
   
     @media only screen and (min-device-width: 0px) and (max-device-width: 759px) {
        .choices-wrapper {
            flex-direction: column;
            align-items: center;
     }
`;

export default YouAreStyledStyled;