import styled from 'styled-components';

const MapStyled = styled.div`
    width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	background-color: rgba(0, 0, 0, .4);
    z-index: 100000;
`;

export default MapStyled;