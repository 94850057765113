import { 
  CHANGE_ACTIV_COURSE_ID,
  SAVE_FORMATIONS,
  SAVE_FORMATIONS_HOME,
  CHANGE_LOAD_FORMATION,
  SET_MODAL_INSC_LOADER_OPEN,
  SET_INSC_COLLAB_STEP_ONE,
  SET_INSC_COLLAB_STEP_TWO,
  SET_INSC_COLLAB_STEP_THREE,
  SET_INSC_COLLAB_STEP_FOUR,
  SET_INSC_COLLAB_ERROR,
  SAVE_FACTURE_E,
  SAVE_FACTURE_P,
  CHANGE_CLICKED_FACTURE,
  REDIRECT_TO_MY_FINANCEMENT,
  FACTURE_P_CHARGED,
  FACTURE_E_CHARGED,
  SAVE_MY_ENTREPRISE_PART,
  SET_SALARIE_CLICKED,
  SAVE_FORMATIONS_PAGINATION
} from "../actions/course";

export const initialState = {
  activCourseId: 1,
  loadFormation: true,
  formations: [],
  formationsHome: [],
  formationsPagination: [],
  modalInscLoaderOpen: false,
  inscCollabStepOne: '',
  inscCollabStepTwo: '',
  inscCollabStepThree: '',
  inscCollabStepFour: '',
  inscCollabError: false,
  factureE: [],
  factureP: [],
  clickedFacture: false,
  redirectToMyFinancement: false,
  facturePStatut: false,
  factureEStatut: false,
  myEntreprisePart: [],
  salarieClickedName: '',
  salarieClickedId: ''
};

// reducer = traducteur d'une intention/action vers une modification du state
const course = (state = initialState, action = {}) => {
  switch (action.type) {
      case CHANGE_ACTIV_COURSE_ID:
        return {
          ...state,
          activCourseId: action.id,
        };
      case SAVE_FORMATIONS:
        return {
          ...state,
          formations: action.formations,
        };
      case SAVE_FORMATIONS_PAGINATION:
        return {
          ...state,
          formationsPagination: action.data,
        };
      case SAVE_FORMATIONS_HOME:
        return {
          ...state,
          formationsHome: action.formations,
        };
      case CHANGE_LOAD_FORMATION:
        return {
          ...state,
          loadFormation: action.bool,
        };
      case SET_MODAL_INSC_LOADER_OPEN:
        return {
          ...state,
          modalInscLoaderOpen: action.bool
        }
      case SET_INSC_COLLAB_STEP_ONE:
        return {
          ...state,
          inscCollabStepOne: action.string
        }
      case SET_INSC_COLLAB_STEP_TWO:
        return {
          ...state,
          inscCollabStepTwo: action.string
        }
      case SET_INSC_COLLAB_STEP_THREE:
        return {
          ...state,
          inscCollabStepThree: action.string
        }
      case SET_INSC_COLLAB_STEP_FOUR:
        return {
          ...state,
          inscCollabStepFour: action.string
        }
      case SET_INSC_COLLAB_ERROR:
        return {
          ...state,
          inscCollabError: action.bool
        }
      case SAVE_FACTURE_E:
        return {
          ...state,
          factureE: action.data
        }
      case SAVE_FACTURE_P:
        return {
          ...state,
          factureP: action.data
        }
      case CHANGE_CLICKED_FACTURE:
        return {
          ...state,
          clickedFacture: action.factureId
        }
      case REDIRECT_TO_MY_FINANCEMENT:
        return {
          ...state,
          redirectToMyFinancement: action.bool
        }
      case FACTURE_P_CHARGED:
        return {
          ...state,
        facturePStatut: action.bool
        }
      case FACTURE_E_CHARGED:
        return {
          ...state,
        factureEStatut: action.bool
        }
      case SAVE_MY_ENTREPRISE_PART:
        return {
          ...state,
          myEntreprisePart: action.data
        }
      case SET_SALARIE_CLICKED:
        return {
          ...state,
          salarieClickedId: action.id,
          salarieClickedName: action.name
        }
    default:
      return state;
  }
};

export default course;