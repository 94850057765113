/**** Import des modules ****/
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import {AiOutlineClose} from "react-icons/ai"


/**** Import des css ****/
import ModalMessageStyled from './ModalMessageStyled'

const ModalMessage = ({modalMessageContent, modalMessageIsOpen, toggleModalMessage}) => {

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          toggleModalMessage('', false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <ModalMessageStyled>
    <section className="modal-content-container">
      <div className="page-wrapper-message" ref={wrapperRef}>
        <div className="message-wrapper" dangerouslySetInnerHTML={ {__html : modalMessageContent} }></div>
      <main>
      <div className="icon-wrapper">
            <AiOutlineClose 
            className="close-icon"
            onClick={() => toggleModalMessage('', false)}
            />
      </div>
      </main>
      </div>
    </section>
    </ModalMessageStyled>
  )
}

ModalMessage.propTypes = {

}

export default ModalMessage