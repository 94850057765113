/**** Import des modules ****/
import React, { useState } from 'react'
import { } from "react-icons/ai";
import PropTypes from 'prop-types'

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import CourseCardDomaine from '../../containers/CourseCardDomaine'

/**** Import des images ****/
import shield from '../../assets/img/picto/shield.png'
import ampoule from '../../assets/img/illustration/ampoule.png'
import goWork from '../../assets/img/illustration/GO-WORK-02.png'
import logo from '../../assets/img/logo/everycheck.png'

/**** Import des css ****/
import CourseSecteurStyled from './CourseSecteurStyled'

const CourseSecteur = ({ secteurs, currentDomaineClicked }) => {

  let branches = secteurs[0].domaines[currentDomaineClicked - 1].formations[0].branches

  return (
    <CourseSecteurStyled>
      <Header bcgColor={'#d47558'}/>
      <section className="illustration-header">
        <div className="opacity-light-section">
          <div className="left">
          <h1 className="bold">{secteurs[0].domaines[currentDomaineClicked - 1].formations[0].name}</h1>
          </div>
            <img src={logo} alt='logo wefor'/>
        </div>
      </section>
      <main>
        <section className="prog-pres">
          <div className="design-top">
            <div className="separator-yellow"></div>
            <img src={ampoule} alt="ampoule ronde jaune" />
            <div className="separator-yellow"></div>
          </div>
          <div className="definition-prog">
            <div className="left">
              <h3 className="bold">Qu’est ce qu’une formation à la souscription d’assurances ?</h3>
              <p>La formation Souscripteur en assurance prépare à des responsabilités techniques, commerciales et administratives complexes. En Assurances de biens et de responsabilité : conditions de souscription des contrats après analyse des besoins et évaluation des risques à assurer pour une clientèle d’entreprises ou de collectivités locales En Assurances de personnes : analyse et évaluation des risques à assurer en assurance de personnes individuelles ou collectives, élaboration et suivi de contrats collectifs d’assurance souscrits par des entreprises pour leur personnel.</p>
            </div>
            <div className="separator-grey"></div>
            <div className="right">
              <h3 className="bold">Pourquoi se former à la souscription d’assurances avec WEFOR ?</h3>
              <p>Hanc regionem praestitutis celebritati diebus invadere parans dux ante edictus per solitudines Aboraeque amnis herbidas ripas, suorum indicio proditus, qui admissi flagitii metu exagitati ad praesidia descivere Romana. qui admissi flagitii metu exagitati ad praesidia descivere Romana.</p>
            </div>
          </div>
        </section>
        <section className="formation-section">
          <h2>LES FORMATIONS<span className="separator-blue"></span></h2>
          <div className="formation-wrapper">
            {!branches && secteurs[0].domaines[currentDomaineClicked - 1].formations.map((formation) => (
              <CourseCardDomaine key={formation.id} {...formation}/>
            ))}
            {branches && secteurs[0].domaines[currentDomaineClicked - 1].formations[0].branches.map((branche) => (
              <CourseCardDomaine key={branche.id} {...branche} name={branches && secteurs[0].domaines[currentDomaineClicked - 1].formations[0].name}/>
            ))}
          </div>
        </section>
        <section className="work-section">
          <div className="section-wrapper">
            <img src={goWork} alt="illustration avec deux humains allant au travail" />
            <div className="work-right">
              <h2>TRAVAILLER DANS LA SOUSCRIPTION D’ASSURANCES<span className="separator-blue"></span></h2>
              <p>Hanc regionem praestitutis celebritati diebus invadere parans dux ante edictus per solitudines Aboraeque amnis herbidas ripas, suorum indicio proditus, qui admissi flagitii metu exagitati ad praesidia descivere Romana. qui admissi flagitii metu exagitati ad praesidia descivere Romana.</p>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </CourseSecteurStyled>
  )
}

CourseSecteur.propTypes = {
  secteurs: PropTypes.array.isRequired,
  currentDomaineClicked: PropTypes.number.isRequired
}

export default CourseSecteur