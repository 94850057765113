/**** Import de modules ****/
import React, {useState} from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { MdCancel } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import { FaHome, FaUsers, FaBriefcase, FaUserCircle, FaBook, FaFileAlt, FaChartPie, FaFileSignature, FaUserTie, FaChartArea} from "react-icons/fa";
import { BsArrowReturnRight } from "react-icons/bs";
import { MdDashboard } from "react-icons/md";
import * as CryptoJS from "crypto-js";
import 'react-toastify/dist/ReactToastify.css';



/**** Import d'images ****/
import homeaccount from '../../assets/img/illustration/home.png'
import menaccount from '../../assets/img/illustration/candidat.png'
import progaccount from '../../assets/img/illustration/en-train-detudier.png'
import femme from '../../assets/img/illustration/candidats.png'
import suitcasemini from '../../assets/img/illustration/star.png'
import documentM from '../../assets/img/illustration/document.png'

/**** Import de css ****/
import AccountMenuStyled from './AccountMenuStyled'
import { disconnectUser } from '../../actions/login';
import { setMenuDocument } from '../../actions/menu';

const AccountMenu = ({currentProfilUser, userRole, saveUser, changeIsLogged, disconnectUser, setMenuDocument, menuDocument, setMenuFormation, menuFormation, session, login, password}) => {
  let location = useLocation()
  let navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const encrypted = CryptoJS.AES.encrypt(password, "salut c'est nico").toString();

  return (
    <AccountMenuStyled>
      <nav className="menu-account-nav">
        {userRole === 'entreprise' && 
        <ul className="pc-nav">
          <li className={location.pathname === "/account/home" ? "activ-link bold li-connected" : "li-connected"}>
            <MdDashboard />
            <Link 
              to="/account/home" 
            >Tableau de bord</Link>
          </li>
          {session.user.admin !== null &&
          <li className={location.pathname === "/account/jobs" ? "activ-link bold li-connected" : "li-connected"}>
            <FaChartArea />
            <a href={`https://testdadmin.wefor.fr/login?login=${login}&password=${encrypted}`}
              target='_blank' rel='noreferrer'
            >Analyse</a>
          </li>
           }
          <li className={location.pathname === "/account/candidats" ? "activ-link bold li-connected" : "li-connected"}>
            <FaUsers />
            <Link 
              to="/account/candidats" 
            >Vos candidats</Link>
          </li>
          <li className={location.pathname === "/account/jobs" ? "activ-link bold li-connected" : "li-connected"}>
            <FaBriefcase />
            <Link 
              to="/account/jobs" 
            >Vos offres d'emploi</Link>
          </li>
          <li 
          className={location.pathname === "/account/mes-financements" ? "activ-link bold li-connected" : "li-connected"} 
          >
            <FaFileSignature />
            <Link 
              to="/account/mes-financements"
            >Vos financements</Link>
          </li>
          <li 
          className={location.pathname === "/account/documents" ? "activ-link bold li-connected" : "li-connected"} 
          >
            <FaFileAlt />
            <Link 
              to="/account/documents"
            >Vos documents</Link>
          {location.pathname === "/account/documents" &&
          <div className="submenu-activ">
            <div 
              onClick={() => {setMenuDocument('recrutement')}}
              className={menuDocument === "recrutement" ? "bold" : ""} 
            ><BsArrowReturnRight /> recrutement</div>
            <div 
              onClick={() => {setMenuDocument('factures')}}
              className={menuDocument === "factures" ? "bold" : ""}
            ><BsArrowReturnRight />factures</div>
            <div 
              onClick={() => {setMenuDocument('contrats')}}
              className={menuDocument === "contrats" ? "bold" : ""}
            ><BsArrowReturnRight />contrats</div>
          </div>
          }
          </li>
          <li className={location.pathname === "/account/course" ? "activ-link bold li-connected" : "li-connected"}>
            <FaBook />
            <Link 
              to="/account/course"
            >Vos formations</Link>
          </li>
          <li className={location.pathname === "/account/ocean" ? "activ-link bold li-connected" : "li-connected"}>
            <FaChartPie />
            <Link 
              to="/account/ocean"
            >Test de personnalité</Link>
          </li>
          <li className={location.pathname === "/account/profil" ? "activ-link bold li-connected" : "li-connected"}>
            <FaUserCircle />
            <Link 
            to="/account/profil"
            >Profil</Link>
          </li>
          <li className="btn-deconnexion"
            onClick={() => {
              navigate('/');
              disconnectUser()
            }}
          >Déconnexion</li>
        </ul>
        }
        {userRole === 'particulier' && 
        <ul className="pc-nav">
          <li className={location.pathname === "/account/home" ? "activ-link bold li-connected" : "li-connected"}>
            <MdDashboard />
            <Link 
              to="/account/home" 
            >Tableau de bord</Link>
          </li>
          <li 
          className={location.pathname === "/account/mes-financements" ? "activ-link bold li-connected" : "li-connected"}
          >
            <FaFileSignature />
            <Link 
              to="/account/mes-financements"
            >Vos financements</Link>
          </li>
          <li 
          className={location.pathname === "/account/documents" ? "activ-link bold li-connected" : "li-connected"}
          >
            <FaFileAlt />
            <Link 
              to="/account/documents"
            >Vos documents</Link>
          {location.pathname === "/account/documents" &&
          <div className="submenu-activ">
            {/* 
            <div 
              onClick={() => {setMenuDocument('diplome')}}
              className={menuDocument === "diplome" ? "bold" : ""} 
            ><BsArrowReturnRight /> diplôme</div>*/}
            <div 
              onClick={() => {setMenuDocument('factures')}}
              className={menuDocument === "factures" ? "bold" : ""}
            ><BsArrowReturnRight />factures</div>
            <div 
              onClick={() => {setMenuDocument('contrats')}}
              className={menuDocument === "contrats" ? "bold" : ""}
            ><BsArrowReturnRight />contrats</div>
          </div>
          }
          </li>
          <li className={location.pathname === "/account/course" ? "activ-link bold li-connected" : "li-connected"}>
            <FaBook />
            <Link 
              to="/account/course"
            >Vos formations</Link>
            {location.pathname === "/account/course" &&
            <div className="submenu-activ">
              <div 
                onClick={() => {setMenuFormation('encours')}}
                className={menuFormation === "encours" ? "bold" : ""} 
              ><BsArrowReturnRight /> En cours</div>
              <div 
                onClick={() => {setMenuFormation('terminée')}}
                className={menuFormation === "terminée" ? "bold" : ""}
              ><BsArrowReturnRight /> Terminées</div>
              <div 
                onClick={() => {setMenuFormation('demandes')}}
                className={menuFormation === "demandes" ? "bold" : ""}
              ><BsArrowReturnRight /> Demandes</div>
            </div>
            }
          </li>
          <li className={location.pathname === "/account/recrutement" ? "activ-link bold li-connected" : "li-connected"}>
            <FaUserTie />
            <Link 
              to="/account/recrutement"
            >Test de recurtement</Link>
          </li>
          <li className={location.pathname === "/account/ocean" ? "activ-link bold li-connected" : "li-connected"}>
            <FaChartPie />
            <Link 
              to="/account/ocean"
            >Test de personnalité</Link>
          </li>
          <li className={location.pathname === "/account/profil" ? "activ-link bold li-connected" : "li-connected"}>
            <FaUserCircle />
            <Link 
            to="/account/profil"
            >Profil</Link>
          </li>
          <li className="btn-deconnexion"
            onClick={() => {
              navigate('/');
              disconnectUser()
            }}
          >Déconnexion</li>
        </ul>
        }


        {/*** Mobile nav ***/}
        {currentProfilUser === 'Entreprise' && 
        <ul className="mobile-nav">
          <li className={location.pathname === "/account/home" ? "activ-link bold li-connected" : "li-connected"}>
            <Link 
              to="/account/home" 
            ><MdDashboard /></Link>
          </li>
          <li className={location.pathname === "/account/candidats" ? "activ-link bold li-connected" : "li-connected"}>
            <Link 
              to="/account/candidats" 
            ><FaUsers /></Link>
          </li>
          <li className={location.pathname === "/account/jobs" ? "activ-link bold li-connected" : "li-connected"}>
            <Link 
              to="/account/jobs" 
            ><FaBriefcase /></Link>
          </li>
          <li className={location.pathname === "/account/mes-financements" ? "activ-link bold li-connected" : "li-connected"}>
            <Link 
              to="/account/mes-financements"
            ><FaFileSignature /></Link>
          </li>
          <li 
          className={location.pathname === "/account/documents" ? "activ-link bold li-connected" : "li-connected"}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick} 
          >
            <a href="#"
            ><FaFileAlt /></a>
          
          </li>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
          <MenuItem onClick={() => {setMenuDocument('recrutement'); handleClose()}}><Link 
              to="/account/documents"
            >Recrutement</Link></MenuItem>
          <MenuItem onClick={() => {setMenuDocument('factures'); handleClose()}}><Link 
              to="/account/documents"
            >Factures</Link></MenuItem>
          <MenuItem onClick={() => {setMenuDocument('contrats'); handleClose()}}><Link 
              to="/account/documents"
            >Contrats</Link></MenuItem>
          </Menu>
          <li className={location.pathname === "/account/course" ? "activ-link bold li-connected" : "li-connected"}>
            <Link 
              to="/account/course"
            ><FaBook /></Link>
          </li>
          <li className={location.pathname === "/account/profil" ? "activ-link bold li-connected" : "li-connected"}>
            <Link 
            to="/account/profil"
            ><FaUserCircle /></Link>
          </li>
          <li className={location.pathname === "/account/ocean" ? "activ-link bold li-connected" : "li-connected"}>
            <Link 
              to="/account/ocean"
            ><FaChartPie /></Link>
          </li>
          <li className="btn-deconnexion"
            onClick={() => {
              navigate('/');
              disconnectUser()
            }}
          >Déconnexion</li>
        </ul>
        }
        {currentProfilUser !== 'Entreprise' && 
        <ul className="mobile-nav">
          <li className={location.pathname === "/account/home" ? "activ-link bold li-connected" : "li-connected"}>
            <Link 
              to="/account/home" 
            ><MdDashboard /></Link>
          </li>
          <li className={location.pathname === "/account/mes-financements" ? "activ-link bold li-connected" : "li-connected"}>
            <Link 
              to="/account/mes-financements"
            ><FaFileSignature /></Link>
          </li>
          <li className={location.pathname === "/account/documents" ? "activ-link bold li-connected" : "li-connected"}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick} 
          >
            <a href="#"
            ><FaFileAlt /></a>
          
          </li>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {/* 
          <MenuItem onClick={() => {setMenuDocument('recrutement'); handleClose()}}><Link 
              to="/account/documents"
            >Recrutement</Link></MenuItem>*/}
          <MenuItem onClick={() => {setMenuDocument('factures'); handleClose()}}><Link 
              to="/account/documents"
            >Factures</Link></MenuItem>
          <MenuItem onClick={() => {setMenuDocument('contrats'); handleClose()}}><Link 
              to="/account/documents"
            >Contrats</Link></MenuItem>
          </Menu>
          <li className={location.pathname === "/account/course" ? "activ-link bold li-connected" : "li-connected"}>
            <Link 
              to="/account/course"
            ><FaBook /></Link>
          </li>
          <li className={location.pathname === "/account/recrutement" ? "activ-link bold li-connected" : "li-connected"}>
            <Link 
              to="/account/recrutement"
            ><FaUserTie /></Link>
          </li>
          <li className={location.pathname === "/account/ocean" ? "activ-link bold li-connected" : "li-connected"}>
            <Link 
              to="/account/ocean"
            ><FaChartPie /></Link>
          </li>
          <li className={location.pathname === "/account/profil" ? "activ-link bold li-connected" : "li-connected"}>
            <Link 
            to="/account/profil"
            ><FaUserCircle /></Link>
          </li>
          <li className="btn-deconnexion"
            onClick={() => {
              disconnectUser()
              navigate('/');
              
            }}
          >Déconnexion</li>
        </ul>
        }
      </nav>
    </AccountMenuStyled>
  )
}

AccountMenu.propTypes = {
}

export default AccountMenu