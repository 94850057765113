import styled from 'styled-components';

const CourseDoneCardStyled = styled.article`
    width: 100%;
    background-color: #fff;
    border-radius: 15px;
    padding: 10px 30px 10px 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    box-shadow: 0px 1px 10px #00000029;
    margin-bottom: 50px;

    img {
        width: 100px;
    }

    .circle-wrapper {
        width: 90px;
        height: 90px;
        position: relative;
        border-radius: 50%;
        box-shadow: 0px 1px 10px #00000029;
        .score-div {
            position: absolute;
            top: 0;
            text-align: center;
            width: 100%;
            height: 100%;
            color: #6AAF8B;
            font-size: 19px; 

            .top {
                margin-top: 25px;
                margin-left: -30px;
                display: block;
            }
            .middle {
                margin-top: -10px;
                margin-left: -5px;
                display: block;
                font-size: 22px;
            }
            .bottom {
                margin-top: -10px;
                margin-left: 30px;
                display: block;
            }
        }
    }

    .title-wrapper {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid #4d4d4d;
        align-items: center;
        padding: 0 0 5px 0;
        margin: 0 0 5px 0;
        font-size: 18px;

        h3 {
            color: #D47558;
            font-size: 18px;
            margin: 0 20px 0 0;
        }

        h4 {
            color: #4d4d4d;
            font-size: 18px;
        }
    }

    .text-wrapper {
        width: calc(98% - 120px);


        .txt-skeleton {
            font-size: 18px;
        }

        h3 {
            color: #D47558;
            font-size: 18px;
            margin: 0 20px 0 0;
        }

        .bottom-wrapper {

            h4 {
                color: #4d4d4d;
            }

            .flex-bottom {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 10px;

                p {
                    display: inline-block;
                    color: #D47558;
                    padding-top: 10px;
                }

                .btn-lightblue {
                    color: #fff;
                    background-color: #649ACE;
                    border-radius: 12px;
                    border: 0;
                    padding: 5px 10px;
                    cursor: pointer;

                    &:hover {
                        background-color: #202D3C;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 0px) and (max-width: 759px) {
        flex-direction: column;
        border-radius: 50px;
        padding-bottom: 40px;
        padding: 10px 10px 10px 5px;

        .text-wrapper {
            width: calc(95%);
            text-align: center;

            .bottom-wrapper {
                .flex-bottom {
                    justify-content: center;
                    flex-direction :column;
                    .btn-lightblue {
                        margin: 20px 0 0 0;
                        padding: 10px 10px;
                    }
                }
            }
        }
    }
`;

export default CourseDoneCardStyled;