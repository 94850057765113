/**** Import des modules ****/
import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'


/**** Import des css ****/
import ModalPostulerTutoStyled from './ModalPostulerTutoStyled'
import axios from 'axios'
import * as qs from 'qs'

import urlBackEnd from '../../assets/js/urlBackEnd'
import { Skeleton } from '@mui/material';

const URL = urlBackEnd

/**** ****/



const ModalPostulerTuto = ({toggleModalPostulerTuto, setJobIdModalPostulerTuto, setCvPosted, setDidTest, isModalPostulerTutoOpen, jobIdModalPostulerTuto, didTest, cvPosted, userInfo, isLogged, userRole, fetchJobPostuler, setFullPageLoaderJobInsc, nameOfCourse, setDidOcean, didOcean, jobs, activJobId, changeActivCourseId, openModal, jobsHome, jobForModalPostuler }) => {

  useEffect(() => {
    if(isLogged) {
      if(userInfo.data[0].attributes.cv && userInfo.data[0].attributes.cv.data !== null){
        setCvPosted(true)
      }
      verfiyDidTest()
      verifyDidOcean()
      verifyDidCourse()
    }
  }, []);

  let location = useLocation()


  const verfiyDidTest = () => {
      const queryFilterObj= qs.stringify({
        filters: {
          particulier: {
            id: userInfo.data[0].id
          },
          session_id : 'recrutement'
        }
        }, {
          encodeValuesOnly: true,
        });
      axios.get(`${URL}score-formations?${queryFilterObj}`, {})
      .then((response) => {
        if (response.data.data.length > 0) {
          setDidTest(true)
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const verifyDidOcean = () => {
    const queryFilterObj= qs.stringify({
      filters: {
        particulier: {
          id: userInfo.data[0].id
        }
      }
      }, {
        encodeValuesOnly: true,
      });
    axios.get(`${URL}test-oceans?${queryFilterObj}`, {})
    .then((response) => {
      if (response.data.data.length > 0) {
        setDidOcean(true)
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }

  const job = [jobForModalPostuler]

  const isUserDidCourse = () => {
    if (job[0].attributes.formation.data !== null) {
      const selectedCourseId = job[0].attributes.formation.data.id;
      const userCourseEnded = userInfo.data[0].attributes.formations_termine.data;
      
      let result = []
      let resultData = userCourseEnded.filter(selectedCourse => selectedCourseId === selectedCourse.id)
      let IASCanAll = userCourseEnded.filter(course => course.id === 12)
      result.push(resultData)
      result.push(IASCanAll)

      if (resultData.length > 0 || IASCanAll.length > 0) {
        return true;
      } else {
        return false
      }
    } else {
      return true
    }
  }
  

  const isUserDoingCourse = () => {
    if (job[0].attributes.formation.data !== null) {
      const selectedCourseId = job[0].attributes.formation.data.id;
      const userCourseEnded = userInfo.data[0].attributes.en_cours_formations.data;
      
      let result = []
      let resultData = userCourseEnded.filter(selectedCourse => selectedCourseId === selectedCourse.id)
      let IASCanAll = userCourseEnded.filter(course => course.id === 12)
      
      result.push(resultData, IASCanAll)
      
      if (resultData.length > 0 || IASCanAll.length > 0) {
        return true;
      } else {
        return false
      }
    } else {
      return true
    }
  }

  const verifyDidCourse = () => {
    if (isUserDidCourse() === false && isUserDoingCourse() === true) {
      return true
    } else if (isUserDidCourse() === false && isUserDoingCourse() === false) {
      return false
    } 
    else if (isUserDoingCourse() === false && isUserDidCourse() === true) {
      return true
    } else {
      return true
    }
  }

  const verifySecteurAndTest = () => {
    if (job[0].attributes.formation.data !== null) {
      return true
    } else if (job[0].attributes.formation.data === null) {
      if (job[0].attributes.secteurs.data[0].attributes.nom === 'Assurance') {
        return didTest
      } else {
        return true
      }
    }
  }

  if(isLogged && (userRole !== 'entreprise')) {
  return (
    <ModalPostulerTutoStyled>
      <section className="modal-content-wrapper">
        {didTest && cvPosted && didOcean && verifyDidCourse() ? 
          <h4 className='bold'>Vous pouvez postuler à cette offre : {job[0].attributes.nom}</h4>
          :
          <h4 className='bold'>Effectuez les étapes ci-dessous pour postuler à l'offre d'emploi</h4>
        }
        <Link to='/account/profil'>
        <div className='step-1'><span className="number">1</span><span className={cvPosted ? 'txt done' : 'txt'}>Postez votre cv en ligne</span></div>
        </Link>
        <Link to='/account/ocean'>
        <div className='step-2'><span className="number">2</span><span className={didOcean ? 'txt done' : 'txt'}>Passez le test de personnalité</span></div>
        </Link>
        {job[0].attributes.formation.data !== null ? 
          <div 
          className='step-3'
          onClick={() => {
            changeActivCourseId(job[0].attributes.formation.data.id)
            openModal("formation")
          }}
          >
            <span className="number">3</span><span className={verifyDidCourse() ? 'txt done' : 'txt'}>Inscrivez-vous et commencez la formation éligible au CPF pré-requise</span>
          </div>
        :
        <>
          { job[0].attributes.secteurs.data[0].attributes.nom === 'Assurance' &&
            <Link to='/account/recrutement'>
              <div className='step-3'><span className="number">3</span><span className={didTest ? 'txt done' : 'txt'}>Passez le test de recrutement</span></div>
            </Link>
          }
        </>
        }
        {didOcean && verifySecteurAndTest() && cvPosted && verifyDidCourse() ?
        <button 
          className="postuler-green"
          onClick={() => {
            setFullPageLoaderJobInsc(true)
            fetchJobPostuler(jobIdModalPostulerTuto)
          }}
        >Postulez</button>
        :
        <button className="postuler-grey">Postulez</button>
        }
      </section>
    </ModalPostulerTutoStyled>
  )
} else {
  return(
    <ModalPostulerTutoStyled className="display-none"></ModalPostulerTutoStyled>
  )
}
}

ModalPostulerTuto.propTypes = {

}

export default ModalPostulerTuto