import { connect } from 'react-redux'

import CourseSecteur from '../../pages/CourseSecteur'

import { changeActivCourseId } from '../../actions/course'

const mapStateToProps = (state) => ({
    currentDomaineClicked: state.menu.currentDomaineClicked,
    secteurs: state.menu.secteurs
})

const mapDispatchToProps = (dispatch) => ({
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(CourseSecteur)