import styled from 'styled-components';

const SocietyStyledStyled = styled.section`

padding: 150px 0;
display: flex;
flex-direction: column;
align-items: center;

h2 {
    font-size: 35px;
    width: auto;
    display: inline;
    margin: 0 auto 10px;
    position: relative;
    color: #649ACE;
    padding-bottom: 10px;
    text-transform: uppercase;

    .separator-blue {
        background-color: #649ACE;
        height: 4px;
        width: 80px;
        margin: 10px 0 0 auto;
        position: absolute;
        right: 0;
        bottom: 0;
    }
}

p {
    color: #707070;
    margin-bottom: 40px;

    .login-span {
        color: #649ACE;
    }

    a { 
        color: #649ACE;
    }
}

.account-form-comp {
    width: 90vw;
    min-width: 350px;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: #707070;
    padding-bottom: 50px;

    label {
        width: 100%;
    }

    h3 {
        color: #649ACE;
        font-size: 20px;
        margin: 10px 0 30px; 
    }

    .blue {
        color: #649ACE;
    }

    input {
        width: 100%;
        border: 0;
        border-radius: 10px;
        background-color: #F2F2F2;
        padding: 20px;
        margin: 10px 0px 30px;
        box-sizing: border-box;

        &:focus {
            outline: 2px solid #007aff;
        }
    }

    .search-adresse-wrapper {

        position: relative;

        .result-adresse-wrapper {
            position: absolute;
            top: 70px;
            left: 0;
            background-color: #f2f2f2;
            width: calc(100% + 4px);
            margin-left: -2px;
            border-radius: 10px;
            padding-top: 15px;
            border: 2px solid #007aff;
            box-sizing: border-box;
            border-top: 0;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            z-index: 10;

            li {
                padding: 10px 10px 10px 22px;
                font-size: 14px;

                &:last-child {
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                }
                &:hover {
                    background-color: #fff;
                }
            }
        }
    }

    .mdp-wrapper, .name-wrapper, .contact-wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        position: relative;

        img {
            width: 45%;
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    .invisible-front {
        display: none;
    }

    .mdp-wrapper .mdp-cond-wrapper {
        margin: 0 auto;
        color: #979797;

        p {
            margin-bottom: 10px;
            color: #979797;
        }

        ul {
            margin-bottom: 30px;

            li {
                display: flex;
                align-items: center;
                padding: 5px 0;

                span {
                    padding-top: 2px;
                }
                svg {
                    margin:0 5px 0 0;
                }

                .check-picto {
                    color: #6aaf8b;
                }

                .close-picto {
                    color: #dc4a46;
                }
            }
        }
    }

    .mdp-wrapper, .name-wrapper, .contact-wrapper {
        
        label {
            width: 45%;
        }
    }

    .date-title {
        text-align: center;
        padding-bottom: 20px;
    }

    .secteur-wrapper {
        width: 45%;
    }

    .date-wrapper, .secteur-wrapper {
        
        label {
            width: 30%;

            select {
                width: 100%;
                border: 0;
                border-radius: 10px;
                background-color: #F2F2F2;
                padding: 20px;
                margin: 10px 0px 30px;
                box-sizing: border-box;
            }
        }
    }
    .contact-wrapper.align-left {
        flex-direction: column;
        align-items: flex-start;
    }

    .search-wrapper {
        background-color: #E1EDF7;
        border-radius: 10px;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        margin: 0 0 30px;

        h4 {
            width: 100%;
        }

        label {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 45%;

            input {
                width: 30px;
                margin: 20px 0;
            }
        }
    }

    .confirmation-wrapper {
        display:flex;
        align-items: center;
        justify-content: space-between;

        p {
            width: 45%;
            margin: 0;
        }

        .lightblue-btn {
            margin: 0;
        }
    }

    .lightblue-btn {
        width: 300px;
        margin: 0px auto 30px;
        color: #fff;
        background-color: #649ACE;
        border: 2px solid #87B7E5;
        padding: 15px;
        font-size: 18px;
        box-sizing: border-box;
        cursor: pointer;
        box-shadow: 16px 0px 16px #00000014;

        &:hover {
            color: #649ACE;
            background-color: #fff;
        }
    }

    .generator-pw-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 0 20px 0;

        div {
            width: 45%;
        }

        .btn-generator {
            background-color: #dbe8f4;
            border-radius: 10px;
            padding: 15px;
            text-align: center;
            cursor: pointer;
            transition: all .3s ease;

            &:hover {
                opacity: .7;
            }
        }

        .pw-wrapper {
            background-color: #ECF3EF;
            border-radius: 5px;
            text-align: center;
            padding: 7px;

            p {
                margin: 0;
            }
        }
    }

    label.newsletter {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        margin: 0 0 30px;

        input {
            width: 30px;
            margin: 0;
        }

        a {
            padding: 0 5px;
        }
    }
}

@media only screen and (min-device-width: 0px) and (max-device-width: 759px) {
    h2 {
        font-size: 25px;
        text-align: center;

        .separator-blue {
            right: calc(50% - 40px);
        }
    }

    .account-form-comp {

        img {
            display: none;
        }
        .confirmation-wrapper {
            flex-direction: column;
    
            p {
                width: 100%;
                margin: 0 0 20px;
            }
        }
    }
 }

`;

export default SocietyStyledStyled;