/**** Import des modules ****/
import React, { useState, useEffect } from 'react'

/**** Import des css ****/
import StepThreeStyled from './StepThreeStyled'


const StepThree = ({ }) => {

  return (
    <StepThreeStyled>
        Step Three
    </StepThreeStyled>
  )
}

export default StepThree