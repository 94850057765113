/**** Import des modules ****/
import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { FaBicycle, FaCarSide, FaTrain } from "react-icons/fa"
import PropTypes from 'prop-types'
import axios from 'axios'
import * as qs from 'qs'
import Moment from 'react-moment'

/**** Import d'images ****/
import suitcase from '../../assets/img/picto/suitcase-green.png'
import suitcaseWhite from '../../assets/img/picto/suitcase-white.png'

/**** components ****/
import ModalJobFiche from '../../containers/ModalJobFiche';
import ModalCourseProg from '../../containers/ModalCourseProg';

/**** Import des css ****/
import JobCardStyled from './JobCardStyled'
import { Skeleton } from '@mui/material'
import urlBackEnd from '../../assets/js/urlBackEnd'

const URL = urlBackEnd

const JobCard = ({activJobId, changeActivJobId, jobId, attributes, userRole, setJobFicheId, changeActivCourseId, jobsWithDistances, userInfo, kmJobFilter, id, isLogged, showDistance, openModal, fullPageLoaderDistance, toggleModalPostulerTuto, setJobIdModalPostulerTuto, setCvPosted, setDidTest, setJobForModalPostuler, isActive }) => {
  const [modalJobFicheOpen, setModalJobFicheOpen] = useState(false);
  const [modalCourseProgOpen, setModalCourseProgOpen] = useState(false);
  const [enCoursF, setEnCoursF] = useState([]);
  const [termineF, setTermineF] = useState([]);

  useEffect(() => {
    if (isLogged === true) {
    const queryFilterPart = qs.stringify({
      filters: {
        en_cours_particuliers: {
          id: userInfo.data[0].id
        },
      }
      }, {
        encodeValuesOnly: true,
      });

    axios.get(`${URL}formations?${queryFilterPart}`, {})
      .then((response) => {
       setEnCoursF(response.data.data)
      })
      .catch((error) => {
        console.error(error);
      });

    const queryFilterPartt = qs.stringify({
      filters: {
        particuliers_termine: {
          id: userInfo.data[0].id
        },
      }
      }, {
        encodeValuesOnly: true,
      });

    axios.get(`${URL}formations?${queryFilterPartt}`, {})
      .then((response) => {
        setTermineF(response.data.data)
      })
      .catch((error) => {
        console.error(error);
      });
    }
  }
  , [])

  useEffect(() => {

    if (isLogged === true) {
    const queryFilterPart = qs.stringify({
      filters: {  
        session_id: 'recrutement',
        particulier: {
          id: userInfo.data[0].id
        },
      }
      }, {
        encodeValuesOnly: true,
      });

    axios.get(`${URL}score-formations?${queryFilterPart}`, {})
      .then((response) => {
        if(response.data.data.length > 0) {
          setDidTest(true)
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }
  }
  , [])

  useEffect(() => {
    if(isActive !== undefined && isActive === true) {
      changeActivJobId(jobId)
    }
  }
  , [isActive])

  let location = useLocation()

  const thisJob = () => {
    const jobToFind = jobsWithDistances.find( thisjob => thisjob.id === id)
    return jobToFind
  }

  const verifsModalPostulerTuto = () => {
    toggleModalPostulerTuto(true)
    setJobIdModalPostulerTuto(activJobId, attributes.nom)
    if(isLogged) {
      if(userInfo.data[0].attributes.cv.data !== null){
        setCvPosted(true)
      }
    }
  }

  const secteurCart = attributes.secteurs.data[0]

  return (
    <JobCardStyled
    className={`${activJobId === jobId & location.pathname === '/job' ? "active-job" : ""} ${location.pathname === '/job' ? "pointer" : ""}`}
      onClick={() => changeActivJobId(jobId)}
    >
      {modalJobFicheOpen &&
        <ModalJobFiche modalJobFicheOpen={modalJobFicheOpen} setModalJobFicheOpen={setModalJobFicheOpen} />
      }
      {modalCourseProgOpen &&
        <ModalCourseProg modalCourseProgOpen={modalCourseProgOpen} setModalCourseProgOpen={setModalCourseProgOpen} />
      }
        <div className="title-wrapper">
          <h4 className="first-title bold">{secteurCart.attributes.nom}</h4>
        </div>
        <p className="job-cat">Lieu: {attributes.lieu}</p>
        <h3 className="bold">{attributes.nom}</h3>
        <div className="salary-wrapper">
            <span className="bold">Rémunération:</span>
            {attributes.remuneration === 0 ? 
              <span className="small-txt bold"><span className="black-small bold">à déterminer</span></span> 
              :
              <span className="small-txt bold"><span className="black bold">{attributes.remuneration}K€</span> annuel</span> 
          }
        </div>
        <p className="publication-date">Publiée le <span><Moment format="DD/MM/YYYY"  className="bold">{attributes.createdAt}</Moment></span></p>
        {userRole !== 'entreprise' &&
        <>
        { location.pathname === "/job" ?
        <button 
          className="btn-lightgreen mobile-none"
          onClick={() => {
            openModal('postuler'); 
            changeActivJobId(jobId)
            let jobObj = {
              id: jobId,
              attributes: attributes
            }
            setJobForModalPostuler(jobObj)
            //setJobFicheId(attributes.fiche_metier.data.id)
            verifsModalPostulerTuto()
          }}
        >Postuler</button>
        :
        <>
        <button 
          className="btn-lightgreen mobile-none"
          onClick={() => {
            openModal('postuler'); 
            changeActivJobId(jobId)
            let jobObj = {
              id: jobId,
              attributes: attributes
            }
            setJobForModalPostuler(jobObj)
            //setJobFicheId(attributes.fiche_metier.data.id)
            verifsModalPostulerTuto()
          }}
        >Postuler</button>
        </>
        /*
        <Link to={`/job/${jobId}`} onClick={() => setJobFicheId(attributes.fiche_metier.data.id)}>
          <button className="btn-lightgreen">Fiche métiers</button>
        </Link>
        */
        }
        </>
        }
        
        {/*false && location.pathname === "/job" ?
        <button 
          className="btn-darkblue"
          onClick={() => {
            changeActivCourseId(attributes.formation.data.id)
            setModalCourseProgOpen(true)
          }}
        >Formation nécessaire</button>
        :
        <>
        {/*attributes.formation.data === null ? "" :
        <button 
        className="btn-darkblue"
        onClick={() => {
          changeActivCourseId(attributes.formation.data.id)
          setModalCourseProgOpen(true)
        }}
        >Formation nécessaire</button>
        /*
        <Link to={`/formation/${attributes.formation.data.id}`}>
        <button className="btn-darkblue">Formation nécessaire</button>
        </Link>
        */
        //</>}
        //</>
        }
        {!activJobId !== jobId &&
        <div className="suitcase-wrapper">
          <img src={suitcase} className="suitcase-picto" alt="sac a main vert" />
        </div>
        }
        {activJobId === jobId && ( location.pathname === '/job') &&
        <div className="suitcase-wrapper green">
          <img src={suitcaseWhite} className="suitcase-picto" alt="sac a main vert" />
        </div>
        }
        {isLogged && userRole !== 'entreprise' && enCoursF.length > 0 | termineF.length > 0 ?
        <div className="distance-wrapper">
          { kmJobFilter === '' && 
          <>
            <div className="voiture-wrapper">
              <FaCarSide />
            </div>
            <div className="velo-wrapper">
              <FaBicycle />
            </div>
            <div className="train-wrapper">
              <FaTrain />
            </div>
          </>
          }
          { kmJobFilter === 'Temps à vélo' &&
          <>
          {!fullPageLoaderDistance ? thisJob().direction !== undefined &&
            <div className='col-wrapper'>
              <FaBicycle className="svg-normal"/>
                <div>Distance: {thisJob().direction.km.text}</div>
                <div>{thisJob().direction.time.text}</div>
            </div>
            :
            <div className='col-wrapper'>
              <Skeleton width='150px' height='58px'/>
            </div>
          }
          </>
          }
          { kmJobFilter === 'Temps en voiture' &&  
          <>
          {!fullPageLoaderDistance ? thisJob().direction !== undefined &&
            <div className='col-wrapper'>
              <FaCarSide className="svg-normal"/>
              <div>Distance: {thisJob().direction.km.text}</div>
              <div>{thisJob().direction.time.text}</div>
            </div>
            :
            <div className='col-wrapper'>
              <Skeleton width='150px' height='58px'/>
            </div>
          }
          </>
          }
          { kmJobFilter === 'Temps en transport' && thisJob().direction !== undefined &&
          <>
          {!fullPageLoaderDistance ?
            <div className='col-wrapper'>
              <FaTrain className="svg-small"/>
                <div>Distance: {thisJob().direction.km.text}</div>
                <div>{thisJob().direction.time.text}</div>
            </div>
              :
              <div className='col-wrapper'>
                <Skeleton width='150px' height='58px'/>
              </div>
            }
            </>
          }
        </div>
        :''
      }
    </JobCardStyled>
  )
}

JobCard.propTypes = {
  activJobId: PropTypes.number.isRequired,
  changeActivJobId: PropTypes.func.isRequired, 
}

export default JobCard