/**** Import des modules ****/
import React, {useEffect, useState} from 'react'
import { useNavigate, Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import axios from 'axios'
import * as qs from 'qs'

/**** Import de components et containers ****/
/**** Import des images ****/
import progaccount from '../../../assets/img/illustration/progaccount.png'

/**** Import des css ****/
import ModalCourseInscriptionStyled from './ModalCourseInscriptionStyled'
import { Skeleton } from '@mui/material'
import urlBackEnd from '../../../assets/js/urlBackEnd'

const URL = urlBackEnd

const ModalCourseInscription = ({prenom, closeModal, formations, activCourseId, fetchDemandeFormation, setInscCollabStepOne, setModalInscLoaderOpen,  redirectToMyFinancement}) => {

  const [secteurCart, setSecteurCart] = useState([]);

  useEffect(() => {
    const queryFilterPart = qs.stringify({
      filters: {
        formations: {
          id: activCourseId
        },
      }
      }, {
        encodeValuesOnly: true,
      });

    axios.get(`${URL}secteurs?${queryFilterPart}`, {})
      .then((response) => {
       setSecteurCart(response.data.data)
      })
      .catch((error) => {
        console.error(error);
      });
  }
  , [])

  if(redirectToMyFinancement) {
    closeModal('')
    return (<Navigate to="/account/mes-financements" replace />)
  }

  const filterdFormation = () => {
    if (formations !== undefined) {
      const findFormation = formations.find(formation => formation.id === activCourseId)
      return findFormation
    } else {
      return false
    }
      
  }

  let euroFormat = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
  });
  return (
    <ModalCourseInscriptionStyled>
      <section>
        <h3 className="answer bold">VOUS AVEZ SÉLECTIONNÉ CETTE FORMATION</h3>
        {filterdFormation() !== false && 
        <div className="card">
          <img src={progaccount} alt="picto d'un écran vidéo bleu" />
          <div className="text-wrapper">
            <h3 className="title-blue bold">{filterdFormation().attributes.nom}</h3>
            {secteurCart.length > 0 ? 
            <>
              <h4 className="">Secteur: {secteurCart[0].attributes.nom}</h4>
            </>
            :
              <Skeleton variant="text" className='txt-skeleton' />
            }
            <div className="bottom-wrapper">
              <p className="bold capitalize">{euroFormat.format(filterdFormation().attributes.prix)} TTC - {filterdFormation().attributes.duree} H</p>
            </div>
          </div>
        </div>
        }
        <button 
          className="btn-lightblue"
          onClick={() => {
            fetchDemandeFormation(filterdFormation().id)
          }}
        >Demande d'inscription</button>
      </section>
    </ModalCourseInscriptionStyled>
  )
}

ModalCourseInscription.propTypes = {
  closeModal: PropTypes.func.isRequired,
  prenom: PropTypes.string.isRequired,
}

export default ModalCourseInscription

