/**** Import de modules ****/
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { saveAs } from 'file-saver'
import Skeleton from '@mui/material/Skeleton'

/**** import des components ****/
import ModalJobFiche from '../../containers/ModalJobFiche'
import FactureCourseU from './factureU'

/**** Import d'images' ****/


/**** Import des css ****/
import FactureCourseStyled from './FactureCourseStyled'
import urlBackEndSave from '../../assets/js/urlBackEndSave';

const FactureCourse = ({factureP, factureE, userRole, factureEStatut, facturePStatut}) => {

  const savePdf = (url, name) => {
    saveAs(
      `${urlBackEndSave}${url}`,
      `${name}`
    );
  };

  
  return (
    <FactureCourseStyled>
      <section className="tab-wrapper">
        <div className='legend'>
          <div className="row">
            <div className='bold'>Numéro</div>
            <div className='bold'>Date</div>
          </div>
          <div className="row">
            <div className='bold'>Montant</div>
            <div className='bold'>Nom</div>
          </div>
          <div className="row bold">
            Fichier
          </div>
          <div className="row last bold">
            Statut
          </div>
        </div>
        {userRole === 'entreprise' && !factureEStatut &&
        <>
        <div className='full-width-w'>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-statut" variant="rectangular" />
          </div>
        </div>
          <div className='full-width-w'>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-statut" variant="rectangular" />
          </div>
        </div>
          <div className='full-width-w'>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-statut" variant="rectangular" />
          </div>
        </div>
          <div className='full-width-w'>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-statut" variant="rectangular" />
          </div>
        </div>
        </>
        }
        {userRole !== 'entreprise' && !facturePStatut &&
        <>
        <div className='full-width-w'>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-statut" variant="rectangular" />
          </div>
        </div>
          <div className='full-width-w'>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-statut" variant="rectangular" />
          </div>
        </div>
          <div className='full-width-w'>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-statut" variant="rectangular" />
          </div>
        </div>
          <div className='full-width-w'>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-txt" variant="text" />
            <Skeleton className="skeleton-txt" variant="text" />
          </div>
          <div className="wrapper-skeleton">
            <Skeleton className="skeleton-statut" variant="rectangular" />
          </div>
        </div>
        </>
        }
        {(userRole === 'entreprise' ? factureE.length : factureP.length) > 0 &&
          (userRole === 'entreprise' ? factureE : factureP).map((facture) => (
            <FactureCourseU key={facture.id} facture={facture} userRole={userRole} factureEStatut={factureEStatut} facturePStatut={facturePStatut}/>
          ))
        }
      </section>
    </FactureCourseStyled>
  )
}

FactureCourse.propTypes = {
}

export default FactureCourse