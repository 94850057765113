/**** Import des modules ****/
import React from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

/**** Import de components et containers ****/
import Private from '../../../containers/SignIn/Private'
import YouAre from '../../../containers/SignIn/YouAre'
import Society from '../../../containers/SignIn/Society'

/**** Import des css ****/
import ModalAccountCreationStyled from './ModalAccountCreationStyled'

const ModalAccountCreation = ({signInStep}) => {
  return (
    <ModalAccountCreationStyled>
      {signInStep === 1 && 
        <YouAre />
      }
      {signInStep === 2.1 && 
        <Private />
      }
      {signInStep === 2.2 && 
        <Society />
      }
    </ModalAccountCreationStyled>
  )
}

ModalAccountCreation.propTypes = {
  openModal: PropTypes.func.isRequired,
  changeIsLogged: PropTypes.func.isRequired,
  changeValue: PropTypes.func.isRequired,
}

export default ModalAccountCreation

