import styled from 'styled-components';
import bcgHeader from '../../assets/img/header/formation-ill.jpg'

const CourseSecteurStyled = styled.main`
    padding-top: 0px;

    .illustration-header {
        width: 100vw;
        background: rgb(129, 64, 33);
        background: linear-gradient(180deg, rgba(129, 64, 33 ,1) 0%, rgba(51, 25, 13,1) 66%);
        height: 700px;
        clip-path: polygon(100% 0, 100% 88%, 74% 100%, 47% 92%, 19% 100%, 0 70%, 0 0);

        .opacity-light-section {
            height: 100%;
            top: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            padding: 0px 0;

            .left {
                width: calc(60% - 20%);
                margin-right: 5%;

                h1 {
                    font-size: 50px;
                    color: #fff;
                }

                p {
                    font-size: 25px;
                    color: #fff;
                    line-height: 30px;
                    padding: 30px 0;
                }
            }
            img {
                max-width: 25%;
            }
        }
    }

    .prog-pres {
        background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(255,255,255,1) 30%, rgba(244,244,244,1) 49%);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 0;

        .design-top {
            display: flex;
            flex-direction: row;
            align-items: center;

            img {
                width: 200px;
                margin: 0 20px;
            }

            .separator-yellow {
                height: 6px;
                width: 150px;
                background-color: #EDBE31;
            }
        }

        .definition-prog {
            width: 96%;
            max-width: 1200px;
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            justify-content: space-between;

            .left, .right {
                width: 45%;

                h3 {
                    color: #202D3C;
                    font-size: 18px;
                    line-height: 22px;
                    max-width: 320px;
                    margin: 0 auto 30px;
                }

                p {
                    color: #656464;
                    font-size: 16px;
                    line-height: 22px;
                }
            }

            .separator-grey {
                width: 1px;
                background-color: #707070;
                margin-top: 50px;
            }
        }
    }

    .formation-section {
        padding: 50px 0;
        text-align: center;

        h2 {
            font-size: 45px;
            width: auto;
            display: inline;
            margin: 0 auto;
            position: relative;
            color: #202D3C;
            padding-bottom: 10px;

            .separator-blue {
                background-color: #6399ce;
                height: 6px;
                width: 240px;
                margin: 10px 0 0 auto;
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }

        .formation-wrapper {
            max-width: 760px;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            padding: 50px 0 0 0;
        }
        
    }

    .work-section {
        background-color: #F4F4F4;
        padding: 50px 0;

        .section-wrapper {
            width: 96%;
            max-width: 1200px;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            
            img {
                width: 40%;
            }

            .work-right {
                width: 55%;

                h2 {
                    font-size: 45px;
                    width: auto;
                    display: inline;
                    margin: 0 auto;
                    position: relative;
                    color: #202D3C;
                    padding-bottom: 10px;

                    .separator-blue {
                        background-color: #6399ce;
                        height: 6px;
                        width: 240px;
                        margin: 10px 0 0 auto;
                        position: absolute;
                        right: 0;
                        bottom: 0;
                    }
                }

                p {
                    margin-top: 50px;
                    color: #656464;
                    line-height: 22px;
                }
            }
        }
    }

    @media only screen and (max-width: 1400px) {
        .illustration-header {
            .opacity-light-section {
                flex-direction: column-reverse;
    
                .left {
                    width: calc(80%);
                    margin: 0 auto;
    
                    h1 {
                        font-size: 35px;
                        text-align: center;
                        margin-top: 20px;
                    }
    
                    p {
                        font-size: 18px;
                        line-height: 22px;
                        text-align: justify;
                    }
                }
                img {
                    max-width: calc(250px - 0%);
                    margin-top: 100px;
                }
            }
        } 
    }
        
    @media only screen and (max-width: 1079px) {
        .illustration-header {
    
            .opacity-dark {
    
                .opacity-white {
                    width: 96%;
                    max-width: 1200px;
                    box-sizing: border-box;

                    h1 {
                        width: 100%;
                        font-size: 45px;
                        margin: 20px 0 0 0px;
                        text-align: center;
                    }
                }
            }
        } 
    }


    @media only screen and (min-width: 0px) and (max-width: 759px) {

        .illustration-header {
    
            .opacity-light-section {
                flex-direction: column-reverse;
    
                .left {
                    width: calc(80%);
                    margin: 0 auto;
    
                    h1 {
                        font-size: 35px;
                        text-align: center;
                    }
    
                    p {
                        font-size: 18px;
                        line-height: 22px;
                        text-align: justify;
                        padding: 20px 0;
                        width: 80%;
                        margin: 0 auto;
                    }
                }
                img {
                    max-width: calc(250px - 5%);
                    margin-top: 100px;
                }
            }
        }

        .formation-section {
            h2 {
                font-size: 35px;
                margin: 0 auto;

                .separator-blue {
                    width: 100px;
                }
            }

            .formation-wrapper {
                article {
                    margin-left: 20px !important;
                }
            }
        }

        .prog-pres {
            .design-top {
                .separator-yellow {
                    width: 50px;
                }

                img {
                    width: 150px;
                }
            }

            .definition-prog {
                flex-direction: column;

                .left, .right {
                    width: 100%;
                }
            }
        }

        .work-section {
            background-color: #F4F4F4;
            padding: 50px 0;
    
            .section-wrapper {
                flex-direction: column;

                img { width: 250px;}

                .work-right {
                    width: 100%;
                    margin-top: 20px;
                    text-align: center;

                    p {
                        text-align: justify;
                    }

                    h2 {
                        font-size: 35px;
                        margin: 0 auto;

                        .separator-blue {
                            width: 100px;
                        }
                    }
                }
            }
        }
    }
`;

export default CourseSecteurStyled;