import styled from 'styled-components';

const FormCourseStyled = styled.div`
    margin: 200px 0 0 0;

    .form-wrapper {
        max-width: 750px;
        margin: 0 auto 50px;

        .title {
            font-size: 18px;
            margin-bottom: 20px;
        }

        .textField {
            width: 100%;
            margin: 10px 0;
        }

        .center {
            text-align: center;
        }

        .btn-submit {
            background-color: #202c3c;
            border-radius: 10px;
            border: 0;
            color: #fff;
            padding: 15px;
            cursor: pointer;
            transition: all .3s ease;

            &:hover {
                opacity: .7;
            }
        }
    }
    
    @media only screen and (min-width: 0px) and (max-width: 759px) {
    }
`;

export default FormCourseStyled;