/**** Import de modules ****/
import React, { useState, useEffect, useRef } from 'react'
import { Link, useLocation } from "react-router-dom"
import { AiOutlineCaretDown, AiOutlineCaretRight } from "react-icons/ai";
import { motion, AnimatePresence } from "framer-motion";
import PropTypes from 'prop-types'
import { HiMenu, HiX } from "react-icons/hi";

/**** Import d'images' ****/

/**** Import des components ou containers ****/
import JobLi from '../../../containers/Header/JobLi'
import CourseLi from '../../../containers/Header/CourseLi'

/**** Import des css ****/
import NavUserStyled from './NavUserStyled'

const NavUser = ({currentProfilUser, changeCurrentProfilUser, windowTop, setIsShowMenu, isShowMenu, userRole, changeSigninStep, isLogged}) => {

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsShowChooseBtn(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const [isShowChooseBtn, setIsShowChooseBtn] = useState(false);
  let locationAccount = useLocation().pathname.includes('/account')
  let comboHeaderColor = windowTop && !locationAccount

  return (
    <NavUserStyled 
      windowTop={windowTop} 
      isShowChooseBtn={isShowChooseBtn} 
      comboHeaderColor={comboHeaderColor}
      ref={wrapperRef}
    >
      <div className="absolute-wrapper">
      {currentProfilUser === "notChoose" && 
      <div className="user-not-choosed-first">
          <ul 
            className="list-statut bold"
          >
            <li className="bold statut">Votre statut <AiOutlineCaretRight className="arrow-right" /></li>
            <Link to="/job"><li 
            className="choose-btn green bold pc"
            onClick={() => {
              setIsShowMenu(false)
              changeCurrentProfilUser("Demandeur d'emploi")
            }}
            >Demandeur d'emploi</li></Link>
            <Link to="/formation"><li 
            className="choose-btn orange bold pc"
            onClick={() => changeCurrentProfilUser('Salarié')}
            >Salarié</li></Link>
            <Link to="/signin"><li
            className="choose-btn lightblue bold pc" 
            onClick={() => {
              changeCurrentProfilUser('Entreprise')
              changeSigninStep(2.2)
            }}
            >Entreprise</li></Link>
            {isShowChooseBtn &&
            <>
              <Link to="/job"><li 
              className="choose-btn green bold mobile"
              onClick={() => {
                setIsShowMenu(false);
                changeCurrentProfilUser("Demandeur d'emploi");
              }}
              >Demandeur d'emploi</li></Link>
              <Link to="/formation"><li 
              className="choose-btn orange bold mobile"
              onClick={() => changeCurrentProfilUser('Salarié')}
              >Salarié</li></Link>
              <Link to="/signin"><li
              className="choose-btn lightblue bold mobile" 
              onClick={() => {
                changeCurrentProfilUser('Entreprise')
                changeSigninStep(2.2)
              }}
              >Entreprise</li></Link>
            </>
            }
          </ul>
          <div className="menu-burger"
            onClick={() => setIsShowChooseBtn(!isShowChooseBtn)}
          >
            {!isShowChooseBtn &&
              <HiMenu className="burger" />
            }
            {isShowChooseBtn &&
              <HiX className="cross" />
            }
          </div>
        </div>
      } 
      {currentProfilUser === "Demandeur d'emploi" && 
      <>
        <div 
        className="user-not-choosed left"
        >
        <div 
          className={`btn-wrapper ${isShowMenu ? "border-change" : ""}`}
          onClick={() => setIsShowMenu(!isShowMenu)}
        >
          <p className="bold" >{currentProfilUser} <AiOutlineCaretDown  className="arrow-down" />
          </p>
        {isShowMenu && 
        <ul className="list-statut">
          <li className="bold" onClick={() => changeCurrentProfilUser("Demandeur d'emploi")}>Demandeur d'emploi</li>
          <li className="bold" onClick={() => changeCurrentProfilUser('Salarié')}>Salarié</li>
          <li className="bold" onClick={() => {changeCurrentProfilUser('Entreprise'); changeSigninStep(2.2)}}>Entreprise</li>
        </ul>
        }
        </div>
      </div>
      <nav className="user-choosed">
        <ul className="main-menu pc">
          <Link to="/job" className="bold"><JobLi className="main-menu" /></Link>
          <Link to="/formation" className="bold"><CourseLi className="main-menu" /></Link>
          <li className="financement-btn main-menu"><Link to="/financement" className="bold">Les financements</Link></li>
          <Link to="/qui-sommes-nous" className="bold qsm-a"><li className="qsm-btn main-menu bold">Qui sommes-nous ?</li></Link>
          {/*<Link to="/actualites" className="bold qsm-a ent-btn-width"><li className="qsm-btn main-menu bold yellow">Actualités</li></Link>*/}
        </ul>
        {isShowChooseBtn &&
        <ul className="main-menu mobile">
          <Link to="/job" className="bold"><JobLi className="main-menu" /></Link>
          <Link to="/formation" className="bold"><CourseLi className="main-menu" /></Link>
          <Link to="/financement" className="bold"><li className="financement-btn main-menu bold">Les financements</li></Link>
          <Link to="/qui-sommes-nous" className="bold qsm-a"><li className="qsm-btn main-menu bold">Qui sommes-nous ?</li></Link>
          {/*<Link to="/actualites" className="bold qsm-a ent-btn-width"><li className="qsm-btn main-menu bold yellow">Actualités</li></Link>*/}
        </ul>
        }
        <div className="menu-burger"
            onClick={() => setIsShowChooseBtn(!isShowChooseBtn)}
          >
            {!isShowChooseBtn &&
              <HiMenu className="burger" />
            }
            {isShowChooseBtn &&
              <HiX className="cross" />
            }
          </div>
      </nav>
      </>
      }
      {currentProfilUser === "Salarié" && 
      <>
      <div 
      className="user-not-choosed left"
      >
      <div 
        className={`btn-wrapper ${isShowMenu ? "border-change" : ""}`}
        onClick={() => setIsShowMenu(!isShowMenu)}
      >
        <p className="bold" >{currentProfilUser} <AiOutlineCaretDown  className="arrow-down" />
        </p>
      {isShowMenu && 
      <ul className="list-statut">
        <li className="bold" onClick={() => changeCurrentProfilUser("Demandeur d'emploi")}>Demandeur d'emploi</li>
        <li className="bold" onClick={() => changeCurrentProfilUser('Salarié')}>Salarié</li>
       <li className="bold" onClick={() => {changeCurrentProfilUser('Entreprise'); changeSigninStep(2.2)}}>Entreprise</li>
      </ul>
      }
      </div>
    </div>
    <nav className="user-choosed">
      <ul className="main-menu pc">
      <Link to="/job" className="bold"><JobLi className="main-menu" /></Link>
        <Link to="/formation" className="bold"><CourseLi className="main-menu" /></Link>
        <Link to="/financement" className="bold"><li className="financement-btn main-menu bold">Les financements</li></Link>
        <Link to="/qui-sommes-nous" className="bold qsm-a"><li className="qsm-btn main-menu bold">Qui sommes-nous ?</li></Link>
        {/*<Link to="/actualites" className="bold qsm-a"><li className="qsm-btn main-menu bold yellow">Actualités</li></Link>*/}
      </ul>
      {isShowChooseBtn &&
      <ul className="main-menu mobile">
        <Link to="/job" className="bold"><JobLi className="main-menu" /></Link>
        <Link to="/formation" className="bold"><CourseLi className="main-menu" /></Link>
        <Link to="/financement" className="bold"><li className="financement-btn main-menu bold">Les financements</li></Link>
        <Link to="/qui-sommes-nous" className="bold qsm-a"><li className="qsm-btn main-menu bold">Qui sommes-nous ?</li></Link>
        {/*<Link to="/actualites" className="bold qsm-a"><li className="qsm-btn main-menu bold yellow">Actualités</li></Link>*/}
      </ul>
      }
      <div className="menu-burger"
          onClick={() => setIsShowChooseBtn(!isShowChooseBtn)}
        >
          {!isShowChooseBtn &&
            <HiMenu className="burger" />
          }
          {isShowChooseBtn &&
            <HiX className="cross" />
          }
        </div>
    </nav>
      </>
      }
      {currentProfilUser === "Entreprise" && 
      <>
      {userRole !== "entreprise" &&
      <>
        <div 
        className="user-not-choosed left"
        >
        
        <div 
          className={`btn-wrapper ${isShowMenu ? "border-change" : ""}`}
          onClick={() => setIsShowMenu(!isShowMenu)}
        >
          <p className="bold" >{currentProfilUser} <AiOutlineCaretDown  className="arrow-down" />
          </p>
        {isShowMenu &&
        <ul className="list-statut">
         <li className="bold" onClick={() => changeCurrentProfilUser("Demandeur d'emploi")}>Demandeur d'emploi</li>
          <li className="bold" onClick={() => changeCurrentProfilUser('Salarié')}>Salarié</li>
         <li className="bold" onClick={() => {changeCurrentProfilUser('Entreprise'); changeSigninStep(2.2)}}>Entreprise</li>
        </ul>
        }
        </div>

      </div>
      </>
      }
      <nav className="user-choosed">
        <ul className="main-menu pc">
          {userRole === "entreprise" ?
          <>
          {isLogged ?
            <Link to="/account/jobs" className="main-menu"><li className="job-btn bold">Recruter des collaborateurs</li></Link>
            :
            <Link to="/signin" className="main-menu"><li className="job-btn bold">Recruter des collaborateurs</li></Link>
          }
          </>
          :
          <>
          { currentProfilUser === "Entreprise" ?
            <Link to="/signin" className="bold"><JobLi className="main-menu" /></Link>
            :
            <Link to="/job" className="bold"><JobLi className="main-menu" /></Link>
          }
          </>
          }
          <Link to="/formation" className="bold"><CourseLi className="main-menu" /></Link>
          <Link to="/financement" className="bold"><li className="financement-btn main-menu ent-btn-width bold">Les financements</li></Link>
          <Link to="/qui-sommes-nous" className="bold qsm-a"><li className="qsm-btn main-menu bold ent-btn-width">Qui sommes-nous ?</li></Link>
          {/*<Link to="/actualites" className="bold qsm-a"><li className="qsm-btn main-menu bold ent-btn-width yellow">Actualités</li></Link>*/}
        </ul>
        {isShowChooseBtn &&
        <ul className="main-menu mobile">
          {userRole === "entreprise" ?
          <>
          {isLogged ?
          <Link to="/account/jobs" className="main-menu"><li className="job-btn bold">Recruter des collaborateurs</li></Link>
          :
          <Link to="/signin" className="main-menu"><li className="job-btn bold">Recruter des collaborateurs</li></Link>
          }
          </>
          :
          <>
          { currentProfilUser === "Entreprise" ?
            <Link to="/signin" className="bold"><JobLi className="main-menu" /></Link>
            :
            <Link to="/job" className="bold"><JobLi className="main-menu" /></Link>
          }
          </>
          }
          <Link to="/formation" className="bold"><CourseLi className="main-menu" /></Link>
          <Link to="/financement" className="bold"><li className="financement-btn main-menu ent-btn-width bold">Les financements</li></Link>
          <Link to="/qui-sommes-nous" className="bold qsm-a ent-btn-width"><li className="qsm-btn main-menu bold">Qui sommes-nous ?</li></Link>
          {/*<Link to="/actualites" className="bold qsm-a ent-btn-width"><li className="qsm-btn main-menu bold yellow">Actualités</li></Link>*/}
        </ul>
        }
        <div className="menu-burger"
            onClick={() => setIsShowChooseBtn(!isShowChooseBtn)}
          >
            {!isShowChooseBtn &&
              <HiMenu className="burger" />
            }
            {isShowChooseBtn &&
              <HiX className="cross" />
            }
          </div>
      </nav>
      </>
      }
      {/*currentProfilUser === "Autres" && 
      <>
      <div 
      className="user-not-choosed left"
      >
      <div 
        className={`btn-wrapper ${isShowMenu ? "border-change" : ""}`}
        onClick={() => setIsShowMenu(!isShowMenu)}
      >
        <p className="bold" >{currentProfilUser} <AiOutlineCaretDown  className="arrow-down" />
        </p>
      {isShowMenu && 
      <ul className="list-statut">
        <li className="bold" onClick={() => changeCurrentProfilUser("Demandeur d'emploi")}>Demandeur d'emploi</li>
        <li className="bold" onClick={() => changeCurrentProfilUser('Salarié')}>Salarié</li>
        <li className="bold" onClick={() => {changeCurrentProfilUser('Entreprise'); changeSigninStep(2.2)}}>Entreprise</li>
      </ul>
      }
      </div>
    </div>
    <nav className="user-choosed">
      <ul className="main-menu pc">
        <Link to="/job" className="bold"><JobLi className="main-menu" /></Link>
        <Link to="/formation" className="bold"><CourseLi className="main-menu" /></Link>
        <Link to="/financement" className="bold"><li className="financement-btn main-menu bold">Les financements</li></Link>
        <Link to="/qui-sommes-nous" className="bold qsm-a"><li className="qsm-btn main-menu bold">Qui sommes-nous ?</li></Link>
        <Link to="/actualites" className="bold qsm-a"><li className="qsm-btn main-menu bold yellow">Actualités</li></Link>
      </ul>
      {isShowChooseBtn &&
      <ul className="main-menu mobile">
        <Link to="/job" className="bold"><JobLi className="main-menu" /></Link>
        <Link to="/formation" className="bold"><CourseLi className="main-menu" /></Link>
        <Link to="/financement" className="bold"><li className="financement-btn main-menu bold">Les financements</li></Link>
        <Link to="/qui-sommes-nous" className="bold qsm-a"><li className="qsm-btn main-menu bold">Qui sommes-nous ?</li></Link>
        <Link to="/actualites" className="bold qsm-a ent-btn-width"><li className="qsm-btn main-menu bold yellow">Actualités</li></Link>
      </ul>
      }
      <div className="menu-burger"
          onClick={() => setIsShowChooseBtn(!isShowChooseBtn)}
        >
          {!isShowChooseBtn &&
            <HiMenu className="burger" />
          }
          {isShowChooseBtn &&
            <HiX className="cross" />
          }
        </div>
    </nav>
      </>
        */}
      </div>
    </NavUserStyled>
  )
}

NavUser.propTypes = {
  currentProfilUser: PropTypes.string.isRequired,
}

export default NavUser