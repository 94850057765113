import styled from 'styled-components';

const ModalJobFicheStyled = styled.div`
    width: 100vw;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, .4);
    z-index: 100000;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-content-container {
        width: 92%;
        max-width: 1000px;
        min-height: 300px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 5px 15px 10px #00000066;
        position: relative;
        box-sizing: border-box;

        .page-wrapper {
            max-width: 1300px;   
            max-height: 90vh;     
            overflow-y: auto;
            overflow-x: hidden;
            border-radius: 20px;
        }

        .illustration-header {
            width: 100%;
            height: 450px;
            z-index: 10;
            position: relative;
            background-color: #E1EDF7;
        
            &::before {
                content:'';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width:100%;
                background: linear-gradient(180deg, #f7edea 0%, #247558 100%);
                z-index: -10;
            }
        
        
            .opacity-light-section {
                height: 100%;
                top: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                flex-direction: column-reverse;
                justify-content: center;
                padding: 0px 0; 
                height: auto;
        
                .left {
                    width: 96%;
                    margin: 0 auto;
                    height: 400px;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    box-sizing: border-box;

                    h1 {
                    
                        font-size: 35px;
                        text-align: center;
                    }
    
                    p {
                        font-size: 18px;
                        line-height: 22px;
                        text-align: justify;
                        padding: 20px 0;
                        width: 80%;
                        margin: 0 auto;;
                    }
                }
                img {
                        max-width:380px;
                        width: 80%;
                        position: absolute;
                        bottom: -200px;
                    }
                }
    
                .title-wrapper-prog {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    align-items: center;
    
                    h2 {
                        color: #fff;
                        padding: 0 0 5px;
                        font-size: 40px;
                        text-transform: uppercase;
                        text-align: center;
    
                        &.border {
                            padding: 0 0 20px;
                            margin: 0 0 20px;
                            border-bottom: 4px solid #6aaf8b;
                        }
                    }
    
                    h1 {
                        display: block;
                        font-size: 50px;
                        color: #fff;
                        text-transform: uppercase;
                        color: #4d4d4d;
                    }
                }
            }
        }

        .info-job-wrapper {

            margin: 50px 0 50px;

            &:first-child {
                margin: 200px 0 50px;
            }

            &:nth-child(even) {
                
            }

            p, pre {
                color: #4d4d4d;
                text-align: justify;
                padding: 0px 0px;
                width: 80%;
                margin: 0 auto;

                code {
                    width: 80%;
                    content: "\a";
                    white-space: pre;
                }
            }

            h3 {
                width: 80%;
                text-align: left;
                margin: 0 auto;
                color: #4d4d4d;
                font-family: 'gilroySemibold' !important;
            }

            pre {
                padding-bottom: 30px;
            }

            p {
                color: #4d4d4d;
                text-align: justify;
                padding: 15px 0px;
                width: 80%;
                margin: 0 auto;
            }

            ul {
                color: #4d4d4d;
                text-align: justify;
                padding: 0px 0px 30px;
                width: 80%;
                margin: 0 auto;

                li {
                    list-style: inside; 

                    p {
                        margin: 0;
                        padding: 0;
                        display: inline-block;
                    }
                }
            }

            h2 {
                font-size:30px;
                margin: 0px;
                width: auto;
                display: inline-block;
                position: relative;
                color: #4d4d4d;
                padding-bottom: 10px;
                text-transform: uppercase;
                text-align: center;
                width: 100%;
    
                .separator-blue {
                    background-color: #6399ce;
                    height: 6px;
                    width: 100px;
                    right: calc(50% - 50px);
                    margin: 10px 0 0 auto;
                    position: absolute;
                    bottom: 0;
                }
            }

        }
    
        .section-job-def {
            display: flex;
            flex-direction: column;
            padding: 50px 0;
            background-color: #E1EDF7;
            padding-top: 150px;
    
            .title-wrapper {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
    
                img {
                    width: 100px;
                }
    
                h2 {
                    font-size:30px;
                    margin: 20px;
                    width: auto;
                    display: inline;
                    position: relative;
                    color: #4d4d4d;
                    padding-bottom: 10px;
                    text-transform: uppercase;
        
                    .separator-blue {
                        background-color: #6399ce;
                        height: 6px;
                        width: 100px;
                        right: calc(50% - 40px);
                        margin: 10px 0 0 auto;
                        position: absolute;
                        bottom: 0;
                    }
                }
    
            }
    
            .def-wrapper {
                width: 100%;
                margin: 50px auto 0;
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                justify-content: space-between;
                align-items: stretch;
    
                .txt-left {
                    color: #656464;
                    font-size: 16px;
                    line-height: 22px;
                    width: 100%;
                    text-align: justify;
                    padding: 0 20px;
                    box-sizing: border-box;
                }
    
                .salary-wrapper {
                    color: #fff;
                    background-color: #649ACE;
                    width: 96%;
                    max-width: 465px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                    box-shadow: 0px 8px 8px #00000029;
                    border-radius: 30px;
                    position: relative;
                    padding-bottom: 40px;
                    padding-right: 20px;
                    text-align: center;
                    margin: 30px auto;
                    box-sizing: border-box;
    
                    .disclaimer {
                        position: absolute;
                        bottom: 10px;
                        right: 50px;
                        color: #4d4d4d;
    
                        a {
                            color: #4d4d4d;
                        }
                    }
    
                    img { 
                        width: 120px;
                    }
    
                    .flex-box-wrapper {
                        display: flex;
                        align-items: stretch;
                        flex-direction: column;
    
                        h3 {
                            font-size: 26px;
                            text-transform: uppercase;
                            padding-bottom: 10px;
                            border-bottom: 2px solid #4d4d4d;
                            margin-bottom: 10px;
                        }
    
                        p {
                            font-size: 33px;
    
                            span {
                                color: #4d4d4d;
                            }
                        }
                    }
                }
            }
        }
    
        .section-formation-list {
            text-align: center;
            padding: 50px 0px 50px 0px;
    
            h2 {
                font-size: 30px;
                display: inline;
                margin: 20px 0;;
                position: relative;
                color: #4d4d4d;
                padding-bottom: 10px;
                text-transform: uppercase;
                width: 100%;
                max-width: 465px;
                box-sizing: border-box;
                display: block;
                margin: 0 auto;
    
                .separator-blue {
                    background-color: #6399ce;
                    height: 6px;
                    width: 100px;
                    margin: 10px 0 0 auto;
                    position: absolute;
                    width: 100px;
                    right: calc(50% - 50px);
                    bottom: 0;
                }
            }
    
            .card-wrapper {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
                max-width: 1200px;
                margin: 50px auto;
            }
    
            .btn-blue {
                width: 300px;
                color: #fff;
                background-color: #6399ce;
                text-decoration: none;
                padding: 10px;
                text-align: center;
                font-size: 18px;
                transition: background-color .5s;
                margin: 50px auto 0;
                border-radius: 10px;
                cursor: pointer;
                border: 0;
                font-size: 20px;
        
                &:hover {
                    background-color: #202c3c;
                }
            }
        }
    
        .section-mission-list {
            padding: 50px 0;
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;
            background-color: #E1EDF7;
    
            h2 {
                font-size: 30px;
                display: inline;
                margin: 20px;
                width: 100%;
                position: relative;
                color: #4d4d4d;
                padding-bottom: 10px;
                text-transform: uppercase;
                box-sizing: border-box;
                text-align: center;
    
                .separator-blue {
                    background-color: #6399ce;
                    height: 6px;
                    width: 100px;
                    right: calc(50% - 50px);
                    margin: 10px 0 0 auto;
                    position: absolute;
                    bottom: 0;
                }
            }
    
            .list-wrapper {
                display: flex;
                flex-wrap: wrap;
                max-width: 465px;
                align-items: stretch;
                justify-content: space-between;
                margin-top: 0px;
                padding-left: 20px;
                box-sizing: border-box;
                text-align: left;
    
                .separator-blue {
                    width: 3px;
                    background-color: #325076;
                    margin-top: 40px;
                }

                p { color: #656464 }
    
                .left, right {
                    width: 100%;
                    margin: 0 auto;
    
                    h3 {
                        color: #325076;
                        font-size: 24px;
                        text-transform: uppercase;
                        padding-bottom: 30px;
                        text-align: center;
                    }
    
                    ul {
                        list-style: disc;
                    }
    
                    ul li {
                        color: #656464;
                        font-size: 16px;
                        line-height: 30px;
                    }
                }
            }
        }
    
        .section-evolution-job {
            padding: 50px 0;
            display: flex;
            flex-direction: column;
    
            h2 {
                font-size:30px;
                display: inline;
                margin: 20px;
                position: relative;
                color: #4d4d4d;
                padding-bottom: 10px;
                text-transform: uppercase;
                width: 100%;
                box-sizing: border-box;
                text-align: center;
    
                .separator-blue {
                    background-color: #6399ce;
                    height: 6px;
                    width: 100px;
                    right: calc(50% - 50px);
                    margin: 10px 0 0 auto;
                    position: absolute;
                    bottom: 0;
                }
            }
    
            .evo-list {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: flex-start;
                margin-top: 0;
                text-align: left;
                width: 465px;
                margin: 0 auto;

                p { color: #656464 }
    
                img { 
                    width: 150px;
                }
    
                ul {
                    margin-left: 20px;
                    color :#656464;
                    font-size: 16px;
                    line-height: 30px;
                    list-style: disc;
                }
            }
        }
    
        .section-perspective-graph {
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 50px 0;
            background-color: #F4F4F4;
    
            h2 {
                font-size: 30px;
                display: inline;
                margin: 20px;
                width: 100%;
                box-sizing: border-box;
                text-align: center;
                position: relative;
                color: #4d4d4d;
                padding-bottom: 10px;
                text-transform: uppercase;
    
                .separator-blue {
                    background-color: #6399ce;
                    height: 6px;
                    width: 100px;
                    right: calc(50% - 50px);
                    margin: 10px 0 0 auto;
                    position: absolute;
                    bottom: 0;
                }
            }
    
            .txt-grey {
                color: #656464;
                font-size: 18px;
                line-height: 22px;
                text-align: justify;
                width: 96%;
                max-width: 1200px;
                margin-top: 50px;
            }
    
            .txt-blue {
                color: #757991;
                font-size: 18px;
                margin-top: 20px;
    
                span {
                    color: #639ACD;
                }
            }

            .recharts-wrapper {
                width: 96% !important;
                text-align: center;

                svg {
                    width: 100% !important;
                    max-width: 500px !important;
                }
            }
    
            .graph { 
                margin: 50px 10px 0px 10px;
                padding-right: 5px;
            }
        }
    
        .section-btn-bottom {
            padding: 100px 0;
            display: flex;
            justify-content: center;
            flex-direction: column;
    
            .btn-left, .btn-right {
                background-color: #639ACD;
                color: #fff;
                border-radius: 12px;
                padding: 20px 10px 20px 50px;
                position: relative;
                font-size: 24px;
                width: 300px;
                box-sizing: border-box;
                cursor: pointer;
                
                &:hover {
                    background-color: #325076;
                }
    
                img {
                    position: absolute;
                    width: 80px;
                    left: -35px;
                    top: -8px;
                }
            }
    
            .btn-right {
                margin: 0 auto;
                padding: 20px 10px 20px 90px;
            }
        }

        .icon-wrapper {
            position: absolute;
            top: -35px;
            right: -35px;
            width: 40px;
            height: 40px;
            background-color: #649ACE;
            border-radius: 17.5px;
            overflow: hidden;
            display:flex;
            align-items: center;
            justify-content: center;
            z-index: 10;

            .close-icon {
                color: #fff;
                font-size: 30px;
                cursor: pointer;
                border-radius: 20px;
            }
        }
    }

    @media only screen and (max-width: 1079px) {
        .modal-content-container {
            .icon-wrapper {
                position: absolute;
                top: -20px;
                right: -8px;
            }
        }
    }

    @media only screen and (min-width: 0px) and (max-width: 759px) {
        .modal-content-container {
            width: 96%;

            .choices-wrapper {
                width: 100%;
            }
            .section-job-def { 
                .def-wrapper {
                    .salary-wrapper {
                        justify-content: center;
                    }
                }
            }

            .icon-wrapper {
                position: absolute;
                top: -20px;
                right: -8px;
            }
        }
    }
`;

export default ModalJobFicheStyled;