import styled from 'styled-components';

const JobOnlineCardStyled = styled.article`
    width: 96%;
    
    border-radius 15px;
    padding: 15px 15px 15px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    box-shadow: 0px 1px 10px #00000029;
    margin-bottom: 30px;
    position: relative;

    .modalValidation {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .7);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 10000;

        p {
            color: #fff;
            font-size: 22px;
        }

        .decision-wrapper {
            padding-top: 20px;
            display: flex;
            flex-wrap: wrap;

            button {
                border: 0;
                border-radius: 10px;
                width: 100px;
                padding: 10px 0;
                font-size: 22px;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                cursor: pointer;
            }

            .btn-yes {
                background-color: #6aaf8b;
                color: #fff;

                &:hover {
                    opacity: .8;
                }
            }

            .btn-no {
                background-color: #dc4a46;
                color: #fff;
                margin-left: 20px;

                &:hover {
                    background-color: rgba(220,74,70,.8);
                }
            }
        }

        .modal {
            width: 100vw;
            min-height: 100vh;
            background-color: rgba(0, 0, 0, .4);
            z-index: 10000;
            position: fixed;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
        
            .modal-content-container {
                width: 96%;
                max-width: 620px;
                min-height: 300px;
                background-color: #fff;
                border-radius: 20px;
                box-shadow: 5px 15px 10px #00000066;
                position: relative;

                .content-condition {
                    padding: 40px 20px 40px 20px;
                    max-height: 80vh;
                    overflow-y: auto;
                    overflow-x: hidden;
                    box-sizing: border-box;
                }

                h3 {
                    color: #649ACE;
                    font-size: 30px;
                    text-align: center;
                    margin: 0 auto;
                    padding: 20px 0 0 0;
                }

                .account-form-comp {
                    width: 96%;
                    max-width: 500px;
                    display: flex;
                    flex-direction: column;
                    font-size: 18px;
                    color: #707070;
                    padding: 30px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
            
                    label {
                        width: 100%;
                    }

                    textarea {
                        height: 150px;
                    }

                    .drow-wrapper {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        label {
                            width: 48%;
                        }
                    }
            
                    input, textarea {
                        width: 100%;
                        border: 0;
                        border-radius: 10px;
                        background-color: #F2F2F2;
                        padding: 20px;
                        margin: 5px 0px 10px;
                        box-sizing: border-box;
                        font-size: 10px;
                    }

                    .small-placeholder {
                        font-size: 10px;
                    }

                    .select-box {
                        select {
                            width: 100%;
                            margin: 5px 0 20px;
                            border: 0;
                            border-radius: 10px;
                            background-color: #F2F2F2;
                            padding: 20px;
                        }
                    }

                    .btn-test-ocean {
                        padding: 20px 20px;
                        box-shadow: 0px 1px 10px #00000029;
                        margin-bottom: 20px;
                        border-radius: 10px;
                    }
            
                    .lightblue-btn {
                        color: #fff;
                        background-color: #649ACE;
                        border: 2px solid #87B7E5;
                        padding: 15px;
                        font-size: 18px;
                        box-sizing: border-box;
                        cursor: pointer;
            
                        &:hover {
                            color: #649ACE;
                            background-color: #fff;
                        }
                    }
                }
        
                .icon-wrapper {
                    position: absolute;
                    top: -35px;
                    right: -35px;
                    width: 40px;
                    height: 40px;
                    background-color: #649ACE;
                    border-radius: 17.5px;
                    overflow: hidden;
                    display:flex;
                    align-items: center;
                    justify-content: center;
        
                    .close-icon {
                        color: #fff;
                        font-size: 30px;
                        cursor: pointer;
                        border-radius: 20px;
                    }
                }
            }
        }
    }

    .delete-card-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 20px;
        color: #dc4a46;
        cursor: pointer;
    }

    img {
        width: 80px;
    }

    .text-wrapper {
        width: calc(98% - 80px);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .top-wrapper {
            width: 30%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            h3 {
                color: #4d4d4d;
                font-size: 20px;
                display: inline-block;
                border-bottom: 2px solid #b4cfe8;
                width: 100%;
                padding-bottom: 10px;
                text-align: center;
            }
            
            p {
                color: #649ACE;
                padding: 10px 0 5px 0;;
            }
            .btn-blue {
                width: 125px;
                background-color: #4d4d4d;
                border-radius: 12px;
                padding: 5px 20px;
                border: 0;
                color: #fff;
                margin-right: 20px;
            }
        }

        .middle-wrapper {
            width: 30%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            .infos-left {
                color: #4d4d4d;
                display: flex;
                flex-direction: column;
                text-align: left;
                padding-bottom: 10px;

                .salary, .city {
                    display: inline-block;
                }

                .salary {
                }
            }

            .no-offer {
                text-align: center;
            }

            .btn-light-blue {
                width: 125px;
                background-color: #649ACE;
                border-radius: 12px;
                padding: 5px 20px;
                border: 0;
                color: #fff;
                margin: 10px 0 0 0;
                cursor: pointer;
                transition: all .3s ease;

                &:hover {
                    opacity: .7;
                }

                a {
                    color: #fff;
                }
            }
        }

        .bottom-wrapper {

            width: 30%;

            .top {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                .candidats {
                    color: #4d4d4d;
                    padding-bottom: 10px;
                }

                svg {
                    color: #649ACE;
                    font-size: 30px;
                }
            }

            .btn-light-blue {
                width: 125px;
                background-color: #649ACE;
                border-radius: 12px;
                padding: 5px 20px;
                border: 0;
                color: #fff;
                margin: 10px 0 0 0;
                cursor: pointer;
                transition: all .3s ease;

                &:hover {
                    opacity: .7;
                }

                a {
                    color: #fff;
                }
            }
            .img-wrapper {
                display: flex;
                flex-wrap: wrap;

                img {
                    width: 40px;
                }
            }
        }
    }

    @media only screen and (max-width: 1079px) {
        .left-img {
            width: 75px;
        }

        .text-wrapper {
            width: calc(98% - 80px);

            h3 {
                max-width: 60%;
            }
        }
    }
    
    @media only screen and (min-width: 0px) and (max-width: 759px) {
        padding: 5px;
        .left-img {
            display: none;
        }

        .text-wrapper {
            width: calc(100%) !important;
            flex-wrap: wrap;

            .top-wrapper {
                width: 100%;
            }

            .top-wrapper, .middle-wrapper {
                flex-direction: column;

                h3 {
                    width: 100%;
                    max-width: 100%;
                    font-size: 16px;
                }

                .salary {
                    font-size: 14px;
                    text-align: center;
                }

                time {
                    font-size: 14px;
                }
                button {
                    max-width: 100%;
                    box-sizing: border-box;
                    padding: 5px !important;
                }
            }

            .middle-wrapper {
                width: 50%;
            }
            .bottom-wrapper {
                width: 50%;
                .candidats {
                    font-size: 14px;
                }

                button {
                    max-width: 100%;
                    box-sizing: border-box;
                    padding: 5px !important;
                }
            }

        }
    }
`;

export default JobOnlineCardStyled;