import styled from 'styled-components';
import maintenance from '../../assets/img/illustration/maintenance.jpg';

const MaintenanceStyled = styled.section`
   display: flex;
   flex-direction column;
   align-items: center;
   justify-content: space-between;
   min-height: 100vh;
   background-image: url(${maintenance});
   background-size: cover;

   .vsc-tools {
    font-size: 60px;
    color: #6399ce;
    margin: 20px 0;
   }

   h2 {
       font-size: 29px;
       text-align: center;
       margin: 0 0 20px;
   }

   img {
       width: 150px;
   }

   .btn-orange {
        background-color: #d47558 !important;
        color: #fff !important;
        border: 0 !important;
        border-radius: 10px !important;
        padding: 15px;
        cursor: pointer;
        transition: all .3s ease;

        &:hover {
            opacity: .7;
        }
    }

    div {
        text-align: center;

        p {
            margin-bottom: 20px;
        }
    }

    .contact-m {
        svg {
            font-size: 40px;
            margin: 0 10px;
            cursor: pointer;
            transition: all .3s ease;

            &:hover {
                opacity: .7;
            }
        }
    }
`;

export default MaintenanceStyled;