/**** Import des modules ****/
import React, {useEffect} from 'react'
import { AiOutlineDown } from "react-icons/ai"
import { useHistory, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'


/**** Import des containers et components ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'

/**** Import d'images ****/
import docgrey from '../../assets/img/illustration/docgrey.png'
import graphpink from '../../assets/img/illustration/graphpink.png'
import poucegreen from '../../assets/img/illustration/poucegreen.png'
import programme from '../../assets/img/illustration/programme.png'
import loupegreen from '../../assets/img/illustration/loupegreen.png'
import trophe from '../../assets/img/illustration/trophe.png'
import timeyellow from '../../assets/img/illustration/timeyellow.png'
import arrowyellowgreen from '../../assets/img/illustration/arrowyellowgreen.png'
import logoformateur from '../../assets/img/logo/logo-qualiopi.png'
import logo from '../../assets/img/illustration/EMPLOYEUR-02.png'

/**** Import des css ****/
import CourseProgStyled from './CourseProgStyled'

const CourseProg = ({openModal, formations, userRole }) => {

  const {id} = useParams()
  const currentFormationId = Number(id)
  

  let currentFormation = () => {
    if (formations !== undefined) {
      let currentFormation = formations.find(x => x.id === currentFormationId)
      return currentFormation;
    }
  }

  let euroFormat = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
  });
  
  return (
    <CourseProgStyled>
      <Header bcgColor={'#d47558'}/>
      {formations !== undefined &&
      <>
      <section className="illustration-header">
        <div className="opacity-light-section">
        <img src={logo} alt='logo wefor'/>
          <div className="left">
          <div className="title-wrapper-prog">
                <h2 className="bold">Formation {currentFormation().attributes.niveau}</h2>
                <h3 className="bold top">{currentFormation().attributes.secteurs.data[0].attributes.nom}</h3>
                <h1 className="bold">{currentFormation().attributes.nom}</h1>
              </div>
          </div>
        </div>
      </section>
      <main>
        <section className="section-programme-formation">
          <h2>Prix de la formation<span className="separator-blue"></span></h2>
          <div className="tarif-wrapper">
            <div className="tarif-formation bold">{euroFormat.format(currentFormation().attributes.prix)}</div>
            {userRole === "entreprise" ? 
            <button 
            className="btn-lightblue"
              onClick={() => openModal("inscription-collab")}
            >Inscrire un collaborateur</button>
            :
            <button 
              className="btn-lightblue"
              onClick={() => openModal('formation')}
            >S'inscrire</button>
            }
          </div>
          <h2 className="no-margin">Programme de la formation<span className="separator-blue"></span></h2>
          <article className="article-programme-description">
            <img src={poucegreen} className="illustration-article-left" alt="icon pouce en l'air" />
            <div className="article-list-wrapper">
              <h3 className="bold">Prérequis</h3>
              <ReactMarkdown>{currentFormation().attributes.prerequis}</ReactMarkdown>
            </div>
          </article>
          <article className="article-programme-description">
            <img src={graphpink} className="illustration-article-left" alt="graphique rose" />
            <div className="article-list-wrapper">
              <h3 className="bold">Compétences acquises</h3>
              <ReactMarkdown>{currentFormation().attributes.competences_acquises}</ReactMarkdown>
            </div>
          </article>
          <article className="article-programme-description">
            <img src={docgrey} className="illustration-article-left" alt="icon avec document gris" />
            <div className="article-list-wrapper">
              <h3 className="bold">Objectifs de la formation</h3>
              <ReactMarkdown>{currentFormation().attributes.objectifs}</ReactMarkdown>
            </div>
          </article>
        </section>
        <section className="section-module-formation">
          <div className="design-green-top">
            <div className="separator-green"></div>
            {/*<img src={loupegreen} alt="loupe avec fond vert" />*/}
            <div className="separator-green"></div>
          </div>
          <article className="module-formation-list">
            <div className="left-prog">
              <h3 className="bold">Contenu du programme</h3>
              {currentFormation().attributes.modules_formations.data.map((module, index) => (
              <>
                <h4 className="bold">Module {index + 1} : {module.attributes.nom}</h4>
                <ReactMarkdown>{module.attributes.description}</ReactMarkdown>
              </>
              ))}
            </div>
          </article>
          <AiOutlineDown className="arrow-bottom"/>
          <div className="design-blue-bottom">
            <div className="separator-blue"></div>
            <img src={trophe} alt="trophé avec fond bleu" />
            <div className="separator-blue"></div>
          </div>
        </section>
        <section className="section-modalité-formation">
            <article className="time-prog-course">
              <img src={timeyellow} alt="ampoule ronde jaune" />
              <div className="time-wrapper">
                <h3 className="bold">Durée de la formation :</h3>
                <p className="date bold">{currentFormation().attributes.duree}</p>
                <h3 className="bold">Modalités et délais d'accès :</h3>
                <p className="txt">{currentFormation().attributes.modalites_delais}</p>
              </div>
            </article>
            <article className="modalite-prog-course">
              <img src={arrowyellowgreen} alt="flêche jaune et vert" />
              <div className="evaluation-wrapper">
                <h3 className="bold">Modalités d'évaluation</h3>
                <ul className="list-eval">
                  <li className="bold">{currentFormation().attributes.modalites_evaluation}</li>
                </ul>
              </div>
            </article>
            <article className="formateur-prog-course">
              <h3 className="bold">Formateur RCDPRO / Organisme formation QUALIOPI</h3>
              <div className="formateur-wrapper">
                <div className="img-wrapper">
                  <img src={logoformateur} alt="logo du formateur" />
                </div>
                <div className="txt-formateur">
                  <h4 className="bold">QUALIOPI</h4>
                  <p>Seule la certification Qualiopi permet d’être référencé auprès des financeurs publics et paritaires : OPCO, Pôle emploi, Caisse des Dépôts, Régions et Collectivités. Qualiopi sera l’unique moyen d’accéder au fichier national des organismes référencés par les financeurs publics et paritaires en lieu et place du système de référencement mis en place avec la réforme de 2014 composé notamment du Datadock.</p>
                </div>
              </div>
            </article>
        </section>
      </main>
      </>
      }
      <Footer />
    </CourseProgStyled>
  )
}

CourseProg.propTypes = {
}

export default CourseProg