/**** Import des modules ****/
import React, {useEffect} from 'react'
import { Link } from "react-router-dom"
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import AOS from 'aos';
import "aos/dist/aos.css"

/**** Import des components et containers ****/
import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import BannerAccount from '../../components/Header/BannerAccount'

/**** import des images ****/
import logo from '../../assets/img/illustration/header-homme.png'

/**** Import des css ****/
import LoginLostStyled from './LoginLostStyled'

const LoginLost = ({changeValue, isLogged, fetchLostPassword}) => {
  useEffect(()=> {
    AOS.init({ duration: 1000 })
  }, [])
  return (
    <LoginLostStyled>
      <Header bcgColor={'rgba(100,154,206,1)'}/>
      {isLogged && 
      <Navigate to='/account/home' replace={true} />
      }
      {!isLogged &&
      <>
      <section className="top-illustration">
        <div className="opacity-light-section">
        <img src={logo} alt='logo wefor'/>
          <div className="left">
            <h1 className="bold">Mot de passe oublié</h1>
          </div>
        </div>
      </section>
      <main className="page-connexion">
        <h2 className="bold">Merci de renseigner votre adresse email<span className="separator-blue"></span></h2>
        <form 
          className="account-form-comp"
          onSubmit={(event) => {
            event.preventDefault();
            fetchLostPassword();
          }}
        >
          <label>
            Adresse Email
            <input 
              type="email" 
              name="emailLost" 
              placeholder=""
              onChange={(event) => {
                changeValue(event.target.value, event.target.name);
              }}
            />
          </label>
          <label>
            <input 
              type="submit" 
              value="Envoyer" 
              className="lightblue-btn"
            />
          </label>
        </form>
      </main>
      </>
      }
      <Footer />
    </LoginLostStyled>
  )
}

LoginLost.propTypes = {

}

export default LoginLost