/**** Import de modules ****/
import React, { Component, useEffect, useState } from 'react'
import { Route, useLocation, Routes, Link } from 'react-router-dom'
import useScrollPosition from '@react-hook/window-scroll'
import PropTypes from 'prop-types'
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from "react-helmet";

/**** Import des components ou containers ****/

import Home from '../../containers/Homebis'
import NotFound from '../../pages/NotFound'
import ScrollToTop from '../ScrollToTop/ScrollToTop'
import Contact from '../../containers/Contact'
import Financement from '../../containers/Financement'
import Formation from '../../containers/Formation'
import CourseSecteur from '../../containers/CourseSecteur'
import CourseProg from '../../containers/CourseProg'
import Job from '../../containers/Job'
import Wefor from '../../containers/Wefor'
import AccountHome from '../../containers/AccountHome'
import AccountFacture from '../../containers/AccountFacture'
import AccountFinancement from '../../containers/AccountFinancement'
import AccountDoc from '../../containers/AccountDoc'
import AccountCourse from '../../containers/AccountCourse'
import AccountProfil from '../../containers/AccountProfil'
import AccountJobs from '../../containers/AccountJobs'
import AccountCandidats from '../../containers/AccountCandidats'
import AccountOcean from '../../containers/AccountOcean'
import AccountRecrutement from '../../containers/AccountRecrutement'
import Login from '../../containers/Login'
import LoginValid from '../../containers/LoginValid'
import FormCourse from '../../containers/FormCourse'
import LoginLost from '../../containers/LoginLost'
import NewPassword from '../../containers/NewPassword'
import SignIn from '../../containers/SignIn'
import SignInEntreprise from '../../containers/SignInEntreprise';
import JobFiche from '../../containers/JobFiche'
import Legal from '../../containers/Legal'
import CGV from '../../pages/CGV'
import NewsFlux from '../../containers/NewsFlux'
import ModalInscription from '../../containers/ModalInscription'
import ModalInscLoader from '../../containers/ModalMessage'
import ModalPostulerTuto from '../../containers/ModalPostulerTuto'
import ModalMessage from '../../containers/ModalInscription'
import Maintenance from '../../components/Maintenance'
import Map from '../Map'
import Arrow from '../ScrollToTop/Arrow'
import { ParallaxProvider } from 'react-scroll-parallax'
import CookieConsent, { Cookies } from "react-cookie-consent"
import PDFDocument from '../PDFDocument'
import FullPageLoader from '../../containers/FullPageLoader'
import ModalCreateLMSSession from '../../containers/ModalCreateLMSSession';

/**** Import des css ****/
import AppStyled from './AppStyled'
import { setFullPageLoaderRecrutementObj } from '../../actions/login';

const App = ({isLogged, 
  fullPageLoader, 
  fetchGetCoursesLms, 
  fetchGetOneProgram, 
  viewModal, 
  setWindowTop, 
  fetchSecteurs, 
  fetchFormations, 
  fetchJobs, 
  fetchDomaines, 
  fetchJobFiches, 
  isShowMenu, 
  setIsShowMenu, 
  isShowAccountFormMenu, 
  setIsShowAccountFormMenu, 
  changeActivCourseId, 
  changeActivJobId, 
  courseList, 
  jobsList, 
  modalInscLoaderOpen, 
  changeSigninStep, 
  modalMessageIsOpen, 
  fullPageLoaderConnexion, 
  fullPageLoaderJobInsc, 
  fullPageLoaderContact, 
  fullPageLoaderOcean,
  fullPageLoaderPutAccount,
  fullPageLoaderJobOfferAction,
  fullPageLoaderFacturation,
  fullPageLoaderRecrutementObj,
  fullPageLoaderNews,
  loadConnexion,
  isModalPostulerTutoOpen,
  isHeLogged,
  secteursData,
  domainesData,
  jobsFiches,
  maintenance
}) => {

  const [testConnect, setTestConnect] = useState(true)
  const [input, setInput] = useState(false)

  let windowTop = useScrollPosition()
  let location = useLocation().pathname
  useEffect(() => {
    if (secteursData.length === 0) {
      fetchSecteurs()
    }
  }, []);
  useEffect(() => {
    if (domainesData.length === 0) {
      fetchDomaines()
    }
  }, []);
  useEffect(() => {
    if (jobsFiches.length === 0) {
      fetchJobFiches()
    }
  }, []);
  useEffect(fetchGetOneProgram, []);
  useEffect(fetchGetCoursesLms, []);
  useEffect(() => {
    if (location !== '/signin') {
      changeSigninStep(1)
    }
  }, [location]);
  useEffect(() => {
    
    if (windowTop === 0) {
      setWindowTop(true)
    } else {
      setWindowTop(false)
    }
  }, [windowTop]);

 

  return (
    <AppStyled 
      onClick={() => { 
        if(isShowMenu === true) {
          setIsShowMenu(false)
        }
        /*if(isShowAccountFormMenu === true) {
          setIsShowAccountFormMenu(false)
      }*/}}
    >
      {testConnect ?
      <>        
      <form 
        className="account-form-comp"
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        <input type='password' name='pwd' onChange={(event) => setInput(event.target.value)} />
        <input type='submit' name='submit' onClick={() => {
          if(input === 'Motdepasse92.'){setTestConnect(false)}
          console.log(input)
        }} />
        </form>
      </>
      :
      <>
       <Helmet>
        <title>WEFOR</title>
        <meta name='description' content="Wefor vous propose une multitude de services depuis la formation de vos collaborateurs jusqu'au recrutement de nouveaux talents."/>
        <meta name='keywords' content='Wefor, formation, cabinet de recrutement'/>
      </Helmet>
      <ScrollToTop />
      <Arrow /> 
      {fullPageLoader &&
      <FullPageLoader />
      }
      {fullPageLoaderConnexion &&
      <FullPageLoader />
      }
      {fullPageLoaderNews &&
      <FullPageLoader />
      }
      {fullPageLoaderJobInsc &&
      <FullPageLoader />
      }
      {fullPageLoaderContact &&
      <FullPageLoader />
      }
      {fullPageLoaderOcean &&
      <FullPageLoader />
      }
      {fullPageLoaderPutAccount &&
      <FullPageLoader />
      }
      {fullPageLoaderJobOfferAction &&
      <FullPageLoader />
      }
      {fullPageLoaderFacturation &&
      <FullPageLoader />
      }
      {fullPageLoaderRecrutementObj &&
      <FullPageLoader />
      }
      {loadConnexion &&
      <FullPageLoader />
      }
      {viewModal &&
        <ModalInscription />
      }
      {modalInscLoaderOpen &&
        <ModalInscLoader />
      }
      {isHeLogged && isModalPostulerTutoOpen &&
        <ModalPostulerTuto />
      }

      {/* TEST COMPONENTS */}

      {/* TEST COMPONENTS */}
      
        {maintenance ?
        <Maintenance />
        :
          <>
            <ParallaxProvider>
            <Routes>
              <Route path='*' element={<NotFound />} />
              <Route index path="/" element={<Home/>} />
              <Route path="/contact" element={<Contact/>} />
              <Route path="/financement" element={<Financement/>} />
              <Route path="/formation" element={<Formation/>} />
              <Route path="/qui-sommes-nous" element={<Wefor/>} />
              <Route path="/domaine/:name" element={<CourseSecteur/>} />
              <Route path="/formation/:id" element={<CourseProg/>} />
              <Route path="/job" element={<Job/>} />
              <Route path="/actualites" element={<NewsFlux/>} />
              <Route path="/job/:id" element={<JobFiche />} />
              <Route path="/account/home" element={<AccountHome />} />
              <Route path="/account/profil" element={<AccountProfil />} />
              <Route path="/account/course" element={<AccountCourse />} />
              <Route path="/account/jobs" element={<AccountJobs />} />
              <Route path="/account/mes-financements" element={<AccountFacture />} />
              <Route path="/account/financements" element={<AccountFinancement />} />
              <Route path="/account/documents" element={<AccountDoc />} />
              <Route path="/account/candidats" element={<AccountCandidats />} />
              <Route path="/account/ocean" element={<AccountOcean />} />
              <Route path="/account/recrutement" element={<AccountRecrutement />} />
              <Route path="/login" element={<Login />} />
              <Route path="/login-validation" element={<LoginValid />} />
              <Route path="/formulaire-formation" element={<FormCourse/>} />
              <Route path="/mot-de-passe-oublie" element={<LoginLost />} />
              <Route path="/nouveau-mot-de-passe" element={<NewPassword />} />
              <Route path="/SignIn" element={<SignIn />} />
              <Route path="/sign-in-entreprise" element={<SignInEntreprise />} />
              <Route path="/legal" element={<Legal />} />
              <Route path="/conditions-generales-de-vente" element={<CGV />} />
            </Routes>
            </ParallaxProvider>
            <ToastContainer className="toast-container" />
            <CookieConsent
              location="bottom"
              buttonText="Continuer"
              cookieName="myAwesomeCookieName2"
              style={{ background: "#202c3c" }}
              buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
              expires={150}
              buttonClasses="btn-orange"
            >
              Nous utilisons des cookies sur notre site web pour vous offrir la meilleure expérience possible. Si vous cliquez sur continuer, nous partons du principe que vous acceptez notre{" "}<Link to='legal'>Politique de confidentialité</Link>
            </CookieConsent>
          </>
        }
      </>
      }
     

    </AppStyled>
  )
}

App.propTypes = {
  fetchSecteurs: PropTypes.func.isRequired
}

export default App