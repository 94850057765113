import styled from 'styled-components';

const AccountCandidatsStyled = styled.div`
    .connexion {
        margin-top: 150px;
    }
    
    .page-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;
        max-width: 1300px;
        margin: 175px auto 0;

        .right-wrapper {
            width: calc(100% - 250px);
            display: flex;
            align-items: center;
            flex-direction: column;

            h2 {
                font-size: 30px;
                display: inline-block;
                margin: 0 auto 10px;
                color: #202D3C;
            }

            .layout-btn {
                font-size: 25px;
                margin-right: 10px;
                color: #6AAF8B;
                cursor: pointer;
    
                &.active-layout {
                    color: #40775a;
                }
            }

            .pagination-pc {
                width: 100%;
                margin-top: 30px;

                .MuiPagination-ul {
                    justify-content: center;
                }
            }
            
            .filters {
                width: 100%;
                text-align: center;
                padding: 20px 20px 50px 20px;
                cursor: pointer;

                .skeleton-candidat {
                    width: 96%;
                    max-width: 696px;
                    height: 104px;
                    margin: -23px auto 0;
                }

                .filters-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    margin: 0 auto;
                    text-align: center;

                    p {
                        font-size: 20px;
                        padding-bottom: 20px;
                    }

                    .offers-wrapper {
                        width: 100%;
                        background-color: #fff;
                        color: #6AAF8B;
                        box-shadow: 0px 1px 10px #00000029;
                        border-radius: 10px;
                        position: relative;
                        transition: height 1s ease;

                        .arrow-wrapper {
                            position: absolute;
                            top: 0;
                            right: 0;
                            height: 60px;
                            border-top-right-radius: 10px;
                            border-bottom-right-radius: ${props => (props.showOfferMenu ? "0px" : "10px")};
                            
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .arrow-down {
                                font-size: 50px;
                            }
                        }

                        .choosed-offer {
                            padding: 20px 50px 20px 20px;
                            font-size: 18px;
                            z-index: 1001;
                            text-align: left;
                            background-color: #fff;
                            border-radius: 10px;
                            border-bottom-right-radius: ${props => (props.showOfferMenu ? "0px" : "10px")};
                            border-bottom-left-radius: ${props => (props.showOfferMenu ? "0px" : "10px")};
                        }

                        ul {
                            display: ${props => (props.showOfferMenu ? "block" : "none")};
                            position: absolute;
                            top: 60px;
                            left: 0;
                            width: 100%;
                            transition: height 1s ease;
                            box-shadow: 0px 1px 10px #00000029;
                            z-index: 100;
                            font-size: 16px;

                            .cache {
                                width: 100%;
                                position: absolute;
                                height: 10px;
                                background-color: #fff;
                                top: -10px;
                                left: 0;
                            }

                            li {
                                background-color: #fff;
                                padding: 10px 0;
                                border-top: 1px solid #CAD7CF;
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: space-between;
                                align-items: center;

                                div {
                                    box-sizing: border-box;
                                    text-align: left;

                                    &:nth-child(1) {
                                        width: 40%;
                                        padding-left: 20px;
                                    }
                                    &:nth-child(2) {
                                        width: 220px;
                                        color: #4d4d4d;
                                        font-size: 16px;
                                    }
                                    &:nth-child(3) {
                                        width: 150px;
                                        color: #4d4d4d;
                                        font-size: 18px;

                                        .number {
                                            color: #6AAF8B;
                                            font-size: 18px;
                                        }
                                    }
                                }

                                &:hover {
                                    background-color: #e1efe7;
                                    cursor: pointer;
                                }

                                &:last-child {
                                    border-bottom-left-radius: 10px;
                                    border-bottom-right-radius: 10px;
                                }
                            }
                        }
                    }
                }
            }

            .candidats {
                width: 100%;
                padding: 0px 0px 50px 0px;
                text-align: center;

                .card-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: stretch;
                    padding: 0 0px;
                    width: 100%;
                    margin: 0 auto;
                }
            }
        }
    }

    @media only screen and (max-width: 1400px) {
        .page-wrapper {
            .right-wrapper {
                width: calc(100% - 250px);
    
                h2 {
                    font-size: 35px;
                }
            }
        }
    }
    
    @media only screen and (max-width: 1079px) {
 
        .page-wrapper {

            margin: 150px auto 0;
            .right-wrapper {
                width: calc(100% - 250px);
    
                h2 {
                    font-size: 30px;
    
                    .separator-blue {
                        width: 100px;
                    }
                }

                .filters {
                    padding: 50px 10px;

    
                    .filters-wrapper {
                        flex-direction: column;
                        align-items: center;
                        width: 96%;

                        .offers-wrapper {
                            .choosed-offer {
                                font-size: 20px;
                            }
                        }
    
                        .filter-row {
                            width: 80%;
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
    
    @media only screen and (min-width: 0px) and (max-width: 759px) {
        .page-wrapper {
            .right-wrapper {
                width: calc(100%);
    
    
                h2 {
                    font-size: 25px;
    
                    .separator-blue {
                        width: 50px;
                        margin: 10px auto;
                        position: static;
                    }
                }

                .candidats {
                    padding: 0;
                    .card-wrapper {
                        padding: 0;
                    }
                }
            }
        }
    }
`;

export default AccountCandidatsStyled;