/**** Import des modules ****/
import React, {useState, useEffect} from 'react'
import { useNavigate, Link, Navigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { MdDownload } from "react-icons/md" 
import { saveAs } from 'file-saver'
import axios from 'axios'
import * as qs from 'qs'

/**** Import de components et containers ****/
/**** Import des images ****/
import suitcase from '../../../assets/img/illustration/suitcase.png'
import progaccount from '../../../assets/img/illustration/progaccount.png'

/**** Import des css ****/
import ModalJobInscriptionStyled from './ModalJobInscriptionStyled'
import { Skeleton } from '@mui/material'
import urlBackEnd from '../../../assets/js/urlBackEnd'

const URL = urlBackEnd

const ModalJobInscription = ({prenom, closeModal, jobs, activJobId, user, fetchJobPostuler, userInfo, fetchDemandeFormation, redirectToMyFinancement, fetchCvParticulier, formations, setFullPageLoaderJobInsc, fetchGetScoreRecrutement, scoreRecrutementObject, jobsHome}) => {

  useEffect(fetchGetScoreRecrutement, []);

  let location = useLocation()

  const [files, setFiles] = useState(false)


  const job = location.pathname === "/" ? jobsHome.filter(job => job.id === activJobId) : jobs.filter(job => job.id === activJobId)

  const course = job[0].attributes.formation.data
  const entreprise = job[0].attributes.entreprise.data

  if(redirectToMyFinancement) {
    closeModal('')
    return (<Navigate to="/account/mes-financements" replace />)
  }

  const isUserDidCourse = () => {
    if (job[0].attributes.formation.data !== null) {
      const selectedCourseId = job[0].attributes.formation.data.id;
      const userCourseEnded = userInfo.attributes.formations_termine.data;
      
      let result = []
      let resultData = userCourseEnded.filter(selectedCourse => selectedCourseId === selectedCourse.id)
      let IASCanAll = userCourseEnded.filter(course => course.id === 12)
      result.push(resultData)
      result.push(IASCanAll)

      if (resultData.length > 0 || IASCanAll.length > 0) {
        return true;
      } else {
        return false
      }
    } else {
      return true
    }
  }
  

  const isUserDoingCourse = () => {
    if (job[0].attributes.formation.data !== null) {
      const selectedCourseId = job[0].attributes.formation.data.id;
      const userCourseEnded = userInfo.attributes.en_cours_formations.data;
      
      let result = []
      let resultData = userCourseEnded.filter(selectedCourse => selectedCourseId === selectedCourse.id)
      let IASCanAll = userCourseEnded.filter(course => course.id === 12)
      
      result.push(resultData, IASCanAll)
      
      if (resultData.length > 0 || IASCanAll.length > 0) {
        return true;
      } else {
        return false
      }
    } else {
      return true
    }
  }


  const isItTrueTest = () => {
    if (job[0].attributes.formation.data === null && job[0].attributes.secteurs.data[0].attributes.nom === "Assurance") {
      if(scoreRecrutementObject.length > 0) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  const isItTrueCourse = () => {
    if (isUserDidCourse() === false && isUserDoingCourse() === true) {
      return true
    } else if (isUserDidCourse() === false && isUserDoingCourse() === false) {
      return false
    } 
    else if (isUserDoingCourse() === false && isUserDidCourse() === true) {
      return true
    } else {
      return true
    }
  }

  return (
    <ModalJobInscriptionStyled>
        {isItTrueCourse() === false &&
          <section>
            <h2 className="prenom bold">{user.prenom}</h2>
            <h3 className="answer bold">Pour pouvoir postuler à ce poste, vous devez effectuer cette formation:</h3>
            <div className="card">
              {job !== undefined &&
              <>
              <img src={progaccount} alt="picto d'un écran vidéo bleu" />
              <div className="text-wrapper">
                <h3 className="title-blue bold">{course.attributes.nom}</h3>
                <div className="bottom-wrapper">
                  <p className="padding-bottom">Secteur : {job[0].attributes.secteurs.data[0].attributes.nom}</p>
                  <h4 className="bold capitalize">{course.attributes.prix}€ - {course.attributes.duree}H</h4>
                </div>
              </div>
              </>
              }
            </div>
            <button 
              className="btn-lightblue"
              onClick={() => {
                fetchDemandeFormation(course.id)
              }}
            >Poursuivre</button>
          </section>
          }
          {isItTrueTest() === false &&
          <section>
            <h2 className="prenom bold">{user.prenom}</h2>
            <h3 className="answer bold">Pour pouvoir postuler à ce poste, vous devez effectuer le test de recrutement</h3>
            <Link to="/account/recrutement">
            <button className="btn-lightblue" onClick={() => {
                closeModal('')
              }}>Passer le test de recrutement</button>
            </Link>
          </section>
          }
          {isItTrueCourse() === true && isItTrueTest() === true &&
          <>
          {userInfo.attributes.cv.data !== null ? 
          <>
            {userInfo.attributes.test_ocean.data !== null ?
            <section>
              <h2 className="prenom bold">{user.prenom}</h2>
              <h3 className="answer bold">SOUHAITEZ VOUS POSTULER À CETTE OFFRE ?</h3>
              <div className="card">
                {job !== undefined &&
                <>
                <img src={suitcase} alt="picto d'un écran vidéo bleu" />
                <div className="text-wrapper">
                  <h3 className="title-blue bold">{job[0].attributes.nom}</h3>
                  <div className="bottom-wrapper">
                    <h4 className="bold">Entreprise: {entreprise.attributes.Nom_entreprise}</h4>
                    <p>Lieu: {job[0].attributes.lieu}</p>
                  </div>
                </div>
                </>
                }
              </div>
              <p className="disclaimer">En validant votre candidature, vous acceptez que votre profil et vos données personnelles soient visibles auprès de l’employeur. Sans nouvelles sa part au bout de 3 semaines, considérez que votre candidature n'a pas été retenue.</p>
              <button 
                className="btn-lightblue"
                onClick={() => {
                  setFullPageLoaderJobInsc(true)
                  fetchJobPostuler(job[0].id)
                  closeModal('')
                }}
              >Postuler</button>
            </section>
            :
            <section className='test-perso-modal'>
              <h3 className="answer bold">Pour pouvoir postuler à ce poste, vous devez passer notre test de personnalité:</h3>
              <Link to='/account/ocean' className="btn-test" onClick={() => {
                closeModal('')
              }}>Passez le test de personnalité</Link>
            </section>
            }
          </>
          :
          <section className='upload-cv-modal'>
          <h3 className="answer bold">Pour pouvoir postuler à ce poste, vous devez déposer un CV:</h3>
          <form 
          onSubmit={(e) => {
          e.preventDefault();
          }}
          className="drop-file-wrapper"
          >
        <label htmlFor="file-upload" className="file-upload-btn">
        <div>
          <MdDownload /> Déposer un CV
          <input 
            id="file-upload"
            type="file" 
            name="upload" 
            accept="application/pdf,application/vnd.ms-excel" 
            onChange={(e)=> { 
              setFiles(e.target.files)
              fetchCvParticulier(e.target.files, userInfo.id)
            }}
            style={{
              backgroundColor: "#fff !important"
            }}
          />
        </div>
        </label>
        </form>
          </section>
          }
          </>
          }  
    </ModalJobInscriptionStyled>
  )
}

ModalJobInscription.propTypes = {
  closeModal: PropTypes.func.isRequired,
  prenom: PropTypes.string.isRequired,
}

export default ModalJobInscription

