/**** Import des modules ****/
import React from 'react'


/**** Import de components et containers ****/


/**** Import des css ****/
import ModalLoginLostStyled from './ModalLoginLostStyled'

const ModalLoginLost = ({changeValue, isLogged, fetchLostPassword}) => {
  return (
    <ModalLoginLostStyled>
      <main className="page-connexion">
        <h2 className="bold">Merci de renseigner votre adresse email<span className="separator-blue"></span></h2>
        <form 
          className="account-form-comp"
          onSubmit={(event) => {
            event.preventDefault();
            fetchLostPassword();
          }}
        >
          <label>
            
            <input 
              type="email" 
              name="emailLost" 
              placeholder="Email"
              onChange={(event) => {
                changeValue(event.target.value, event.target.name);
              }}
            />
          </label>
          <label>
            <input 
              type="submit" 
              value="Envoyer" 
              className="lightblue-btn"
            />
          </label>
        </form>
      </main>
    </ModalLoginLostStyled>
  )
}

ModalLoginLost.propTypes = {
}

export default ModalLoginLost

