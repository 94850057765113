import { connect } from 'react-redux'

import ModalCourseInscriptionCollab from '../../../components/ModalInscription/ModalCourseInscriptionCollab'

import { 
    closeModal,
} from '../../../actions/modal'

import { 
    fetchDemandeFormation,
    fetchDemandeFormationCollab,
    setModalInscLoaderOpen,
    setInscCollabStepOne
} from '../../../actions/course'

import {
    changeValue
} from '../../../actions/login'

const mapStateToProps = (state) => ({
    prenom: state.login.session.user.prenom,
    formations: state.course.formations,
    activCourseId: state.course.activCourseId,
    emailInsCollab:state.login.emailInsCollab,
    nomInsCollab:state.login.nomInsCollab,
    prenomInsCollab:state.login.prenomInsCollab,
    redirectToMyFinancement: state.course.redirectToMyFinancement
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: (name) => {
        dispatch(closeModal(name));
    },
    fetchDemandeFormation: (id) => {
        dispatch(fetchDemandeFormation(id));
    },
    changeValue: (value, name) => {
        dispatch(changeValue(value, name));
    },
    fetchDemandeFormationCollab: (id, array) => {
        dispatch(fetchDemandeFormationCollab(id, array));
    },
    setModalInscLoaderOpen: (bool) => {
        dispatch(setModalInscLoaderOpen(bool));
    },
    setInscCollabStepOne: (string) => {
        dispatch(setInscCollabStepOne(string));
    },
})

// Export du retour de connect = le composant enrichi de props
export default connect(mapStateToProps, mapDispatchToProps)(ModalCourseInscriptionCollab)

